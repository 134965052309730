import { AnimatePresence } from 'framer-motion'
import { useRef } from 'react'
import { Container, Wrapper } from './Modal.styled'
import { ModalProps } from './Modal.types'
import { useEffect } from 'react'
import { modalOverlayVariants } from './Modal.data'

function Modal({ showModal, toggleModal, children }: ModalProps) {
    const ref = useRef<HTMLDivElement>(null!)

    useEffect(() => {
        const listener = (event: MouseEvent) => {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                return toggleModal()
            }
        }

        window.addEventListener('mousedown', listener)

        return () => {
            window.removeEventListener('mousedown', listener)
        }
    }, [ref, toggleModal])

    return (
        <AnimatePresence>
            {showModal && (
                <Container
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                    variants={modalOverlayVariants}
                >
                    <Wrapper ref={ref}>
                        {children}
                    </Wrapper>
                </Container>
            )}
        </AnimatePresence>
    )
}

export default Modal