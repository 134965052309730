import { motion } from 'framer-motion'
import styled from 'styled-components'

export const Container = styled(motion.div)`
    z-index: 50;
    position: fixed;
    inset: 0px;
    display: grid;
    place-items: center;
    overflow-y: scroll;
    background-color: rgba(0, 0, 0, 0.9);
    padding: 20px;
`

export const Wrapper = styled.div`
    display: grid;
    place-items: center;
    width: 100%;
    max-width: 500px;
`