import { FlexRowBetween, FormSelect } from 'src/components/common/common'
import styled from 'styled-components'

export const Container = styled.div`
    width: 100%;
    max-width: 400px;
    margin-right: 50px;

    > * + * {
        margin-top: 20px;
    }
`

export const HeaderContainer = styled(FlexRowBetween)`
    align-items: center;
`

export const Heading = styled.h2`
    color: white;
    font-size: 24px;
    font-weight: 700;
`

export const CustomSelect = styled(FormSelect)`
    height: 50px;
    width: 100px;
    padding: 10px;
`

export const ReleaseItemContainer = styled.div`
    max-height: 2115px;
    border-radius: 15px;
    box-sizing: content-box;

    > * + * {
        margin-top: 10px;
    }
`

export const ButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`

export const PageResult = styled.p`
    color: #fff;
`

export const Button = styled.button<{ selected?: boolean; }>`
    color: #ffffff;
    font-weight: 600;
    font-size: 16px;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 9999px;
    background-color: #ffffff10;
    border: 1px solid ${({ selected }) => selected ? '#ffffff90' : '#ffffff10'};
    outline: none;
    font-family: 'Prompt', sans-serif;
    cursor: pointer;
    transition-property: border-color;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 300ms;

    &:disabled {
        cursor: not-allowed;
        color: #ffffff10;
    }

    &:not(:disabled):hover {
        border-color: #ffffff90;
    }
`

export const InputContainer = styled.div`
    font-weight: 600;
    font-size: 16px;
    padding: 15px 10px;
    border-radius: 15px;
    background-color: #ffffff10;
    border: 1px solid #ffffff10;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    width: 90px;
    margin-left: auto;
    margin-right: auto;
    transition-property: border-color;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 300ms;

    &:hover {
        border-color: #ffffff90;
    }
`

export const Input = styled.input`
    color: #ffffff;
    font-weight: 600;
    font-size: 16px;
    background-color: transparent;
    border: 1px solid #ffffff10;
    outline: none;
    width: 30px;
    text-align: center;

    &:focus {
        outline: none;
    }
`