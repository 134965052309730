import { CSSObjectWithLabel } from "react-select"

export const selectStyles = {
    control(base: CSSObjectWithLabel) {
        return {
            ...base,
            backgroundColor: '#FFFFFF10',
            borderColor: '#FFFFFF10',
            borderRadius: '15px',
            padding: '10px',
        }
    },
    valueContainer(base: CSSObjectWithLabel) {
        return {
            ...base,
            padding: '0px',
        }
    },
    input(base: CSSObjectWithLabel) {
        return {
            ...base,
            margin: '0px',
            color: '#FFFFFF'
        }
    },
    menuList(base: CSSObjectWithLabel) {
        return {
            ...base,
            padding: '0px'
        }
    },
    menu(base: CSSObjectWithLabel) {
        return {
            ...base,
            backgroundColor: '#424349',
            borderRadius: '15px',
            overflow: 'hidden',
        }
    },
    option(base: CSSObjectWithLabel) {
        return {
            ...base,
            background: 'transparent',
            '&:hover': {
                background: '#cccccc'
            },
            '&:active': {
                background: '#cccccc'
            },
        }
    },
    multiValue(base: CSSObjectWithLabel) {
        return {
            ...base,
            background: '#FFFFFF50',
        }
    },
    multiValueLabel(base: CSSObjectWithLabel) {
        return {
            ...base,
            color: '#FFF'
        }
    }
}