import { isEqual, omit } from 'lodash'
import {
    CustomFile,
    ReleaseRecordGeneralObjectArrayPropsValues,
    ReleaseRecordObjectArrayPropsValues,
    GenericObject,
    Image,
    WithId,
} from 'src/types/ReleaseRecord'

export const validateImages = ({ images }: { images: CustomFile[] }) => {
    if (!images.length) {
        throw new Error('Form is incomplete. Please provide images')
    }
}

export const getDeleteIdsFromImages = ({
    images,
    selectedImages,
}: {
    images: Image[]
    selectedImages: Image<WithId<CustomFile>>[]
}) => {
    const deletedValues: number[] = []
    const matches: Record<string, boolean> = {}

    images.forEach((image) => {
        matches[image.value] = true
    })

    selectedImages.forEach((selectedImage) => {
        const image = matches[selectedImage.value]

        if (!image) {
            deletedValues.push(Number(selectedImage.id))
        }
    })

    return deletedValues
}

export const getNewValuesFromImages = ({
    images,
    selectedImages,
}: {
    images: Image[]
    selectedImages: Image<WithId<CustomFile>>[]
}) => {
    const newValues: Image[] = []
    const matches: Record<string, boolean> = {}

    selectedImages.forEach((selectedItem) => {
        matches[selectedItem.value] = true
    })

    images.forEach((image) => {
        const item = matches[image.value]

        if (!item) {
            return newValues.push(image)
        }
    })

    return newValues
}

export const getDeletedIdsFromPrimitiveArrays = ({
    releaseRecordItems,
    selectedItems,
    key = 'value',
}: {
    releaseRecordItems: string[]
    selectedItems: GenericObject[]
    key?: keyof ReleaseRecordGeneralObjectArrayPropsValues[number]
}): number[] => {
    const deletedValues: number[] = []
    const matches: Record<string, boolean> = {}

    releaseRecordItems.forEach((recordItem) => {
        matches[recordItem] = true
    })

    selectedItems.forEach((selectedItem) => {
        const item = matches[selectedItem[key] as number]

        if (!item) {
            return deletedValues.push(selectedItem.id as number)
        }
    })

    return deletedValues
}

export const getNewValuesFromPrimitiveArrays = ({
    releaseRecordItems,
    selectedItems,
    key = 'value',
}: {
    releaseRecordItems: string[]
    selectedItems: GenericObject[]
    key?: keyof ReleaseRecordGeneralObjectArrayPropsValues[number]
}): string[] => {
    if (!releaseRecordItems.length) return []

    const newValues: string[] = []
    const matches: Record<string, boolean> = {}

    selectedItems.forEach((selectedItem) => {
        matches[selectedItem[key]] = true
    })

    releaseRecordItems.forEach((recordItem) => {
        const item = matches[recordItem]

        if (!item) {
            return newValues.push(recordItem)
        }
    })

    return newValues
}

export const getDeletedIdsFromObjectArrays = <T extends { id?: number }>({
    releaseRecordItems,
    selectedItems,
    key = 'id',
}: {
    releaseRecordItems: T[]
    selectedItems: T[]
    key?: keyof ReleaseRecordObjectArrayPropsValues[number]
}): number[] => {
    const deletedValues: number[] = []
    const matches: Record<number, boolean> = {}

    releaseRecordItems.forEach((recordItem) => {
        matches[recordItem[key] as number] = true
    })

    selectedItems.forEach((selectedItem) => {
        const item = matches[selectedItem[key] as number]

        if (!item) {
            return deletedValues.push(selectedItem.id as number)
        }
    })

    return deletedValues
}

export const getNewValuesFromObjectArraysWithoutOmitFields = <
    T extends { id?: number }
>({
    releaseRecordItems,
    selectedItems,
    key = 'id',
}: {
    releaseRecordItems: T[]
    selectedItems: T[]
    key?: keyof ReleaseRecordObjectArrayPropsValues[number]
}) => {
    if (!releaseRecordItems.length) return []

    const newValues: T[] = []
    const matches: Record<number, T> = {}

    selectedItems.forEach((selectedItem) => {
        matches[selectedItem[key] as number] = selectedItem
    })

    releaseRecordItems.forEach((recordItem) => {
        const item = matches[recordItem[key] as number]

        if (!item) {
            console.log('Here')
            return newValues.push(recordItem as T)
        }

        const updatedValue = omit(recordItem, ['exists'])

        if (!isEqual(item, updatedValue)) {
            return newValues.push(updatedValue as T)
        }
    })

    return newValues
}

export const getNewValuesFromObjectArrays = <T extends { id?: number }>({
    releaseRecordItems,
    selectedItems,
    key = 'id',
}: {
    releaseRecordItems: T[]
    selectedItems: T[]
    key?: keyof ReleaseRecordObjectArrayPropsValues[number]
}) => {
    if (!releaseRecordItems.length) return []

    const newValues: T[] = []
    const matches: Record<number, T> = {}

    selectedItems.forEach((selectedItem) => {
        matches[selectedItem[key] as number] = selectedItem
    })

    releaseRecordItems.forEach((recordItem) => {
        const item = matches[recordItem[key] as number]

        if (!item) {
            console.log('Here')
            return newValues.push(omitFields(recordItem) as T)
        }

        const updatedValue = omit(recordItem, ['exists'])

        if (!isEqual(item, updatedValue)) {
            // @ts-ignore
            if (!isEqual(item.locations, updatedValue.locations)) {
                // Get the new values from the locations array
                const newLocations =
                    getNewValuesFromObjectArraysWithoutOmitFields({
                        // @ts-ignore
                        releaseRecordItems: updatedValue.locations,
                        // @ts-ignore
                        selectedItems: item.locations,
                    })
                // Get the deleted IDs from the locations array
                // @ts-ignore
                const deletedLocations = item.locations
                    // @ts-ignore
                    .map((location) => location.id)
                    .filter(
                        // @ts-ignore
                        (element) =>
                            // @ts-ignore
                            !updatedValue.locations
                                // @ts-ignore
                                .map((location) => location.id)
                                .includes(element)
                    )
                // @ts-ignore
                return newValues.push({
                    // @ts-ignore
                    ...updatedValue,
                    locations: {
                        connect: newLocations.map((location) => location.id),
                        disconnect: deletedLocations,
                    },
                } as T)
                // @ts-ignore
            } else if (item.locations) {
                // @ts-ignore
                return newValues.push({
                    ...updatedValue,
                    // @ts-ignore
                    locations: {
                        connect: [],
                        disconnect: [],
                    },
                } as T)
            } else {
                return newValues.push(updatedValue as T)
            }
        }
    })

    return newValues
}

export const omitFields = <T extends { id?: number; exists?: boolean }>(
    item: T
) => omit(item, ['id', 'exists'])
