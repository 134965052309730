import Phones from '../../../assets/Phones.png'
import WidgetData from './data'
import {
    Container,
    Heading,
    HeadingContainer,
    PhonesContainer,
    PhonesWrapper,
    SubHeading,
    Text,
    WidgetRow,
    WidgetsContainer,
    WidgetText,
} from './InfoBlock.styled'
import { SocialProof } from '../SocialProof/SocialProof'
import { InfoBlockProps } from './InfoBlock.types'

function InfoBlock({ total }: InfoBlockProps) {
    return (
        <Container>
            <PhonesContainer>
                <PhonesWrapper src={Phones} alt="phone" />
            </PhonesContainer>

            <HeadingContainer>
                <Heading>SwiftSole V3</Heading>
            </HeadingContainer>

            <SocialProof />

            <Text>
                The only tool you need on your iPhone. Secure Sneakers,
                Clothing, and other limited edition items with ease. See some of
                our top features below 🚀
            </Text>

            <WidgetsContainer>
                {WidgetData.map(({ widgetIcon: Icon, widgetText }) => (
                    <WidgetRow key={widgetText}>
                        <Icon />
                        <WidgetText>{widgetText}</WidgetText>
                    </WidgetRow>
                ))}
            </WidgetsContainer>
        </Container>
    )
}

export default InfoBlock
