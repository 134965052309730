import React from 'react';
import { ReactComponent as RemoveIcon } from 'src/assets/remove-icon.svg';
import StarIcon from 'src/components/common/StarIcon/StarIcon';
import { deleteImage, updateField } from 'src/contexts/StaffReleaseFormContext/StaffReleaseFormContext.services';
import { useStaffDispatchContext, useStaffReleaseFormContext } from 'src/hooks/useStaffReleaseForm';
import { CustomFile } from 'src/types/ReleaseRecord';
import Table from '../Table';
import { Image, ImageContainer, ItemContainer, Spacer, TableData, TableText } from '../Table.styled';
import { tableHeadings } from './ImagesTable.data';
import { ImagesTableProps } from './ImagesTable.types';

const ImagesTable = ({ toggleModal }: ImagesTableProps) => {
    const dispatch = useStaffDispatchContext()
    const { formState: { images, cover_image } } = useStaffReleaseFormContext();

    const handleAddItem = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        toggleModal();
    }

    const handleDeleteItem = (url: string) => (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.preventDefault();
        deleteImage(dispatch)(url)
    }

    const handleSelectCoverImage = ({ value, transparent }: CustomFile) => (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.preventDefault();
        if (value === cover_image) return
        updateField(dispatch)('cover_image', value);
        updateField(dispatch)('cover_transparent', transparent);
    }

    return (
        <Table tableHeadings={tableHeadings} handleAddItem={handleAddItem} itemCount={images.length} heading='Image'>
            {
                images.map(({ value, transparent }, idx) => {
                    const splitURL = value.split('/');
                    const name = splitURL[splitURL.length - 1].split('?')[0];

                    return (
                        <tr key={value + idx}>
                            <TableData>
                                <ItemContainer>
                                    <ImageContainer>
                                        <Image
                                            src={value}
                                            alt={value}
                                        />
                                    </ImageContainer>
                                    <TableText>{name}</TableText>
                                </ItemContainer>
                            </TableData>

                            <TableData>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '5px' }}>
                                    <input id="transparent" type="checkbox" checked={transparent} onChange={() => {}} />
                                    <TableText>Enable Transparency</TableText>
                                </div>
                            </TableData>
                            
                            <TableData>
                                <Spacer>
                                    <div style={{ cursor: 'pointer' }} onClick={handleSelectCoverImage({ value, transparent })}>
                                        <StarIcon fill={cover_image === value} />
                                    </div>
                                    <div style={{ cursor: 'pointer' }} onClick={handleDeleteItem(value)}>
                                        <RemoveIcon />
                                    </div>
                                </Spacer>
                            </TableData>
                        </tr>
                    )
                })
            }
        </Table>
    )
}

export default ImagesTable