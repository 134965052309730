import { Notification } from 'src/types/Notification'
import { pick } from 'lodash'
import { SearchReleaseRecord } from 'src/hooks/useSearchReleases'
import { transformImage } from 'src/utils/imageKit'

export const buildNotification = (
    release: SearchReleaseRecord
): Partial<Notification> => {
    const propertiesToPick: Array<keyof SearchReleaseRecord> = [
        'id',
        'cover_image',
        'cover_transparent',
        'cover_color_hex',
    ]
    const notification = pick(release, ...propertiesToPick)

    // const transformedImage = transformImage({
    //     image_url: notification.cover_image as string,
    //     cover_transparent: notification.cover_transparent as boolean,
    //     cover_color_hex: notification.cover_color_hex as string,
    // })

    const updatedNotification: Partial<Notification> = {
        ...notification,
        release_id: notification.id,
        image_url: notification.cover_image as string,
    }

    return updatedNotification
}
