import { QueryKeys } from 'src/constants/QueryKeys';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { axiosInstance } from "src/utils/axios"

const deleteRelease = (id: string) => axiosInstance.delete(`/releases/delete/${id}`);

export const useDeleteRelease = () => {
    const queryClient = useQueryClient();

    const onSuccess = async () => {
        await queryClient.invalidateQueries([QueryKeys.releases]);
    }

    return useMutation(deleteRelease, { onSuccess })
}