import React, { useContext } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { AuthContext } from '../contexts/Auth'
import Loading from './Loading'

const ProtectedRoute = ({ component, ...rest }: any) => {
    const authContext = useContext(AuthContext)
    const routeComponent = (props: any) => (
        !authContext.isLoading ?
        (
            authContext.isAuth ?   
            (
                authContext.userLoaded ? 
                (
                    authContext.validPhone ? 
                    React.createElement(component, props) : 
                    <Redirect to={{ pathname: '/setup' }} />
                ) : 
                React.createElement(Loading, props)
            ) :
            window.location.href = "/api/v1/auth/loginRedirect?path=" + window.location.pathname
        ) :
        React.createElement(Loading, props)
    );
    
    return <Route {...rest} render={routeComponent} />;
};

export default ProtectedRoute