import styled from "styled-components"

export const Item = styled.div`
    display: flex;
    align-items: center;
    padding: 10px;
    gap: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    
    &:hover {
        background-color: #FFFFFF10;
    }
`

export const Thumbnail = styled.img`
    width: 55px;
    height: 40px;

`