import styled, { css } from 'styled-components'
import { shimmer } from 'src/components/common/common'

const transition = css`
    height: calc(100% + 20px);
    width: calc(100% + 20px);
    box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1),
        0 8px 10px -6px rgb(0 0 0 / 0.1) #00000020;
    border: 1px solid #978bff;
    background-color: rgba(151, 139, 255, 0.25);
`

export const ReleaseItemContainer = styled.div<{ selected: boolean }>`
    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 5px;
    position: relative;
    border: 0 solid #e5e7eb;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;

    &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 1;
        height: 100%;
        width: 100%;
        transform: translate(-50%, -50%);
        border-top-left-radius: 35px;
        border-top-right-radius: 35px;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        transition-property: height, width, border, background-color;
        transition-timing-function: ease-in;
        transition-duration: 0.15s;
        ${({ selected }) => selected && transition}
    }

    &:hover::before {
        ${transition}
    }
`

export const ReleaseItemImageContainer = styled.div<{
    cover_color_hex?: string
}>`
    position: relative;
    z-index: 2;
    width: 100%;
    display: grid;
    place-items: center;
    overflow: hidden;
    border-radius: 25px;
    aspect-ratio: 1 / 1;
    background-color: ${({ cover_color_hex }) =>
        `#${cover_color_hex}` || 'rgb(83, 99, 94)'};
    background-image: radial-gradient(
        circle at 50% 0%,
        rgba(255, 255, 255, 0.25) 0%,
        rgba(255, 255, 255, 0) 75%
    );
`

export const ReleaseItemImage = styled.img`
    width: 100%;
    position: absolute;
    object-fit: contain;
    padding: 15px;
`

export const ShadowContainer = styled.span`
    > * {
        transform: translateY(40px);
    }
`

export const ReleaseItemTitle = styled.h3`
    font-weight: semibold;
    font-size: 11px;
    color: #f7eff3;
    text-align: center;
`

export const SkeletonContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
`

export const SkeletonImage = styled.div`
    width: 137px;
    height: 137px;
    border-radius: 25px;
    background-color: #ffffff10;
    ${shimmer}
`

export const SkeletonTitle = styled.div`
    height: 20px;
    width: 130px;
    border-radius: 5px;
    background-color: #ffffff10;
    ${shimmer}
`
