import z from 'zod';

export const InstructionSchema = z.object({
    title: z.string({
        invalid_type_error: "Title must be a string",
    }).min(1, "Title is required"),
    description: z.string({
        invalid_type_error: "Description must be a string",
    }).min(1, "Description is required"),
    id: z.number({
        invalid_type_error: "ID must be a number",
    }).optional()
})

