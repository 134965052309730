import { Button, Container, Text } from './Tooltip.styled'
import { TooltipProps } from './Tooltip.types'

const Tooltip = ({ children, hoverText, ...rest }: TooltipProps) => {
    return (
        <Container>
            <Text>{hoverText}</Text>
            <Button {...rest}>{children}</Button>
        </Container>
    )
}

export default Tooltip
