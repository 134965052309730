import DateTimePicker from "react-datetime-picker"
import { CalendarProps } from "src/types/Calendar"

type CustomDateTimePickerProps = Parameters<typeof DateTimePicker>[0] & CalendarProps

const CustomDateTimePicker = (props: CustomDateTimePickerProps) => {

    const defaultProps: CustomDateTimePickerProps = {
        calendarType: 'US',
        disableClock: true,
        ...props
    }

    return (
        <DateTimePicker {...defaultProps} />
    )
}

export default CustomDateTimePicker;