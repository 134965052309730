import { Container, IFrame } from './SuccessVideo.styled'

function SuccessVideo() {
    return (
        <Container>
            <IFrame
                frameBorder="0"
                allowFullScreen
                title="YouTube video player"
                src="https://www.youtube.com/embed/8sJGfMxCHYE"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            />
        </Container>
    )
}

export default SuccessVideo

