import React, { useState, useEffect, ReactNode } from 'react';
import axios from 'axios';

export interface IAuthContext {
    userInfo: any;
    isAuth: boolean;
    isLoading: boolean;
    validPhone: boolean;
    userLoaded: boolean;
    setUserInfo: (data: any) => void;
    setLoading: (loading: boolean) => void;
};
const AuthContextDefaults: IAuthContext = {
    userInfo: [],
    isAuth: false, 
    isLoading: true, 
    validPhone: false, 
    userLoaded: false,
    setUserInfo: (data: any) => null,
    setLoading: (loading: boolean) => null
};
export const AuthContext = React.createContext(AuthContextDefaults);
const Auth: React.FC<ReactNode> = ({ children }): JSX.Element => {
    const [userInfo, setUserInfoLocal] = useState<any>({});
    const [isAuth, setIsAuth] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const [validPhone, setValidPhone] = useState(false);
    const [userLoaded, setUserLoaded] = useState(false);

    useEffect(() => {
        checkAuth()
    }, []);

    useEffect(() => {
        const checkPhone = async () => {
            axios.get("/api/v1/users/@me", { withCredentials: true }).then(u => {
                let data = u.data
                setUserInfo(data);
                if (data && data.phone && data.email) {
                    setValidPhone(true);
                    setUserLoaded(true);
                } else {
                    setValidPhone(false);
                    setUserLoaded(true);
                }
            }).catch(err => {
                setValidPhone(false);
                setUserLoaded(true);
            });
        };

        if (isAuth && !validPhone) checkPhone();
    }, [isAuth, validPhone]);

    const checkAuth = async () => {
        axios.post("/api/v1/users/check", { path: window.location.pathname }, { withCredentials: true }).then(u => {
            setIsAuth(true);
            setIsLoading(false);
        }).catch(err => {
            setIsAuth(false);
            setIsLoading(false);
        })
    };

    const setUserInfo = (data: any) => setUserInfoLocal(data);
    const setLoading = (loading: boolean) => setIsLoading(loading);
    return (
        <AuthContext.Provider value={{ userInfo, isAuth, isLoading, validPhone, userLoaded, setUserInfo, setLoading }}>
            {children}
        </AuthContext.Provider>
    );
};

export default Auth;