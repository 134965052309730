import { useStaffDispatchContext, useStaffReleaseFormContext } from 'src/hooks/useStaffReleaseForm';
import Table from '../Table'
import { Spacer, TableData, TableText } from '../Table.styled';
import { tableHeadings } from './ItemsTable.data';
import { ReactComponent as EditIcon } from 'src/assets/edit.svg'
import { ItemsTableProps } from './ItemsTable.types';
import { Image, ImageContainer, ItemContainer } from './ItemsTable.styled';
import { ReactComponent as RemoveIcon } from 'src/assets/remove-icon.svg';
import { deleteItem } from 'src/contexts/StaffReleaseFormContext/StaffReleaseFormContext.services';
import { Item } from 'src/types/ReleaseRecord';

const ItemsTable = ({ toggleModal, setSelectedItem }: ItemsTableProps) => {
    const dispatch = useStaffDispatchContext()
    const { formState: { items } } = useStaffReleaseFormContext();

    const handleAddItem = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        setSelectedItem({} as Item);
        toggleModal();
    }

    const handleDeleteItem = (title: string) => (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.preventDefault();
        deleteItem(dispatch)(title);
    }

    return (
        <Table handleAddItem={handleAddItem} itemCount={items.length} tableHeadings={tableHeadings}>
            {
                items.map(({ title, brand, sku, category, retail, resell, url, image, id }) => (
                    <tr key={id}>
                        <TableData>
                            <ItemContainer>
                                <ImageContainer>
                                    <Image
                                        src={image}
                                        alt={title}
                                    />
                                </ImageContainer>
                                <TableText>{title}</TableText>
                            </ItemContainer>
                        </TableData>
                        <TableData>
                            <TableText>{brand}</TableText>
                        </TableData>
                        <TableData>
                            <TableText>{sku}</TableText>
                        </TableData>
                        <TableData>
                            <TableText>{category}</TableText>
                        </TableData>
                        <TableData>
                            <TableText>${retail}</TableText>
                        </TableData>
                        <TableData>
                            <TableText>${resell}</TableText>
                        </TableData>
                        <TableData>
                            <Spacer>
                                <TableText>{url}</TableText>
                                <div style={{ cursor: 'pointer' }} onClick={() => {
                                    setSelectedItem({ id, title, brand, sku, category, retail, resell, url, image });
                                    toggleModal();
                                }}>
                                    <EditIcon />
                                </div>
                                <div style={{ cursor: 'pointer' }} onClick={handleDeleteItem(title)}>
                                    <RemoveIcon />
                                </div>
                            </Spacer>
                        </TableData>
                    </tr>
                ))
            }
        </Table>
    )
}

export default ItemsTable