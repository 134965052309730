import ReactS3Client from 'react-aws-s3-typescript'
import { s3Config } from 'src/utils/aws'
import { NewFile } from './FileUpload.types'

const allowedTypes = ['image/png', 'image/jpg', 'image/jpeg']

export const getFilesLimit = (label: string) => {
    const lowerCasedLabel = label.toLowerCase()
    const fileLimit = lowerCasedLabel === 'cover image' || lowerCasedLabel === 'image' ? 1 : 5
    return fileLimit
}

export const fileIsOverLimit = (limit: number, filesTotal: number) => {
    return filesTotal > limit
}

export const fileIsValid = (type: string) => {
    if (!allowedTypes.includes(type)) {
        throw new Error(`Error: Form does not accept files of type "${type}"`)
    }
}

export const uploadFileToS3 = (file: File) => {
    const s3 = new ReactS3Client(s3Config)
    return s3.uploadFile(file)
}

export const readFile = (file: File) => {
    return new Promise<NewFile>(resolve => {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => {
            const url = reader.result as string;
            resolve({ url, name: file.name, transparent: false });
        }
    })
}