import styled from 'styled-components'

const Container = styled.div`
    width: 100%;
    margin: 0 auto;
    max-width: 48rem;

    > * + * {
        margin-top: 1.5rem;
    }
`

const Heading = styled.h1`
    font-size: 1.875rem;
    font-weight: 600;
`

const Subheading = styled.h3`
    font-size: 1.25rem;
    font-weight: 600;
`

const OrderedList = styled.ol`
    list-style-type: numbered;
    padding-left: 2rem;

    > * + * {
        margin-top: 1rem;
    }
`

const ListItem = styled.li`
    color: #FFFFFF60;
`

const Text = styled.p`
    color: #FFFFFF60;
`

const Link = styled.a`
    color: #3b82f6;

    &:hover {
        text-decoration: underline;
    }
`

const ToS = () => {
    return (
        <Container>
            <Heading>SwiftSole Development LLC Terms of Service</Heading>
            <Text>
                These Terms of Service govern your use of the website located at{' '}
                <Link href="https://swiftsole.app">https://swiftsole.app</Link> and any
                related services provided by SwiftSole Development LLC.
            </Text>
            <Text>
                By accessing <Link href="https://swiftsole.app">https://swiftsole.app</Link>,
                you agree to abide by these Terms of Service and to comply with all
                applicable laws and regulations. If you do not agree with these Terms of
                Service, you are prohibited from using or accessing this website or
                using any other services provided by SwiftSole Development LLC.
            </Text>
            <Text>
                We, SwiftSole Development LLC, reserve the right to review and amend any
                of these Terms of Service at our sole discretion. Upon doing so, we will
                update this page. Any changes to these Terms of Service will take effect
                immediately from the date of publication.
            </Text>
            <Text>These Terms of Service were last updated on 13 December 2021.</Text>
            <Subheading>Refund Policy</Subheading>
            <Text>
                All sales are final, and the Company does not offer any money-back
                guarantees. You recognize and agree that you shall not be entitled to a
                refund for any purchase under any circumstances.
            </Text>
            <Subheading>Limitations of Use</Subheading>
            <Text>
                By using this website, you warrant on behalf of yourself, your users,
                and other parties you represent that you will not:
            </Text>
            <OrderedList>
                <ListItem>
                modify, copy, prepare derivative works of, decompile, or reverse
                engineer any materials and software contained on this website;
                </ListItem>
                <ListItem>
                remove any copyright or other proprietary notations from any materials
                and software on this website;
                </ListItem>
                <ListItem>
                transfer the materials to another person or “mirror” the materials on
                any other server;
                </ListItem>
                <ListItem>
                knowingly or negligently use this website or any of its associated
                services in a way that abuses or disrupts our networks or any other
                service SwiftSole Development LLC provides;
                </ListItem>
                <ListItem>
                use this website or its associated services to transmit or publish any
                harassing, indecent, obscene, fraudulent, or unlawful material;
                </ListItem>
                <ListItem>
                use this website or its associated services in violation of any
                applicable laws or regulations;
                </ListItem>
                <ListItem>
                use this website in conjunction with sending unauthorized advertising
                or spam;
                </ListItem>
                <ListItem>
                harvest, collect, or gather user data without the user’s consent; or
                </ListItem>
                <ListItem>
                use this website or its associated services in such a way that may
                infringe the privacy, intellectual property rights, or other rights of
                third parties.
                </ListItem>
            </OrderedList>
            <Subheading>Intellectual Property</Subheading>
            <Text>
                The intellectual property in the materials contained in this website are
                owned by or licensed to SwiftSole Development LLC and are protected by
                applicable copyright and trademark law. We grant our users permission to
                download one copy of the materials for personal, non-commercial
                transitory use.
            </Text>
            <Text>
                This constitutes the grant of a license, not a transfer of title. This
                license shall automatically terminate if you violate any of these
                restrictions or the Terms of Service, and may be terminated by SwiftSole
                Development LLC at any time.
            </Text>
            <Subheading>Liability</Subheading>
            <Text>
                Our website and the materials on our website are provided on an 'as is'
                basis. To the extent permitted by law, SwiftSole Development LLC makes
                no warranties, expressed or implied, and hereby disclaims and negates
                all other warranties including, without limitation, implied warranties
                or conditions of merchantability, fitness for a particular purpose, or
                non-infringement of intellectual property, or other violation of rights.
            </Text>
            <Text>
                In no event shall SwiftSole Development LLC or its suppliers be liable
                for any consequential loss suffered or incurred by you or any third
                party arising from the use or inability to use this website or the
                materials on this website, even if SwiftSole Development LLC or an
                authorized representative has been notified, orally or in writing, of
                the possibility of such damage.
            </Text>
            <Text>
                In the context of this agreement, “consequential loss” includes any
                consequential loss, indirect loss, real or anticipated loss of profit,
                loss of benefit, loss of revenue, loss of business, loss of goodwill,
                loss of opportunity, loss of savings, loss of reputation, loss of use
                and/or loss or corruption of data, whether under statute, contract,
                equity, tort (including negligence), indemnity, or otherwise.
            </Text>
            <Text>
                Because some jurisdictions do not allow limitations on implied
                warranties, or limitations of liability for consequential or incidental
                damages, these limitations may not apply to you.
            </Text>
            <Subheading>Accuracy of Materials</Subheading>
            <Text>
                The materials appearing on our website are not comprehensive and are for
                general information purposes only. SwiftSole Development LLC does not
                warrant or make any representations concerning the accuracy, likely
                results, or reliability of the use of the materials on this website, or
                otherwise relating to such materials or on any resources linked to this
                website.
            </Text>
            <Subheading>Links</Subheading>
            <Text>
                SwiftSole Development LLC has not reviewed all of the sites linked to
                its website and is not responsible for the contents of any such linked
                site. The inclusion of any link does not imply endorsement, approval, or
                control by SwiftSole Development LLC of the site. Use of any such linked
                site is at your own risk and we strongly advise you make your own
                investigations with respect to the suitability of those sites.
            </Text>
            <Subheading>Right to Terminate</Subheading>
            <Text>
                We may suspend or terminate your right to use our website and terminate
                these Terms of Service immediately upon written notice to you for any
                breach of these Terms of Service.
            </Text>
            <Subheading>Severance</Subheading>
            <Text>
                Any term of these Terms of Service which is wholly or partially void or
                unenforceable is severed to the extent that it is void or unenforceable.
                The validity of the remainder of these Terms of Service is not affected.
            </Text>
            <Subheading>Governing Law</Subheading>
            <Text>
                These Terms of Service are governed by and construed in accordance with
                the laws of Texas. You irrevocably submit to the exclusive jurisdiction
                of the courts in that State or location.
            </Text>
        </Container>
    )
}

export default ToS
