import styled from "styled-components";
import { FlexRowBetween } from "../common";

export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`

export const LabelContainer = styled(FlexRowBetween)`
    margin-bottom: 5px;
    color: rgba(255,255,255,.7);
    font-size: 14px;
    font-weight: 400;
`

export const Wrapper = styled.label`
    border-radius: 15px;
    background-color: #00000070;
    border: 2px dashed #FFFFFF20;
    outline: none;
    width: 100%;
    cursor: pointer;
    height: 100%;
`

export const DropZoneContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 35px;
    gap: 10px;
`

export const Text = styled.p`
    font-weight: 500;
    color: rgba(255,255,255,.7);
    font-size: 14px;
    font-weight: 400;
`

export const FileInput = styled.input`
    display: none;
`