import { useState } from 'react'
import styled from 'styled-components'

import axios from 'axios'
import dayjs from 'dayjs'
import { useEffect } from 'react'
import editIcon from '../assets/edit-icon.svg'
import trashIcon from '../assets/trash-icon.svg'
import CreateReleaseForm from '../components/CreateReleaseForm'

const Page = styled.div`
  display: flex;
  width: 1240px;
  margin: 0 auto;
  max-width: 90vw;

  @media only screen and (max-width: 1080px) {
    max-width: 97vw;
  }

  @media only screen and (max-width: 850px) {
    max-width: 100vw;
    flex-direction: column;
  }
`

const Col = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
`

const Card = styled(Col)`
  background: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
  padding: 20px;
  margin: 0 auto;
  width: 100%;

  @media only screen and (max-width: 850px) {
    max-width: 100vw;
    box-sizing: border-box;
    margin-right: 0 !important;
    margin-bottom: 20px;
    border-radius: 0;
    width: 100%!important;
  }
`

const TopRow = styled.div`
  display: flex;
`

const Icon = styled.img`
  margin-right: 10px;
  width: 20px;
`

const Title = styled.span`
  font-weight: bold;
  font-size: 18px;
`

const Separator = styled.div`
  margin-top: 20px;
  margin-bottom: 10px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 0.5px;
  height: 1px;
`

const Button = styled.button`
  margin-top: 20px;
  background: linear-gradient(180deg,#ff6895 0%,#ff425e 100%,#ff3951 100%);
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  font-weight: bold;
  text-align: center;
  padding: 12px 0;
  text-decoration: none;
  color: white;
  border: 0px;
`

const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    font-size: 14px;
    color: #333;
    margin-top: 20px;
    margin-bottom: 20px;
`

const TH = styled.th`
    padding: 10px;
    text-align: left;
    color: white;
`

const TD = styled.td<{ flex?: boolean }>`
    padding: 10px;
    text-align: left;
    color: white;
    display: ${({ flex }) => flex ? 'flex' : 'table-cell'};
`

const TR = styled.tr`
    border-bottom: 1px solid #3c3c3c;
`


const AdminReleases = () => {
  const [releasesInfo, setReleasesInfo] = useState<any | undefined>(undefined)
  const [isCreating, setIsCreating] = useState<boolean>(false)
  const [editRelease, setEditRelease] = useState<string>('')
  const [isEditing, setIsEditing] = useState<boolean>(false)

  useEffect(() => {
    try {
      axios.get(`/api/v1/releases`, { withCredentials: true }).then(u => {
        let data = u.data
        console.log('release', data)
        setReleasesInfo(data)
      })
    } catch (err) {
      console.log(err)
      setReleasesInfo(undefined)
    }
  }, [])

  const deleteRelease = (password: string) => {
    try {
      axios.delete(`/api/v1/releases/${password}`, { withCredentials: true }).then(u => {
        let data = u.data
        console.log('release', data)
        if (data) {
          setReleasesInfo(data)
        } else {
          setReleasesInfo(undefined)
        }
      })
    } catch (err) {
      setReleasesInfo(undefined)
    }
  }

  return (
    <Page>
        <Card>
          <TopRow>
            <Col>
              <Title>Releases</Title>
              <Button onClick={() => setIsCreating(!isCreating)}>Create</Button>
            </Col>
          </TopRow>

            {(isCreating || isEditing) && <>
                <Separator />
                <CreateReleaseForm editRelease={editRelease} isEditing={isEditing} setIsEditing={setIsEditing} setIsCreating={setIsCreating} setReleasesInfo={setReleasesInfo} />
            </>}
            
          <Separator />

          <Table>
            <thead>
                <TR>
                    <TH>Product</TH>
                    <TH>Password</TH>
                    <TH>Release Date</TH>
                    <TH>Stock</TH>
                    <TH>Queue</TH>
                    <TH>Trial days</TH>
                    <TH>Action</TH>
                </TR>
            </thead>
            <tbody>
                {releasesInfo && releasesInfo.map((release: any) => {  
                    return (
                        <TR key={release.id}>
                            <TD>{release.product}</TD>
                            <TD>{release.password}</TD>
                            <TD>{dayjs.unix(Math.round(release.startsAt / 1000)).format('MMMM DD, YYYY HH:mm:ss A')}</TD>
                            <TD>{release.stock}</TD>
                            <TD>{release.queue ? 'Enabled' : 'Disabled'}</TD>
                            <TD>{release.trialDays || '0'}</TD>
                            <TD flex>
                              <Icon src={editIcon} onClick={() => { setEditRelease(release); setIsEditing(true) }}/>
                              <Icon src={trashIcon} onClick={() => deleteRelease(release.password)}/>
                            </TD>
                        </TR>
                    )
                })}
            </tbody>
          </Table>
        </Card> 
    </Page>
  )
}

export default AdminReleases
