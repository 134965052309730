import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import axios from 'axios'
import { toast } from 'react-hot-toast'
import { Reservation } from 'src/pages/StaffReservationsPage'
import FormTextInput from 'src/components/common/FormTextInput/FormTextInput'
import {
    DatePicker,
    Label,
} from 'src/components/notifications/NotificationForm/NotificationForm.styled'
import RequiredText from 'src/components/common/RequiredText/RequiredText'
import { FormDatePicker } from 'src/components/common/common'

const Column = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-left: 60px;
    padding-right: 40px;
    height: calc(100vh - 225px);
    overflow-y: auto;

    &::-webkit-scrollbar {
        display: none;
    }

    * {
        flex-shrink: 0;
    }
`

const Heading = styled.span`
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
`

const FormRow = styled.div`
    display: flex;
    margin-bottom: 20px;
    gap: 10px;

    * {
        flex-shrink: 1;
    }
`

const SaveButton = styled.span`
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 18px;
    cursor: pointer;
    border-radius: 30px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
    background: linear-gradient(
        180deg,
        rgba(127, 112, 255, 1) 0%,
        rgba(107, 90, 255, 1) 100%
    );
    font-size: 16px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 20px;
    s &:hover {
        filter: brightness(1.25);
    }

    transition: 0.1s filter ease-out;
`

interface ReservationEditFormProps {
    isEditing: boolean
    editingReservation?: Reservation | null
    shouldRevalidate: () => void
    shouldRevertToHomeView: () => void
}

// {
//     rate: number;
//     shipping_rate: number;
//     notes: string | null;
//     reservationRecordId: string;
//     closeDate: Date;
//     openDate: Date;
//     estimatedSuccess: number;
//     sizes: {
//         id: number;
//         title: string;
//     }[];
// }
const ReservationEditForm = (props: ReservationEditFormProps) => {
    const [rate, setRate] = useState(0)
    const [subscriberRate, setSubscriberRate] = useState(0)
    const [shippingRate, setShippingRate] = useState(0)
    const [notes, setNotes] = useState('')
    const [releaseId, setReleaseId] = useState('')
    const [closeDate, setCloseDate] = useState(new Date())
    const [openDate, setOpenDate] = useState(new Date())
    const [estimatedSuccess, setEstimatedSuccess] = useState(0)
    const [sizes, setSizes] = useState<{ id: number | null; title: string }[]>(
        []
    )

    const onSaveClick = async () => {
        if (props.editingReservation) {
            await toast.promise(
                axios.put(
                    `https://pro-alerts-api-production.up.railway.app/api/v2/reservations/update`,
                    {
                        reservationId:
                            props.editingReservation.reservationRecordId,
                        closeDate: closeDate,
                        openDate: openDate,
                        estimatedSuccess: estimatedSuccess,
                        rate: rate,
                        subscriberRate: subscriberRate,
                        shippingRate: shippingRate,
                        notes: notes,
                    },
                    {
                        headers: {
                            Authorization:
                                'a1d3b746-0702-4d5b-900e-313dbf9ab0a2',
                        },
                    }
                ),
                {
                    loading: 'Updating reservation...',
                    success: 'Reservation updated!',
                    error: 'Failed to update reservation',
                }
            )
            props.shouldRevalidate()
        } else {
            await toast.promise(
                axios.post(
                    'https://pro-alerts-api-production.up.railway.app/api/v2/reservations/create',
                    {
                        closeDate: closeDate,
                        openDate: openDate,
                        estimatedSuccess: estimatedSuccess,
                        rate: rate,
                        subscriberRate: subscriberRate,
                        shippingRate: shippingRate,
                        notes: notes,
                        releaseId: releaseId,
                        sizes: sizes.map((size) => size.title),
                    },
                    {
                        headers: {
                            Authorization:
                                'a1d3b746-0702-4d5b-900e-313dbf9ab0a2',
                        },
                    }
                ),
                {
                    loading: 'Creating reservation...',
                    success: 'Reservation created!',
                    error: 'Failed to create reservation',
                }
            )
            props.shouldRevalidate()
            props.shouldRevertToHomeView()
        }
    }

    useEffect(() => {
        if (props.isEditing) {
            setRate(props.editingReservation?.rate || 0)
            setSubscriberRate(props.editingReservation?.subscriber_rate || 0)
            setShippingRate(props.editingReservation?.shipping_rate || 0)
            setNotes(props.editingReservation?.notes || '')

            setCloseDate(props.editingReservation?.closeDate || new Date())
            setOpenDate(props.editingReservation?.openDate || new Date())
            setEstimatedSuccess(props.editingReservation?.estimatedSuccess || 0)
            setSizes(props.editingReservation?.sizes || [])
            setReleaseId(props.editingReservation?.releaseRecordId || '')
        } else {
            setRate(0)
            setSubscriberRate(0)
            setShippingRate(0)
            setNotes('')
            setCloseDate(new Date())
            setOpenDate(new Date())
            setEstimatedSuccess(0)
            setSizes([])
            setReleaseId('')
        }
    }, [props.isEditing, props.editingReservation])

    const handleOpenDateChange = (date: Date) => setOpenDate(date)
    const handleCloseDateChange = (date: Date) => setCloseDate(date)

    return (
        <>
            <Column>
                <Heading>
                    {props.isEditing
                        ? `Edit Reservation`
                        : 'Create a new reservation'}
                </Heading>
                <FormRow>
                    <FormTextInput
                        label="Free Slot Rate"
                        value={rate.toString()}
                        onChange={(e) => setRate(parseFloat(e.target.value))}
                        placeholder="Enter Rate"
                        required
                    />
                    <FormTextInput
                        label="Subscriber Slot Rate"
                        value={subscriberRate.toString()}
                        onChange={(e) =>
                            setSubscriberRate(parseFloat(e.target.value))
                        }
                        placeholder="Enter Rate"
                        required
                    />
                </FormRow>
                <FormRow>
                    <FormTextInput
                        label="Release Record ID"
                        value={releaseId}
                        onChange={(e) => setReleaseId(e.target.value)}
                        placeholder="Enter Release Record ID"
                        required
                    />
                    <FormTextInput
                        label="Estimated Success"
                        value={estimatedSuccess.toString()}
                        onChange={(e) =>
                            setEstimatedSuccess(parseFloat(e.target.value))
                        }
                        placeholder="Enter Estimated Success"
                        required
                    />
                </FormRow>
                <FormRow>
                    <FormTextInput
                        label="Shipping Rate (estimated)"
                        value={shippingRate.toString()}
                        onChange={(e) =>
                            setShippingRate(parseFloat(e.target.value))
                        }
                        placeholder="Enter Shipping Rate"
                        required
                    />
                    <FormTextInput
                        label="Notes"
                        value={notes}
                        onChange={(e) => setNotes(e.target.value)}
                        placeholder="Enter Notes"
                    />
                </FormRow>
                <FormRow>
                    <div style={{ width: '100%' }}>
                        <Label htmlFor="open_date">
                            <p>Open Date</p>
                            <RequiredText />
                        </Label>
                        <FormDatePicker
                            value={new Date(openDate)}
                            onChange={handleOpenDateChange}
                        />
                    </div>
                    <div style={{ width: '100%' }}>
                        <Label htmlFor="open_date">
                            <p>Close Date</p>
                            <RequiredText />
                        </Label>
                        <FormDatePicker
                            value={new Date(closeDate)}
                            onChange={handleCloseDateChange}
                        />
                    </div>
                </FormRow>
                {/* TODO: sizes */}
                <SaveButton onClick={onSaveClick}>
                    {props.isEditing ? 'Save' : 'Create'}
                </SaveButton>
            </Column>
        </>
    )
}

export default ReservationEditForm
