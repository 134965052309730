import styled from "styled-components"

export const Container = styled.div`
    width: 100%;
    max-width: 440px;
    padding: 30px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 30px;
`

export const Heading = styled.h1`
    color: #FFFFFF;
    font-weight: 700;
    font-size: 22px;
    text-align: center;
    
    @media only screen and (min-width: 1024px) {
        font-size: 30px;
        text-align: left;
    }
`

export const Text = styled.p`
    color: #FFFFFF90;
    font-weight: 500;
    margin-bottom: 25px;
    text-align: center;
    font-size: 14px;

    @media only screen and (min-width: 1024px) {
        text-align: left;
        font-size: 16px;
    }
`

export const ButtonsContainer = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;

    > * + * {
        margin-left: 0px;
        margin-top: 10px;
    }

    @media only screen and (min-width: 1024px) {
        flex-direction: row;

        > * + * {
            margin-left: 10px;
            margin-top: 0px;
        }
    }
`

const Button = styled.button`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    color: #FFFFFF;
    height: 60px;
    font-size: 18px;
    font-weight: 700;
    line-height: 20px;
    border-radius: 30px;
    box-shadow: 0 5px 10px 0 rgba(0,0,0,0.20);
    padding: 20px;
    cursor: pointer;
   

    > * + * {
        margin-left: 10px;
    }
`

export const DiscordButton = styled(Button)`
    background-image: linear-gradient(180deg, #818DFF 0%, #6E7CFF 100%);
`

export const DownloadButton  = styled(Button)`
    background-image: linear-gradient(180deg, #00EA4B 0%, #00D025 100%);
`