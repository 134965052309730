import axios from 'axios'
import { useContext, useState } from 'react'
import { load } from 'recaptcha-v3'
import FormButton from 'src/components/common/FormButton/FormButton'
import { AuthContext, IAuthContext } from '../../../contexts/Auth'
import sleep from '../../../utils/sleep'
import { ErrorAlert, SuccessAlert } from '../../common/common'
import CardWithSadEmoji from '../CardWithSadEmoji/CardWithSadEmoji'
import CustomRadio from '../CustomRadio/CustomRadio'
import {
    CancelContainer,
    CardInfoContainer,
    Heading,
    HeadingContainer,
    InfoHeading,
    RadioContainer,
    Subheading,
    TextArea,
} from './SorryCard.styled'
import { SoryCardTypes } from './SorryCard.types'

const SorryCard = ({ toggleModal }: SoryCardTypes) => {
    const { setUserInfo } = useContext<IAuthContext>(AuthContext)
    const [reason, setReason] = useState('')
    const [additional, setAdditional] = useState('')
    const [{ error, message }, setState] = useState({
        error: '',
        message: '',
    })

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
        setReason(event.target.value)

    const handleContinue = async () => {
        try {
            const { status, data } = await axios.post(
                'api/v1/users/subscription/pause',
                { product: 'swiftsole_v3' },
                { withCredentials: true }
            )

            if (status === 200) setUserInfo(data)

            const recaptcha = await load(
                '6Le6inQdAAAAADdk84HikP5jAGrMHHIUhHXC-cXJ',
                {
                    useRecaptchaNet: true,
                    useEnterprise: true,
                }
            )

            const token = await recaptcha.execute('survey')

            const res = await axios.post(
                '/api/v1/users/survey',
                {
                    type: 'cancel',
                    data: {
                        reason,
                        additional,
                    },
                    token,
                },
                { withCredentials: true }
            )

            if (res.status === 200) {
                setState({
                    error: '',
                    message: 'Subscription successfully cancelled.',
                })
                await sleep(2000)
                toggleModal()
            }
        } catch (err) {
            let errorMessage = ''

            if (axios.isAxiosError(err)) {
                errorMessage = err.response?.data?.message
            } else {
                errorMessage = (err as Error).message
            }

            setState({ error: errorMessage, message: '' })
        }
    }

    return (
        <CardWithSadEmoji>
            {error && <ErrorAlert>{error}</ErrorAlert>}
            {message && <SuccessAlert>{message}</SuccessAlert>}

            <CardInfoContainer>
                <HeadingContainer>
                    <Heading>We’re sorry to see you go…</Heading>
                    <Subheading>
                        Before you cancel, please let us know the reason you’re
                        leaving. Which of these describes your experience?
                    </Subheading>
                </HeadingContainer>

                <RadioContainer>
                    <CustomRadio
                        value="reason1"
                        name="reason"
                        label="Technical issues"
                        checked={reason === 'reason1'}
                        handleChange={handleChange}
                    />

                    <CustomRadio
                        value="reason2"
                        name="reason"
                        label="Too expensive"
                        checked={reason === 'reason2'}
                        handleChange={handleChange}
                    />

                    <CustomRadio
                        value="reason3"
                        name="reason"
                        label="Switching to another account"
                        checked={reason === 'reason3'}
                        handleChange={handleChange}
                    />

                    <CustomRadio
                        value="reason4"
                        name="reason"
                        label="No longer need"
                        checked={reason === 'reason4'}
                        handleChange={handleChange}
                    />

                    <CustomRadio
                        value="reason5"
                        name="reason"
                        label="Unsure how to use properly"
                        checked={reason === 'reason5'}
                        handleChange={handleChange}
                    />

                    <CustomRadio
                        value="reason6"
                        name="reason"
                        label="Missing features I need"
                        checked={reason === 'reason6'}
                        handleChange={handleChange}
                    />
                </RadioContainer>

                <TextArea
                    rows={3}
                    placeholder="These are my thoughts…"
                    name="additional"
                    onChange={(e) => setAdditional(e.target.value)}
                />

                <FormButton fullWidth color="purple" onClick={toggleModal}>
                    Nevermind
                </FormButton>

                <CancelContainer>
                    <InfoHeading onClick={handleContinue}>Continue</InfoHeading>
                </CancelContainer>
            </CardInfoContainer>
        </CardWithSadEmoji>
    )
}

export default SorryCard
