import styled from 'styled-components';

export const Container = styled.div`
    max-width: 160px;
    padding: 10px;
    border-radius: 15px;
    background: #FFFFFF10;
    cursor: pointer;
    height: 180px;
    display: flex;
    flex-direction: column;
`

export const ImageContainer = styled.div`
    background-color: white;
    max-width: 140px;
    border-radius: 10px;
    overflow: hidden;
`

export const ImageWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`

export const Image = styled.img`
    max-width: 130px;
`

export const Heading = styled.h2`
    font-weight: 700;
    font-size: 12px;
    color: #FFFFFF;
    margin-top: 10px;
`

export const InfoContainer = styled.div`
    margin-top: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
`

export const Brand = styled.p`
    font-weight: 500;
    font-size: 10px;
    color: #FFFFFF60;
`

export const Price = styled.p`
    font-weight: 700;
    font-size: 10px;
    color: #43EA80;
`