import { StarIconProps } from "./StarIcon.types"

const StarIcon = ({ fill }: StarIconProps) => {
  return (
    <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g id="Admin-Updates-January-2023" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Release-Update" transform="translate(-1253.000000, -301.000000)" fill="#FFFFFF" fillRule="nonzero">
                <g id="Group" transform="translate(539.000000, 125.000000)">
                    <g id="Group-3" transform="translate(0.000000, 60.000000)">
                        <g id="Image-Drop-Copy" transform="translate(30.000000, 91.000000)">
                            <g id="Group-13" transform="translate(1.000000, 10.000000)">
                                <g id="Group-12" transform="translate(507.000000, 15.000000)">
                                    <g id="Group-7-Copy" transform="translate(176.000000, 0.000000)">
                                        <path d="M10,0 C4.48,0 0,4.48 0,10 C0,15.52 4.48,20 10,20 C15.52,20 20,15.52 20,10 C20,4.48 15.52,0 10,0 Z" id="Shape" fill={fill ? '#FFAD00' : '#FFFFFF'} fillOpacity={fill ? '1' : '0.1'}></path>
                                        <path d="M10,13.0037211 L12.4249274,14.4703639 C12.8690104,14.739151 13.4124279,14.3418135 13.2955639,13.8392984 L12.6528121,11.0813088 L14.7972659,9.22317167 C15.1887602,8.88426616 14.9784051,8.24151433 14.4642036,8.20061194 L11.6419388,7.96104081 L10.5375743,5.3549743 C10.3389055,4.88167523 9.66109449,4.88167523 9.46242574,5.3549743 L8.35806124,7.95519761 L5.5357964,8.19476874 C5.02159493,8.23567113 4.81123979,8.87842296 5.20273409,9.21732847 L7.34718791,11.0754656 L6.70443608,13.8334552 C6.58757211,14.3359703 7.13098957,14.7333078 7.57507265,14.4645207 L10,13.0037211 L10,13.0037211 Z" id="Path" opacity={fill ? '1' : '0.5'}></path>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
  )
}

export default StarIcon