import dayjs from 'dayjs'
import { ReactComponent as RedCross } from '../../../assets/cross.svg'
import { ReactComponent as GreenTick } from '../../../assets/green-tick.svg'
import { ReactComponent as Info } from '../../../assets/info.svg'
import ReleaseItem from '../ReleaseItem/ReleaseItem'
import {
    Container,
    Cross,
    FlexRowAlignBetween,
    HeaderContainer,
    Image,
    ImageContainer,
    ImageWrapper,
    InfoContainer,
    LargeLeftArrow,
    LargeRightArrow,
    LightBoldText,
    MonetaryHeading,
    MonetaryInfo,
    PriceInfoContainer,
    PriceItem,
    ReleaseInfo,
    ReleaseName,
    ReleaseType,
    ReleaseTypeHeading,
    ReleaseTypesContainer,
    ResellItem,
    TitleContainer,
} from './ReleaseView.styled'
import { ReleaseViewProps } from './ReleaseView.types'

const ReleaseView = ({
    name,
    images,
    timestamp,
    drop_info: { drops, raffles },
    item_info: { price, resell, description },
    getNextRelease,
    getPrevRelease,
    disableLeft,
    disableRight,
    toggleModal,
}: ReleaseViewProps) => {
    const doesHaveResell = parseInt(resell.slice(1)) > parseInt(price.slice(1))
    const willBreakEven = parseInt(resell.slice(1)) === parseInt(price.slice(1))

    console.log(raffles)
    console.log(drops)
    return (
        <Container>
            <HeaderContainer>
                <TitleContainer>
                    <LargeLeftArrow
                        onClick={getPrevRelease}
                        isDisabled={disableLeft}
                    />
                    <div>
                        <LightBoldText>
                            {dayjs(timestamp._seconds * 1000).format(
                                'dddd, MMMM D[th], YYYY'
                            )}
                        </LightBoldText>
                        <ReleaseName>{name}</ReleaseName>
                    </div>
                </TitleContainer>

                <LargeRightArrow
                    onClick={getNextRelease}
                    isDisabled={disableRight}
                />

                <Cross onClick={toggleModal} />
            </HeaderContainer>

            <InfoContainer>
                <ImageContainer>
                    <ImageWrapper>
                        <Image src={images[0]} alt="item" />
                    </ImageWrapper>
                </ImageContainer>

                <PriceInfoContainer>
                    <PriceItem
                        doesHaveResell={doesHaveResell}
                        willBreakEven={willBreakEven}
                    >
                        <FlexRowAlignBetween>
                            <MonetaryHeading>{resell}</MonetaryHeading>
                            {willBreakEven ? (
                                <p></p>
                            ) : doesHaveResell ? (
                                <GreenTick />
                            ) : (
                                <RedCross />
                            )}
                        </FlexRowAlignBetween>
                        <LightBoldText>Estimated Resell</LightBoldText>
                    </PriceItem>

                    <ResellItem>
                        <MonetaryHeading>{price}</MonetaryHeading>
                        <LightBoldText>Retail Price</LightBoldText>
                    </ResellItem>

                    <MonetaryInfo>
                        <Info />
                        <span>
                            Retail and resell predictions provided by GOAT
                        </span>
                    </MonetaryInfo>
                </PriceInfoContainer>
            </InfoContainer>

            <ReleaseInfo>{description}</ReleaseInfo>

            <ReleaseTypesContainer>
                <ReleaseType>
                    <ReleaseTypeHeading>Raffles</ReleaseTypeHeading>
                    {raffles.length > 0 ? (
                        raffles.map((raffle) => (
                            <ReleaseItem
                                key={raffle.title}
                                {...raffle}
                                releaseType="raffle"
                            />
                        ))
                    ) : (
                        <p>No raffles available</p>
                    )}
                </ReleaseType>

                <ReleaseType>
                    <ReleaseTypeHeading>Drops</ReleaseTypeHeading>
                    {drops.length > 0 ? (
                        drops.map((drop) => (
                            <ReleaseItem
                                key={drop.title}
                                {...drop}
                                releaseType="drop"
                            />
                        ))
                    ) : (
                        <p>No drops available</p>
                    )}
                </ReleaseType>
            </ReleaseTypesContainer>
        </Container>
    )
}

export default ReleaseView
