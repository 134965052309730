import styled from "styled-components"

export const Container = styled.div`
    position: absolute;
    top: 110%;
    left: 0;
    width: 100%;
    border-radius: 10px;
    background-color: #112d3e;
    border: 1px solid #FFFFFF10;
    max-height: 400px;
    overflow-y: scroll;
    padding: 0px;

    > * + * {
        border-top: 1px solid #FFFFFF10;
    }
`