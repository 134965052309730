import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Store } from 'src/pages/StaffStoresPage'
import FormTextInput from '../../common/FormTextInput/FormTextInput'
import LocationsBox from '../LocationsBox/LocationsBox'
import { useDropzone } from 'react-dropzone'
import fileDropIcon from '../../../assets/file-drop-icon.svg'
import AddLocationModal from '../AddLocationModal/AddLocationModal'
import axios from 'axios'
import { uploadLogo } from 'src/utils/imageKit'
import { toast } from 'react-hot-toast'

const Column = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-left: 60px;
    padding-right: 40px;
    height: calc(100vh - 225px);
    overflow-y: auto;

    &::-webkit-scrollbar {
        display: none;
    }

    * {
        flex-shrink: 0;
    }
`

const Heading = styled.span`
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
`

const FormRow = styled.div`
    display: flex;
    margin-bottom: 20px;
    gap: 10px;

    * {
        flex-shrink: 1;
    }
`

const LocationsRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
`

const LocationsCol = styled.div`
    display: flex;
    flex-direction: column;
`

const LocationsHeading = styled.span`
    font-size: 18px;
    font-weight: 600;
`

const LocationsSubheading = styled.span`
    color: rgba(247, 239, 243, 0.6);
    font-size: 12px;
`

const NewLocationButton = styled.span`
    height: 46px;
    display: flex;
    align-items: center;
    padding: 0 18px;
    cursor: pointer;
    border-radius: 30px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
    background: linear-gradient(
        180deg,
        rgba(127, 112, 255, 1) 0%,
        rgba(107, 90, 255, 1) 100%
    );
    font-size: 16px;
    font-weight: bold;

    &:hover {
        filter: brightness(1.25);
    }

    transition: 0.1s filter ease-out;
`

const SaveButton = styled.span`
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 18px;
    cursor: pointer;
    border-radius: 30px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
    background: linear-gradient(
        180deg,
        rgba(127, 112, 255, 1) 0%,
        rgba(107, 90, 255, 1) 100%
    );
    font-size: 16px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 20px;
    s &:hover {
        filter: brightness(1.25);
    }

    transition: 0.1s filter ease-out;
`

const LogoDropzoneContainer = styled.div<{
    isDragActive: boolean
    isDragReject: boolean
    isError: boolean
}>`
    background: rgba(0, 0, 0, 0.2);
    height: 100px;
    border: 1px dashed
        ${(props) =>
            props.isDragReject || props.isError
                ? 'red'
                : props.isDragActive
                ? '#7F70FF'
                : 'rgba(255, 255, 255, 0.15)'};
    border-radius: 15px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const LogoInformation = styled.span`
    margin-bottom: 20px;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.7);

    span {
        color: rgba(255, 255, 255, 0.9);
    }
`

const DropzoneIcon = styled.img`
    margin-bottom: 5px;
`

const DropzoneText = styled.span`
    font-size: 12px;
    color: rgba(255, 255, 255, 0.7);

    span {
        color: #6e7cff;
        text-decoration: underline;
        cursor: pointer;
    }
`

interface StoreEditFormProps {
    isEditing: boolean
    storeName?: string
    editingStore?: Store | null
    shouldRevalidate: () => void
    shouldRevertToHomeView: () => void
}

const StoreEditForm = (props: StoreEditFormProps) => {
    const [storeTitle, setStoreTitle] = useState('')
    const [websiteURL, setWebsiteURL] = useState('')
    const [instagramURL, setInstagramURL] = useState('')
    const [twitterURL, setTwitterURL] = useState('')
    const [mobileAppURL, setMobileAppURL] = useState('')
    const [locations, setLocations] = useState<Store['store_locations']>([])
    const [isAddLocationModalOpen, setIsAddLocationModalOpen] = useState(false)
    const [logo, setLogo] = useState<File | null>(null)

    const [isStoreNameError, setIsStoreNameError] = useState(false)
    const [isWebsiteURLError, setIsWebsiteURLError] = useState(false)
    const [isLogoError, setIsLogoError] = useState(false)

    const onLogoDrop = useCallback((acceptedFiles) => {
        if (acceptedFiles.length > 0) {
            setLogo(acceptedFiles[0])
        }
    }, [])

    const { getRootProps, getInputProps, open, isDragActive, isDragReject } =
        useDropzone({
            accept: {
                'image/png': ['.png'],
                'image/jpeg': ['.jpeg', '.jpg'],
            },
            onDrop: onLogoDrop,
        })

    const onSaveClick = async () => {
        if (props.editingStore) {
            // Update store

            const hasUploadedLogo = !!logo

            if (hasUploadedLogo) {
                const fileReader = new FileReader()
                fileReader.readAsDataURL(logo)
                fileReader.onload = async () => {
                    const base64 = fileReader.result
                    console.log(base64)
                    if (!base64) return
                    if (typeof base64 !== 'string') return
                    const { value: logoURL } = await toast.promise(
                        uploadLogo({
                            file: base64,
                        }),
                        {
                            loading: 'Uploading logo...',
                            success: 'Logo uploaded!',
                            error: 'Failed to upload logo',
                        }
                    )
                    const response = await toast.promise(
                        axios.put(
                            // @ts-ignore
                            `https://pro-alerts-api-production.up.railway.app/api/v1/stores/update/${props.editingStore.id}`,
                            {
                                store_name: storeTitle,
                                store_logo: logoURL,
                                store_site: websiteURL,
                            },
                            {
                                headers: {
                                    Authorization:
                                        'a1d3b746-0702-4d5b-900e-313dbf9ab0a2',
                                },
                            }
                        ),
                        {
                            loading: 'Updating store...',
                            success: 'Store updated!',
                            error: 'Failed to update store',
                        }
                    )
                    setStoreTitle(response.data.store_name || '')
                    setWebsiteURL(response.data.store_site || '')
                    setLogo(null)
                    props.shouldRevalidate()
                }
            } else {
                const response = await toast.promise(
                    axios.put(
                        `https://pro-alerts-api-production.up.railway.app/api/v1/stores/update/${props.editingStore.id}`,
                        {
                            store_name: storeTitle,
                            store_site: websiteURL,
                        },
                        {
                            headers: {
                                Authorization:
                                    'a1d3b746-0702-4d5b-900e-313dbf9ab0a2',
                            },
                        }
                    ),
                    {
                        loading: 'Updating store...',
                        success: 'Store updated!',
                        error: 'Failed to update store',
                    }
                )
                setStoreTitle(response.data.store_name || '')
                setWebsiteURL(response.data.store_site || '')
                setLogo(null)
                props.shouldRevalidate()
            }
        } else {
            // Create store
            // Validate inputs
            if (storeTitle === '') setIsStoreNameError(true)
            // if (websiteURL === '') setIsWebsiteURLError(true)
            if (logo === null) setIsLogoError(true)

            if (logo && storeTitle) {
                const fileReader = new FileReader()
                fileReader.readAsDataURL(logo)
                fileReader.onload = async () => {
                    const base64 = fileReader.result
                    console.log(base64)
                    if (!base64) return
                    if (typeof base64 !== 'string') return
                    const { value: logoURL } = await toast.promise(
                        uploadLogo({
                            file: base64,
                        }),
                        {
                            loading: 'Uploading logo...',
                            success: 'Logo uploaded!',
                            error: 'Failed to upload logo',
                        }
                    )
                    await toast.promise(
                        axios.post(
                            'https://pro-alerts-api-production.up.railway.app/api/v1/stores/create',
                            {
                                store_name: storeTitle,
                                store_logo: logoURL,
                                store_site: websiteURL,
                            },
                            {
                                headers: {
                                    Authorization:
                                        'a1d3b746-0702-4d5b-900e-313dbf9ab0a2',
                                },
                            }
                        ),
                        {
                            loading: 'Creating store...',
                            success: 'Store created!',
                            error: 'Failed to create store',
                        }
                    )
                    setStoreTitle('')
                    setWebsiteURL('')
                    setInstagramURL('')
                    setTwitterURL('')
                    setMobileAppURL('')
                    setLogo(null)
                    setLocations([])
                    props.shouldRevalidate()
                    props.shouldRevertToHomeView()
                }
            }
        }
    }

    useEffect(() => {
        setIsStoreNameError(false)
        setIsWebsiteURLError(false)
        setIsLogoError(false)
    }, [props.isEditing])

    useEffect(() => {
        setIsStoreNameError(false)
    }, [storeTitle])

    useEffect(() => {
        setIsWebsiteURLError(false)
    }, [websiteURL])

    useEffect(() => {
        setIsLogoError(false)
    }, [logo])

    useEffect(() => {
        if (props.isEditing) {
            console.log(props.editingStore)
            setStoreTitle(props.editingStore?.store_name || '')
            setWebsiteURL(props.editingStore?.store_site || '')
            // setInstagramURL(props.editingStore?.store || '')
            // setTwitterURL(props.editingStore?.twitter_url || '')
            // setMobileAppURL(props.editingStore?.mobile_app_url || '')
            setLogo(null)
            setLocations(props.editingStore?.store_locations || [])
        } else {
            setStoreTitle('')
            setWebsiteURL('')
            setInstagramURL('')
            setTwitterURL('')
            setMobileAppURL('')
            setLogo(null)
            setLocations([])
        }
    }, [props.isEditing, props.editingStore, props.storeName])

    return (
        <>
            <AddLocationModal
                isOpen={isAddLocationModalOpen}
                onClose={() => setIsAddLocationModalOpen(false)}
                onAddLocation={(location) => {
                    if (props.editingStore)
                        axios
                            .post(
                                'https://pro-alerts-api-production.up.railway.app/api/v2/locations/create',
                                {
                                    ...location,
                                    storeId: props.editingStore.id,
                                },
                                {
                                    headers: {
                                        Authorization:
                                            'a1d3b746-0702-4d5b-900e-313dbf9ab0a2',
                                    },
                                }
                            )
                            .then((res) => {
                                console.log(res.data)
                                setLocations((prev) => [...prev, res.data])
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                            .finally(() => {
                                props.shouldRevalidate()
                            })
                }}
            />
            <Column>
                <Heading>
                    {props.isEditing
                        ? `Edit '${props.storeName}'`
                        : 'Create a new store'}
                </Heading>
                <FormRow>
                    <FormTextInput
                        label="Store Title"
                        value={storeTitle}
                        onChange={(e) => setStoreTitle(e.target.value)}
                        placeholder="Enter Store Title"
                        required
                        isError={isStoreNameError}
                    />
                </FormRow>
                <LogoDropzoneContainer
                    {...getRootProps({ refKey: 'innerRef' })}
                    isDragActive={isDragActive}
                    isDragReject={isDragReject}
                    isError={isLogoError}
                >
                    <input {...getInputProps()} />
                    <DropzoneIcon
                        src={fileDropIcon}
                        height={24}
                        width={24}
                        alt=""
                    />
                    <DropzoneText>
                        Drag and drop file or <span onClick={open}>browse</span>
                    </DropzoneText>
                    {isDragReject && (
                        <DropzoneText style={{ color: 'red' }}>
                            Only .png, .jpg, and .jpeg files are accepted
                        </DropzoneText>
                    )}
                </LogoDropzoneContainer>
                {logo && (
                    <LogoInformation>
                        <span>{logo.name}</span> ({logo.size} bytes) (save to
                        upload)
                    </LogoInformation>
                )}
                <FormRow>
                    <FormTextInput
                        label="Website (Optional)"
                        value={websiteURL}
                        onChange={(e) => setWebsiteURL(e.target.value)}
                        placeholder="https://www.nike.com"
                        isError={isWebsiteURLError}
                    />
                </FormRow>
                {/* <FormRow>
                <FormTextInput
                    label="Instagram (Optional)"
                    value={instagramURL}
                    onChange={(e) => setInstagramURL(e.target.value)}
                    placeholder="https://www.instagram.com/nike"
                />
                <FormTextInput
                    label="Twitter (Optional)"
                    value={twitterURL}
                    onChange={(e) => setTwitterURL(e.target.value)}
                    placeholder="https://www.twitter.com/nike"
                />
            </FormRow>
            <FormRow>
                <FormTextInput
                    label="Mobile App Link (Optional)"
                    value={mobileAppURL}
                    onChange={(e) => setMobileAppURL(e.target.value)}
                    placeholder="https://apps.apple.com/us/app/nike-shoes-apparel-stories/id1095459556"
                />
            </FormRow> */}
                <LocationsRow>
                    <LocationsCol>
                        <LocationsHeading>Locations</LocationsHeading>
                        <LocationsSubheading>
                            {locations.length} Location
                            {locations.length !== 1 ? 's' : ''}
                        </LocationsSubheading>
                    </LocationsCol>
                    {props.editingStore && (
                        <NewLocationButton
                            onClick={() => setIsAddLocationModalOpen(true)}
                        >
                            New Location
                        </NewLocationButton>
                    )}
                </LocationsRow>
                <LocationsBox
                    shouldShowNewMessage={!props.editingStore}
                    locations={locations}
                    onDeleteLocation={(locationId) => {
                        if (props.editingStore) {
                            axios
                                .delete(
                                    `https://pro-alerts-api-production.up.railway.app/api/v2/locations/delete/${locationId}`,
                                    {
                                        headers: {
                                            Authorization:
                                                'a1d3b746-0702-4d5b-900e-313dbf9ab0a2',
                                        },
                                    }
                                )
                                .then((res) => {
                                    console.log(res.data)
                                    setLocations((prev) =>
                                        prev.filter(
                                            (location) =>
                                                location.id !== locationId
                                        )
                                    )
                                })
                                .catch((err) => {
                                    console.log(err)
                                })
                                .finally(() => {
                                    props.shouldRevalidate()
                                })
                        }
                    }}
                />
                <SaveButton onClick={onSaveClick}>
                    {props.isEditing ? 'Save' : 'Create'}
                </SaveButton>
            </Column>
        </>
    )
}

export default StoreEditForm
