import z from 'zod'

export enum NotificationType {
    ALERT = 'ALERT',
    RESTOCK = 'RESTOCK',
    DROP = 'DROP',
}

export enum AudienceType {
    PAID = 'PAID',
    FREE = 'FREE',
    BOTH = 'BOTH',
}

export const NotificationSchema = z.object({
    send_at: z.coerce.date().optional(),
    type: z.nativeEnum(NotificationType),
    title: z.string().min(1, 'Please provide a title'),
    content: z.string().optional(),
    url: z.string().optional(),
    image_url: z.string().optional(),
    brand: z.string().optional(),
    category: z.string().optional(),
    audience_type: z.nativeEnum(AudienceType).optional(),
    release_id: z.string().optional(),
    publisher_id: z.string().min(1, 'Please provide a publisher ID'),
})
