import { useState } from 'react'
import { ErrorAlert } from 'src/components/common/common'
import useDebounce from 'src/hooks/useDebounce'
import { useError } from 'src/hooks/useError'
import { useGetReleases } from 'src/hooks/useGetReleases'
import { useSearchReleases } from 'src/hooks/useSearchReleases'
import ReleaseItem, { ReleaseSkeleton } from '../ReleaseItem/ReleaseItem'
import { defaultQuery } from './SearchBar.data'
import {
    SearchBarContainer,
    SearchBarInput,
    SearchBarWrapper,
    SearchReleasesList,
    TextContainer,
} from './SearchBar.styled'
import { ReleasesListProps } from './SearchBar.types'

const NoReleasesFound = () => {
    return (
        <TextContainer>
            <p>No releases found</p>
        </TextContainer>
    )
}

const ReleasesSkeleton = () => {
    return (
        <>
            {Array.from({ length: 4 }, (_, i) => (
                <ReleaseSkeleton key={i} />
            ))}
        </>
    )
}

const ReleasesList = ({
    releases,
    selectedRelease,
    handleSelectedRelease,
}: ReleasesListProps) => {
    return (
        <>
            {releases.map((release) => (
                <ReleaseItem
                    setSelectedRelease={handleSelectedRelease(release.id)}
                    selected={selectedRelease === release.id}
                    key={release.id}
                    release={release}
                />
            ))}
        </>
    )
}

const SearchBar = () => {
    const [errorMessage, setErrorMessage] = useState('')
    const [selectedRelease, setSelectedRelease] = useState('')
    const [query, setQuery] = useState('')
    const value = useDebounce(query)
    const {
        data: searchReleaseData,
        error,
        isLoading: isLoadingSearchReleaseData,
    } = useSearchReleases(value)
    const { data: defaultReleaseData, isLoading: isLoadingDefaultReleaseData } =
        useGetReleases(1, defaultQuery, { refetchOnWindowFocus: false })
    
    useError(error, setErrorMessage)

    const handleSelectedRelease = (id: string) => () => setSelectedRelease(id)

    let result: React.ReactNode

    if (!value) {
        if (isLoadingDefaultReleaseData || !defaultReleaseData) {
            result = <ReleasesSkeleton />
        } else {
            const { releases } = defaultReleaseData

            if (!releases.length) {
                result = <NoReleasesFound />
            } else {
                result = (
                    <ReleasesList
                        releases={releases}
                        selectedRelease={selectedRelease}
                        handleSelectedRelease={handleSelectedRelease}
                    />
                )
            }
        }
    } else {
        if (isLoadingSearchReleaseData || !searchReleaseData) {
            result = <ReleasesSkeleton />
        } else {
            const { releases } = searchReleaseData

            if (!releases.length) {
                result = <NoReleasesFound />
            } else {
                result = (
                    <ReleasesList
                        releases={releases}
                        selectedRelease={selectedRelease}
                        handleSelectedRelease={handleSelectedRelease}
                    />
                )
            }
        }
    }

    return (
        <SearchBarContainer>
            <SearchBarWrapper>
                <SearchBarInput
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    placeholder="Search Releases"
                />
            </SearchBarWrapper>

            <SearchReleasesList>
                {errorMessage && (
                    <TextContainer>
                        <ErrorAlert>{errorMessage}</ErrorAlert>
                    </TextContainer>
                )}

                {result}
            </SearchReleasesList>
        </SearchBarContainer>
    )
}

export default SearchBar
