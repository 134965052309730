import axios from 'axios'
import dayjs from 'dayjs'
import { useContext, useState } from 'react'
import ReactPixel from 'react-facebook-pixel'
import styled from 'styled-components'
import { ErrorAlert, SuccessAlert } from '../components/common/common'
import Modal from '../components/common/Modal/Modal'
import AnotherChanceCard from '../components/subscriptions/AnotherChanceCard/AnotherChanceCard'
import BetaCard from '../components/subscriptions/BetaCard/BetaCard'
import ReviewCard from '../components/subscriptions/ReviewCard/ReviewCard'
import SorryCard from '../components/subscriptions/SorryCard/SorryCard'
import SubInfoCard from '../components/subscriptions/SubInfoCard/SubInfoCard'
import { AuthContext, IAuthContext } from '../contexts/Auth'

const Container = styled.div`
    display: flex;
    justify-content: center;
    padding: 10px;

    > * + * {
        margin-left: 30px;
    }

    @media only screen and (max-width: 768px) {
        flex-direction: column;
        padding-left: 10px;
        padding-right: 10px;
        align-items: center;

        > * + * {
            margin-left: 0px;
        }

        > * + * {
            margin-top: 30px;
        }
    }
`

const AlertContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

function Subscriptions() {
    const { userInfo, setUserInfo } = useContext<IAuthContext>(AuthContext)
    const [errorMessage, setErrorMessage] = useState('')
    const [successMessage, setSuccessMessage] = useState('')

    const [showSorryModal, setShowSorryModal] = useState(false)
    const [showReviewModal, setShowReviewModal] = useState(false)
    const [showAnotherChanceModal, setShowAnotherChanceModal] = useState(false)

    const toggleSorryModal = () => setShowSorryModal(prev => !prev)
    const toggleReviewModal = () => setShowReviewModal(prev => !prev)
    const toggleAnotherChanceModal = () =>
        setShowAnotherChanceModal(prev => !prev)

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    })

    const isOn =
        userInfo?.swiftsole_v3?.status === 'active' ||
        userInfo?.swiftsole_v3?.status === 'trialing'
    const isLifetime = userInfo?.swiftsole_v3?.type === 'lifetime'
    const isActive = userInfo?.swiftsole_v3?.active === true
    const canJoinBetaDiscord =
        userInfo?.swiftsole_v3?.active === true &&
        !userInfo?.swiftsole_v3?.isTrialing
    const isTrialing = userInfo?.swiftsole_v3?.status === 'trialing'
    const price = formatter.format(userInfo?.upcomingInvoice?.finalAmount / 100)
    const createdAt = userInfo?.swiftsole_v3?.created || 0
    const expiresAt = userInfo?.swiftsole_v3?.expiresAt || 0
    const period = 'monthly'

    const subscribedSinceText = isLifetime
        ? 'Lifetime active'
        : isActive
        ? `Subscribed ${dayjs
              .unix(userInfo?.swiftsole_v3?.created)
              .format('MMMM DD, YYYY')}`
        : 'Not subscribed'

    const toggleSubscription = async (product: string, isOn: boolean) => {
        try {
            const res = await axios.post(
                `api/v1/users/subscription/${isOn ? 'pause' : 'resume'}`,
                { product },
                { withCredentials: true }
            )

            if (res.status === 200) setUserInfo(res.data)
        } catch (error) {
            console.log(error)
        }
    }

    const joinDiscord = async () => {
        setErrorMessage('')
        setSuccessMessage('')

        try {
            const res = await axios.post(
                '/api/v1/users/subscription/joinDiscord',
                { product: 'swiftsole_desktop' },
                { withCredentials: true }
            )
            if (res.status === 200)
                setSuccessMessage(
                    `You have successfully joined the desktop server, check discord for further instructions 🚀`
                )
        } catch (err) {
            console.log(err)
            setErrorMessage(
                `There was an error joining the Discord server. Please try again later.`
            )
        }
    }

    const purchaseSubscription = async () => {
        ReactPixel.track('AddToCart', {
            value: '9.99',
            currency: 'USD',
            content_type: 'product',
            content_name: 'swiftsole_v3',
        })
        window.location.href = `${window.location.protocol}//${window.location.host}/release/V2LIFETIME2022?code=INITIALOFF100`
    }

    const downloadBot = async () => {
        setErrorMessage('')
        setSuccessMessage('')

        try {
            const res = await axios.post(
                '/api/v1/users/@me/sendV3Invite',
                {},
                { withCredentials: true }
            )
            if (res.status === 200) setSuccessMessage(`Download successful.`)
        } catch (err) {
            let errorMessage = ''

            if (axios.isAxiosError(err)) {
                errorMessage = err.response?.data?.message
            } else {
                errorMessage = (err as Error).message
            }

            setErrorMessage(errorMessage)
        }
    }

    // pixel tracking
    const advancedMatching = {
        em: userInfo.email as string,
        ph: userInfo.phone as string,
    }
    const options = { autoConfig: true, debug: false }

    // @ts-ignore
    ReactPixel.init('761286231002067', advancedMatching, options)
    ReactPixel.pageView()

    const toggleModal =
        userInfo?.swiftsole_v3?.status === 'trialing'
            ? toggleReviewModal
            : !isOn
            ? () => toggleSubscription('swiftsole_v3', false)
            : userInfo.claimedSpecialOffers.length > 0
            ? toggleReviewModal
            : toggleAnotherChanceModal

    const joinV3Discord = async () => {
        setErrorMessage('')
        setSuccessMessage('')

        try {
            const res = await axios.post(
                '/api/v1/users/subscription/joinDiscord',
                { product: 'swiftsole_v3' },
                { withCredentials: true }
            )
            if (res.status === 200)
                setSuccessMessage(
                    `You have successfully joined the Discord server!`
                )
        } catch (err) {
            console.log(err)
            setErrorMessage(
                'There was an error joining the Discord server. Please try again later.'
            )
        }
    }

    const handleClick = !isActive ? purchaseSubscription : joinV3Discord

    return (
        <>
            <AlertContainer>
                {errorMessage && <ErrorAlert>{errorMessage}</ErrorAlert>}
                {successMessage && (
                    <SuccessAlert>{successMessage}</SuccessAlert>
                )}
            </AlertContainer>

            <Container>
                <SubInfoCard
                    isOn={isOn}
                    isLifetime={isLifetime}
                    isActive={isActive}
                    price={price}
                    isTrialing={isTrialing}
                    createdAt={createdAt}
                    expiresAt={expiresAt}
                    toggleModal={toggleModal}
                    handleClick={handleClick}
                    subscribedSince={subscribedSinceText}
                    period={period}
                />

                {/* <BetaCard
                    joinDiscord={joinDiscord}
                    isActive={canJoinBetaDiscord}
                /> */}
            </Container>

            <Modal showModal={showReviewModal} toggleModal={toggleReviewModal}>
                <ReviewCard
                    toggleModal={toggleReviewModal}
                    toggleOtherModal={toggleSorryModal}
                />
            </Modal>

            <Modal showModal={showSorryModal} toggleModal={toggleSorryModal}>
                <SorryCard toggleModal={toggleSorryModal} />
            </Modal>

            <Modal
                showModal={showAnotherChanceModal}
                toggleModal={toggleAnotherChanceModal}
            >
                <AnotherChanceCard
                    toggleModal={toggleAnotherChanceModal}
                    toggleOtherModal={toggleSorryModal}
                />
            </Modal>
        </>
    )
}

export default Subscriptions
