import dayjs from 'dayjs'
import { memo } from 'react'
import { NewFile } from 'src/components/common/FileUpload/FileUpload.types'
import FormButton from 'src/components/common/FormButton/FormButton'
import {
    setFiles,
    setFormFields,
} from 'src/contexts/StaffAlertFormContext/StaffAlertFormContext.services'
import { useCancelNotification } from 'src/hooks/useCancelNotification'
import { useStaffAlertDispatchContext } from 'src/hooks/useStaffAlertForm'
import { getLinkName } from 'src/utils/getLinkName'
import { notify } from 'src/utils/notify'
import {
    ButtonsContainer,
    Container,
    Content,
    Image,
    ImageContainer,
    Link,
    NotificationTitle,
    ReleaseDate,
    SkeletonButton,
    SkeletonImage,
    SkeletonText,
} from './NotificationCard.styled'
import { NotificationCardProps } from './NotificationCard.types'
import { buildNotification } from './NotificationCard.utils'

export function NotificationCardSkeleton() {
    return (
        <Container>
            <div style={{ display: 'flex', gap: '10px', marginBottom: '20px' }}>
                <SkeletonImage />

                <Content>
                    <SkeletonText width="115px" height="25px" />
                    <SkeletonText width="355px" height="20px" />
                    <SkeletonText width="135px" height="20px" />
                </Content>
            </div>

            <ButtonsContainer>
                <SkeletonButton />
                <SkeletonButton />
            </ButtonsContainer>
        </Container>
    )
}

function NotificationCard({ notification }: NotificationCardProps) {
    const dispatch = useStaffAlertDispatchContext()

    const { mutateAsync: cancelNotification, isLoading } =
        useCancelNotification()

    const { id, title, image_url, url, send_at } = notification

    const isExpired = !send_at || new Date(send_at).getTime() < Date.now()

    const scheduledDate = send_at
        ? dayjs(send_at).format('MM/DD/YYYY [@] h:mA [EST]')
        : ''

    const handleCancelNotification = async (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        e.preventDefault()
        try {
            await cancelNotification(id)
            notify('Notification cancelled', 'success')
        } catch (error) {
            notify((error as Error).message, 'error')
        }
    }

    const handleEditRelease = (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        e.preventDefault()

        const selectedNotification = buildNotification(notification)

        if (selectedNotification.image_url) {
            const { image_url } = selectedNotification

            const name = getLinkName(image_url)

            const file: NewFile = {
                url: image_url,
                name,
                transparent: false,
            }

            setFiles(dispatch)([file])
        } else {
            setFiles(dispatch)([])
        }

        setFormFields(dispatch)(selectedNotification)
    }

    return (
        <Container>
            <div style={{ display: 'flex', gap: '10px', marginBottom: '20px' }}>
                <ImageContainer>
                    <Image alt={title} src={image_url} />
                </ImageContainer>

                <Content>
                    <NotificationTitle>{title}</NotificationTitle>
                    <Link href={url}>{url}</Link>
                    {isExpired && <ReleaseDate>{scheduledDate}</ReleaseDate>}
                </Content>
            </div>

            {!isExpired && (
                <ButtonsContainer>
                    <FormButton
                        isSmall
                        disabled={isExpired}
                        onClick={handleEditRelease}
                        color="purple"
                    >
                        Edit
                    </FormButton>
                    <FormButton
                        isSmall
                        isLoading={isLoading}
                        disabled={isExpired || isLoading}
                        onClick={handleCancelNotification}
                        color="red"
                    >
                        Cancel
                    </FormButton>
                </ButtonsContainer>
            )}
        </Container>
    )
}

export default memo(NotificationCard)
