import { useCallback } from 'react'
import FormButton from 'src/components/common/FormButton/FormButton'
import withModal from 'src/components/common/withModal/withModal'
import NotificationForm from 'src/components/notifications/NotificationForm/NotificationForm'
import { NotificationFormProps } from 'src/components/notifications/NotificationForm/NotificationForm.types'
import NotificationsList from 'src/components/notifications/NotificationsList/NotificationsList'
import { StaffAlertFormProvider } from 'src/contexts/StaffAlertFormContext/StaffAlertFormContext'
import { useToggle } from 'src/hooks/useToggle'
import styled from 'styled-components'

const Padding = styled.div`
    padding: 20px;
`

const Container = styled.div`
    width: 100%;
    max-width: 1200px;
    border-radius: 30px;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
`

const Wrapper = styled.div`
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-rows: 900px;
    grid-template-areas: 
        'list list form form form'
    ;
    grid-auto-columns: 1fr;

    @media (max-width: 900px) {
        grid-template-areas: 
            'list'
        ;
    }
`

const Left = styled.div`
    border-right: 1px solid #3c3b3b;
    grid-area: list;
    background: #272727;

    @media (max-width: 900px) {
        border-right: none;
    }
`

const Right = styled.div`
    grid-area: form;
    background: #333232;

    @media (max-width: 900px) {
        display: none;
    }
`

const ButtonContainer = styled.div`
    margin-bottom: 30px;
    display: none;

    @media (max-width: 900px) {
        display: block;
    }
`

const NotificationFormWithModal = withModal<NotificationFormProps>(NotificationForm)

const StaffAlertsPage = () => {
    const { on, toggle } = useToggle()

    const handleClick = useCallback(() => {
        toggle()
        document.querySelector('html')?.classList.toggle('overflow-hidden');
    }, [toggle])


    return (
        <StaffAlertFormProvider>
            <Padding>
                <ButtonContainer>
                    <FormButton onClick={handleClick} color='green' fullWidth>Create</FormButton>
                </ButtonContainer>
                    
                <Container>
                    <Wrapper>
                        
                        <Left>
                            <NotificationsList />
                        </Left>

                        <Right>
                            <NotificationForm
                                showModal={on}
                                toggleModal={handleClick}
                            />
                        </Right>
                    </Wrapper>
                </Container>
            </Padding>

            <NotificationFormWithModal
                showModal={on}
                toggleModal={handleClick}
            />
        </StaffAlertFormProvider>
    )
}

export default StaffAlertsPage
