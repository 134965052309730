import { useCallback, useState } from "react";

type OnClickFn = (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;

type OnClickParams = Parameters<OnClickFn>[number];

type ToggleProps = {
    onClick?: (e: OnClickParams) => void;
}

const callAll = (...fns: Array<((args: OnClickParams) => void) | undefined>) => (args: OnClickParams) => {
    fns.forEach(fn => fn?.(args))
}

export const useToggle = (initialState = false) => {
    const [state, setState] = useState(initialState);

    const toggle = useCallback(() => setState(prev => !prev), []);

    const getTogglerProps = ({ onClick }: ToggleProps = {}) => ({
        onClick: callAll(onClick, toggle)
    })

    return { on: state, toggle, getTogglerProps }
}