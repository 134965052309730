import { Input, Label, Placeholder, Circle } from './CustomRadio.styled'
import { CustomRadioProps } from './CustomRadio.types'

function CustomRadio({ name, handleChange, label, checked, value }: CustomRadioProps) {
    return (
        <Label htmlFor={value}>
            <Input
                id={value}
                name={name}
                value={value}
                type="radio"
                checked={checked}
                onChange={handleChange}
            />

            { checked && <Circle /> }

            <Placeholder checked={checked}>{label}</Placeholder>
        </Label>
    )
}

export default CustomRadio