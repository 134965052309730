import styled from "styled-components";

export const Container = styled.div`
    padding: 30px;
    width: 100%;
    max-width: 400px;
    background: #171717;
    border-radius: 30px;
    text-align: center;

    > * + * {
        margin-top: 30px;
    }
`

export const Heading = styled.h2`
    color: white;
    font-size: 24px;
    font-weight: 700;
`

export const CloseButton = styled.button`
    cursor: pointer;
    font-size: 18px;
    line-height: 20px;
    font-weight: 700;
    color: #FF3C4F;
    background-color: transparent;
`