import { ZodSchema } from 'zod'

export function validateForm(schema: ZodSchema, data: unknown) {
    const result = schema.safeParse(data)

    if (!result.success) {
        console.log(result)

        const message = result.error.issues.reduce((str, err) => {
            if (str) {
                return str + '\n' + err.message
            }

            return str + err.message
        }, '')

        throw new Error(message)
    }
}

export function validateArrayForm(schema: ZodSchema, data: unknown[]) {
    for (let i = 0; i < data.length; i++) {
        const formData = data[i]

        const result = schema.safeParse(formData)

        if (!result.success) {
            console.log(formData, result)

            const message = result.error.issues.reduce((str, err) => {
                if (str) {
                    return str + '\n' + err.message
                }

                return str + err.message
            }, '')

            throw new Error(message)
        }
    }
}
