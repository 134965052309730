import FormButton from 'src/components/common/FormButton/FormButton'
import {
    CancelContainer,
    CardInfoContainer,
    Container,
    Heading,
    HeadingContainer,
    InfoHeading,
    Wrapper
} from './EmailCheckCard.styled'
import { EmailCheckCardProps } from './EmailCheckCard.types'

const EmailCheckCard = ({
    toggleModal,
    handleContinue,
}: EmailCheckCardProps) => {
    return (
        <Wrapper>
            <Container>
                <CardInfoContainer>
                    <HeadingContainer>
                        <Heading>
                            We found a match with an existing subscription. Are
                            you sure you want to continue?
                        </Heading>
                    </HeadingContainer>

                    <FormButton
                        fullWidth
                        color="purple"
                        onClick={handleContinue}
                    >
                        Continue
                    </FormButton>

                    <CancelContainer>
                        <InfoHeading onClick={toggleModal}>Cancel</InfoHeading>
                    </CancelContainer>
                </CardInfoContainer>
            </Container>
        </Wrapper>
    )
}

export default EmailCheckCard
