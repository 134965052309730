import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as Discord } from '../assets/discord.svg';
// import { Redirect } from 'react-router-dom';
import Phones from '../assets/phones-2.png';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 400px;
    margin: auto;
    width: 100%;
    padding: 10px;

    > * + * {
        margin-top: 20px;
    }
`

export const CardContainer = styled.div`
    border-radius: 30px;
    overflow: hidden;
`

export const Top = styled.div`
    display: flex;
    justify-content: center;
    background-image: linear-gradient(15deg, #00A4FF 0%, #F45A90 98%);
    overflow: hidden;
    height: 210px;
`

export const Image = styled.img`
    width: 288px;
    height: 420px;
    margin-top: -45px;
`

export const Bottom = styled.div`
    padding: 30px;
    background-color: #FFFFFF20;

    > * + * {
        margin-top: 20px;
    }
`

export const Heading = styled.h2`
    color: white;
    font-size: 26px;
    font-weight: 700;
    text-align: center;
`

export const Info = styled.p`
    color: #FFFFFF80;
    font-size: 16px;
`

export const DiscordButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-image: linear-gradient(180deg, #7F89FF 0%, #5762F2 100%);
    box-shadow: 0 5px 10px 0 rgba(0,0,0,0.20);
    border-radius: 30px;
    font-size: 18px;
    color: #FFFFFF;
    line-height: 20px;
    height: 50px;
    font-weight: bold;
    cursor: pointer;

    > * + * {
        margin-left: 10px;
    }
`

const Alert = styled.p`
    width: 100%;
    border-radius: 10px;
    padding: 10px;
    color: white;
    font-weight: bold;
    text-align: center;
`

const ErrorAlert = styled(Alert)`
    background: #ff425e;
`

const SuccessAlert = styled(Alert)`
  background: #20bd6f;
`
interface Token {
    token: string
}

interface Match {
    params: Token
}

interface Props {
    match: Match
}

function getCookie(name: string) {
    const value = "; " + document.cookie;
    const parts = value.split("; " + name + "=");
    return (parts.pop() as string).split(";").shift() as string;
}

const AccountSetup = (props: Props) => {
    const { token } = props.match.params
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    /*if (authContext.userLoaded && authContext.userInfo.id) {
        return <Redirect to='/subscriptions' />
    }*/

    const connectDiscord = () => {
        window.location.href = `/api/v1/auth/loginRedirect?token=${token}`
    }

    useEffect(() => {
        if (getCookie('swiftsole-error-message')) {
            setSuccessMessage('')
            setErrorMessage(decodeURIComponent(getCookie('swiftsole-error-message')));
            document.cookie = 'swiftsole-error-message=; expires=Thu, 01 Jan 1970 00:00:01 GMT;'
        }
    
        if (getCookie('swiftsole-success-message')) {
            setErrorMessage('')
            setSuccessMessage(decodeURIComponent(getCookie('swiftsole-success-message')));
            document.cookie = 'swiftsole-success-message=; expires=Thu, 01 Jan 1970 00:00:01 GMT;'
        }
    }, [])


    return (
        <Container>
            { errorMessage && <ErrorAlert>{errorMessage}</ErrorAlert> }
            { successMessage && <SuccessAlert>{successMessage}</SuccessAlert> }
            <CardContainer>
                <Top>
                    <Image src={Phones} />
                </Top>

                <Bottom>
                    <div>
                        <Heading>Complete Account Setup</Heading>
                        <Info>Please connect your Discord account to continue.</Info>
                    </div>

                    <DiscordButton onClick={() => connectDiscord()}>
                        <Discord />
                        <span>Connect Discord</span>
                    </DiscordButton>
                </Bottom>
            </CardContainer>
        </Container>
    )
}

export default AccountSetup
