import styled from 'styled-components'
import { Store } from 'src/pages/StaffStoresPage'
import { useState } from 'react'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    border: 1px solid rgba(255, 255, 255, 0.15);
`

const EmptyViewText = styled.span`
    color: rgba(255, 255, 255, 0.65);
    font-size: 12px;
`

const BoxRow = styled.div`
    display: flex;
    align-items: center;
    padding: 15px;
    justify-content: space-between;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);

    &:last-child {
        border-bottom: none;
    }
`

const BoxCol = styled.div`
    display: flex;
    flex-direction: column;
`

const LocationAddress = styled.span`
    font-size: 14px;
    font-weight: 600;
`

const LocationPhone = styled.span`
    font-size: 12px;
    color: rgba(255, 255, 255, 0.7);
`

const DeleteButton = styled.div`
    background: #ff3c4f;
    height: 20px;
    width: 20px;
    border-radius: 10px;
    padding: 0 5px;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        filter: brightness(1.15);
    }

    * {
        user-select: none;
    }

    span {
        font-size: 12px;
        font-weight: 500;
    }
`

const DeleteButtonInner = styled.div`
    height: 2px;
    width: 10px;
    border-radius: 2px;
    background: white;
`

interface LocationsBoxProps {
    locations: Store['store_locations']
    onDeleteLocation: (locationId: number) => void
    shouldShowNewMessage?: boolean
}

const LocationsBox = (props: LocationsBoxProps) => {
    const [confirmDeleteStoreID, setConfirmDeleteStoreID] = useState<
        number | null
    >(null)

    const handleDeleteClick = (locationId: number) => {
        if (confirmDeleteStoreID) {
            props.onDeleteLocation(locationId)
            setConfirmDeleteStoreID(null)
            return
        }
        setConfirmDeleteStoreID(locationId)
        setTimeout(() => {
            setConfirmDeleteStoreID(null)
        }, 2_000)
    }

    if (props.shouldShowNewMessage) {
        return (
            <Container
                style={{
                    height: '150px',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <EmptyViewText>
                    To add locations to this store, you have to create and save
                    it first.
                </EmptyViewText>
            </Container>
        )
    }

    if (props.locations.length === 0) {
        return (
            <Container
                style={{
                    height: '150px',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <EmptyViewText>
                    No locations added yet. Add a location to see it here.
                </EmptyViewText>
            </Container>
        )
    }

    return (
        <Container>
            {props.locations.map((location) => (
                <BoxRow>
                    <BoxCol>
                        <LocationAddress>
                            {location.location_address_formatted}
                        </LocationAddress>
                        <LocationPhone>{location.location_phone}</LocationPhone>
                    </BoxCol>
                    <DeleteButton
                        style={{
                            width:
                                confirmDeleteStoreID === location.id
                                    ? 'unset'
                                    : '20px',
                        }}
                        onClick={() => handleDeleteClick(location.id)}
                    >
                        {confirmDeleteStoreID === location.id ? (
                            <span>Delete?</span>
                        ) : (
                            <DeleteButtonInner />
                        )}
                    </DeleteButton>
                </BoxRow>
            ))}
        </Container>
    )
}

export default LocationsBox
