import { useEffect, useRef, useState } from 'react';
import { Container, Text, Toggle } from './ToggleButton.styled';
import { ToggleButtonProps } from './ToggleButton.types';

function ToggleButton({ options: { option1, option2 }, checked, onClick, isLarge = false }: ToggleButtonProps) {
    const initialMount = useRef(true);
    const [changeText, setChangeText] = useState(false);

    useEffect(() => {
        let timeout: NodeJS.Timeout;

        if (initialMount.current) {
            initialMount.current = false;
        } else {
            timeout = setTimeout(() => {
                setChangeText(prev => !prev);
            }, 200)
        }

        return () => timeout && clearTimeout(timeout)
    }, [checked])

    return (
        <Container isLarge={isLarge}>
            <Toggle
                checked={checked}
                isLarge={isLarge}
                onClick={onClick}
            >
                { !changeText ? option1 : option2 }
            </Toggle>

            <Text
                checked={checked}
                isLarge={isLarge}
            >
                { !changeText ? option2 : option1 }
            </Text>
        </Container>
    )
}

export default ToggleButton