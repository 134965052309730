import React from 'react'
import { resetForm } from 'src/contexts/StaffReleaseFormContext/StaffReleaseFormContext.services'
import { useCreateRelease } from 'src/hooks/useCreateRelease'
import { useDeleteRelease } from 'src/hooks/useDeleteRelease'
import { useError } from 'src/hooks/useError'
import {
    useStaffDispatchContext,
    useStaffReleaseFormContext,
} from 'src/hooks/useStaffReleaseForm'
import { useUpdateRelease } from 'src/hooks/useUpdateRelease'
import { CreateReleaseSchema } from 'src/schema/CreateReleaseSchema'
import {
    CreateReleaseRecord,
    UpdateReleaseRecord,
} from 'src/types/ReleaseRecord'
import { notify } from 'src/utils/notify'
import { validateForm } from 'src/utils/validateForm'
import ReleaseForm from '../ReleaseForm/ReleaseForm'
import ReleasesList from '../ReleasesList/ReleasesList'
import { Container, Wrapper } from './StaffFormContainer.styled'
import {
    getDeletedIdsFromObjectArrays,
    getDeletedIdsFromPrimitiveArrays,
    getDeleteIdsFromImages,
    getNewValuesFromImages,
    getNewValuesFromObjectArrays,
    getNewValuesFromPrimitiveArrays,
    omitFields,
    validateImages,
} from './StaffFormContainer.utils'
import { isArray } from 'lodash'

const StaffFormContainer = () => {
    const dispatch = useStaffDispatchContext()
    const { formState: state, selectedRelease } = useStaffReleaseFormContext()

    const { mutateAsync: createRelease, error: createReleaseError } =
        useCreateRelease()

    const { mutateAsync: updateRelease, error: updateReleaseError } =
        useUpdateRelease()

    const { mutateAsync: deleteRelease, error: deleteReleaseError } =
        useDeleteRelease()

    const handleCreateRelease = async (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        e.preventDefault()

        try {
            validateImages({ images: state.images })

            const releaseRecord: CreateReleaseRecord = {
                ...state,
                release_at: new Date(state.release_at).toISOString(),
            }

            validateForm(CreateReleaseSchema, releaseRecord)

            const items = releaseRecord.items.map(omitFields)
            const attachments = releaseRecord.attachments.map(omitFields)
            const instructions = releaseRecord.instructions.map(omitFields)

            await createRelease({
                ...releaseRecord,
                items,
                attachments,
                instructions,
            })
            resetForm(dispatch)()
            notify('Release successfully created', 'success')
        } catch (error) {
            notify((error as Error).message, 'error')
        }
    }

    const handleUpdateRelease = async (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        e.preventDefault()

        try {
            validateImages({ images: state.images })

            const instructionsToDelete = getDeletedIdsFromObjectArrays({
                releaseRecordItems: state.instructions,
                selectedItems: selectedRelease.instructions,
            })

            const attachmentsToDelete = getDeletedIdsFromObjectArrays({
                releaseRecordItems: state.attachments,
                selectedItems: selectedRelease.attachments,
            })

            const itemsToDelete = getDeletedIdsFromObjectArrays({
                releaseRecordItems: state.items,
                selectedItems: selectedRelease.items,
            })

            const brandsToDelete = getDeletedIdsFromPrimitiveArrays({
                releaseRecordItems: state.brands,
                selectedItems: selectedRelease.brands,
            })

            const categoriesToDelete = getDeletedIdsFromPrimitiveArrays({
                releaseRecordItems: state.categories,
                selectedItems: selectedRelease.categories,
            })

            const keywordGroupToDelete = getDeletedIdsFromPrimitiveArrays({
                releaseRecordItems: state.keyword_group,
                selectedItems: selectedRelease.keyword_group,
            })

            const imagesToDelete = getDeleteIdsFromImages({
                images: state.images,
                selectedImages: selectedRelease.images,
            })

            const instructionsToCreate = getNewValuesFromObjectArrays({
                releaseRecordItems: state.instructions,
                selectedItems: selectedRelease.instructions,
            })

            let attachmentsToCreate = getNewValuesFromObjectArrays({
                releaseRecordItems: state.attachments,
                selectedItems: selectedRelease.attachments,
            })

            console.log('releaseRecordItems', state.attachments)
            console.log('selectedItems', selectedRelease.attachments)
            console.log('attachmentsToCreate', attachmentsToCreate)

            attachmentsToCreate = attachmentsToCreate.map((attachment) => ({
                ...attachment,
                // @ts-ignore
                store: attachment.store?.id || undefined,
                locations: isArray(attachment.locations)
                    ? {
                          connect: attachment.locations.map(
                              // @ts-ignore
                              (location) => location.id
                          ),
                      }
                    : attachment.locations,
            }))

            console.log('final attachmentsToCreate', attachmentsToCreate)

            const itemsToCreate = getNewValuesFromObjectArrays({
                releaseRecordItems: state.items,
                selectedItems: selectedRelease.items,
            })

            const brandsToCreate = getNewValuesFromPrimitiveArrays({
                releaseRecordItems: state.brands,
                selectedItems: selectedRelease.brands,
            })

            const categoriesToCreate = getNewValuesFromPrimitiveArrays({
                releaseRecordItems: state.categories,
                selectedItems: selectedRelease.categories,
            })

            const keywordGroupToCreate = getNewValuesFromPrimitiveArrays({
                releaseRecordItems: state.keyword_group,
                selectedItems: selectedRelease.keyword_group,
            })

            const imagesToCreate = getNewValuesFromImages({
                images: state.images,
                selectedImages: selectedRelease.images,
            })

            const releaseRecord: UpdateReleaseRecord = {
                ...state,
                id: selectedRelease.id,
                release_at: new Date(state.release_at).toISOString(),
                instructions: {
                    create: instructionsToCreate,
                    delete: instructionsToDelete,
                },
                brands: {
                    create: brandsToCreate,
                    delete: brandsToDelete,
                },
                categories: {
                    create: categoriesToCreate,
                    delete: categoriesToDelete,
                },
                images: {
                    create: imagesToCreate,
                    delete: imagesToDelete,
                },
                attachments: {
                    create: attachmentsToCreate,
                    delete: attachmentsToDelete,
                },
                items: {
                    create: itemsToCreate,
                    delete: itemsToDelete,
                },
                keyword_group: {
                    create: keywordGroupToCreate,
                    delete: keywordGroupToDelete,
                },
            }

            await updateRelease({ releaseRecord, id: releaseRecord.id })
            resetForm(dispatch)()
            notify('Release successfully updated', 'success')
        } catch (error) {
            notify((error as Error).message, 'error')
        }
    }

    const handleDeleteRelease =
        (id: string) =>
        async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            e.preventDefault()

            try {
                await deleteRelease(id)
                notify('Release successfully deleted', 'success')
            } catch (error) {
                notify((error as Error).message, 'error')
            }
        }

    const handleClearForm = (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        e.preventDefault()
        resetForm(dispatch)()
    }

    useError(createReleaseError, (message: string) => notify(message, 'error'))
    useError(updateReleaseError, (message: string) => notify(message, 'error'))
    useError(deleteReleaseError, (message: string) => notify(message, 'error'))

    return (
        <Container>
            <Wrapper>
                <ReleasesList handleDeleteRelease={handleDeleteRelease} />

                <ReleaseForm
                    handleClearForm={handleClearForm}
                    handleUpdateRelease={handleUpdateRelease}
                    handleCreateRelease={handleCreateRelease}
                />
            </Wrapper>
        </Container>
    )
}

export default StaffFormContainer
