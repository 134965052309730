import { setFormFields } from 'src/contexts/StaffReleaseFormContext/StaffReleaseFormContext.services'
import useAuthContext from 'src/hooks/useAuthContext'
import {
    useStaffDispatchContext,
    useStaffReleaseFormContext,
} from 'src/hooks/useStaffReleaseForm'
import { CreateReleaseRecord, ReleaseType } from 'src/types/ReleaseRecord'
import { Item, Thumbnail } from './SearchItem.styled'
import { SearchItemProps } from './SearchItem.types'

const SearchItem = ({
    item: {
        name,
        thumbnail_url,
        style_id,
        release_date,
        description,
        make,
        price,
        colorway,
        media,
        brand,
        product_category,
    },
}: SearchItemProps) => {
    const { userInfo } = useAuthContext()
    const dispatch = useStaffDispatchContext()
    const {
        formState: { type, region },
    } = useStaffReleaseFormContext()

    return (
        <Item
            onClick={async () => {
                let release = {} as CreateReleaseRecord
                const date = new Date(release_date || Date.now())
                const image =
                    media.smallImageUrl || 'https://i.imgur.com/WDBcPXF.png'
                const images = [{ value: image, transparent: false }]
                const sku = style_id || ''
                const content = description || ''
                const itemName = name || make || ''
                const brands = [brand.toLowerCase()]
                const categories = [product_category.toLowerCase()]

                if (type === ReleaseType.SINGLE) {
                    release = {
                        sku,
                        title: itemName,
                        region,
                        content,
                        colorway,
                        cover_color_hex: 'FFFFFF',
                        published: false,
                        publisher_id: userInfo.id,
                        type: ReleaseType.SINGLE,
                        release_at: date,
                        images,
                        cover_image: '',
                        cover_transparent: false,
                        brands,
                        categories,
                        keyword_group: [],
                        instructions: [],
                        attachments: [],
                        retail_price_usd: price,
                        resell_price_usd: 0,
                        items: [],
                    }
                } else {
                    release = {
                        sku,
                        title: itemName,
                        region,
                        content,
                        colorway,
                        cover_color_hex: 'FFFFFF',
                        published: false,
                        publisher_id: userInfo.id,
                        type: ReleaseType.SINGLE,
                        release_at: date,
                        images,
                        brands,
                        categories,
                        keyword_group: [],
                        cover_image: '',
                        cover_transparent: false,
                        instructions: [],
                        items: [],
                        attachments: [],
                    }
                }

                setFormFields(dispatch)(release)
            }}
        >
            <Thumbnail src={thumbnail_url} alt={name} />
            <p>{name || make}</p>
        </Item>
    )
}

export default SearchItem
