import axios from 'axios'
import { useState } from 'react'
import FormButton from 'src/components/common/FormButton/FormButton'
import Phones from '../../../assets/phones-2.png'
import sleep from '../../../utils/sleep'
import { ErrorAlert, SuccessAlert } from '../../common/common'
import CardWithSadEmoji from '../CardWithSadEmoji/CardWithSadEmoji'
import {
    ArrowRight,
    CancelContainer,
    CardInfoContainer,
    GreenPrice,
    Heading,
    HeadingContainer,
    Image,
    ImageContainer,
    ImageWrapper,
    InfoHeading,
    PriceContainer,
    PriceHeading,
    PriceWrapper,
    RedPrice,
    Subheading,
} from './AnotherChanceCard.styled'
import { AnotherChanceCardProps } from './AnotherChanceCard.types'

const AnotherChanceCard = ({
    toggleModal,
    toggleOtherModal,
}: AnotherChanceCardProps) => {
    const [declinedFirstOffer, setDeclinedFirstOffer] = useState(false)
    const [{ error, message }, setState] = useState({
        error: '',
        message: '',
    })

    const handleAcceptOffer = async () => {
        if (declinedFirstOffer) {
            // Declined the first offer but accepts the second offer
            try {
                const res = await axios.post(
                    '/api/v1/users/@me/claimOffer',
                    { offerId: 'cancellationOffer100' },
                    { withCredentials: true }
                )

                if (res.status === 200) {
                    setState({
                        error: '',
                        message: 'Offer successfully claimed.',
                    })
                    await sleep(2000)
                    toggleModal()
                    window.location.href = '/subscriptions'
                }
            } catch (err) {
                const errorMessage = (err as Error).message
                setState({ error: errorMessage, message: '' })
            }
        } else {
            // Accepts the first offer
            try {
                const res = await axios.post(
                    '/api/v1/users/@me/claimOffer',
                    { offerId: 'cancellationOffer50' },
                    { withCredentials: true }
                )

                if (res.status === 200) {
                    setState({
                        error: '',
                        message: 'Offer successfully claimed.',
                    })
                    await sleep(2000)
                    toggleModal()
                    window.location.href = '/subscriptions'
                }
            } catch (err) {
                if (axios.isAxiosError(err)) {
                    const errorMessage = err.response?.data?.message
                    setState({ error: errorMessage, message: '' })
                }
            }
        }
    }

    const handleDecline = async () => {
        if (!declinedFirstOffer) return setDeclinedFirstOffer(true)

        toggleModal()
        await sleep(500)
        toggleOtherModal()
    }

    return (
        <CardWithSadEmoji>
            {error && <ErrorAlert>{error}</ErrorAlert>}
            {message && <SuccessAlert>{message}</SuccessAlert>}

            <CardInfoContainer>
                <HeadingContainer>
                    <Heading>
                        {declinedFirstOffer
                            ? 'Last chance'
                            : 'Give us another chance?'}
                    </Heading>
                    <Subheading>
                        {declinedFirstOffer
                            ? 'Have a free month on us.'
                            : 'We’ve prepared a special offer for you.'}
                    </Subheading>
                </HeadingContainer>

                <ImageContainer>
                    <ImageWrapper>
                        <Image src={Phones} />
                    </ImageWrapper>

                    <PriceContainer>
                        <PriceWrapper>
                            <PriceHeading>Regular Price</PriceHeading>
                            <RedPrice>$10.00/mo</RedPrice>
                        </PriceWrapper>

                        <ArrowRight />

                        <PriceWrapper>
                            <PriceHeading>Next Month</PriceHeading>
                            <GreenPrice>
                                {declinedFirstOffer ? 'FREE' : '$5.00/mo'}
                            </GreenPrice>
                        </PriceWrapper>
                    </PriceContainer>
                </ImageContainer>

                <FormButton
                    fullWidth
                    color="purple"
                    onClick={handleAcceptOffer}
                >
                    Accept Offer
                </FormButton>

                <CancelContainer>
                    <InfoHeading onClick={handleDecline}>No thanks</InfoHeading>
                </CancelContainer>
            </CardInfoContainer>
        </CardWithSadEmoji>
    )
}

export default AnotherChanceCard
