import { ReleaseRecord, CreateReleaseRecord } from 'src/types/ReleaseRecord';
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { QueryKeys } from "src/constants/QueryKeys"
import { axiosInstance } from "src/utils/axios"

const createRelease = (
    releaseRecord: CreateReleaseRecord,
) => {
    return axiosInstance.post<ReleaseRecord>(
        '/releases/create',
        releaseRecord
    ).then(({ data }) => data)
}

export const useCreateRelease = () => {
    const queryClient = useQueryClient();

    const onSuccess = async () => {
        await queryClient.invalidateQueries([QueryKeys.releases])
        // queryClient.setQueryData<ReleaseRecord[]>([QueryKeys.releases], (oldQueryData) => {
        //     if (oldQueryData) {
        //         const newReleases = [data, ...oldQueryData];
        //         const sortedReleases = newReleases.sort((a: any, b: any) => a.date - b.date);                
        //         return sortedReleases
        //     }
        //     return [data];
        // })
    }

    return useMutation(createRelease, { onSuccess })
}