import { useContext, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import logo from '../assets/logo.svg'

import hamburgerIcon from '../assets/hamburger.svg'
import logOutIcon from '../assets/logout.svg'
import backNavArrow from '../assets/back-nav-arrow.svg'
import { AuthContext } from '../contexts/Auth'

const Row = styled.div`
  display: flex;
  align-items: center;
  width: 1240px;
  max-width: 1040px;
  /* max-width: 90vw; */
  margin: 20px auto 50px;

  @media only screen and (max-width: 900px) {
    display: none;
  }
`

const Logo = styled.img`
  margin-right: auto;
`

const StyledLink = styled(Link)`
  text-decoration: none;
`

const Button = styled.span<{ active: boolean }>`
  padding: 5px 10px;
  color: ${({ active }) => (active ? 'white' : '#828493')};
  border-radius: 10px;
  font-weight: bold;
  cursor: pointer;
  margin-right: 10px;
`

const SignOutButton = styled.span`
  cursor: pointer;
  font-weight: 600;
  background: linear-gradient(180deg,#ff83af 0%,#f45a90 100%);
  box-shadow: 0px 5px 10px rgba(0,0,0,0.25);
  border-radius: 30px;
  margin-left: auto;
  width: 120px;
  height: 50px;
  display: block;
  text-align: center;
  line-height: 50px;
`

const MobileRow = styled.div`
  display: flex;
  align-items: center;
  background: #2e2e2e;
  padding: 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);

  @media only screen and (min-width: 901px) {
    display: none;
  }
`

const MobileOpener = styled.img`
  margin-right: auto;
`

const MobileLogo = styled.img`
  height: 30px;
`

const MobileLogOut = styled.img`
  margin-left: auto;
`

const MobileNavPopout = styled.div`
  background: #2e2e2e;
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-top: 1px solid #3e3e3e;
`

const PopoutLink = styled(Link)`
  color: white;
  opacity: 0.8;
  font-weight: 600;
  margin-bottom: 5px;
  text-decoration: none;
`

const ForceRefesh = styled.span`
  color: white;
  opacity: 0.8;
  font-weight: 600;
  margin-bottom: 5px;
  text-decoration: none;
`

interface NavProps {
  onClose?: () => void
  modalNav?: boolean
}

const Nav = ({ modalNav, onClose }: NavProps) => {
  const location = useLocation()
  const authContext = useContext(AuthContext)

  const signOutHandler = () => {
    document.cookie = "swiftsole=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    window.location.href = "/api/v1/auth/loginRedirect?path=" + window.location.pathname
  }

  const loginHandler = () => {
    window.location.href = "/api/v1/auth/loginRedirect?path=" + window.location.pathname
  }

  const [navOpen, setNavOpen] = useState(false)

  return (
    <>
      <Row>
        <Logo src={logo} />
        <span
          onClick={() => {
            window.location.href = '/subscriptions';
          }}
        >
          <Button
            active={
              location.pathname === '/subscriptions' ||
              location.pathname === '/'
            }
          >
            Subscriptions
          </Button>
        </span>
        { authContext.isAuth && authContext.userInfo && (authContext.userInfo.role === 'admin' || authContext.userInfo.role === 'customer') &&
        <StyledLink to="/release-calendar">
          <Button active={location.pathname === '/release-calendar'}>
            Release Calendar
          </Button>
        </StyledLink>
        }
        { authContext.isAuth && authContext.userInfo && (authContext.userInfo.role === 'admin' || authContext.userInfo.role === 'customer') &&
          <StyledLink to="/downloads">
            <Button active={location.pathname === '/downloads'}>Downloads</Button>
          </StyledLink>
        }

        { authContext.isAuth && authContext.userInfo && (authContext.userInfo.role === 'admin' || authContext.userInfo.role === 'customer') &&
         <StyledLink to="/referrals">
          <Button active={location.pathname === '/referrals'}>Referrals</Button>
        </StyledLink>
        }
       
        <StyledLink to="/settings">
          <Button active={location.pathname === '/settings'}>Settings</Button>
        </StyledLink>
        {
          authContext.userInfo.id && 
          <SignOutButton onClick={signOutHandler}>Sign Out</SignOutButton>
        }
        {
          !authContext.userInfo.id && 
          <SignOutButton onClick={loginHandler}>Login</SignOutButton>
        }
      </Row>
      <MobileRow>
        <MobileOpener
          src={modalNav ? backNavArrow : hamburgerIcon}
          onClick={() =>
            modalNav && onClose ? onClose() : setNavOpen((prev) => !prev)
          }
        />
        <MobileLogo src={logo} />
        <MobileLogOut onClick={signOutHandler} src={logOutIcon} />
      </MobileRow>
      {navOpen && (
        <MobileNavPopout>
          <ForceRefesh onClick={() => {
            window.location.href = '/subscriptions';
            setNavOpen(false);
          }}>
            Subscriptions
          </ForceRefesh>
          { authContext.isAuth && authContext.userInfo && (authContext.userInfo.role === 'admin' || authContext.userInfo.role === 'customer') &&
            <PopoutLink onClick={() => setNavOpen(false)} to="/release-calendar">
              Release Calendar
            </PopoutLink>
          }
          { authContext.isAuth && authContext.userInfo && (authContext.userInfo.role === 'admin' || authContext.userInfo.role === 'customer') &&
            <PopoutLink onClick={() => setNavOpen(false)} to="/downloads">
              Downloads
            </PopoutLink>
          }
          <PopoutLink onClick={() => setNavOpen(false)} to="/settings">
            Settings
          </PopoutLink>
          <PopoutLink onClick={() => setNavOpen(false)} to="/referrals">
            Referrals
          </PopoutLink>
        </MobileNavPopout>
      )}
    </>
  )
}

export default Nav
