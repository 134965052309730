import axios from 'axios'
import dayjs from 'dayjs'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ReactComponent as ClearIcon } from '../assets/clear-input.svg'
import { ReactComponent as LeftArrow } from '../assets/left-arrow-light.svg'
import { ReactComponent as RightArrow } from '../assets/right-arrow-light.svg'
import { ReactComponent as SearchIcon } from '../assets/search-icon.svg'
import Modal from '../components/common/Modal/Modal'
import Spinner from '../components/common/Spinner/Spinner'
import DropItem from '../components/release-calendar/DropItem/DropItem'
import ReleaseCard from '../components/release-calendar/ReleaseCard/ReleaseCard'
import ReleaseView from '../components/release-calendar/ReleaseView/ReleaseView'
import { Release } from '../components/types.2'
import {
    calcDaysInCurrentMonth,
    calcDaysInNextMonth,
    calcDaysInPreviousMonth,
    calculateEventDays,
} from '../utils/calendar'
dayjs.extend(weekOfYear)

const Container = styled.div`
    width: 100%;
    max-width: 1190px;
    margin: 0 auto;
    display: flex;
    padding: 20px;

    > * + * {
        margin-left: 40px;
    }

    @media only screen and (max-width: 768px) {
        flex-direction: column;

        > * + * {
            margin-left: 0px;
        }
    }
`

const ReleaseViewContainer = styled.div`
    flex: 1;

    @media only screen and (max-width: 768px) {
        order: 2;
    }
`

const ReleaseToolsContainer = styled.div`
    width: 100%;
    height: 100%;
    max-width: 365px;
    display: flex;
    flex-direction: column;

    > * + * {
        margin-top: 40px;
    }

    @media only screen and (max-width: 768px) {
        order: 1;
        margin: 0 auto;

        > * + * {
            margin-top: 0px;
        }

        > * + * {
            margin-bottom: 40px;
        }
    }
`

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    > * + * {
        margin-top: 10px;
    }
`

const CalendarWrapper = styled(Wrapper)`
    order: 1;

    @media only screen and (max-width: 768px) {
        order: 2;
    }
`

const SearchWrapper = styled(Wrapper)`
    order: 2;

    @media only screen and (max-width: 768px) {
        order: 1;
    }
`

export const FlexRow = styled.div`
    display: flex;
`

export const FlexRowBetween = styled(FlexRow)`
    width: 100%;
    justify-content: space-between;
`

export const ClearButton = styled(ClearIcon)`
    cursor: pointer;
`

export const ReleaseCardsContainer = styled.div`
    display: none;
    gap: 10px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    max-width: 330px;
    margin: 40px auto 0px auto;
    width: 100;
    justify-content: center;

    @media only screen and (max-width: 768px) {
        display: grid;
    }
`

export const CurrentDate = styled.h2`
    color: white;
    font-size: 18px;
    font-weight: 700;
    grid-column: span 2 / span 2;
`

export const ReleaseViewWrapper = styled.div`
    display: block;

    @media only screen and (max-width: 768px) {
        display: none;
    }
`

type Region = 'US' | 'EU' | 'AS'

const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

export type RequestStatus = 'idle' | 'pending' | 'fulfilled' | 'rejected'

type State = {
    status: RequestStatus
    releases: Release[]
    error: string
}

function ReleaseCalendarPage() {
    const [region, setRegion] = useState<Region>('US')
    const [isCurrMonth, setIsCurrMonth] = useState(false)
    const [{ status, releases, error }, setState] = useState<State>({
        status: 'idle',
        releases: [] as Release[],
        error: '',
    })
    const [search, setSearch] = useState('')
    const [selectedRelease, setSelectedRelease] = useState<Release>(
        {} as Release
    )
    const [searchedReleases, setSearchedReleases] = useState<Release[]>(
        [] as Release[]
    )
    const [currDisplayedDate, setCurrDisplayedDate] = useState(Date.now())
    const [daysWithEventsInCurrMonth, setDaysWithEventsInCurrMonth] = useState([
        1,
    ])
    const [daysWithEventsInNextMonth, setDaysWithEventsInNextMonth] = useState([
        1,
    ])
    const [daysWithEventsInPrevMonth, setDaysWithEventsInPrevMonth] = useState([
        1,
    ])
    const [currentReleaseIdx, setCurrentReleaseIdx] = useState(0)
    const [showModal, setShowModal] = useState(false)
    const toggleModal = () => setShowModal((prev) => !prev)

    const currentDay = dayjs(currDisplayedDate).format('D')
    const currentYear = dayjs(currDisplayedDate).format('YYYY')
    const currentMonth = dayjs(currDisplayedDate).format('MMMM')

    const getNextMonth = () =>
        setCurrDisplayedDate((prev) => {
            if (
                dayjs(prev).add(1, 'month').valueOf() ===
                dayjs(Date.now()).valueOf()
            ) {
                return dayjs(Date.now()).valueOf()
            }
            return dayjs(prev).add(1, 'month').valueOf()
        })

    const getPrevMonth = () =>
        setCurrDisplayedDate((prev) => {
            if (
                dayjs(prev).subtract(1, 'month').valueOf() ===
                dayjs(Date.now()).valueOf()
            ) {
                return dayjs(Date.now()).valueOf()
            }
            return dayjs(prev).subtract(1, 'month').valueOf()
        })

    const handleClearSearch = () => {
        setSearch('')
        setSearchedReleases(releases)
    }

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value)
    }

    const handleRegionChange = (
        event: React.ChangeEvent<HTMLSelectElement>
    ) => {
        setRegion(event.target.value as Region)
    }

    const handleDropItemClick = (name: string) => {
        const release = releases.find((release, idx) => {
            if (release.name === name) {
                setCurrentReleaseIdx(idx)
                return true
            }
            return false
        })
        setSelectedRelease(release as Release)
    }

    const handleDateClick = (day: number) => {
        const date = new Date(currDisplayedDate)
        const currMonth = date.getMonth()

        const release = releases.find((release, idx) => {
            const releaseDate = new Date(release.timestamp._seconds * 1000)
            const releaseDay = releaseDate.getDate()
            const releaseMonth = releaseDate.getMonth()

            if (day === releaseDay && currMonth === releaseMonth) {
                setCurrentReleaseIdx(idx)
                return true
            }

            return false
        })

        setSelectedRelease(release as Release)
    }

    const getNextRelease = () => {
        setCurrentReleaseIdx((prev) => {
            return prev === releases.length - 1 ? prev : prev + 1
        })
    }
    const getPrevRelease = () => {
        setCurrentReleaseIdx((prev) => {
            return prev === 0 ? prev : prev - 1
        })
    }

    useEffect(() => {
        setState((prev) => ({ ...prev, status: 'pending' }))
        ;(async () => {
            try {
                const {
                    data: { data },
                } = await axios.get<{ data: Release[] }>(
                    `/api/v1/users/calendar/${region}`,
                    { withCredentials: true }
                )
                const filteredReleases = data
                    .filter(
                        (release) => !release?.description && release.item_info
                    )
                    .filter((release) => {
                        const today = new Date()
                        const releaseDate = new Date(
                            release.timestamp._seconds * 1000
                        )
                        return (
                            today.toDateString() ===
                                releaseDate.toDateString() ||
                            releaseDate.getTime() > today.getTime()
                        )
                    })
                    .sort(
                        (release1: Release, release2: Release) =>
                            release1.timestamp._seconds -
                            release2.timestamp._seconds
                    )
                setSelectedRelease(filteredReleases[currentReleaseIdx])
                setSearchedReleases(filteredReleases)
                setState({
                    status: 'fulfilled',
                    releases: filteredReleases,
                    error: '',
                })
            } catch (error: any) {
                setState({
                    status: 'rejected',
                    releases: [],
                    error: error.message,
                })
            }
        })()
    }, [region])

    useEffect(() => {
        const isCurrMonth =
            dayjs(currDisplayedDate).startOf('month').valueOf() ===
            dayjs(Date.now()).startOf('month').valueOf()

        setIsCurrMonth(isCurrMonth)
        calculateEventDays(
            releases,
            currDisplayedDate,
            setDaysWithEventsInCurrMonth,
            setDaysWithEventsInPrevMonth,
            setDaysWithEventsInNextMonth
        )
    }, [currDisplayedDate, releases])

    useEffect(() => {
        calculateEventDays(
            releases,
            currDisplayedDate,
            setDaysWithEventsInCurrMonth,
            setDaysWithEventsInPrevMonth,
            setDaysWithEventsInNextMonth
        )
    }, [releases])

    useEffect(() => {
        setSelectedRelease(releases[currentReleaseIdx])
    }, [currentReleaseIdx])

    useEffect(() => {
        if (releases.length > 0) {
            const searchedReleases = releases.filter((release) =>
                release.name.toLowerCase().includes(search.toLowerCase())
            )
            setSearchedReleases(searchedReleases)
        }
    }, [search, releases])

    const filteredMobileReleases =
        releases.length > 0
            ? releases.filter(({ timestamp }) => {
                  const releaseDateString = new Date(
                      timestamp._seconds * 1000
                  ).toDateString()
                  const currentDateString = new Date().toDateString()
                  if (releaseDateString === currentDateString) return true
                  return false
              })
            : []

    return (
        <Container>
            <ReleaseViewContainer>
                {status === 'idle' || status === 'pending' ? (
                    <Spinner maxHeight="700px" maxWidth="795px" />
                ) : releases.length > 0 ? (
                    <>
                        <ReleaseViewWrapper>
                            <ReleaseView
                                {...selectedRelease}
                                getNextRelease={getNextRelease}
                                getPrevRelease={getPrevRelease}
                                disableLeft={currentReleaseIdx === 0}
                                disableRight={
                                    releases.length - 1 === currentReleaseIdx
                                }
                            />
                        </ReleaseViewWrapper>

                        <ReleaseCardsContainer>
                            <CurrentDate>
                                {dayjs(Date.now()).format(
                                    'dddd, MMMM D[th], YYYY'
                                )}
                            </CurrentDate>

                            {filteredMobileReleases.length > 0 ? (
                                filteredMobileReleases.map((release) => (
                                    <ReleaseCard
                                        {...release}
                                        key={release.name}
                                        handleClick={() => {
                                            setSelectedRelease(release)
                                            toggleModal()
                                        }}
                                    />
                                ))
                            ) : (
                                <p>No releases today</p>
                            )}
                        </ReleaseCardsContainer>
                    </>
                ) : (
                    <p>No releases found</p>
                )}
            </ReleaseViewContainer>

            <ReleaseToolsContainer>
                <CalendarWrapper>
                    <FlexRowBetween>
                        <div>
                            <SectionTitle>{currentMonth}</SectionTitle>{' '}
                            <CalendarYear>{currentYear}</CalendarYear>
                        </div>

                        <ButtonsContainer>
                            <LeftArrow
                                style={{ cursor: 'pointer' }}
                                onClick={getPrevMonth}
                            />
                            <RightArrow
                                style={{ cursor: 'pointer' }}
                                onClick={getNextMonth}
                            />
                        </ButtonsContainer>
                    </FlexRowBetween>

                    {status === 'idle' || status === 'pending' ? (
                        <Spinner maxHeight="310px" maxWidth="365px" />
                    ) : (
                        <CalendarContainer>
                            <GridHeader>
                                {days.map((day) => (
                                    <GridItem key={day}>
                                        <GridItemInfo>{day}</GridItemInfo>
                                    </GridItem>
                                ))}
                            </GridHeader>
                            <GridBody>
                                {calcDaysInPreviousMonth(currDisplayedDate).map(
                                    (day) => (
                                        <NonCurrDate key={day}>
                                            <span>{day}</span>
                                            {daysWithEventsInPrevMonth.includes(
                                                day
                                            ) && <Dot />}
                                        </NonCurrDate>
                                    )
                                )}
                                {calcDaysInCurrentMonth(currDisplayedDate).map(
                                    (day) => (
                                        <GridItem
                                            key={day}
                                            isCurrentWeek={
                                                isCurrMonth &&
                                                dayjs().date(day).week() ===
                                                    dayjs().week()
                                            }
                                            onClick={() =>
                                                daysWithEventsInCurrMonth.includes(
                                                    day
                                                ) && handleDateClick(day)
                                            }
                                        >
                                            <GridItemInfo
                                                isCurrentDay={
                                                    isCurrMonth &&
                                                    +currentDay === day
                                                }
                                            >
                                                <span>{day}</span>
                                                {daysWithEventsInCurrMonth.includes(
                                                    day
                                                ) && (
                                                    <Dot
                                                        hasEvent
                                                        isCurrentDay={
                                                            +currentDay === day
                                                        }
                                                    />
                                                )}
                                            </GridItemInfo>
                                        </GridItem>
                                    )
                                )}
                                {calcDaysInNextMonth(currDisplayedDate).map(
                                    (day) => (
                                        <NonCurrDate key={day}>
                                            <span>{day}</span>
                                            {daysWithEventsInNextMonth.includes(
                                                day
                                            ) && <Dot />}
                                        </NonCurrDate>
                                    )
                                )}
                            </GridBody>
                        </CalendarContainer>
                    )}
                </CalendarWrapper>

                <SearchWrapper>
                    <FlexRowBetween>
                        <SectionTitle>Region</SectionTitle>

                        <select name="region" onChange={handleRegionChange}>
                            <option value="US">US</option>
                            <option value="EU">EU</option>
                            <option value="AS">AS</option>
                        </select>
                    </FlexRowBetween>

                    {status === 'idle' || status === 'pending' ? (
                        <Spinner maxHeight="310px" maxWidth="365px" />
                    ) : (
                        <DropListContainer>
                            <SeachbarContainer>
                                <SearchIcon />
                                <Searchbar
                                    name="search"
                                    value={search}
                                    placeholder="Search"
                                    onChange={handleSearchChange}
                                />
                                <ClearButton onClick={handleClearSearch} />
                            </SeachbarContainer>

                            <DropList>
                                {searchedReleases.length > 0 &&
                                    searchedReleases.map((release, idx) => (
                                        <DropItem
                                            key={release.name + idx}
                                            name={release.name}
                                            retailPrice={
                                                release.item_info.price
                                            }
                                            imageSrc={release.images[0]}
                                            releaseTime={
                                                release.timestamp._seconds
                                            }
                                            handleClick={() => {
                                                if (window.innerWidth <= 768) {
                                                    setSelectedRelease(release)
                                                    toggleModal()
                                                    return
                                                }
                                                handleDropItemClick(
                                                    release.name
                                                )
                                            }}
                                        />
                                    ))}
                            </DropList>
                        </DropListContainer>
                    )}
                </SearchWrapper>
            </ReleaseToolsContainer>

            <Modal showModal={showModal} toggleModal={toggleModal}>
                <ReleaseView toggleModal={toggleModal} {...selectedRelease} />
            </Modal>
        </Container>
    )
}

export default ReleaseCalendarPage

export const DropListContainer = styled.div`
    width: 100%;
    border-radius: 15px;
    background-color: #ffffff10;
    overflow: hidden;
`

export const SeachbarContainer = styled(FlexRow)`
    align-items: center;
    padding: 10px;
    background-color: #ffffff10;
    border-bottom: 1px solid #ffffff20;
`

export const Searchbar = styled.input`
    width: 100%;
    font-size: 16px;
    font-weight: 600;
    color: white;
    background: transparent;
    font-family: Prompt;
    margin: 0 10px;

    &:focus {
        outline: none;
    }
`

export const DropList = styled.div`
    max-height: 390px;
    overflow-y: auto;

    > * + * {
        border-top: 1px solid #ffffff20;
    }
`
// ##########################

const CalendarDate = styled.span`
    font-size: 18px;
`

export const SectionTitle = styled(CalendarDate)`
    font-weight: 700;
`

export const CalendarYear = styled(CalendarDate)`
    font-weight: 500;
`

export const ButtonsContainer = styled(FlexRow)`
    > * + * {
        margin-left: 5px;
    }
`

export const CalendarContainer = styled.div`
    width: 100%;
    border-radius: 15px;
    background-color: #ffffff10;
    overflow: hidden;
`

export const GridHeader = styled.div`
    display: grid;
    font-size: 14px;
    color: #ffffff80;
    background-color: #ffffff10;
    border-bottom: 1px solid #ffffff20;
    grid-template-columns: repeat(7, minmax(0, 1fr));
`

export const GridBody = styled.div`
    display: grid;
    grid-template-columns: repeat(7, minmax(0, 1fr));
`

export const GridItem = styled.div<{
    isCurrentDay?: boolean
    isCurrentWeek?: boolean
}>`
    width: 100%;
    height: 52px;
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    max-width: 52px;
    grid-column: span 1 / span 1;
    background: ${({ isCurrentWeek }) =>
        isCurrentWeek ? '#6E7CFF15' : 'unset'};
`

export const GridItemInfo = styled.div<{ isCurrentDay?: boolean }>`
    width: 100%;
    height: 100%;
    font-weight: 500;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: ${({ isCurrentDay }) => (isCurrentDay ? '15px' : 'unset')};
    background: ${({ isCurrentDay }) => (isCurrentDay ? '#6E7CFF' : 'unset')};
`

export const NonCurrDate = styled(GridItem)`
    color: #ffffff40;
`

export const Dot = styled.div<{ hasEvent?: boolean; isCurrentDay?: boolean }>`
    background: ${({ hasEvent, isCurrentDay }) =>
        isCurrentDay ? 'white' : hasEvent ? '#FF566F' : '#FF566F70'};
    border-radius: 100%;
    height: 4px;
    width: 4px;
`
