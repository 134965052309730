import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe
} from '@stripe/react-stripe-js'
import { StripeError } from '@stripe/stripe-js'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import ReactPixel from 'react-facebook-pixel'
import { TailSpin } from 'react-loading-icons'
import { useLocation } from 'react-router-dom'
import Modal from '../../common/Modal/Modal'
import EmailCheckCard from '../EmailCheckCard/EmailCheckCard'
import {
  ApplyButton,
  BreakdownContainer,
  BreakDownText,
  BreakDownWrapper,
  Container,
  FlexRow,
  FormInput,
  FormInputContainerLarge,
  FormInputContainerSmall,
  FormLabel,
  InfoContainer,
  Link,
  stripeStyle,
  SubmitButton,
  TOS
} from './PurchaseForm.styled'
import { FormState, PurchaseFormProps } from './PurchaseForm.types'

function PurchaseForm({
  userInfo,
  product,
  password,
  couponDetails,
  setCouponDetails,
  total,
  trialDays,
  setState,
  state
}: PurchaseFormProps) {
  const stripe = useStripe()
  const elements = useElements()
  const { search } = useLocation()

  const referralCodeCookie = document.cookie.replace(
    /(?:(?:^|.*;\s*)referralCode\s*\=\s*([^;]*).*$)|^.*$/,
    '$1'
  )

  const [formState, setFormState] = useState<FormState>({
    fullName: '',
    email: '',
    coupon: '',
    referralCode: referralCodeCookie || '',
  })

  const [showEmailCheckModal, setShowEmailCheckModal] = useState(false)

  const toggleEmailCheckModal = () => setShowEmailCheckModal((prev) => !prev)

  const onSubscriptionComplete = (result: any) => {
    if (!result) return null

    setState((prev) => ({
      ...prev,
      isSubmitting: false,
    }))

    // Payment was successful.
    if (
      result.subscription.status === 'active' ||
      result.subscription.status === 'trialing'
    ) {
      ReactPixel.track('Purchase', {
        value: '59.99',
        currency: 'USD',
        content_type: 'product',
        content_name: 'swiftsole_v3',
      })
      // remove referralCode cookie
      document.cookie =
        'referralCode=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'

      if (userInfo?.discord) return (window.location.href = '/success')
      window.location.href = `/complete-account-setup/${result.subscription.userId}`
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target

    setFormState((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const handlePaymentThatRequiresCustomerAction = (result: any) => {
    if (!result) return null

    const {
      subscription,
      paymentMethodId,
      isRetry,
    }: any = result

    if (
      subscription &&
      (subscription.status === 'active' || subscription.status === 'trialing')
    ) {
      // Subscription is active, no customer actions required.
      return { subscription, paymentMethodId }
    }

    if (subscription && subscription.code && subscription.message) {
      throw subscription.message
    }
    // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
    // If it's a retry, the payment intent will be on the invoice itself.
    let paymentIntent = subscription.latest_invoice.payment_intent
    if (
      paymentIntent.status === 'requires_action' ||
      (isRetry === true && paymentIntent.status === 'requires_payment_method')
    ) {
      // Block native form submission.
      if (!stripe) {
        return
      }

      return stripe
        .confirmCardPayment(paymentIntent.client_secret, {
          payment_method: paymentMethodId,
        })
        .then((result) => {
          if (result.error) {
            throw result.error.message
          } else {
            if (result.paymentIntent.status === 'succeeded') {
              // Show a success message to your customer.
              subscription.status = 'active'
              return {
                subscription: subscription,
                paymentMethodId: paymentMethodId,
              }
            }
          }
        })
        .catch((error) => {
          setState((prev) => ({
            ...prev,
            error,
          }))
        })
    } else {
      // No customer action needed.
      return { subscription, paymentMethodId }
    }
  }

  const handleRequiresPaymentMethod = (result: any) => {
    if (!result) return null

    const {
      subscription,
      paymentMethodId,
    }: any = result

    if (
      subscription.status === 'active' ||
      subscription.status === 'trialing'
    ) {
      // subscription is active, no customer actions required.
      return { subscription, paymentMethodId }
    } else if (
      subscription.latest_invoice.payment_intent.status ===
      'requires_payment_method'
    ) {
      throw new Error('Your card was declined.')
    } else {
      return { subscription, paymentMethodId }
    }
  }

  const handleEmailCheckContinue = () => {
    toggleEmailCheckModal()
    handleSubmit(null, true)
  }

  const handleSubmit = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    proceedWithoutEmailCheck = false
  ) => {
    if (event) {
      event.preventDefault()
    }

    console.log('handle submit', proceedWithoutEmailCheck, state)

    if (state.isSubmitting) return

    if (!stripe || !elements) {
      return
    }

    setState((prev) => ({
      ...prev,
      isSubmitting: true,
    }))

    const cardElement = elements.getElement(CardNumberElement)

    const { fullName, email, referralCode, coupon } = formState

    if (cardElement) {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
          name: fullName,
        },
      })

      if (error) {
        setState((prev) => ({
          ...prev,
          isSubmitting: false,
          error: (error as StripeError).message as string,
        }))
        console.log('[error]', error)
      } else {
        if (paymentMethod) {
          try {
            fetch(`/api/v1/releases/${password}`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                fullname: fullName,
                coupon,
                referralCode,
                emailAddress: userInfo.email || email,
                cardToken: paymentMethod.id,
                proceedWithoutEmailCheck
              }),
              credentials: 'include',
            })
              .then(async (response) => {
                if (response.status === 402) {
                  let data = await response.json()
                  throw data
                }

                if (response.status === 418) {
                  setState((prev) => ({
                    ...prev,
                    isSubmitting: false,
                  }))
                  toggleEmailCheckModal()
                  return null
                }

                return response.json()
              })
              // If the card is declined, display an error to the user.
              .then((result) => {
                if (!result) return null

                if (result.error || result.message) {
                  // The card had an error when trying to attach it to a customer.
                  throw result.error
                    ? result.error.message
                    : result.message
                    ? result.message
                    : result.error
                }
                return result.data
              })
              // Normalize the result to contain the object returned by Stripe.
              // Add the additional details we need.
              .then((result) => {
                if (!result) return null

                return {
                  paymentMethodId: paymentMethod.id,
                  subscription: result,
                }
              })
              // Some payment methods require a customer to be on session
              // to complete the payment process. Check the status of the
              // payment intent to handle these actions.
              .then(handlePaymentThatRequiresCustomerAction)
              // If attaching this card to a Customer object succeeds,
              // but attempts to charge the customer fail, you
              // get a requires_payment_method error.
              .then(handleRequiresPaymentMethod)
              // No more actions required. Provision your service for the user.
              .then(onSubscriptionComplete)
              .catch((error) => {
                // An error has happened. Display the failure to the user here.
                // We utilize the HTML element we created.
                setState((prev) => ({
                  ...prev,
                  isSubmitting: false,
                  error: error.message
                    ? error.message
                    : error.error
                    ? error.error.message
                    : error
                    ? error
                    : 'Something went wrong. Please try again.',
                }))
                console.log(error)
              })
          } catch (err: any) {
            console.log('[error]', err.response)

            if (err.response && err.response.status === 402) {
              setState((prev) => ({
                ...prev,
                isSubmitting: false,
                error: err.response.data.message,
              }))
            } else {
              setState((prev) => ({
                ...prev,
                isSubmitting: false,
                error: `Something went wrong. Please try again.`,
              }))
            }
          }
        } else {
          setState((prev) => ({
            ...prev,
            isSubmitting: false,
          }))
        }
      }
    } else {
      setState((prev) => ({
        ...prev,
        isSubmitting: false,
      }))
    }
  }

  const handleApplyCoupon = async (coupon: string) => {
    if (userInfo.waiveInitial) {
      return setState((prev) => ({
        ...prev,
        error: 'Coupon not valid for recurring fee',
      }))
    }

    setState((prev) => ({
      ...prev,
      error: '',
    }))

    console.log(coupon)

    if (coupon !== '') {
      try {
        const { data } = await axios.post(
          '/api/v1/releases/apply-coupon',
          {
            coupon,
          },
          { withCredentials: true }
        )

        if (data.success && data.response.valid) {
          setCouponDetails({
            isValid: true,
            amountOff: data.response.amount_off,
            name: data.response.name,
          })
        } else {
          setState((prev) => ({
            ...prev,
            error: 'Invalid coupon applied',
          }))
          setCouponDetails({ isValid: false, amountOff: 0, name: '' })
        }
      } catch (error) {
        setState((prev) => ({
          ...prev,
          error: 'Invalid coupon applied',
        }))
        setCouponDetails({ isValid: false, amountOff: 0, name: '' })
      }
    } else {
      setCouponDetails({ isValid: false, amountOff: 0, name: '' })
    }
  }

  const getPrice = () => {
    if (product === 'swiftsole_v3') {
      return '$9.99/month'
    }
    return ''
  }

  const getInitialPrice = () => {
    if (userInfo.waiveInitial === true) {
      return ''
    } else if (product === 'swiftsole_v3') {
      return 'Initial $49.99 +'
    } else {
      return ''
    }
  }

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })

  // pixel tracking
  const advancedMatching = {
    em: userInfo.email as string,
    ph: userInfo.phone as string,
  }
  const options = {
    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: false, // enable logs
  }
  // @ts-ignore
  ReactPixel.init('761286231002067', advancedMatching, options)
  ReactPixel.pageView()

  useEffect(() => {
    if (search) {
      const params = new URLSearchParams(search)
      const code = params.get('code')
      if (code) {
        setFormState((prev) => ({
          ...prev,
          coupon: code,
        }))
        handleApplyCoupon(code)
      }
    }
  }, [])

  return (
    <>
    <Container>
      <InfoContainer>
        <FormInputContainerLarge>
          <FormLabel>Full Name</FormLabel>
          <FormInput
            name="fullName"
            placeholder="John Doe"
            value={formState.fullName}
            onChange={handleChange}
          />
        </FormInputContainerLarge>

        {!userInfo.email && (
          <FormInputContainerLarge>
            <FormLabel>Email Address</FormLabel>
            <FormInput
              name="email"
              placeholder="john.doe@swiftsole.app"
              value={formState.email}
              onChange={handleChange}
            />
          </FormInputContainerLarge>
        )}

        <FormInputContainerLarge>
          <FormLabel>Coupon code</FormLabel>
          <FlexRow>
            <FormInput
              name="coupon"
              placeholder="COUPON10"
              value={formState.coupon}
              onChange={handleChange}
            />
            <ApplyButton onClick={() => handleApplyCoupon(formState.coupon)}>
              Apply
            </ApplyButton>
          </FlexRow>
        </FormInputContainerLarge>

        <FormInputContainerLarge>
          <FormLabel>Referral code</FormLabel>
          <FormInput
            name="referralCode"
            placeholder="XXXXXX"
            value={formState.referralCode}
            onChange={handleChange}
          />
        </FormInputContainerLarge>

        <FormInputContainerLarge>
          <FormLabel>Card Number</FormLabel>
          <CardNumberElement
            className="stripe-input-neutral"
            options={stripeStyle}
          />
        </FormInputContainerLarge>

        <FormInputContainerSmall>
          <FormLabel>Expiration Date</FormLabel>
          <CardExpiryElement
            className="stripe-input-neutral"
            options={stripeStyle}
          />
        </FormInputContainerSmall>

        <FormInputContainerSmall>
          <FormLabel>Security Code</FormLabel>
          <CardCvcElement
            className="stripe-input-neutral"
            options={stripeStyle}
          />
        </FormInputContainerSmall>
      </InfoContainer>

      <BreakdownContainer>
        {(trialDays > 0 && !userInfo.trialed) && (
          <BreakDownWrapper>
            <p>Trial</p>
            <BreakDownText>
              Subscription Starts After {trialDays} Days
            </BreakDownText>
          </BreakDownWrapper>
        )}
        {(trialDays > 0 && !userInfo.trialed) && (
          <BreakDownWrapper>
            <p>Standard Price</p>
            <BreakDownText>
              {getInitialPrice() + ' ' + getPrice()}
            </BreakDownText>
          </BreakDownWrapper>
        )}

        {couponDetails.isValid === true && (
          <BreakDownWrapper>
            <p>Promo Code {couponDetails.name && `(${couponDetails.name})`}</p>
            <p>
              -
              {couponDetails?.isValid &&
                formatter.format(couponDetails.amountOff / 100)}
            </p>
          </BreakDownWrapper>
        )}

        <BreakDownWrapper largeText>
          <p>{(trialDays > 0 && !userInfo.trialed) ? 'Total After Trial' : 'Total'}</p>
          <p>{total}</p>
        </BreakDownWrapper>
      </BreakdownContainer>

      <SubmitButton onClick={handleSubmit}>
        {state.isSubmitting ? (
          <TailSpin />
        ) : (trialDays > 0 && !userInfo.trialed) ? (
          `Subscribe To Free Trial`
        ) : (
          'Subscribe'
        )}
      </SubmitButton>

      <TOS>
        <span>
          {(trialDays > 0 && !userInfo.trialed) 
            ? 'You will become a monthly subscriber effective immediately, and you will be charged at the end of your trial. '
            : ''}
          By subscribing to SwiftSole you agree to our
        </span>{' '}
        <Link href="/tos" target="_blank">
          Terms of Service
        </Link>
      </TOS>
    </Container>

    <Modal
      showModal={showEmailCheckModal}
      toggleModal={toggleEmailCheckModal}
    >
      <EmailCheckCard toggleModal={toggleEmailCheckModal} handleContinue={handleEmailCheckContinue} />
    </Modal>

    </>
  )
}

export default PurchaseForm
