import withModal from 'src/components/common/withModal/withModal'
import {
    setFormFields,
    setImages,
    setSelectedRelease,
} from 'src/contexts/StaffReleaseFormContext/StaffReleaseFormContext.services'
import { useStaffDispatchContext } from 'src/hooks/useStaffReleaseForm'
import { useToggle } from 'src/hooks/useToggle'
import { ReleaseType } from 'src/types/ReleaseRecord'
import { ReactComponent as Trash } from '../../../assets/trash.svg'
import DeleteReleaseForm from '../DeleteReleaseForm/DeleteReleaseForm'
import { DeleteReleaseFormProps } from '../DeleteReleaseForm/DeleteReleaseForm.types'
import {
    Container,
    DateContainer,
    DeleteReleaseButton,
    Flex,
    Image,
    ImageContainer,
    Price,
    ProductName,
    Text,
    Wrapper,
} from './ReleaseCard.styled'
import { ReleaseCardProps } from './ReleaseCard.types'
import { buildRelease } from './ReleaseCard.utils'

const DeleteReleaseFormWithModal =
    withModal<DeleteReleaseFormProps>(DeleteReleaseForm)

function ReleaseCard({ release, handleDeleteRelease }: ReleaseCardProps) {
    const { on, toggle } = useToggle()
    const dispatch = useStaffDispatchContext()

    const handleClick = () => {
        setSelectedRelease(dispatch)(release)

        const selectedRelease = buildRelease(release)

        setFormFields(dispatch)(selectedRelease)

        const imageFiles = release.images.map(({ id, ...image }) => image)

        setImages(dispatch)(imageFiles)
    }

    return (
        <>
            <Container
                onClick={handleClick}
                style={{
                    border: release.published ? '' : '1px solid red',
                }}
            >
                <ImageContainer>
                    <Image src={release.cover_image} alt={release.title} />
                </ImageContainer>
                <Wrapper>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'baseline',
                        }}
                    >
                        <ProductName>{release.title}</ProductName>
                        <DeleteReleaseButton
                            onClick={(e) => {
                                e.stopPropagation()
                                toggle()
                            }}
                        >
                            <Trash />
                        </DeleteReleaseButton>
                    </div>
                    <Flex>
                        <div>
                            {release.type === ReleaseType.SINGLE && (
                                <Price>${release.retail_price_usd}</Price>
                            )}

                            <Text>{release.sku}</Text>
                        </div>
                        <DateContainer>
                            <Text>
                                {new Date(
                                    release.release_at
                                ).toLocaleDateString()}
                            </Text>
                        </DateContainer>
                    </Flex>
                </Wrapper>
            </Container>

            {handleDeleteRelease && (
                <DeleteReleaseFormWithModal
                    showModal={on}
                    toggleModal={toggle}
                    handleDeleteRelease={handleDeleteRelease(
                        release.id as string
                    )}
                />
            )}
        </>
    )
}

export default ReleaseCard
