import styled, { keyframes } from 'styled-components'

const Page = styled.div`
  display: flex;
  width: 1240px;
  margin: 0 auto;
  max-width: 90vw;

  @media only screen and (max-width: 850px) {
    max-width: 100vw;
  }
`

const Rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const Spinner = styled.div`
  display: inline-block;
  width: 200px;
  height: 200px;
  
  &:after {
    content: " ";
    display: block;
    width: 160px;
    height: 160px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: ${Rotate} 1.2s linear infinite;
  }
`
const LogoContainer = styled.div`
  display: flex;
  justify-content:center; // centers in the flex direction and the default flex-direction is row
  align-items:center; // centers perpendicular to the flex direction
  height: 100vh; // 100% view height
  width: 100vw; // 100% view width
`;

const Loading = () => {
  return (
    <Page>
      <LogoContainer>
        <Spinner></Spinner>
      </LogoContainer>
    </Page>
  )
}

export default Loading
