import { useQuery } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { QueryKeys } from 'src/constants/QueryKeys'
import { Filter } from 'src/types/ReleaseRecord'
import { axiosInstance } from 'src/utils/axios'

type GetFiltersResponse = {
    brands: Filter[]
    categories: Filter[]
}

const updater = (item: Filter): Filter => {
    const { value } = item
    const capitalizedLabel = value[0].toUpperCase() + value.substring(1)
    return { value, label: capitalizedLabel }
}

const getFilters = () =>
    axiosInstance.get<GetFiltersResponse>('/releases/filters')

export const useGetFilters = () => {
    const select = ({ data }: AxiosResponse<GetFiltersResponse, any>) => {
        const { brands, categories } = data

        const updatedBrands = brands.map(updater)
        const updatedCategories = categories.map(updater)

        return {
            brands: updatedBrands,
            categories: updatedCategories,
        }
    }

    return useQuery([QueryKeys.filters], getFilters, {
        select,
        refetchOnWindowFocus: false,
    })
}
