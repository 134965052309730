import { FormContainer } from 'src/components/common/common'
import styled from 'styled-components'

export const Container = styled(FormContainer)`
    max-width: 500px;
    background: #171717;
    position: relative;
`

export const ButtonContainer = styled.div`
    grid-column: span 2 / span 2;
    margin-top: 20px;
    margin-bottom: 10px;
`
