import { ReleaseItemType, RegionType } from 'src/types/ReleaseRecord'
import z from 'zod'

export const AttachmentSchema = z.object({
    release_at: z.coerce.date(),
    release_end: z.coerce.date().optional(),
    type: z.nativeEnum(ReleaseItemType),
    url: z
        .string({
            invalid_type_error: 'URL must be a string',
        })
        .min(1, 'URL is required'),
    title: z
        .string({
            invalid_type_error: 'Title must be a string',
        })
        .min(1, 'Title is required'),
    id: z
        .number({
            invalid_type_error: 'ID must be a number',
        })
        .optional(),
    store: z.union([
        z
            .number({
                invalid_type_error: 'Store ID must be a number',
            })
            .optional(),
        z
            .object({
                id: z.number().optional(),
            })
            .optional(),
    ]),
    locations: z.union([
        z.array(z.number()).optional(),
        z.array(z.object({ id: z.number() })).optional(),
        z
            .object({
                connect: z.array(z.number()).optional(),
                disconnect: z.array(z.number()).optional(),
            })
            .optional(),
    ]),
    method: z.string().optional(),
    exists: z.boolean().optional(),
    region: z.nativeEnum(RegionType).optional().default(RegionType.US),
})
