import styled from "styled-components"

export const Container = styled.div`
    padding: 30px;
    width: 100%;
    max-width: 495px;
    border-radius: 30px;
    background: rgba(255,255,255,0.05);
    box-shadow: 0 20px 40px 0 rgba(0,0,0,0.15);
`

export const InfoContainer = styled.div`
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
`

const FormInputContainer = styled.div`
    display: flex;
    flex-direction: column;

    > * + * {
        margin-top: 5px;
    }
`

export const FormInputContainerLarge = styled(FormInputContainer)`
    grid-column: span 2 / span 2;
`

export const FormInputContainerSmall = styled(FormInputContainer)`
    grid-column: span 1 / span 1;
`

export const FormLabel = styled.label`
    font-weight: 400;
    color: #FFFFFF90;
    font-size: 14px;
`

export const FormInput = styled.input`
    color: #FFFFFF;
    font-weight: 600;
    font-size: 16px;
    padding: 15px 10px;
    border-radius: 15px;
    background-color: #FFFFFF10;
    border: 1px solid #FFFFFF10;
    outline: none;
    font-family: 'Prompt', sans-serif;
    width: 100%;

    &:focus {
        outline: none;
    }
`

export const BreakdownContainer = styled.div`
    margin-top: 20px;
    margin-bottom: 15px;
    border-top: 2px dotted rgba(255,255,255,0.10);

    > * + * {
        border-top: 2px dotted rgba(255,255,255,0.10);
    }
`

export const BreakDownWrapper = styled.div<{ largeText?: boolean }>`
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #FFFFFF90;
    font-size: ${({ largeText }) => largeText ? '16px' : '14px' };
    font-weight: 500;
`

export const BreakDownText = styled.span`
    color: #FFFFFF;
    font-weight: 600;
`

const Button = styled.button`
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 5px 10px 0 rgba(0,0,0,0.20);
    border-radius: 30px;
    font-size: 18px;
    font-weight: 700;
    color: #FFFFFF;
    text-align: center;
    line-height: 20px;
    cursor: pointer;
    padding: 20px 0;
    background-image: linear-gradient(180deg, #818DFF 0%, #6E7CFF 100%);
`

export const SubmitButton = styled(Button)`
    width: 100%;
`

export const ApplyButton = styled(Button)`
    width: 50%;
`

export const TOS = styled.p`
    font-weight: 400;
    font-size: 12px;
    color: #FFFFFF90;
    margin-top: 20px;
    text-align: center;
`

export const Link = styled.a`
    color: #6E7CFF;

    &:hover {
        text-decoration: underline;
    }
`

export const FlexRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    > * + * {
        margin-left: 10px;
    }
`


export const stripeStyle = {
    style: {
        base: {
            color: '#FFFFFF',
            fontFamily: 'Prompt, sans-serif',
            fontWeight: '600',
            fontSize: '16px'
        }
    }
};