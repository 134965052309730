import FormButton from 'src/components/common/FormButton/FormButton'
import { Container, Count, Header, Heading, StyledTable, TableBody, TableContainer, TableHead, TableHeader, TableWrapper } from './Table.styled'
import { TableProps } from './Table.types'

const Table = ({ tableHeadings, handleAddItem, itemCount, heading = 'Item', children, showHeader = true }: TableProps) => {
    return (
        <Container>
            {
                showHeader && (
                    <Header>
                        <div>
                            <Heading>{`${heading}s`}</Heading>
                            <Count>{itemCount} total</Count>
                        </div>

                        <FormButton
                            color='pink'
                            onClick={handleAddItem}
                        >
                            Add {heading}
                        </FormButton>
                    </Header>
                )
            }

            <TableContainer>
                <TableWrapper>
                    <StyledTable>
                        <TableHead>
                            <tr>
                                {tableHeadings.map((heading, idx) => (
                                    <TableHeader key={idx}>
                                        {heading}
                                    </TableHeader>
                                ))}
                            </tr>
                        </TableHead>
                        <TableBody>
                            { children }
                        </TableBody>
                    </StyledTable>
                </TableWrapper>
            </TableContainer>
        </Container>
    )
}

export default Table
