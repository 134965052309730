import { ReactComponent as ShopifyWidget } from '../../../assets/shopify-widget.svg'
import { ReactComponent as ScreenWidget } from '../../../assets/screen-widget.svg'
import { ReactComponent as QueueWidget } from '../../../assets/queue-widget.svg'
import { ReactComponent as CalendarWidget } from '../../../assets/calendar-widget.svg'
import { ReactComponent as MonitorWidget } from '../../../assets/monitor-widget.svg'
import { ReactComponent as SupremeWidget } from '../../../assets/supreme-widget.svg'

const WidgetData = [
    {
        widgetIcon: ShopifyWidget,
        widgetText: 'Shopify Module',
    },
    {
        widgetIcon: SupremeWidget,
        widgetText: 'Supreme/In-Store',
    },
    {
        widgetIcon: ScreenWidget,
        widgetText: 'Home Widgets',
    },
    {
        widgetIcon: QueueWidget,
        widgetText: 'Queue Tool',
    },
    {
        widgetIcon: CalendarWidget,
        widgetText: 'Release Calendar',
    },
]

export default WidgetData
