import styled from "styled-components"

export const Container = styled.div`
    > * + * {
        margin-top: 10px;
    }
`

export const Heading = styled.h3`
    color: rgba(255,255,255,1);
    font-size: 20px;
    font-weight: 700;
    margin-top: 10px;
`

export const Count = styled.p`
    color: rgba(255,255,255,.7);
    font-size: 14px;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
`

export const TableContainer = styled.div`
    box-shadow: 0px 20px 40px rgba(0,0,0, 0.15);
    overflow: hidden;
    border-radius: 15px;
    border: 1px solid #FFFFFF20;
`

export const TableWrapper = styled.div`
    height: 300px;
    overflow: auto;

    &::-webkit-scrollbar:vertical {
        display: none;
    }
`

export const StyledTable = styled.table`
    border-collapse: collapse;
    width: 100%;
`

export const TableHead = styled.thead`
    border-bottom: 1px solid #ffffff10;
    background: rgba(255,255,255,0.14);
`

export const TableHeader = styled.th`
    font-size: 12px;
    font-weight: 500;
    color: rgba(255,255,255,.7);
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    padding: 12px 24px;
    white-space: nowrap;
`

export const TableBody = styled.tbody`
    overflow: auto;
    background-color: #FFFFFF10;
    

    > * + * {
        border-top: 1px solid #ffffff10;
    }
`

export const TableData = styled.td`
    padding: 16px 24px 16px 24px;
`

export const TableText = styled.p`
    font-size: 12px;
    color: #FFFFFF;
    font-weight: 500;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: flex;
    align-items: center;
`

export const Spacer = styled.div`
    display: flex;
    align-items: center;
    column-gap: 10px;
`

export const ItemContainer = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;

    > * + * {
        margin-left: 10px;
    }
`

export const ImageContainer = styled.div`
    background: #FFFFFF;
    display: flex;
    height: 50px;
    width: 50px;
`

export const Image = styled.img`
    width: 100%;
    object-fit: cover;
`

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`