import ItemsTable from '../../Table/ItemsTable/ItemsTable'
import { MultiReleaseFieldsProps } from './MultiReleaseFields.types'

const MultiReleaseFields = ({
    toggleModal,
    setSelectedItem,
}: MultiReleaseFieldsProps) => {
    return (
        <ItemsTable
            toggleModal={toggleModal}
            setSelectedItem={setSelectedItem}
        />
    )
}

export default MultiReleaseFields
