import Algolia from 'algoliasearch'

export const defaultAlgoliaOptions = {
    page: 0,
    hitsPerPage: 30,
    typoTolerance: true,
}

const client = Algolia('XW7SBCT9V6', '6b5e76b49705eb9f51a06d3c82f7acee')

export const algolia = client.initIndex('products')
