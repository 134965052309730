import styled from "styled-components";

export const Container = styled.div<{ isLarge: boolean; }>`
    position: relative;
    width: ${({ isLarge }) => isLarge ? '200px' : '160px'};
    height: ${({ isLarge }) => isLarge ? '60px' : '56px'};
    background-color: #FFFFFF10;
    border-radius: 9999px;
    cursor: pointer;
    z-index: 50;
`

export const Toggle = styled.div<{ isLarge: boolean; checked: boolean; }>`
    position: absolute;
    top: 0;
    left: ${({ checked }) => checked ? '50%' : 0};
    width: ${({ isLarge }) => isLarge ? '100px' : '80px'};
    height: ${({ isLarge }) => isLarge ? '60px' : '56px'};
    border-radius: 9999px;
    color: white;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    transition-property: all;
    transition-duration: 500ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    background-image: linear-gradient(180deg, #6E7CFF 0%, #5B69F1 100%);
    box-shadow: 0 5px 10px 0 rgba(0,0,0,0.15);
`

export const Text = styled.div<{ isLarge: boolean; checked: boolean; }>`
    position: absolute;
    top: 0;
    right: ${({ checked }) => checked ? '50%' : 0};
    width: ${({ isLarge }) => isLarge ? '100px' : '80px'};
    height: ${({ isLarge }) => isLarge ? '60px' : '56px'};
    border-radius: 9999px;
    color: #FFFFFF20;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    transition-property: all;
    transition-duration: 500ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
`