import styled from 'styled-components';

export const CardInfoContainer = styled.div`
    width: 100%;

    > * + * {
        margin-top: 25px;
    }
`

export const HeadingContainer = styled.div`
    text-align: center;
`

export const Heading = styled.h2`
    color: white;
    font-weight: 700;
    font-size: 24px;
`

export const Subheading = styled.p`
    color: #FFFFFF70;
    font-weight: 400;
`

export const InfoContainer = styled.div`
    display: flex;
    align-items: flex-start;

    > * + * {
        margin-left: 10px;
    }
`

export const InfoHeading = styled.h3`
    color: white;
    font-weight: 600;
    font-size: 18px;   
`

export const InfoHeadingRed = styled(InfoHeading)`
    color: #FF3C4F;
    cursor: pointer;
`

export const InfoText = styled.p`
    font-size: 14px;
    font-weight: 400;
    color: #FFFFFF70;
`

export const InfoTextRed = styled(InfoText)`
    color: #FF3C4F;
`

export const InfoTextWhite = styled.span`
    font-size: 14px;
    font-weight: 400;
    color: white;
`

export const CancelContainer = styled.div`
    text-align: center;
`

export const Image = styled.img`
    height: 40px;
    width: 40px;
`