/* eslint-disable react/jsx-filename-extension */
import axios from 'axios'
import { useState } from 'react'
import FormButton from 'src/components/common/FormButton/FormButton'
import styled from 'styled-components'
import {
    FormContainer,
    FormInput,
    FormInputContainerLarge,
    FormLabel,
} from '../components/common/common'

const Container = styled.div`
    width: 100%;
    max-width: 440px;
    margin: 0 auto;
    padding: 20px;
`

const Subheading = styled.div`
    margin-top: 10px;
    font-weight: bold;
    font-size: 18px;
    grid-column: span 2 / span 2;
    text-align: center;
`

const ErrorAlert = styled.div`
    background: #ff425e;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 10px;
    color: white;
    font-weight: bold;
    text-align: center;
    grid-column: span 2 / span 2;
`

const ButtonContainer = styled.div`
    padding-top: 20px;
    grid-column: span 2 / span 2;
`

const Setup = () => {
    const [phone, setPhone] = useState<any>('')
    const [email, setEmail] = useState<any>('')
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
    const [isError, setIsError] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<any>('')

    const save = async () => {
        if (isSubmitting) return

        setIsSubmitting(true)
        setIsError(false)

        try {
            const res = await axios.patch(
                '/api/v1/users/@me',
                {
                    phone,
                    email,
                },
                { withCredentials: true }
            )

            setIsSubmitting(false)

            if (res.status === 200) {
                window.location.href = '/subscriptions'
            } else {
                setIsError(true)
                setErrorMessage(`Something went wrong. Please try again.`)
            }
        } catch (err: any) {
            setIsError(true)
            setIsSubmitting(false)
            setErrorMessage(err.response.data.message)
            console.log('[error]', err.response)
        }
    }

    return (
        <Container>
            <FormContainer>
                {isError && <ErrorAlert>{errorMessage}</ErrorAlert>}

                <Subheading>
                    Please enter your email and phone number before proceeding
                </Subheading>

                <FormInputContainerLarge>
                    <FormLabel>Phone number</FormLabel>
                    <FormInput
                        placeholder="+1 123 456 7890"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                    />
                </FormInputContainerLarge>

                <FormInputContainerLarge>
                    <FormLabel>Email</FormLabel>
                    <FormInput
                        placeholder="john.doe@gmail.com"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </FormInputContainerLarge>

                <ButtonContainer>
                    <FormButton fullWidth color="purple" onClick={save}>
                        Save
                    </FormButton>
                </ButtonContainer>
            </FormContainer>
        </Container>
    )
}

export default Setup
