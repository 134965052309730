import axios from 'axios'
import { useContext, useEffect, useState } from 'react'
import { TailSpin } from 'react-loading-icons'
import { load } from 'recaptcha-v3'
import styled from 'styled-components'
import premiumIcon from '../assets/v3-logo.svg'
import { PurpleSquaredButton } from '../components/common/common'
import InfoBlock from '../components/purchase/InfoBlock/InfoBlock'
import PurchaseForm from '../components/purchase/PurchaseForm/PurchaseForm'
import { AuthContext, IAuthContext } from '../contexts/Auth'
import { ReactComponent as PlainCard } from '../assets/plain-card.svg';

const Container = styled.div`
  margin: auto;
  max-width: 1030px;
  padding: 20px;
`

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  > * + * {
      margin-top: 40px;
  }

  @media only screen and (min-width: 1024px) {
      flex-direction: row;
      align-items: initial;
      gap: 50px;
      
      > * + * {
          margin-top: 0px;
      }
  }
`
const ErrorContainer = styled.div`
  margin-bottom: 20px;
`

const ErrorAlert = styled.div`
  background: #FF3C4F;
  border-radius: 15px;
  padding: 15px;
  margin-bottom: 10px;
  color: white;
  font-weight: bold;
  text-align: center;
  max-width: 490px;
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`

const CreditCard = styled(PlainCard)`
  margin-right: 5px;
`

const Col = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
`

const Card = styled(Col)`
  background: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
  padding: 20px;
  margin: 0 auto;
  width: 50%;

  @media only screen and (max-width: 850px) {
    max-width: 100vw;
    box-sizing: border-box;
    margin-right: 0 !important;
    margin-bottom: 20px;
    border-radius: 0;
    width: 100%!important;
  }
`

const TopRow = styled.div`
  display: flex;
`

const Icon = styled.img`
  margin-right: 10px;
`

const Title = styled.span`
  font-weight: bold;
  font-size: 18px;
`

const Separator = styled.div`
  margin-top: 20px;
  margin-bottom: 10px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 0.5px;
  height: 1px;
`

const QueueCol = styled.div`
  display: inline-block;
  margin: 0 auto;
  padding: 30px;
`

const QueueSpin = styled(TailSpin)`
  padding-top: 10px;
  display: block;
  margin: 0 auto;
  margin-bottom: 2rem;
  width: -webkit-fill-available;
`

const QueueTitle = styled(Title)`
  display: block;
`

interface Password {
  password: string
}

interface Match {
  params: Password
}

interface Props {
  match: Match
}

export type CouponDetails = {
  isValid: boolean;
  amountOff: number;
  name: string;
}

const Release = (props: Props) => {
  const { password } = props.match.params
  const { userInfo } = useContext<IAuthContext>(AuthContext);
  const [releaseInfo, setReleaseInfo] = useState<any | undefined>(undefined)
  const [releaseLoaded, setReleaseLoaded] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [passedQueue, setPassedQueue] = useState(false)

  const [state, setState] = useState({
    message: '',
    error: '',
    isSubmitting: false,
  })

  const [couponDetails, setCouponDetails] = useState<CouponDetails>({
    isValid: false,
    amountOff: 0,
    name: ''
  });

  const pollQueue = async () => {
    console.log('Passed queue?', passedQueue)
    if (passedQueue) return

    try {
      let response = await axios.get(`/api/v1/releases/${password}/queue/poll`, { withCredentials: true })

      console.log('queue poll', response)

      setIsLoading(false)

      if (response.status === 200) {
        setPassedQueue(true)
      } else {
        setTimeout(pollQueue, 15e3)
      }
    } catch (err) {
      console.log('[queue error]', err)
      setIsLoading(false)
      setTimeout(pollQueue, 15e3)
    }
  }

  const getCaptchaToken = async () => {
    const recaptcha = await load('6Le6inQdAAAAADdk84HikP5jAGrMHHIUhHXC-cXJ', {
      useRecaptchaNet: true,
      useEnterprise: true,
    })

    const token = await recaptcha.execute('queue')
    document.cookie = `qrt=${token}; Max-Age=7200; Path=/`
  }

  useEffect(() => {
    if (releaseLoaded) return

    setIsLoading(true)

    try {
      axios.get(`/api/v1/releases/${password}`, { withCredentials: true }).then(u => {
        let data = u.data
        console.log('release', data)

        if (data) {
          setReleaseInfo(data)
          setReleaseLoaded(true)

          if (data.queue === true) {
            getCaptchaToken()
            pollQueue()
          } else {
            setIsLoading(false)
          }
        } else {
          setReleaseInfo(undefined)
          setReleaseLoaded(false)
        }
      })
    } catch (err) {
      console.log('[release error]', err)
      setIsLoading(false)
      setReleaseInfo(undefined)
      setReleaseLoaded(false)
    }
  }, []);

  const getPrice = (product: 'swiftsole_v3' | 'swiftsole_desktop') => {
    if (product === 'swiftsole_v3') {
      return '$9.99/month'
    }
    return ''
  }

  const getInitialPrice = (product: 'swiftsole_v3' | 'swiftsole_desktop') => {
    if (product === 'swiftsole_v3') {
      return 'Initial $49.99'
    } else {
      return ''
    }
  }

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  let swiftSoleV3Total: string;

  if (userInfo.waiveInitial) {
    swiftSoleV3Total = getPrice('swiftsole_v3');
  } else {
    if (couponDetails?.isValid) {
      const total = ((4999 - couponDetails.amountOff) / 100) > 0 ? (4999 - couponDetails.amountOff) / 100 : 0;
      swiftSoleV3Total = `Initial ${formatter.format(total)} + ${getPrice('swiftsole_v3')}`
    } else {
      swiftSoleV3Total = `${getInitialPrice('swiftsole_v3')} + ${getPrice('swiftsole_v3')}`
    }
  }

  return (
    <Container>
      {state.error && (
        <ErrorContainer>
          <ErrorAlert>
            {state.error.includes('card') && <CreditCard />}
            <p>{state.error}</p>
          </ErrorAlert>
        </ErrorContainer>
      )}
      { releaseInfo && releaseInfo.product === 'swiftsole_v3' && 
          (releaseInfo.instock === true && releaseInfo.saleLive === true && (releaseInfo.queue === true ? passedQueue : true)) ? (
            <Wrapper>
              <InfoBlock 
                total={swiftSoleV3Total}
              />
              <PurchaseForm
                trialDays={releaseInfo.trialDays}
                userInfo={userInfo}
                password={password}
                product={"swiftsole_v3"}
                couponDetails={couponDetails}
                setCouponDetails={setCouponDetails}
                total={swiftSoleV3Total}
                setState={setState}
                state={state}
              />
            </Wrapper>
          ) : (
            <Card>
              <TopRow>
                <Icon src={premiumIcon} />
                <Col>
                  <Title>SwiftSole V3</Title>
                </Col>
              </TopRow>
              <Separator />

              { releaseInfo && (releaseInfo.instock === false || releaseInfo.saleLive === false) && releaseInfo.queue === false && <>
                <PurpleSquaredButton>Out of stock</PurpleSquaredButton>
              </> }

              { releaseInfo && (releaseInfo.instock === false || releaseInfo.saleLive === false) && releaseInfo.queue === true && passedQueue && <>
                <PurpleSquaredButton>Out of stock</PurpleSquaredButton>
              </> }

              { releaseInfo && releaseInfo.queue === true && !passedQueue && <>
                <QueueCol>
                  <QueueSpin></QueueSpin>
                  <QueueTitle>In Queue for Purchase</QueueTitle>
                </QueueCol>
              </> }
            </Card> 
          )
        }

      {/* { releaseInfo && releaseInfo.product === 'swiftsole_desktop' && <>
        <Card>
          <TopRow>
            <Icon src={desktopIcon} />
            <Col>
              <Title>SwiftSole Desktop</Title>
            </Col>
          </TopRow>
          <Separator />

          { releaseInfo && releaseInfo.instock === true && releaseInfo.saleLive === true && (releaseInfo.queue === true ? passedQueue : true) && <>
            <CardFormPurchase userInfo={userInfo} product={"swiftsole_desktop"} password={password}></CardFormPurchase>
          </> }

          { releaseInfo && (releaseInfo.instock === false || releaseInfo.saleLive === false) && releaseInfo.queue === false && <>
            <Button>Out of stock</Button>
          </> }

          { releaseInfo && releaseInfo.queue === true && !passedQueue && <>
            <QueueCol>
              <QueueSpin></QueueSpin>
              <QueueTitle>In Queue for Purchase</QueueTitle>
            </QueueCol>
          </> }
        </Card>
      </> } */}
    </Container>
  )
}

export default Release
