import React, { useState } from 'react'
import { ReactComponent as RemoveIcon } from 'src/assets/remove-icon.svg'
import Table from '../Table'
import {
    Image,
    ImageContainer,
    ItemContainer,
    TableData,
    TableText,
} from '../Table.styled'
import { tableHeadings } from './ImageUploadTable.data'
import { ImageUploadTableProps } from './ImageUploadTable.types'
import { notify } from 'src/utils/notify'
import styled from 'styled-components'

// Add these styled components
const Input = styled.input`
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin-right: 10px;
`

const Button = styled.button`
    padding: 10px 20px;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;

    &:hover {
        background-color: #0056b3;
    }
`

const ImageUploadTable = ({ images, setImages }: ImageUploadTableProps) => {
    const [sku, setSku] = useState('')

    const searchProductImages = async (sku: string) => {
        try {
            const response = await fetch(
                `https://pro-alerts-api-production.up.railway.app/api/v1/helper/${sku.replace(
                    '-',
                    '_'
                )}`
            )
            if (response.status === 200 && response.body) {
                const data: {
                    data: { cover_image: string; images: string[] }
                } = await response.json()

                notify('Fetched Images', 'success')

                return {
                    cover_image: data.data.cover_image,
                    images: data.data.images,
                }
            } else {
                notify('Error Fetching Images, check console', 'error')
            }
        } catch (error) {
            notify((error as Error).message, 'error')
        }
    }

    const handleDeleteItem = (url: string) => () => {
        const filteredImages = images.filter((image) => image.url !== url)
        setImages(filteredImages)
    }

    const handleCheck =
        (name: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
            const newImages = images.map((image) =>
                image.name === name
                    ? { ...image, transparent: e.target.checked }
                    : image
            )
            setImages(newImages)
        }

    const handleAddImagesWithTransparency = async () => {
        if (!sku) {
            notify('SKU is Required', 'error')
            return
        }
        const result = await searchProductImages(sku)
        if (result && result.images) {
            const newImages = result.images.map((url) => ({
                name: url.split('/').pop() || url,
                url,
                transparent: true,
            }))
            setImages([...images, ...newImages])
        } else {
            notify('No Images Found', 'error')
        }
    }

    return (
        <>
            <div className="w-full flex justify-between items-center">
                <Input
                    type="text"
                    value={sku}
                    onChange={(e) => setSku(e.target.value)}
                    placeholder="Enter SKU"
                />
                <Button onClick={handleAddImagesWithTransparency}>
                    Search Images
                </Button>
            </div>
            <Table tableHeadings={tableHeadings} showHeader={false}>
                {images.map(({ name, transparent, url }) => (
                    <tr key={name}>
                        <TableData>
                            <ItemContainer>
                                <ImageContainer>
                                    <Image src={url} alt={name} />
                                </ImageContainer>
                                <TableText>{name}</TableText>
                            </ItemContainer>
                        </TableData>

                        <TableData>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: '5px',
                                }}
                            >
                                <input
                                    id="transparent"
                                    type="checkbox"
                                    checked={transparent}
                                    onChange={handleCheck(name)}
                                />
                                <TableText>Enable Transparency</TableText>
                            </div>
                        </TableData>
                        <TableData>
                            <div
                                style={{ cursor: 'pointer' }}
                                onClick={handleDeleteItem(url)}
                            >
                                <RemoveIcon />
                            </div>
                        </TableData>
                    </tr>
                ))}
            </Table>
        </>
    )
}

export default ImageUploadTable
