import { CSSObject } from "styled-components";

export type Color = 'purple' | 'green' | 'pink' | 'white' | 'grey'| 'red';

type ColorObject = {
    [key in Color]: CSSObject;
}

export const colors: ColorObject = {
    purple: {
        'backgroundImage': 'linear-gradient(180deg, #7F89FF 0%, #5762F2 100%)',
    },
    green: {
        'backgroundImage': 'linear-gradient(180deg, #00EA4B 0%, #00D025 100%)',
    },
    pink: {
        'backgroundImage': 'linear-gradient(180deg, #FF83AF 0%, #F45A90 100%)',
    },
    white: {
        color: '#F45C92',
        'backgroundImage': 'linear-gradient(180deg, #FFFFFF 0%, #F2F2F2 100%)',
    },
    grey: {
        background: 'rgba(255,255,255, 0.07)',
    },
    red: {
        backgroundImage: 'linear-gradient(180deg, #EB4E55 0%, #C83E44 100%)'
    }
}