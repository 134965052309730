import styled from "styled-components"

export const Container = styled.div`
    width: 100%;
    max-width: 495px;

    > * + * {
        margin-top: 20px;
    }
`

export const PhonesContainer = styled.div`
    padding-top: 28px;
    border-radius: 30px;
    overflow: hidden;
    background-image: linear-gradient(15deg, #00A4FF 0%, #F45A90 98%);
`

export const PhonesWrapper = styled.img`
    max-width: 100%;
`

export const HeadingContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
`

export const Heading = styled.h2`
    margin: 0;
    font-size: 24px;
    font-weight: 700;
    font-weight: bold;
    color: white;
`

export const SubHeading = styled.span<{ small?: boolean }>`
    margin: 0;
    font-weight: 500;
    color: #FFFFFF90;
    font-size: ${({ small }) => small ? '18px' : '24px'};
`

export const Text = styled.p`
    color: #FFFFFF90;
    font-size: 16px;
    font-weight: 500;
`

export const WidgetsContainer = styled.div`
    display: grid;
    column-gap: 0px;
    row-gap: 20px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
`

export const WidgetRow = styled.div`
    grid-column: span 1 / span 1;
    display: flex;
    flex-direction: column;
    align-items: initial;
    
    > * + * {
        margin-left: 0px;
        margin-top: 10px;
    }
    
    @media only screen and (min-width: 1024px) {
        flex-direction: row;
        align-items: center;

        > * + * {
            margin-left: 10px;
            margin-top: 0px;
        }
    }
`

export const WidgetText = styled.p`
    font-size: 18px;
    font-weight: 700;
    color: #FFFFFF;
`