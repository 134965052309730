type Args<T extends object> = {
    value: any;
    key: keyof T;
    items: T[];
    acceptedItems?: T[];
}

export const checkDuplicate = <T extends object,>({ value, key, items }: Args<T>) => {
    for (let i = 0; i < items.length; i++) {
        const currentItem = items[i]
        if (value === currentItem[key]) {
            throw new Error(`Error: ${key as string} "${value}" is already taken`)
        }
    }
}

export const checkDuplicatesWithinArray = <T extends { id?: number }>(items: Array<T>) => {
    const matches: Record<string, boolean> = {};

    for (let i = 0; i < items.length; i++) {
        const { id } = items[i];

        if (id) {
            if (matches[id]) {
                throw new Error(`Error: Duplicate items`)
            } else {
                matches[id] = true;
            }
        }

    }
}