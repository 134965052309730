import { FormInput } from 'src/components/common/common'
import { updateField } from 'src/contexts/StaffReleaseFormContext/StaffReleaseFormContext.services'
import {
    useStaffDispatchContext,
    useStaffReleaseFormContext,
} from 'src/hooks/useStaffReleaseForm'
import { ReleaseRecordKeys } from 'src/types/ReleaseRecord'
import { FlexRowBetweenWithSpace, Label } from '../ReleaseForm.styled'
import { SingleReleaseFieldsProps } from './SingleReleaseFields.types'

const SingleReleaseFields = () => {
    const dispatch = useStaffDispatchContext()
    const { formState } = useStaffReleaseFormContext()

    const handleBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
        const { name, value } = e.target

        if (!value) updateField(dispatch)(name as ReleaseRecordKeys, 0)
    }

    const handleChange = (
        e: React.ChangeEvent<
            HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
        >
    ) => {
        const { name, value } = e.target as {
            name: ReleaseRecordKeys
            value: string
        }
        updateField(dispatch)(name, value)
    }

    const handleNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target as {
            name: ReleaseRecordKeys
            value: string
        }
        updateField(dispatch)(name, Number(value))
    }

    return (
        <>
            <FlexRowBetweenWithSpace>
                <div>
                    <Label htmlFor="sku">SKU (Optional)</Label>
                    <FormInput
                        id="sku"
                        name="sku"
                        value={formState.sku}
                        placeholder="DB2908"
                        onChange={handleChange}
                    />
                </div>

                <div>
                    <Label htmlFor="colorway">Colorway (Optional)</Label>
                    <FormInput
                        id="colorway"
                        name="colorway"
                        value={formState.colorway}
                        placeholder="Blue"
                        onChange={handleChange}
                    />
                </div>
            </FlexRowBetweenWithSpace>

            <FlexRowBetweenWithSpace>
                <div>
                    <Label htmlFor="retail_price_usd">Price (Optional)</Label>
                    <FormInput
                        id="retail_price_usd"
                        name="retail_price_usd"
                        value={formState.retail_price_usd}
                        type="number"
                        onChange={handleNumberChange}
                        onBlur={handleBlur}
                    />
                </div>

                <div>
                    <Label htmlFor="resell_price_usd">Resell (Optional)</Label>
                    <FormInput
                        id="resell_price_usd"
                        name="resell_price_usd"
                        value={formState.resell_price_usd}
                        type="number"
                        onChange={handleNumberChange}
                        onBlur={handleBlur}
                    />
                </div>
            </FlexRowBetweenWithSpace>
        </>
    )
}

export default SingleReleaseFields
