import { useState } from 'react'
import styled from 'styled-components'

const Container = styled.div<{ isFocused: boolean; isError?: boolean }>`
    display: flex;
    align-items: center;
    background: #353535;
    border: 1px solid
        ${(props) =>
            props.isFocused
                ? '#7F70FF'
                : props.isError
                ? 'red'
                : 'rgba(247, 239, 243, 0.1)'};
    border-radius: 18px;
    height: 60px;
    padding: 0 15px;
    width: 100%;

    transition: 0.15s border ease-out;
`

const LeftInputCol = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`

const Label = styled.span`
    font-size: 12px;
    font-weight: 500;
    color: rgba(247, 239, 243, 0.8);
`

const TextInput = styled.input`
    font-size: 16px;
    font-weight: 600;
    color: #f7eff3;
    background: none;
    outline: none;
    border: none;
    font-family: inherit;

    &::placeholder {
    }
`

const RequiredText = styled.span`
    font-size: 12px;
    font-weight: 500;
    color: #ff3c4f;
    position: relative;
    height: 60px;
    display: flex;
    align-items: center;
`

const BlurOutRegion = styled.div<{ isFocused: boolean }>`
    position: absolute;
    top: 1px; // 1px border top
    right: 100%;
    width: 30px;
    background: linear-gradient(
        to right,
        rgba(53, 53, 53, 0),
        rgba(53, 53, 53, 1)
    );
    height: calc(100% - 2px); // 1px border top and bottom

    opacity: ${(props) => (props.isFocused ? 0 : 1)};
    transition: 0.2s opacity ease-out;
`

interface FormTextInputProps {
    label: string
    value?: string
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    placeholder?: string
    required?: boolean
    isError?: boolean
    onFocus?: () => void
    onBlur?: () => void
    leadingElement?: React.ReactNode
    shouldHideBlurOutRegion?: boolean
    className?: string
    inputRef?: React.RefObject<HTMLInputElement>
}

const FormTextInput = (props: FormTextInputProps) => {
    const [isFocused, setIsFocused] = useState(false)

    return (
        <Container
            isFocused={isFocused}
            isError={props.isError}
            className={props.className}
        >
            {props.leadingElement}
            <LeftInputCol>
                <Label>{props.label}</Label>
                <TextInput
                    type="text"
                    placeholder={props.placeholder}
                    value={props.value}
                    onChange={props.onChange}
                    onFocus={() => {
                        setIsFocused(true)
                        if (props.onFocus) props.onFocus()
                    }}
                    onBlur={() => {
                        setIsFocused(false)
                        if (props.onBlur) props.onBlur()
                    }}
                    ref={props.inputRef}
                />
            </LeftInputCol>
            <RequiredText>
                {props.shouldHideBlurOutRegion ? null : (
                    <BlurOutRegion isFocused={isFocused} />
                )}
                {props.required && 'Required'}
            </RequiredText>
        </Container>
    )
}

export default FormTextInput
