import {
    ReleaseItemContainer,
    ReleaseItemImage,
    ReleaseItemImageContainer,
    ReleaseItemTitle,
    ShadowContainer,
    SkeletonContainer,
    SkeletonImage,
    SkeletonTitle,
} from './ReleaseItem.styled'
import { ReleaseItemProps } from './ReleaseItem.types'
import { ReactComponent as Shadow } from 'src/assets/shadow.svg'
import { setFiles, setFormFields } from 'src/contexts/StaffAlertFormContext/StaffAlertFormContext.services'
import { useStaffAlertDispatchContext } from 'src/hooks/useStaffAlertForm'
import { buildNotification } from './ReleaseItem.utils'
import { getLinkName } from 'src/utils/getLinkName'
import { NewFile } from 'src/components/common/FileUpload/FileUpload.types'
import { Notification } from 'src/types/Notification'
import { notify } from 'src/utils/notify'

export const ReleaseSkeleton = () => {
    return (
        <SkeletonContainer>
            <SkeletonImage />
            <SkeletonTitle />
        </SkeletonContainer>
    )
}

const ReleaseItem = ({ release, selected, setSelectedRelease }: ReleaseItemProps) => {
    const dispatch = useStaffAlertDispatchContext()

    const handleClick = (
    ) => {
        setSelectedRelease();
        
        const notification = buildNotification(release)

        if (notification.image_url) {
            const { image_url } = notification

            const name = getLinkName(image_url)

            const file: NewFile = {
                url: image_url,
                name,
                transparent: false,
            }

            setFiles(dispatch)([file])
        } else {
            setFiles(dispatch)([])
        }

        setFormFields(dispatch)(notification as Notification)
        notify(`Release ID: ${notification.release_id}`, 'success')
    }

    return (
        <ReleaseItemContainer onClick={handleClick} selected={selected}>
            <ReleaseItemImageContainer
                cover_color_hex={release.cover_color_hex}
            >
                <ReleaseItemImage
                    alt={release.title}
                    src={release.cover_image}
                />
                <ShadowContainer>
                    <Shadow />
                </ShadowContainer>
            </ReleaseItemImageContainer>

            <ReleaseItemTitle>{release.title}</ReleaseItemTitle>
        </ReleaseItemContainer>
    )
}

export default ReleaseItem