import styled from "styled-components"

export const Container = styled.div`
    width: 100%;
    max-width: 400px;
    padding: 20px;
    border-radius: 15px;
    background: rgba(255, 255, 255, 0.05);
`

export const Heading = styled.h3`
     margin: 0px;
     font-size: 18px;
     font-weight: 700;
     margin-bottom: 16px;
`

export const LabelContainer = styled.div`
     display: flex;
     align-items: center;
     justify-content: space-between;
     margin-bottom: 10px;
`

export const InfoContainer = styled.div<{ noPaddingBottom?: boolean }>`
     padding-top: 10px;
     padding-bottom: ${(({ noPaddingBottom }) => noPaddingBottom ? '0px' : '20px' )};
     border-top: 1.5px solid #FFFFFF10;
     border-radius: 0.5px;
` 

export const FlexRowBetween = styled.div`
     display: flex;
     align-items: center;
     justify-content: space-between;
     flex-wrap: wrap;
`

export const Label = styled.p`
     margin: 0px;
     font-size: 15px;
     font-weight: 600;
`

export const EditLink = styled.p`
     color:#00A4FF;
     font-weight: 600;
     cursor: pointer;

     &:hover {
          text-decoration: underline;
     }
`

export const Input = styled.input`
     margin: 0;
     width: 100%;
     border: none;
     display: block;
     padding: 13px 11px;
     border-radius: 15px;
     font-weight: 600;
     color: white;
     background-color: #FFFFFF10;
     font-family: 'Prompt', sans-serif;
     font-size: 16px;

     &::placeholder {
          color: #FFFFFF90;
     }

     &:focus {
          outline: none;
     }
`

export const DetailsContainer = styled.div`
     display: flex;
     align-items: center;
     
     > * + * {
          margin-left: 10px;
     }
`

export const DetailsText = styled.p`
     margin: 0px;
     font-size: 14px;
     font-weight: 600;
     text-transform: capitalize;
`

export const DetailsText2 = styled.p`
     margin: 0px;
     font-size: 14px;
     font-weight: 600;
     text-transform: lowercase;
`

export const Text = styled.p`
     font-size: 12px;
     font-weight: 500;
     color: #FFFFFF60;
`

export const Button = styled.button`
     border: none;
     font-size: 16px;
     color: white;
     font-weight: 600;
     padding: 6px 10px;
     border-radius: 9999px;
     background: linear-gradient(to bottom, #6E7CFF, #5B69F1);
     cursor: pointer;
`

export const FlexCol = styled.div`
     display: flex;
     flex-direction: column;
`

export const FlexRow = styled.div`
     display: flex;
     align-items: center;

     > * + * {
          margin-left: 10px;
     }
`

export const EmailContainer = styled.div`
     display: flex;
     align-items: center;
`

export const LogoContainer = styled.div`
     margin-left: -10px;
`

export const Error = styled.p`
     color: red;
`