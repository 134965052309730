import { useMutation, useQueryClient } from '@tanstack/react-query'
import { QueryKeys } from 'src/constants/QueryKeys'
import { axiosInstance } from 'src/utils/axios'

const cancelNotification = (id: string) => {
    return axiosInstance
        .post<Notification>(`/notifications/cancel/${id}`)
        .then(({ data }) => data)
}

export const useCancelNotification = () => {
    const queryClient = useQueryClient()

    const onSuccess = async (data: Notification) => {
        await queryClient.invalidateQueries([QueryKeys.notifications])
    }

    return useMutation(cancelNotification, { onSuccess })
}
