import { useState } from 'react'
import { FormInputContainerLarge } from 'src/components/common/common'
import FileUpload from 'src/components/common/FileUpload/FileUpload'
import { NewFile } from 'src/components/common/FileUpload/FileUpload.types'
import FormButton from 'src/components/common/FormButton/FormButton'
import {
    setImages,
    updateField,
} from 'src/contexts/StaffReleaseFormContext/StaffReleaseFormContext.services'
import {
    useStaffDispatchContext,
    useStaffReleaseFormContext,
} from 'src/hooks/useStaffReleaseForm'
import { useToggle } from 'src/hooks/useToggle'
import { uploadImage } from 'src/utils/r2'
import ImageUploadTable from '../Table/ImageUploadTable/ImageUploadTable'
import { ButtonContainer, Container } from './ImageUploadForm.styled'
import { ImageUploadFormProps } from './ImageUploadForm.types'

const ImageUploadForm = ({ toggleModal }: ImageUploadFormProps) => {
    const dispatch = useStaffDispatchContext()
    const {
        formState: { images, cover_image },
    } = useStaffReleaseFormContext()
    const [files, setFiles] = useState<NewFile[]>([])
    const [isLoading, setIsLoading] = useState(false)
    const { on: checked, toggle } = useToggle()

    const handleUploadFiles = async () => {
        setIsLoading(true)

        const newFiles = await Promise.all(
            files.map(({ url, transparent }) =>
                uploadImage({ file: url, removeBg: transparent })
            )
        )

        setIsLoading(false)
        setImages(dispatch)([...images, ...newFiles])

        if (!cover_image) {
            const file = newFiles[0]
            updateField(dispatch)('cover_image', file.value)
            updateField(dispatch)('cover_transparent', file.transparent)
        }

        toggleModal()
    }

    return (
        <Container>
            <FormInputContainerLarge>
                <FileUpload
                    id="image"
                    label="Image"
                    files={files}
                    setFiles={(files: NewFile[]) => setFiles(files)}
                />
            </FormInputContainerLarge>

            <FormInputContainerLarge>
                <ImageUploadTable images={files} setImages={setFiles} />
            </FormInputContainerLarge>

            <ButtonContainer>
                <FormButton
                    fullWidth
                    isLoading={isLoading}
                    color="purple"
                    onClick={handleUploadFiles}
                >
                    Upload Images
                </FormButton>
            </ButtonContainer>

            <FormButton
                fullWidth
                color="pink"
                onClick={(e) => {
                    e.preventDefault()
                    setFiles((files) => {
                        return files.map((file) => ({
                            ...file,
                            transparent: !checked,
                        }))
                    })
                    toggle()
                }}
            >
                {checked ? 'Disable' : 'Enable'} Transparency
            </FormButton>

            <FormButton fullWidth color="grey" onClick={toggleModal}>
                Cancel
            </FormButton>
        </Container>
    )
}

export default ImageUploadForm
