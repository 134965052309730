
export const segmentOptions = [
    {
        text: 'All',
        value: 'Subscribed Users',
    },
    {
        text: 'Hat Info',
        value: 'Hat',
    },
    {
        text: 'Lowkey Flips',
        value: 'Lowkey',
    },
    {
        text: 'Shock Drop',
        value: 'Shock',
    },
]

export const timestampOptions = [
    {
        text: 'Now',
        value: 'Now',
    },
    {
        text: 'Date/Time',
        value: 'Date/Time',
    },
]

