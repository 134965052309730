import { Container, Text } from './SocialProof.styled'
import { ReactComponent as Success } from '../../../assets/success.svg'

export function SocialProof() {
    return (
        <Container>
            <Success />
            <Text>500,000+ Supreme Checkouts Recorded</Text>
        </Container>
    )
}
