import FormButton from 'src/components/common/FormButton/FormButton';
import Calendar from '../../../assets/grey-calendar.svg';
import Gear from '../../../assets/grey-gear.svg';
import Logo from '../../../assets/red-logo.svg';
import sleep from '../../../utils/sleep';
import CardWithSadEmoji from '../CardWithSadEmoji/CardWithSadEmoji';
import { CancelContainer, CardInfoContainer, Heading, HeadingContainer, Image, InfoContainer, InfoHeading, InfoHeadingRed, InfoText, InfoTextRed, Subheading } from './ReviewCard.styled';
import { ReviewCardProps } from './ReviewCard.types';

const ReviewCard = ({ toggleModal, toggleOtherModal }: ReviewCardProps) => {
    const handleProceed = async () => {
        toggleModal();
        await sleep(500);
        toggleOtherModal();
    }

    return (
        <CardWithSadEmoji>
            <CardInfoContainer>
                <HeadingContainer>
                    <Heading>Review your cancellation.</Heading>
                    <Subheading>Here is what you can expect if you cancel:</Subheading>
                </HeadingContainer>

                <InfoContainer>
                    <Image src={Logo} alt="logo" />
                    <div>
                        <InfoHeadingRed>No access to SwiftSole app and services</InfoHeadingRed>
                        <InfoTextRed>After your plan expires, you will no longer have access to the SwiftSole app or SwiftSole services such as the dashboard Release Calendar and (others).</InfoTextRed>
                    </div>
                </InfoContainer>

                <InfoContainer>
                    <Image src={Calendar} alt="calendar" />

                    <div>
                        <InfoHeading>Cancellation date</InfoHeading>
                        <InfoText>
                            Your subscription and access to services will end on your next billing date.
                        </InfoText>
                    </div>
                </InfoContainer>

                <InfoContainer>
                    <Image src={Gear} alt="gear" />

                    <div>
                        <InfoHeading>Loss of Settings</InfoHeading>
                        <InfoText>Your SwiftSole settings will no longer be saved. This includes your profiles, monitors, release calendar favorites, and other items.</InfoText>
                    </div>
                </InfoContainer>

                <FormButton
                    fullWidth
                    color='purple'
                    onClick={toggleModal}
                >
                    Nevermind
                </FormButton>
                
                <CancelContainer>
                    <InfoHeadingRed onClick={handleProceed}>Proceed With Cancellation</InfoHeadingRed>
                </CancelContainer>
            </CardInfoContainer>
        </CardWithSadEmoji>
    )
}

export default ReviewCard