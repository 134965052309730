import { createGlobalStyle } from 'styled-components';
import Background from '../assets/bg_gradient.png';

export const GlobalStyles = createGlobalStyle`
    * {
        box-sizing: border-box;
        border-width: 0;
    }

    html {
        background-image: url('${Background}');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        min-height: 100vh;
    }

    body {
        font-family: 'Prompt', sans-serif;
        color: white;
    }

    body,
    blockquote,
    dl,
    dd,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    hr,
    figure,
    p,
    pre {
        margin: 0;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-size: inherit;
        font-weight: inherit;
    }

    ol,
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    img,
    svg,
    video,
    canvas,
    audio,
    iframe,
    embed,
    object {
        display: block;
    }

    a {
        color: inherit;
        text-decoration: inherit;
    }

    button {
        font-family: inherit;
    }

    input, textarea {
        font-family: 'Prompt', sans-serif;
    }

    input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button { 
        -webkit-appearance: none; 
        margin: 0; 
    }

    *::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: transparent;
    }

    *::-webkit-scrollbar {
        width: 7px;
        background-color: transparent;
    }

    *::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: rgba(255, 255, 255, 0.1);
    }

    .react-datetime-picker__wrapper {
        border: 0px!important;
    }

    .react-datetime-picker__button svg {
        stroke: white!important;
    }

    .stripe-input {
        width: 100%;
        border: none;
        display: block;
        padding: 15px 11px;
        border-radius: 15px;
        font-weight: 600;
        color: white;
        border: 1px solid #FFFFFF10;
        background-color: #282828;
        font-family: 'Prompt', sans-serif;
    }

    .stripe-input-light {
        display: block;
        background: rgba(255,255,255,0.10);
        border-radius: 7px;
        padding: 7px 10px;
        border: none;
        border: 2px solid transparent;
        transition-property: all;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-duration: 300ms;
        width: 100%;

        &:focus {
            outline: none;
            border-color: #c3c3c32e;
        }
    }

    .stripe-input-neutral {
        color: #FFFFFF;
        font-weight: 600;
        padding: 15px 10px;
        border-radius: 15px;
        background-color: #FFFFFF10;
        border: 1px solid #FFFFFF10;
        outline: none;

        &:focus {
            outline: none;
        }
    }

    .react-datetime-picker__inputGroup__input {
        color: white !important;
    }

    .react-calendar {
        font-family: inherit;

        & .react-calendar__month-view__weekdays {
            color: black;
            font-weight: 500;
    
            & abbr {
                text-decoration: none;
            }
        }
    }

    .overflow-hidden {
        overflow: hidden;
    }

    @media (max-width: 768px) {
        *::-webkit-scrollbar {
            display: none;
        }
    }
`;
