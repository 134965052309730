import styled from 'styled-components';

export const Label = styled.label`
    display: flex;
    position: relative;
    align-items: center;
`

export const Input = styled.input<{ checked: boolean; }>`
    width: 24px;
    height: 24px;
    outline: none;
    cursor: pointer;
    appearance: none;
    border-radius: 9999px;
    background: #FFFFFF10;
    border: ${({ checked }) => checked ? '1px solid #F45C92' : '1px solid #FFFFFF10' };
`

export const Circle = styled.div`
    top: 7px;
    left: 9px;
    width: 16px;
    height: 16px;
    position: absolute;
    border-radius: 9999px;
    background: #F45C92;
`

export const Placeholder = styled.span<{ checked: boolean; }>`
    margin-left: 7px;
    font-size: 14px;
    font-weight: 400;
    color: ${({ checked }) => checked ? 'white' : '#FFFFFF70'}
`