import { useContext } from 'react'
import styled from 'styled-components'
import FreeImg from '../assets/free-card.svg'
import ReferralImg from '../assets/referral-card.svg'
import { ReactComponent as ShareIcon } from '../assets/share.svg'
import { ReactComponent as Dashed } from '../assets/trail.svg'
import { AuthContext, IAuthContext } from '../contexts/Auth'

function Referrals() {
    const { userInfo } = useContext<IAuthContext>(AuthContext)
    const referral = `https://${window.location.hostname}/r/${userInfo?.referralCode}`
    const canShareLink = navigator.canShare

    const copyText = async () => {
        await navigator.clipboard.writeText(referral)
        alert('Copied referral link')
    }

    const handleClick = async () => {
        if (!canShareLink) return

        // const response = await fetch("https://i.imgur.com/0hxnPWq.png");
        // const buffer = await response.arrayBuffer();

        // const image = new File([buffer], "swiftsole.png", { type: "image/png" });

        await navigator.share({
            url: referral,
            text: 'Use my referral link for SwiftSole iOS!',
            title: 'SwiftSole iOS Referral',
        })
    }

    return (
        <Container>
            <RefContainer>
                <FlexRow>
                    <Step1>1</Step1>
                    <FlexCol>
                        <Heading>Invite users to Swiftsole</Heading>
                        <SubHeading>Simply share your invite link</SubHeading>
                    </FlexCol>
                </FlexRow>
                <Trail />
                <FlexRow>
                    <Step2>2</Step2>
                    <FlexCol>
                        <Heading>Have them sign up</Heading>
                        <SubHeading>
                            Share your referral code: {userInfo?.referralCode}
                        </SubHeading>
                    </FlexCol>
                </FlexRow>
                <Trail />
                <FlexRow>
                    <Step3>3</Step3>
                    <FlexCol>
                        <Heading>Get your rewards 🏆</Heading>
                        <SubHeading>
                            Get one month FREE for every referral
                        </SubHeading>
                    </FlexCol>
                </FlexRow>

                <RefBox>
                    <span>{referral}</span>
                    <CopyText onClick={copyText}>Copy</CopyText>
                </RefBox>

                <Button onClick={handleClick}>
                    <ShareIcon />
                    <span>Share Link</span>
                </Button>
            </RefContainer>

            <CardsContainer>
                <RefCard>
                    <InfoContainer>
                        <CardHeading>Total Referrals</CardHeading>
                        <CardSubHeading>
                            {userInfo?.claimedReferrals} Referrals
                        </CardSubHeading>
                        {/* <CardText>since March 6th, 2022</CardText> */}
                    </InfoContainer>
                </RefCard>

                <FreeCard>
                    <InfoContainer>
                        <CardHeading>Free Months</CardHeading>
                        <CardSubHeading>
                            {userInfo?.claimedReferrals} Months
                        </CardSubHeading>
                        {/* <CardText>since March 6th, 2023</CardText> */}
                    </InfoContainer>
                </FreeCard>
            </CardsContainer>
        </Container>
    )
}

export default Referrals

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    flex-direction: column;

    > * + * {
        margin-top: 20px;
    }

    @media only screen and (min-width: 840px) {
        flex-direction: row;

        > * + * {
            margin-left: 40px;
        }

        > * + * {
            margin-top: 0px;
        }
    }
`

const Trail = styled(Dashed)`
    position: relative;
    left: 8%;
    margin: 0;

    @media only screen and (min-width: 425px) {
        left: 7%;
    }

    @media only screen and (min-width: 840px) {
        left: 9%;
        margin: 5px 0;
    }
`

const Step = styled.div`
    height: 50px;
    width: 50px;
    border: 1px solid red;
    font-weight: bold;
    font-size: 30px;
    border-radius: 100%;
    background-color: #00000020;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;

    @media only screen and (min-width: 840px) {
        height: 60px;
        width: 60px;
    }
`

const Step1 = styled(Step)`
    color: #6e7cff;
    border-color: #6e7cff;
`

const Step2 = styled(Step)`
    color: #fc5c92;
    border-color: #fc5c92;
`

const Step3 = styled(Step)`
    color: #43ea80;
    border-color: #43ea80;
`

const RefContainer = styled.div`
    width: 100%;
    max-width: 387px;
    border-radius: 15px;
    width: 100%;
    padding: 20px;
    background: #ffffff10;

    @media only screen and (min-width: 840px) {
        padding: 30px;
    }
`

const FlexRow = styled.div`
    display: flex;
    align-items: center;

    > * + * {
        margin-left: 10px;
    }
`

const FlexCol = styled.div`
    display: flex;
    flex-direction: column;
`

const Heading = styled.div`
    font-weight: bold;
    color: white;
    font-size: 18px;
`

const SubHeading = styled.p`
    font-size: 14px;
    color: #ffffff60;
`

const RefBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 14px 10px;
    border-radius: 15px;
    font-weight: 600;
    color: white;
    border: 1px solid #ffffff10;
    background-color: #ffffff10;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 300ms;
    margin: 20px 0px 10px 0px;
    font-size: 14px;
`

const CopyText = styled.p`
    color: #00a4ff;
    font-weight: 600;
    cursor: pointer;
`

const Button = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border: none;
    font-size: 16px;
    color: white;
    font-weight: 600;
    padding: 15px 10px;
    border-radius: 9999px;
    background-image: linear-gradient(180deg, #818dff 0%, #6e7cff 100%);
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;

    > * + * {
        margin-left: 10px;
    }
`

const CardsContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 387px;

    > * + * {
        margin-top: 20px;
    }

    @media only screen and (min-width: 840px) {
        > * + * {
            margin-top: 40px;
        }
    }
`

const CardWrapper = styled.div`
    width: 100%;
    height: 209px;
    padding: 20px;
    border-radius: 15px;
    background-size: cover;
    background-repeat: no-repeat;

    @media only screen and (min-width: 840px) {
        padding: 30px;
    }
`

const RefCard = styled(CardWrapper)`
    background-image: url(${ReferralImg});
`

const FreeCard = styled(CardWrapper)`
    background-image: url(${FreeImg});
`

const InfoContainer = styled.div`
    color: white;

    > * + * {
        margin-top: 10px;
    }
`

const CardHeading = styled.div`
    font-weight: 600;
    font-size: 24px;
`

const CardSubHeading = styled.div`
    font-weight: 800;
    font-size: 40px;
`
