import styled from 'styled-components';
import { Color, colors } from './FormButton.data';

type ButtonProps = {
    fullWidth: boolean;
    isSmall: boolean;
    color: Color;
}

export const Button = styled.button<ButtonProps>(({ color, fullWidth, isSmall }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: fullWidth || isSmall ? '100%' : 'auto',
    paddingLeft: '20px',
    paddingRight: '20px',
    boxShadow: '0 5px 10px 0 rgba(0, 0, 0, 0.2)',
    borderRadius: '30px',
    fontSize: isSmall ? '16px' : '18px',
    color: '#ffffff',
    lineHeight: '20px',
    height: isSmall ? '46px': '60px',
    fontWeight: '700',
    cursor: 'pointer',
    transitionProperty: 'all',
    transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
    transitionDuration: '300ms',
    gap: '10px',
    ':hover': {
        transform: 'translateY(-0.15rem)'
    },
    ':disabled': {
        cursor: 'not-allowed'
    },
    gridColumn: fullWidth ? 'span 2 / span 2' : 'span 1 / span 1',
    ...colors[color],
}))

export const LoaderContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
`

export const Loader = styled.div`
    height: 20px;
    width: 20px;
    border-radius: 9999px;
    border: 4px solid #FFFFFF20;
    border-top-color: #FFFFFF80;
    animation: spinner 0.7s linear infinite;

    @keyframes spinner {
        to {
            transform: rotate(360deg);
        }
    }
`