import { CreateNotification, Notification } from 'src/types/Notification'

export const checkDuplicateURL = (
    alert: CreateNotification,
    notifications: Notification[]
) => {
    for (const notification of notifications) {
        if (alert.url && (notification.url === alert.url?.trim())) {
            return {
                sentAt: notification.send_at ?? notification.created_at,
            }
        }
    }

    return false
}