import z from 'zod';

export const ItemSchema = z.object({
    title: z.string({
        invalid_type_error: "Title must be a string",
    }).min(1, 'Title is required'),
    image: z.string({
        invalid_type_error: "Image must be a string",
    }).min(1, 'Image is required'),
    id: z.number({
        invalid_type_error: "ID must be a number",
    }).optional(),
    url: z.string({
        invalid_type_error: "URL must be a string",
    }).optional(),
    sku: z.string({
        invalid_type_error: "SKU must be a string",
    }).optional(),
    brand: z.string({
        invalid_type_error: "Brand must be a string",
    }).optional(),
    category: z.string({
        invalid_type_error: "Category must be a string",
    }).optional(),
    retail: z.number({
        invalid_type_error: "Retail must be a number",
    }).optional(),
    resell: z.number({
        invalid_type_error: "Resell must be a number",
    }).optional()
})