import FormButton from 'src/components/common/FormButton/FormButton'
import { CloseButton, Container, Heading } from './BinaryForm.styled'
import { BinaryModalProps } from './BinaryForm.types'

const BinaryForm = ({
    toggleModal,
    handleClick,
    question,
}: BinaryModalProps) => {
    return (
        <Container>
            <Heading>{question}</Heading>
            <FormButton fullWidth color="purple" onClick={handleClick}>
                Yes
            </FormButton>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <CloseButton onClick={e => {
                    e.preventDefault()
                    toggleModal()
                }}>No</CloseButton>
            </div>
        </Container>
    )
}

export default BinaryForm
