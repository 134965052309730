import Modal from '../Modal/Modal';

type ChildProps<T> = T & {
    toggleModal(): void;
}

type Props<T> = T & {
    toggleModal(): void;
    showModal: boolean;
}

function withModal<T>(Component: (props: ChildProps<T>) => JSX.Element) {
    return function Wrapper(props: Props<T>) {
        return (
            <Modal 
                showModal={props.showModal}
                toggleModal={props.toggleModal}
            >
                <Component {...props}/>
            </Modal>
        )
    }
}

export default withModal