import { useContext } from 'react';
import { StaffDispatchContext, StaffReleaseFormContext } from 'src/contexts/StaffReleaseFormContext/StaffReleaseFormContext';

export function useStaffReleaseFormContext() {
    const context = useContext(StaffReleaseFormContext);

    if (!context) {
        throw new Error("useStaffReleaseFormContext must be used within a StaffReleaseFormProvider");
    }

    return context;
}

export function useStaffDispatchContext() {
    const context = useContext(StaffDispatchContext);

    if (!context) {
        throw new Error("useStaffDispatchContext must be used within a StaffReleaseFormProvider");
    }
    
    return context;
}