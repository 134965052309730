import { Circle, Container, OuterCircle } from './Ping.styled'
import { PingProps } from './Ping.types'

const Ping = ({ color }: PingProps) => {
    return (
        <Container>
            <Circle color={color}>
                <OuterCircle color={color} />
            </Circle>
        </Container>
    )
}

export default Ping
