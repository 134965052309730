enum ActionTypes {
    ADD_ITEM = 'ADD_ITEM',
    ADD_ATTACHMENT = 'ADD_ATTACHMENT',
    ADD_INSTRUCTION = 'ADD_INSTRUCTION',
    UPDATE_ITEM = 'UPDATE_ITEM',
    UPDATE_ATTACHMENT = 'UPDATE_ATTACHMENT',
    UPDATE_INSTRUCTION = 'UPDATE_INSTRUCTION',
    DELETE_ITEM = 'DELETE_ITEM',
    DELETE_ATTACHMENT = 'DELETE_ATTACHMENT',
    DELETE_INSTRUCTION = 'DELETE_INSTRUCTION',
    UPDATE_FIELD = 'UPDATE_FIELD',
    RESET_FORM = 'RESET_FORM',
    SET_FORM_FIELDS = 'SET_FORM_FIELDS',
    SET_SELECTED_RELEASE = 'SET_SELECTED_RELEASE',
    SET_IMAGES = 'SET_IMAGES',
    SET_ATTACHMENTS = 'SET_ATTACHMENTS',
    DELETE_IMAGE = 'DELETE_IMAGE',
}

export default ActionTypes;