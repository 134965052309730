import { QueryKeys } from 'src/constants/QueryKeys'
import { ReleaseRecord, UpdateReleaseRecord } from 'src/types/ReleaseRecord'
import { axiosInstance } from 'src/utils/axios'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { omit } from 'lodash'
import { Store } from 'src/pages/StaffStoresPage'
import axios from 'axios'

type UpdateReleaseOptions = {
    id: string
    releaseRecord: UpdateReleaseRecord
}

const updateRelease = ({ id, releaseRecord }: UpdateReleaseOptions) => {
    const updatedReleaseRecord = omit(releaseRecord, 'id')

    // return axiosInstance
    //     .put<ReleaseRecord>(`/releases/update/${id}`, updatedReleaseRecord)
    //     .then(({ data }) => data)

    return axios
        .put<ReleaseRecord>(
            `https://pro-alerts-api-production.up.railway.app/api/v1/releases/update/${id}`,
            updatedReleaseRecord,
            {
                headers: {
                    Authorization: 'a1d3b746-0702-4d5b-900e-313dbf9ab0a2',
                },
            }
        )
        .then(({ data }) => data)
}

export const useUpdateRelease = () => {
    const queryClient = useQueryClient()

    const onSuccess = async () => {
        await queryClient.invalidateQueries([QueryKeys.releases])
    }

    return useMutation(updateRelease, { onSuccess })
}
