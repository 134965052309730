import styled from "styled-components"
import { FlexRow } from "../../common/common"

export const Container = styled.div`
    padding: 20px;
    border-radius: 20px;
    background: rgba(255,255,255,0.05);
    width: 100%;
    max-width: 387px;
    display: flex;
    flex-direction: column;
`

export const FlexRowSpace = styled(FlexRow)`
    > * + * {
        margin-left: 10px;
    }
`

export const ProductTitle = styled.h3`
    color: white;
    font-weight: 700;
    font-size: 18px;
`

export const SubscriptionDate = styled.p`
    font-weight: 500;
    color: #FFFFFF60;
`

export const Seperator = styled.div<{ isLarge?: boolean }>`
    width: 100%;
    height: 1px;
    background-color: #FFFFFF10;
    margin-bottom: 20px;
    border-radius: 0.5px;
    margin-top: ${({ isLarge }) => isLarge ? '65px' : '20px'};
`

export const Heading = styled.p`
    color: white;
    font-weight: bold;
`

export const CardType = styled.p`
    margin: 0px;
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
`

export const CardNumber = styled.p`
    font-size: 12px;
    font-weight: 500;
    color: #FFFFFF60;
`

export const CardBtn = styled.button`
    border: none;
    font-size: 16px;
    color: white;
    font-weight: 600;
    padding: 6px 10px;
    border-radius: 9999px;
    background: linear-gradient(to bottom, #6E7CFF, #5B69F1);
    cursor: pointer;
`

export const Button = styled.button<{ isOn: boolean; }>`
    color: ${({ isOn }) => isOn ? '#FF566F ' : '#00A4FF'};
    font-size: 14px;
    font-weight: 600;
    border: none;
    cursor: pointer;
    background-color: transparent;

    &:hover {
        text-decoration: underline;
    }
`

export const ProgressBarContainer = styled.div`
    margin: 10px 0px;
`

export const Text = styled.span`
    color: rgba(255, 255, 255, 0.8);
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 5px;
`

export const Stronger = styled.span`
    font-weight: 600;
    color: rgba(255, 255, 255, 1);
`

export const ButtonContainer = styled.div`
    margin-top: auto;
`