import axios from 'axios'
import { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import ChevronLeftIcon from 'src/components/common/icons/ChevronLeft'
import ChevronRightIcon from 'src/components/common/icons/ChevronRight'
import FormTextInput from 'src/components/common/FormTextInput/FormTextInput'
import StoreCard from 'src/components/stores/StoreCard/StoreCard'
import StoreEditForm from 'src/components/stores/StoreEditForm/StoreEditForm'
import styled from 'styled-components'

const Container = styled.div`
    width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
`

const TopRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
`

const Title = styled.span`
    font-size: 28px;
    font-weight: bold;
`

const NewStoreButton = styled.span`
    height: 46px;
    display: flex;
    align-items: center;
    padding: 0 18px;
    cursor: pointer;
    border-radius: 30px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
    background: linear-gradient(
        180deg,
        rgba(127, 112, 255, 1) 0%,
        rgba(107, 90, 255, 1) 100%
    );
    font-size: 16px;
    font-weight: bold;

    &:hover {
        filter: brightness(1.25);
    }

    transition: 0.1s filter ease-out;
`

const StoresContainer = styled.div`
    background: #272727;
    width: 1200px;
    margin: 0 auto;
    display: flex;
    height: calc(100vh - 225px);
    border-radius: 30px;
    position: relative;
`

const StoreCardsCol = styled.div`
    display: flex;
    flex-direction: column;
    width: 490px;
    border-bottom: 1px solid rgb(39, 39, 39);
    border-bottom-left-radius: 30px;
    border-right: 1px solid #3c3b3b;
    overflow-y: auto;

    &::-webkit-scrollbar {
        display: none;
    }
`

const StoreCardsFadeOutRegion = styled.div`
    background: linear-gradient(
        to bottom,
        rgba(39, 39, 39, 0),
        rgba(39, 39, 39, 1)
    );
    height: 40px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 489px;
    border-bottom-left-radius: 29px;
`

const StoreNameSearchInputWrapper = styled.div`
    padding: 20px;
    position: sticky;
    top: 0;
    background: rgba(39, 39, 39, 0.7);
    backdrop-filter: blur(30px);
    border-top-left-radius: 30px;
`

const StoreNameSearchInput = styled(FormTextInput)``

const PaginationControlsRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px;
`

const CircularButton = styled.button<{ selected?: boolean }>`
    color: #ffffff;
    font-weight: 600;
    font-size: 16px;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #ffffff10;
    border: 1px solid
        ${({ selected }) => (selected ? '#ffffff05' : '#ffffff10')};
    outline: none;
    font-family: 'Prompt', sans-serif;
    cursor: pointer;
    transition-property: border-color;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 300ms;

    &:disabled {
        cursor: not-allowed;
        color: #ffffff10;
    }

    &:not(:disabled):hover {
        border-color: #ffffff90;
    }
`

export interface Store {
    id: number
    store_name: string
    store_logo: string
    store_site: string
    store_locations: {
        google_places_id: string
        id: number
        created_at: string
        updated_at: string
        location_name: string
        location_phone: string
        location_address_formatted: string
        location_longitude: number
        location_latitude: number
        location_address_1: string
        location_address_2: string
        location_address_3: string
        location_city: string
        location_state: string
        location_country: string
        location_postal_code: string
        storeId: number
    }[]
}

const StaffStoresPage = () => {
    const [selectedStore, setSelectedStore] = useState<Store | null>(null)
    const [stores, setStores] = useState<Store[]>([])
    const [shouldRevalidate, setShouldRevalidate] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)
    const [searchTerm, setSearchTerm] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        setIsLoading(true)
        axios
            .get(
                searchTerm
                    ? `https://pro-alerts-api-production.up.railway.app/api/v2/stores/search?page=${currentPage}&query=${searchTerm}`
                    : `https://pro-alerts-api-production.up.railway.app/api/v2/stores/fetch?page=${currentPage}`,
                {
                    headers: {
                        Authorization: 'a1d3b746-0702-4d5b-900e-313dbf9ab0a2',
                    },
                }
            )
            .then((res) => {
                setStores(res.data.data)
                setTotalPages(res.data.pagination.total_pages)
            })
            .catch((err) => {
                console.log(err)
                toast.error(
                    'Failed to fetch stores. See console for error log.'
                )
            })
            .finally(() => {
                setIsLoading(false)
            })
    }, [shouldRevalidate, currentPage, searchTerm])

    return (
        <Container>
            <TopRow>
                <Title>Stores</Title>
                <NewStoreButton onClick={() => setSelectedStore(null)}>
                    New Store
                </NewStoreButton>
            </TopRow>
            <StoresContainer>
                <StoreCardsCol>
                    <StoreNameSearchInputWrapper>
                        <StoreNameSearchInput
                            value={searchTerm}
                            onChange={(e) => {
                                setCurrentPage(1)
                                setSearchTerm(e.target.value)
                            }}
                            placeholder="Search for a store..."
                            label="Store Name"
                        />
                        {!isLoading &&
                        totalPages === 0 &&
                        !!searchTerm ? null : (
                            <PaginationControlsRow>
                                <CircularButton
                                    disabled={currentPage === 1 || isLoading}
                                    onClick={() =>
                                        setCurrentPage((prev) =>
                                            prev === 1 ? 1 : prev - 1
                                        )
                                    }
                                >
                                    <ChevronLeftIcon />
                                </CircularButton>
                                <span>
                                    Page <strong>{currentPage}</strong> of{' '}
                                    <strong>
                                        {isLoading ? '...' : totalPages}
                                    </strong>
                                </span>
                                <CircularButton
                                    onClick={() =>
                                        setCurrentPage((prev) => prev + 1)
                                    }
                                    disabled={
                                        isLoading || currentPage >= totalPages
                                    }
                                >
                                    <ChevronRightIcon />
                                </CircularButton>
                            </PaginationControlsRow>
                        )}
                    </StoreNameSearchInputWrapper>
                    {!isLoading && totalPages === 0 && !!searchTerm && (
                        <span style={{ textAlign: 'center' }}>
                            No search results for{' '}
                            <strong>"{searchTerm}"</strong>
                        </span>
                    )}
                    {isLoading ? (
                        <span style={{ textAlign: 'center' }}>Loading...</span>
                    ) : (
                        stores?.map((store) => (
                            <StoreCard
                                id={store.id}
                                name={store.store_name}
                                locationsAmount={store.store_locations?.length}
                                logoUrl={store.store_logo}
                                isSelected={selectedStore?.id === store.id}
                                onDeleteClick={async () => {
                                    await toast.promise(
                                        axios.delete(
                                            `https://pro-alerts-api-production.up.railway.app/api/v1/stores/delete/${store.id}`,
                                            {
                                                headers: {
                                                    Authorization:
                                                        'a1d3b746-0702-4d5b-900e-313dbf9ab0a2',
                                                },
                                            }
                                        ),
                                        {
                                            loading: 'Deleting store...',
                                            success: 'Store deleted!',
                                            error: 'Failed to delete store',
                                        }
                                    )
                                    setShouldRevalidate((prev) => !prev)
                                }}
                                onEditClick={() => {}}
                                onSelect={() => setSelectedStore(store)}
                            />
                        ))
                    )}
                </StoreCardsCol>
                <StoreEditForm
                    isEditing={selectedStore !== null}
                    storeName={selectedStore?.store_name || ''}
                    editingStore={selectedStore}
                    shouldRevalidate={() =>
                        setShouldRevalidate((prev) => !prev)
                    }
                    shouldRevertToHomeView={() => setSelectedStore(null)}
                />
                <StoreCardsFadeOutRegion />
            </StoresContainer>
        </Container>
    )
}

export default StaffStoresPage
