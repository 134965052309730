import dayjs, { ConfigType } from 'dayjs'
import { Release } from '../components/types.2'

type Updater = (value: React.SetStateAction<number[]>) => void

export const calcDaysInPreviousMonth = (
    currentTime: ConfigType | undefined
) => {
    const prevMonth = []
    const daysInPrevMonth = dayjs(currentTime)
        .subtract(1, 'month')
        .daysInMonth()
    const indexOfFirstDayInCurrMonth = dayjs(currentTime).startOf('month').day()
    for (
        let i = daysInPrevMonth - indexOfFirstDayInCurrMonth + 1;
        i <= daysInPrevMonth;
        i++
    ) {
        prevMonth.push(i)
    }
    return prevMonth
}

export const calcDaysInNextMonth = (currentTime: ConfigType | undefined) => {
    const nextMonth = []
    const indexOfLastDayInCurrMonth = dayjs(currentTime).endOf('month').day()
    for (let i = indexOfLastDayInCurrMonth + 1; i <= 6; i++) {
        nextMonth.push(i - indexOfLastDayInCurrMonth)
    }
    return nextMonth
}

export const calcDaysInCurrentMonth = (currentTime: ConfigType | undefined) => {
    const daysInCurrentMonth = dayjs(currentTime).daysInMonth()
    return Array.from({ length: daysInCurrentMonth }, (_, i) => i + 1)
}

export const calculateEventDays = (
    releases: Release[],
    currDisplayedDate: number,
    setDaysWithEventsInCurrMonth: Updater,
    setDaysWithEventsInPrevMonth: Updater,
    setDaysWithEventsInNextMonth: Updater
) => {
    let nextMonth = []
    let currentMonth = []
    let previousMonth = []

    for (let i = 0; i < releases.length; i++) {
        const release = releases[i]
        const releaseDate = dayjs(release.timestamp._seconds * 1000)
        const currMonthDisplayed = dayjs(currDisplayedDate)
            .startOf('month')
            .valueOf()

        if (releaseDate.startOf('month').valueOf() === currMonthDisplayed) {
            currentMonth.push(releaseDate.date())
        }

        if (
            releaseDate.startOf('month').valueOf() ===
            dayjs(currDisplayedDate)
                .subtract(1, 'month')
                .startOf('month')
                .valueOf()
        ) {
            previousMonth.push(releaseDate.date())
        }

        if (
            releaseDate.startOf('month').valueOf() ===
            dayjs(currDisplayedDate).add(1, 'month').startOf('month').valueOf()
        ) {
            nextMonth.push(releaseDate.date())
        }
    }

    nextMonth = Array.from(new Set(nextMonth))
    currentMonth = Array.from(new Set(currentMonth))
    previousMonth = Array.from(new Set(previousMonth))

    setDaysWithEventsInCurrMonth(currentMonth)
    setDaysWithEventsInPrevMonth(previousMonth)
    setDaysWithEventsInNextMonth(nextMonth)
}
