import { v4 as uuidv4 } from 'uuid'
import AWS from 'aws-sdk'
import axios from 'axios'

type UploadImage = {
    file: string
    removeBg?: boolean
}

const R2_ACCOUNT_ID: string = process.env.REACT_APP_R2_ACCOUNT_ID ?? ''
const R2_BUCKET_NAME: string = process.env.REACT_APP_R2_BUCKET_NAME ?? ''
const R2_ACCESS_KEY_ID: string = process.env.REACT_APP_R2_ACCESS_KEY_ID ?? ''
const R2_ACCESS_KEY_SECRET: string =
    process.env.REACT_APP_R2_ACCESS_KEY_SECRET ?? ''

const API_ENDPOINT =
    'https://jklbixpv68.execute-api.us-east-1.amazonaws.com/default/processImages'
const API_KEY: string = process.env.REACT_APP_AWS_LAMBDA_API_KEY ?? ''

const s3 = new AWS.S3({
    endpoint: `https://a413185f2288abf448c095a0e1969c96.r2.cloudflarestorage.com`,
    accessKeyId: R2_ACCESS_KEY_ID,
    secretAccessKey: R2_ACCESS_KEY_SECRET,
    signatureVersion: 'v4',
    region: 'auto',
})

const createFileName = () => uuidv4() + '.png'

async function processImageWithAPI(
    file: File,
    removeBg: boolean
): Promise<File> {
    try {
        // Convert File to base64
        const base64data = await new Promise<string>((resolve) => {
            const reader = new FileReader()
            reader.onloadend = () => resolve(reader.result as string)
            reader.readAsDataURL(file)
        })

        // Remove the data URL prefix
        const base64Image = base64data.split(',')[1]

        // Make the API request
        const apiResponse = await axios.post(
            API_ENDPOINT,
            {
                body: base64Image,
                trim: true,
                remove_bg: removeBg,
            },
            {
                headers: {
                    'x-api-key': API_KEY,
                    'Content-Type': 'application/json',
                },
            }
        )

        const processedBase64 = apiResponse.data.body

        // Convert the base64 string back to a File object
        const byteCharacters = atob(processedBase64)
        const byteNumbers = new Array(byteCharacters.length)
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i)
        }
        const byteArray = new Uint8Array(byteNumbers)
        const blob = new Blob([byteArray], { type: 'image/png' })

        return new File([blob], file.name, { type: 'image/png' })
    } catch (error) {
        console.error('Error processing image with API:', error)
        throw error
    }
}

async function uploadToR2(file: File, fileName: string): Promise<string> {
    const params = {
        Bucket: R2_BUCKET_NAME,
        Key: `releases/uploads/${fileName}`,
        Body: file,
        ContentType: file.type,
    }

    try {
        const result = await s3.upload(params).promise()
        console.log('Upload successful:', result.Location)
        return result.Location
    } catch (error) {
        console.error('Error uploading file:', error)
        if (error instanceof Error) {
            console.error('Error message:', error.message)
            console.error('Error stack:', error.stack)
        }
        throw error
    }
}

export const uploadImage = async ({
    file,
    removeBg = false,
}: UploadImage): Promise<{ value: string; transparent: boolean }> => {
    try {
        const fileName = createFileName()
        const fileObject = await fetch(file).then((res) => res.blob())
        let fileToUpload = new File([fileObject], fileName, {
            type: fileObject.type,
        })

        if (removeBg) {
            fileToUpload = await processImageWithAPI(fileToUpload, removeBg)
        }

        let r2Url = await uploadToR2(fileToUpload, fileName)
        r2Url = r2Url.replace(
            'https://swiftsole-prod.a413185f2288abf448c095a0e1969c96.r2.cloudflarestorage.com',
            'https://cdn.swiftsole.app'
        )

        return { value: r2Url, transparent: removeBg }
    } catch (error) {
        console.error('Error uploading file:', error)
        if (error instanceof Error) {
            console.error('Error message:', error.message)
            console.error('Error stack:', error.stack)
        }
        return { value: '', transparent: removeBg }
    }
}
