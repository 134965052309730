import { Button, Loader, LoaderContainer } from './FormButton.styled'
import { FormButtonProps } from './FormButton.types'

const FormButton = ({ color, isLoading = false, isSmall = false, fullWidth = false, children, ...rest }: FormButtonProps) => {
  return (
    <Button fullWidth={fullWidth} isSmall={isSmall} color={color}  {...rest}>
        {isLoading && (
            <LoaderContainer>
                <Loader />
            </LoaderContainer>
        )}

        {children}
    </Button>
  )
}

export default FormButton
