import React, { useState } from 'react'
import FormButton from 'src/components/common/FormButton/FormButton'
import { CloseButton, Container, Heading } from './DeleteReleaseForm.styled'
import { DeleteReleaseFormProps } from './DeleteReleaseForm.types'

const DeleteReleaseForm = ({
    toggleModal,
    handleDeleteRelease,
}: DeleteReleaseFormProps) => {
    const [isLoading, setIsLoading] = useState(false)

    const handleClick = (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        setIsLoading(true)
        handleDeleteRelease(e)
    }

    return (
        <Container>
            <Heading>Are you sure you want to delete this release?</Heading>
            <FormButton
                fullWidth
                color="purple"
                isLoading={isLoading}
                onClick={handleClick}
            >
                Yes
            </FormButton>

            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <CloseButton onClick={toggleModal}>No</CloseButton>
            </div>
        </Container>
    )
}

export default DeleteReleaseForm
