import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    max-width: 700px;
    height: 400px;
    
    @media only screen and (min-width: 1024px) {
        height: auto;
    }
`

export const IFrame = styled.iframe`
    height: 100%;
    width: 100%;
`