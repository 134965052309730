import { pick } from 'lodash'
import { Notification } from 'src/types/Notification'

export const buildNotification = (selectedNotification: Notification) => {
    const propertiesToPick: Array<keyof Notification> = [
        'id',
        'send_at',
        'publisher_id',
        'type',
        'title',
        'content',
        'url',
        'image_url',
        'brand',
        'category'
    ]

    return pick(selectedNotification, ...propertiesToPick)
}