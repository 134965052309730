import Background from 'src/assets/background.png'
import CustomDateTimePicker from 'src/components/common/CustomDateTimePicker/CustomDateTimePicker'
import styled from 'styled-components'

export const FormContainer = styled.div`
    height: 100%;
    border-radius: 30px;
    background: #333232;
    overflow: hidden;

    @media (max-width: 900px) {
        max-width: 700px;
    }

    @media (max-width: 640px) {
        border-radius: 20px;
    }
`

export const Header = styled.header`
    position: sticky;
    top: 0;
    padding: 20px 0px;
    text-align: center;
    z-index: 10;
    background: #333232;

    @media (max-width: 640px) {
        padding: 10px 0px;
    }
`

export const Title = styled.h2`
    font-weight: 700;
    font-size: 24px;
    color: #f7eff3;
`

export const Form = styled.form`
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 40px;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow-y: scroll;

    &::-webkit-scrollbar:vertical {
        display: none;
    }

    @media (max-width: 640px) {
        padding: 0px 20px 20px 20px;
    }
`

export const FormFieldContainer = styled.div`
    width: 100%;
    background-image: linear-gradient(
        180deg,
        rgba(247, 239, 243, 0.1) 0%,
        rgba(247, 239, 243, 0.07) 99%
    );
    border: 1.5px solid rgba(247, 239, 243, 0.1);
    border-radius: 18px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 5;
`

export const Label = styled.label`
    font-weight: 300;
    font-size: 12px;
    color: #f7eff395;
    display: flex;
    justify-content: space-between;
`

export const Input = styled.input`
    width: 100%;
    font-weight: semibold;
    font-size: 16px;
    color: #f7eff3;
    background-color: transparent;
    outline: none;
    padding: 0px;
`

export const TextArea = styled.textarea`
    color: #f7eff3;
    font-weight: semibold;
    font-size: 16px;
    height: 70px;
    width: 100%;
    outline: none;
    resize: none;
    padding: 0px;
    background-color: transparent;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
`

export const Select = styled.select`
    font-weight: semibold;
    font-size: 16px;
    background-color: transparent;
    color: #f7eff3;
    padding: 0px;
    margin-left: -4px;
    outline: none;
`

export const DatePicker = styled(CustomDateTimePicker)`
    display: block;
    color: #f7eff3;
    font-weight: semibold;
    font-size: 16px;
    outline: none;
    font-family: 'Prompt', sans-serif;
    width: 100%;

    &:focus {
        outline: none;
        border-color: #c3c3c32e;
    }
`

export const FlexRow = styled.div`
    display: flex;
    gap: 10px;
    flex-wrap: no-wrap;

    @media (max-width: 900px) {
        flex-wrap: wrap;
    }
`

export const PreviewWrapper = styled.div`
    @media (max-width: 900px) {
        display: none;
    }
`

export const PreviewBackground = styled.div`
    background-image: url('${Background}');
    background-repeat: no-repeat;
    background-size: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 105px;
    width: 100%;
    margin-top: 10px;
`

export const PreviewContainer = styled.div`
    background: rgba(27, 27, 27, 0.85);
    border-radius: 23px;
    padding: 10px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    width: 100%;
    max-width: 500px;
`

export const PreviewInfoContainer = styled.div`
    color: #ffffff;
    font-family: 'San Francisco', sans-serif;
    width: 100%;
`

export const PreviewTitle = styled.h4`
    font-weight: 700;
    font-size: 17px;
`

export const PreviewText = styled.p`
    font-weight: 400;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
`

export const PreviewTimestamp = styled.p`
    font-size: 14.6px;
    color: rgba(255, 255, 255, 0.5);
    text-align: right;
`
