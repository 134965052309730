import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import ReactDOM from 'react-dom'
import App from './App'
import { inject } from '@vercel/analytics'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient();

const stripePromise = loadStripe(
    window.location.href.includes('localhost')
        ? 'pk_test_LP1IpkZaDSTGBUcJj6sERyrD'
        : 'pk_live_DjDGDqeUoDSBn7PnpTJp6DHR'
)

inject()

ReactDOM.render(
    <QueryClientProvider client={queryClient}>
        <Elements
            stripe={stripePromise}
            options={{
                fonts: [
                    {
                        cssSrc:
                            'https://fonts.googleapis.com/css2?family=Hind+Siliguri:wght@300;400;500;600;700&family=Prompt:wght@300;400;500;600;700&display=swap',
                    },
                ],
            }}
        >
            <App />
        </Elements>
    </QueryClientProvider>,
    document.getElementById('root')
)
