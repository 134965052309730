import { isEmpty } from 'lodash'
import React, { useState } from 'react'
import Select, { ActionMeta, OnChangeValue } from 'react-select'
import makeAnimated from 'react-select/animated'
import {
    BlueText,
    FormDatePicker,
    FormInput,
    FormTextArea,
    HollowButton,
} from 'src/components/common/common'
import CustomCheckbox from 'src/components/common/CustomCheckbox/CustomCheckbox'
import FormButton from 'src/components/common/FormButton/FormButton'
import RequiredText from 'src/components/common/RequiredText/RequiredText'
import ToggleButton from 'src/components/common/ToggleButton/ToggleButton'
import Tooltip from 'src/components/common/Tooltip/Tooltip'
import withModal from 'src/components/common/withModal/withModal'
import { selectStyles } from 'src/constants/selectStyles'
import {
    resetForm,
    updateField,
} from 'src/contexts/StaffReleaseFormContext/StaffReleaseFormContext.services'
import { useGetFilters } from 'src/hooks/useGetFilters'
import {
    useStaffDispatchContext,
    useStaffReleaseFormContext,
} from 'src/hooks/useStaffReleaseForm'
import { useToggle } from 'src/hooks/useToggle'
import {
    Attachment,
    Instruction,
    Item,
    ReleaseRecordKeys,
    ReleaseType,
} from 'src/types/ReleaseRecord'
import { copyText } from 'src/utils/clipboard'
import AttachmentsForm from '../AttachmentsForm/AttachmentsForm'
import { AttachmentsFormProps } from '../AttachmentsForm/AttachmentsForm.types'
import ImageUploadForm from '../ImageUploadForm/ImageUploadForm'
import { ImageUploadFormProps } from '../ImageUploadForm/ImageUploadForm.types'
import InstructionsForm from '../InstructionsForm/InstructionsForm'
import { InstructionsFormProps } from '../InstructionsForm/InstructionsForm.types'
import { ItemsFormProps } from '../ItemsForm/ItemForm.types'
import ItemsForm from '../ItemsForm/ItemsForm'
import ImagesTable from '../Table/ImagesTable/ImagesTable'
import InstructionsTable from '../Table/InstructionsTable/InstructionsTable'
import MultiReleaseFields from './MultiReleaseFields/MultiReleaseFields'
import {
    Container,
    CustomSelect,
    FlexRowBetween,
    Form,
    Heading,
    Label,
    PublisherContainer,
} from './ReleaseForm.styled'
import { ReleaseFormProps } from './ReleaseForm.types'
import SingleReleaseFields from './SingleReleaseFields/SingleReleaseFields'
import AttachmentTable from '../Table/AttachmentsTable/AttachmentsTable'

const INITIAL_HOVER_TEXT = 'Copy to clipboard'

const animatedComponents = makeAnimated()

const ItemsFormWithModal = withModal<ItemsFormProps>(ItemsForm)
const AttachmentsFormWithModal =
    withModal<AttachmentsFormProps>(AttachmentsForm)
const InstructionsFormWithModal =
    withModal<InstructionsFormProps>(InstructionsForm)
const ImageUploadFormWithModal =
    withModal<ImageUploadFormProps>(ImageUploadForm)

const ReleaseForm = ({
    handleCreateRelease,
    handleUpdateRelease,
    handleClearForm,
}: ReleaseFormProps) => {
    const { on: checked, toggle } = useToggle()
    const { on: showItemsForm, toggle: toggleItemsForm } = useToggle()
    const { on: showInstructionForm, toggle: toggleInstructionForm } =
        useToggle()
    const { on: showAttachmentsForm, toggle: toggleAttachmentsForm } =
        useToggle()
    const { on: showImageUploadForm, toggle: toggleImageUploadForm } =
        useToggle()

    const [isLoading, setIsLoading] = useState(false)
    const [selectedItem, setSelectedItem] = useState({} as Item)
    const [hoverText, setHoverText] = useState(INITIAL_HOVER_TEXT)
    const [selectedAttachment, setSelectedAttachment] = useState(
        {} as Attachment
    )
    const [selectedInstruction, setSelectedInstruction] = useState(
        {} as Instruction
    )

    const dispatch = useStaffDispatchContext()
    const { formState, selectedRelease } = useStaffReleaseFormContext()

    const { type } = formState
    const [prevType, setPrevType] = useState<ReleaseType>(type)

    if (type !== prevType) {
        setPrevType(type)
        toggle()
    }

    const handleChange = (
        e: React.ChangeEvent<
            HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
        >
    ) => {
        const { name, value } = e.target
        updateField(dispatch)(name as ReleaseRecordKeys, value)
    }

    const handleDateChange = (date: Date) =>
        updateField(dispatch)('release_at', date)

    const handleChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
        updateField(dispatch)('published', e.target.checked)
    }

    const handleSelect = (
        newValue: OnChangeValue<any, any>,
        actionMeta: ActionMeta<any>
    ) => {
        const { name } = actionMeta
        const values = newValue.map(({ value }: { value: string }) => value)

        updateField(dispatch)(name as ReleaseRecordKeys, values)
    }

    const handleClick = async (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        const fn = !isEmpty(selectedRelease)
            ? handleUpdateRelease
            : handleCreateRelease

        setIsLoading(true)
        await fn(e)
        setIsLoading(false)
    }

    const handleCopyText =
        (text: string) =>
        async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            e.preventDefault()
            setHoverText('Copied')
            await copyText(text)
        }

    const { data: filters } = useGetFilters()

    return (
        <>
            <Container>
                <FlexRowBetween>
                    <Heading>New Release</Heading>

                    <CustomSelect
                        name="region"
                        id="region"
                        onChange={(e: any) => {
                            handleClearForm(e)
                            updateField(dispatch)('region', e.target.value)
                        }}
                    >
                        <option value="US">US</option>
                        <option value="EU">EU</option>
                        <option value="AS">AS</option>
                    </CustomSelect>

                    <div style={{ display: 'flex', gap: '20px' }}>
                        <ToggleButton
                            checked={checked}
                            options={{ option1: 'Single', option2: 'Multiple' }}
                            onClick={() => {
                                resetForm(dispatch)()
                                updateField(dispatch)(
                                    'type',
                                    type === ReleaseType.SINGLE
                                        ? ReleaseType.MULTI
                                        : ReleaseType.SINGLE
                                )
                            }}
                            isLarge
                        />

                        <FormButton
                            color="green"
                            disabled={isLoading}
                            onClick={handleClick}
                            isLoading={isLoading}
                        >
                            {!isEmpty(selectedRelease) ? 'Update' : 'Create'}
                        </FormButton>
                    </div>
                </FlexRowBetween>

                <Form>
                    <ImagesTable toggleModal={toggleImageUploadForm} />

                    {!isEmpty(selectedRelease) && (
                        <div>
                            <Label htmlFor="id">
                                <p>Release ID</p>
                                <Tooltip
                                    hoverText={hoverText}
                                    onClick={handleCopyText(selectedRelease.id)}
                                    onMouseLeave={() =>
                                        setHoverText(INITIAL_HOVER_TEXT)
                                    }
                                >
                                    <BlueText>Copy</BlueText>
                                </Tooltip>
                            </Label>
                            <FormInput
                                id="id"
                                name="id"
                                value={selectedRelease.id}
                                onChange={() => {}}
                            />
                        </div>
                    )}

                    <div>
                        <Label htmlFor="title">
                            <p>Title</p>
                            <RequiredText />
                        </Label>
                        <FormInput
                            id="title"
                            name="title"
                            value={formState.title}
                            onChange={handleChange}
                            placeholder="Adidas Yeezy 350 V2"
                        />
                    </div>

                    <div>
                        <Label htmlFor="release_at">
                            <p>Date</p>
                            <RequiredText />
                        </Label>
                        <FormDatePicker
                            value={formState.release_at as Date}
                            onChange={handleDateChange}
                        />
                    </div>

                    <PublisherContainer>
                        <div style={{ flex: 1 }}>
                            <Label htmlFor="publisher_id">Publisher ID</Label>
                            <FormInput
                                id="publisher_id"
                                name="publisher_id"
                                value={formState.publisher_id}
                                onChange={() => {}}
                            />
                        </div>

                        <CustomCheckbox
                            name="published"
                            handleCheck={handleChecked}
                            label="Published"
                            checked={formState.published as boolean}
                        />
                    </PublisherContainer>

                    <div>
                        <Label htmlFor="cover_color_hex">
                            <p>HEX Cover Color</p>
                            <RequiredText />
                        </Label>
                        <FormInput
                            id="cover_color_hex"
                            name="cover_color_hex"
                            value={formState.cover_color_hex}
                            placeholder="FFFFFF"
                            onChange={handleChange}
                        />
                    </div>

                    <div>
                        <Label htmlFor="categories">
                            Categories (Optional)
                        </Label>
                        <Select
                            isMulti
                            id="categories"
                            name="categories"
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            options={filters?.categories}
                            onChange={handleSelect}
                            styles={selectStyles}
                            value={formState.categories.map((item) => ({
                                value: item.toLowerCase(),
                                label: item,
                            }))}
                        />
                    </div>

                    <div>
                        <Label htmlFor="brands">Brands (Optional)</Label>
                        <Select
                            isMulti
                            id="brands"
                            name="brands"
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            options={filters?.brands}
                            onChange={handleSelect}
                            styles={selectStyles}
                            value={formState.brands.map((item) => ({
                                value: item.toLowerCase(),
                                label: item,
                            }))}
                        />
                    </div>

                    <div>
                        <Label htmlFor="keyword_group">
                            Keyword Groups (Optional)
                        </Label>
                        <Select
                            id="keyword_group"
                            name="keyword_group"
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            isMulti
                            options={filters?.categories}
                            onChange={handleSelect}
                            styles={selectStyles}
                            value={formState.keyword_group.map((item) => ({
                                value: item.toLowerCase(),
                                label: item,
                            }))}
                        />
                    </div>

                    <div>
                        <Label htmlFor="content">Description (Optional)</Label>
                        <FormTextArea
                            id="content"
                            name="content"
                            value={formState.content}
                            placeholder="Enter a release description"
                            onChange={handleChange}
                        />
                    </div>

                    {type === ReleaseType.SINGLE ? (
                        <SingleReleaseFields />
                    ) : (
                        <MultiReleaseFields
                            toggleModal={toggleItemsForm}
                            setSelectedItem={setSelectedItem}
                        />
                    )}

                    <AttachmentTable
                        toggleModal={toggleAttachmentsForm}
                        setSelectedAttachment={setSelectedAttachment}
                        key={formState.title}
                    />

                    <InstructionsTable
                        toggleModal={toggleInstructionForm}
                        setSelectedInstruction={setSelectedInstruction}
                    />

                    <HollowButton onClick={handleClearForm}>
                        Clear Form
                    </HollowButton>
                </Form>
            </Container>

            <InstructionsFormWithModal
                showModal={showInstructionForm}
                toggleModal={toggleInstructionForm}
                selectedInstruction={selectedInstruction}
            />

            <AttachmentsFormWithModal
                showModal={showAttachmentsForm}
                toggleModal={toggleAttachmentsForm}
                selectedAttachment={selectedAttachment}
            />

            <ItemsFormWithModal
                showModal={showItemsForm}
                toggleModal={toggleItemsForm}
                selectedItem={selectedItem}
                filters={filters as any}
            />

            <ImageUploadFormWithModal
                showModal={showImageUploadForm}
                toggleModal={toggleImageUploadForm}
            />
        </>
    )
}

export default ReleaseForm
