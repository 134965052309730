import axios from 'axios';
import { useEffect, useState } from 'react';
import styled from 'styled-components'
// @ts-ignore
import CustomDateTimePicker from './common/CustomDateTimePicker/CustomDateTimePicker';

const Button = styled.button`
    margin-top: 20px;
    background: linear-gradient(180deg, #6e7cff 0%, #5b69f1 100%);
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    font-weight: bold;
    text-align: center;
    padding: 12px 0;
    text-decoration: none;
    color: white;
    border: 0px;
    width: 50%;
`

const Subheading = styled.span`
    font-weight: 600;
    margin-bottom: 10px;
`

const ErrorAlert = styled.div`
    background: #ff425e;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 10px;
    color: white;
    font-weight: bold;
    text-align: center;
`

const Title = styled.div`
  font-size: 14px;
`

const Input = styled.input`
    height: 30px;
    width:98.5%;
    background: rgba(255, 255, 255, 0.10);
    border-radius:10px;
    color:white;
    border: 0px;
    padding-left: 10px;
    padding-top: 2px;
`

const Select = styled.select`
    height: 30px;
    width:98.5%;
    background: rgba(255, 255, 255, 0.10);
    border-radius:10px;
    color:white;
    border: 0px;
    padding-left: 10px;
    padding-top: 2px;
`

interface CreateReleaseFormProps {
    editRelease: any;
    isEditing: boolean;
    setReleasesInfo: Function;
    setIsCreating: Function
    setIsEditing: Function
}

const CreateReleaseForm = ({ editRelease, isEditing, setReleasesInfo, setIsCreating, setIsEditing }: CreateReleaseFormProps) => {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
    const [isError, setIsError] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<any>('')
    const [product, setProduct] = useState<any>('')
    const [stock, setStock] = useState<any>('')
    const [password, setPassword] = useState<any>('')
    const [startsAt, setStartsAt] = useState<any>(new Date())
    const [queueEnabled, setQueueEnabled] = useState<any>('disabled')
    const [trialDays, setTrialDays] = useState<any>('0')

    useEffect(() => {
        if (isEditing) {
            setProduct(editRelease.product)
            setStock(editRelease.stock)
            setPassword(editRelease.password)
            setStartsAt(new Date(editRelease.startsAt))
            setQueueEnabled(editRelease.queue ? 'enabled' : 'disabled')
            setTrialDays(editRelease.trialDays || '0')
        }
    }, [isEditing, editRelease])

    const handleSubmit = async () => {
        if (isSubmitting) return

        setIsSubmitting(true)
        setIsError(false)

        try {
            let res

            if (isEditing) {
                res = await axios.patch(`/api/v1/releases/${editRelease.password}`, {
                    product,
                    password,
                    stock: Number(stock),
                    startsAt: (new Date(startsAt)).getTime(),
                    queue: queueEnabled === 'enabled' ? true : false,
                    trialDays: Number(trialDays)
                }, { withCredentials: true })
            } else {
                res = await axios.post("/api/v1/releases", {
                    product,
                    password,
                    stock: Number(stock),
                    startsAt: (new Date(startsAt)).getTime(),
                    queue: queueEnabled === 'enabled' ? true : false,
                    trialDays: Number(trialDays)
                }, { withCredentials: true })
            }

            console.log(res)

            setIsSubmitting(false)

            if (res.status === 200) {
                setReleasesInfo(res.data)
                setIsCreating(false)
                setIsEditing(false)
            } else {
                setIsError(true)
                setErrorMessage(`Something went wrong. Please try again.`)
            }
        } catch (err: any) {
            setIsSubmitting(false)
            // error planning here
            console.log('[error]', err, err.response)

            if (err.response && err.response.status === 402) {
                setIsError(true)
                setErrorMessage(err.response.data.message)
            } else {
                setIsError(true)
                setErrorMessage(`Something went wrong. Please try again.`)
            }
        }
    }

    return (
        <>
            { isError && 
                <ErrorAlert>{ errorMessage }</ErrorAlert>
            }

            <Subheading>{ isEditing ? `Edit release` : 'Create new release' }</Subheading>

            <Title>Product</Title>
            <Select
                value={product}
                onChange={(e) => setProduct(e.target.value)}
            >
                <option value="" selected disabled>Select a product</option>
                <option value="swiftsole_v3">SwiftSole V3</option>
                <option value="swiftsole_desktop">SwiftSole Desktop</option>
            </Select>

            <Title>Password</Title>
            <Input
                placeholder="Password!345"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
            />

            <Title>Stock</Title>
            <Input
                placeholder="10"
                value={stock}
                onChange={(e) => setStock(e.target.value)}
                type="number"
            />

            <Title>Starts at</Title>

            <CustomDateTimePicker
                onChange={(e: any) => { console.log(e); setStartsAt(e) }}
                value={startsAt}
            />

            <Title>Queue enabled</Title>
            <Select
                value={queueEnabled}
                onChange={(e) => setQueueEnabled(e.target.value)}
            >
                <option value="enabled">Enabled</option>
                <option value="disabled">Disabled</option>
            </Select>

            <Title>Trial days</Title>
            <Input
                placeholder="0"
                value={trialDays}
                onChange={(e) => setTrialDays(e.target.value)}
                type="number"
            />

            <Button onClick={handleSubmit}>{ isEditing ? 'Save' : 'Create' }</Button>
        </>
    )
}

export default CreateReleaseForm