import { isEmpty } from 'lodash'
import { forwardRef, useEffect, useState } from 'react'
import {
    ErrorAlert,
    FormDatePicker,
    FormInput,
    FormInputContainerLarge,
    FormInputContainerSmall,
    FormLabel,
    FormSelect,
} from 'src/components/common/common'
import CustomCheckbox from 'src/components/common/CustomCheckbox/CustomCheckbox'
import FormButton from 'src/components/common/FormButton/FormButton'
import RequiredText from 'src/components/common/RequiredText/RequiredText'
import {
    addAttachment,
    updateAttachment,
} from 'src/contexts/StaffReleaseFormContext/StaffReleaseFormContext.services'
import {
    useStaffDispatchContext,
    useStaffReleaseFormContext,
} from 'src/hooks/useStaffReleaseForm'
import { AttachmentSchema } from 'src/schema/AttachmentSchema'
import {
    Attachment,
    ReleaseItemType,
    RegionType,
} from 'src/types/ReleaseRecord'
import { checkDuplicate } from 'src/utils/checkDuplicate'
import { generateId } from 'src/utils/generateId'
import { validateForm } from 'src/utils/validateForm'
import { ButtonContainer, Container } from './AttachmentsForm.styled'
import { AttachmentsFormProps, FormState } from './AttachmentsForm.types'
import FormTextInput from 'src/components/common/FormTextInput/FormTextInput'
import AttachmentLocationsSearch from '../AttachmentLocationsSearch/AttachmentLocationsSearch'
import { Store } from 'src/pages/StaffStoresPage'

const AttachmentsForm = ({
    toggleModal,
    selectedAttachment,
}: AttachmentsFormProps) => {
    const dispatch = useStaffDispatchContext()
    const { formState: state } = useStaffReleaseFormContext()

    const [errorMessage, setErrorMessage] = useState('')

    const [formState, setFormState] = useState<FormState>({
        id: selectedAttachment?.id || generateId(),
        title: selectedAttachment?.title || '',
        type: selectedAttachment?.type || ReleaseItemType.DROP,
        url: selectedAttachment?.url || '',
        release_at:
            (selectedAttachment?.release_at &&
                new Date(selectedAttachment.release_at)) ||
            new Date(),
        release_end:
            (selectedAttachment?.release_end &&
                new Date(selectedAttachment.release_end)) ||
            undefined,
        store: selectedAttachment?.store || undefined,
        // @ts-ignore
        locations: selectedAttachment?.locations || [],
        method: selectedAttachment?.method || 'ONLINE',
        region: selectedAttachment?.region || 'US',
    })

    const isNewItem = isEmpty(selectedAttachment)

    const handleChange = (
        e: React.ChangeEvent<
            HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
        >
    ) => {
        const { name, value } = e.target

        setFormState((prev) => ({
            ...prev,
            [name]: value,
        }))
    }

    const handleDateChange = (name: string) => (date: Date) => {
        setFormState((prev) => ({
            ...prev,
            [name]: date,
        }))
    }

    const handleChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = e.target

        setFormState((prev) => ({
            ...prev,
            [name]: checked,
        }))
    }

    const handleCreateItem = (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        e.preventDefault()
        setErrorMessage('')

        try {
            checkDuplicate({
                value: formState.id,
                items: state.attachments,
                key: 'id',
            })
            validateForm(AttachmentSchema, formState)
            // const formStateCopy = { ...formState }
            // @ts-ignore
            // formStateCopy.store = formStateCopy.store?.id
            // console.log(formStateCopy)
            addAttachment(dispatch)(formState)
            toggleModal()
        } catch (error) {
            setErrorMessage((error as Error).message)
        }
    }

    const handleEditItem = (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        e.preventDefault()
        setErrorMessage('')

        try {
            if (selectedAttachment?.id !== formState.id) {
                checkDuplicate({
                    value: formState.id,
                    items: state.attachments,
                    key: 'id',
                })
            }

            const attachment: Attachment = {
                ...formState,
                release_at: new Date(formState.release_at),
            }

            validateForm(AttachmentSchema, formState)
            updateAttachment(dispatch)(selectedAttachment.title, attachment)
            toggleModal()
        } catch (error) {
            setErrorMessage((error as Error).message)
        }
    }

    const handleLocationsChange = (newLocations: Store['store_locations']) => {
        // @ts-ignore
        setFormState((prev) => ({
            ...prev,
            locations: newLocations,
        }))
    }

    const handleStoreChange = (newStore: Store) => {
        setFormState((prev) => ({
            ...prev,
            store: newStore,
        }))
    }

    return (
        <Container>
            {errorMessage && <ErrorAlert>{errorMessage}</ErrorAlert>}

            <FormInputContainerSmall>
                <FormLabel htmlFor="title">
                    <p>Title</p>
                    <RequiredText />
                </FormLabel>
                <FormInput
                    id="title"
                    name="title"
                    value={formState.title}
                    placeholder="Nike US"
                    onChange={handleChange}
                />
            </FormInputContainerSmall>
            <FormInputContainerSmall>
                <FormLabel htmlFor="region">
                    <p>Region</p>
                    <RequiredText />
                </FormLabel>
                <FormSelect
                    id="region"
                    name="region"
                    value={formState.region}
                    onChange={handleChange}
                >
                    {Object.keys(RegionType).map((key) => (
                        <option value={key} key={key}>
                            {key}
                        </option>
                    ))}
                </FormSelect>
            </FormInputContainerSmall>

            <FormInputContainerLarge>
                <FormLabel htmlFor="url">
                    <p>URL</p>
                    <RequiredText />
                </FormLabel>
                <FormInput
                    id="url"
                    name="url"
                    value={formState.url}
                    placeholder="https://www.nike.com"
                    onChange={handleChange}
                />
            </FormInputContainerLarge>

            <FormInputContainerLarge>
                <FormLabel htmlFor="release_at">
                    <p>Start Date</p>
                    <RequiredText />
                </FormLabel>
                <FormDatePicker
                    value={formState.release_at}
                    onChange={handleDateChange('release_at')}
                />
            </FormInputContainerLarge>

            <FormInputContainerLarge>
                <FormLabel htmlFor="release_end">
                    <p>End Date (Optional)</p>
                </FormLabel>
                <FormDatePicker
                    value={formState.release_end}
                    onChange={handleDateChange('release_end')}
                />
            </FormInputContainerLarge>

            <FormInputContainerSmall>
                <FormLabel htmlFor="type">Type</FormLabel>
                <FormSelect
                    id="type"
                    name="type"
                    value={formState.type}
                    onChange={handleChange}
                >
                    {Object.keys(ReleaseItemType).map((key) => (
                        <option value={key} key={key}>
                            {key}
                        </option>
                    ))}
                </FormSelect>
            </FormInputContainerSmall>

            <FormInputContainerSmall>
                <FormLabel htmlFor="method">Method</FormLabel>
                <FormSelect
                    id="method"
                    name="method"
                    value={formState.method}
                    onChange={handleChange}
                >
                    {['ONLINE', 'IN_STORE', 'APP', 'OTHER'].map((key) => (
                        <option value={key} key={key}>
                            {key}
                        </option>
                    ))}
                </FormSelect>
            </FormInputContainerSmall>

            {/* <FormInputContainerLarge>
                    <FormLabel htmlFor="store_address">Store Address (Optional)</FormLabel>
                    <FormInput
                        id="store_address"
                        name="store_address"
                        value={formState.store_address}
                        placeholder="5307 E Mockingbird Ln # 7, Dallas, TX 75206"
                        onChange={handleChange}
                    />
                </FormInputContainerLarge> */}

            {/* <FormTextInput */}

            {/* Search box for finding stores and selecting locations */}
            {/* @ts-ignore */}
            <AttachmentLocationsSearch
                // @ts-ignore
                previousLocations={formState.locations}
                // @ts-ignore
                previousStore={formState.store}
                onLocationsChange={(newLocations) =>
                    handleLocationsChange(newLocations)
                }
                onStoreChange={(newStore) => handleStoreChange(newStore)}
            />

            <ButtonContainer>
                <FormButton
                    fullWidth
                    color="purple"
                    onClick={isNewItem ? handleCreateItem : handleEditItem}
                >
                    {isNewItem ? 'Create Attachment' : 'Update Attachment'}
                </FormButton>
            </ButtonContainer>

            <FormButton fullWidth color="grey" onClick={toggleModal}>
                Close
            </FormButton>
        </Container>
    )
}

export default AttachmentsForm
