import React, { useEffect, useState } from 'react'
import logo from 'src/assets/purple-logo.svg'
import FileUpload from 'src/components/common/FileUpload/FileUpload'
import { NewFile } from 'src/components/common/FileUpload/FileUpload.types'
import FormButton from 'src/components/common/FormButton/FormButton'
import RequiredText from 'src/components/common/RequiredText/RequiredText'
import withModal from 'src/components/common/withModal/withModal'
import ImageUploadTable from 'src/components/releases/Table/ImageUploadTable/ImageUploadTable'
import {
    setFiles,
    updateField,
} from 'src/contexts/StaffAlertFormContext/StaffAlertFormContext.services'
import useAuthContext from 'src/hooks/useAuthContext'
import { useCreateNotification } from 'src/hooks/useCreateNotification'
import { useGetFilters } from 'src/hooks/useGetFilters'
import { useGetNotifications } from 'src/hooks/useGetNotifications'
import {
    useStaffAlertDispatchContext,
    useStaffAlertForm,
} from 'src/hooks/useStaffAlertForm'
import { useToggle } from 'src/hooks/useToggle'
import { NotificationSchema, AudienceType } from 'src/schema/NotificationSchema'
import {
    CreateNotification,
    CreateNotificationKeys,
    Notification,
} from 'src/types/Notification'
import { uploadImage } from 'src/utils/r2'
import { notify } from 'src/utils/notify'
import { parseError } from 'src/utils/parseError'
import { validateForm } from 'src/utils/validateForm'
import BinaryForm from '../BinaryForm/BinaryForm'
import { BinaryModalProps } from '../BinaryForm/BinaryForm.types'
import SearchBar from '../SearchBar/SearchBar'
import { timestampOptions } from './NotificationForm.data'
import {
    DatePicker,
    FlexRow,
    Form,
    FormContainer,
    FormFieldContainer,
    Header,
    Input,
    Label,
    PreviewBackground,
    PreviewContainer,
    PreviewInfoContainer,
    PreviewText,
    PreviewTimestamp,
    PreviewTitle,
    PreviewWrapper,
    Select,
    TextArea,
    Title,
} from './NotificationForm.styled'
import { NotificationFormProps } from './NotificationForm.types'
import { checkDuplicateURL } from './NotificationForm.utils'

const ConfirmFormModal = withModal<BinaryModalProps>(BinaryForm)

const WarningFormModal = withModal<BinaryModalProps>(BinaryForm)

const NotificationForm = ({
    toggleModal,
    showModal,
}: NotificationFormProps) => {
    const { on: showConfirmModal, toggle: toggleConfirmModal } = useToggle()
    const { on: showWarningModal, toggle: toggleWarningModal } = useToggle()
    const { userInfo } = useAuthContext()
    const { formState, files } = useStaffAlertForm()
    const dispatch = useStaffAlertDispatchContext()
    const [isUploading, setIsUploading] = useState(false)
    const [when, setWhen] = useState('Now')
    const [date, setDate] = useState('')

    const { mutateAsync: createNotification, isLoading } =
        useCreateNotification()

    const { data: notificationData } = useGetNotifications(1)

    const { data } = useGetFilters()

    useEffect(() => {
        updateField(dispatch)('publisher_id', userInfo.id)
    }, [userInfo.id, dispatch])

    useEffect(() => {
        const handler = (e: Event) => {
            const screenWidth = (e.target as Window).innerWidth
            if (screenWidth > 900 && showModal) toggleModal()
        }

        window.addEventListener('resize', handler)
        window.addEventListener('orientationchange', handler)

        return () => {
            window.removeEventListener('resize', handler)
            window.removeEventListener('orientationchange', handler)
        }
    }, [toggleModal])

    const handleChange = (
        e: React.ChangeEvent<
            HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
        >
    ) => {
        const { name, value } = e.target
        updateField(dispatch)(name as CreateNotificationKeys, value)
    }

    const handleCreateNotification = async (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        e.preventDefault()
        toggleConfirmModal()
        setIsUploading(false)

        try {
            let image_url = ''
            const file = files[0]

            if (file?.url) {
                setIsUploading(true)
                const { value } = await uploadImage({ file: file.url })
                image_url = value
                setIsUploading(false)
            }

            const send_at = when !== 'Now' ? formState.send_at : undefined
            const data: CreateNotification = {
                ...formState,
                image_url,
                send_at,
            }

            validateForm(NotificationSchema, data)

            await createNotification(data)
            notify('Notification successully created', 'success')
        } catch (error) {
            notify(parseError(error), 'error')
        }
    }

    const handleDateChange = (date: Date) =>
        updateField(dispatch)('send_at', date)

    const handleFiles = (files: NewFile[]) => setFiles(dispatch)(files)

    const handleSubmit = (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        e.preventDefault()

        const result = checkDuplicateURL(
            formState,
            notificationData?.notifications.slice(0, 5) as Notification[]
        )

        if (result) {
            setDate(new Date(result.sentAt).toDateString())
            toggleWarningModal()
        } else {
            toggleConfirmModal()
        }
    }

    return (
        <>
            <FormContainer>
                <Form>
                    <Header>
                        <Title>New Notification</Title>
                    </Header>

                    <FormFieldContainer>
                        <Label htmlFor="title">
                            <span>Title</span>
                            <RequiredText isSmall />
                        </Label>
                        <Input
                            value={formState.title}
                            onChange={handleChange}
                            name="title"
                            id="title"
                            placeholder="Adidas Yeezy"
                        />
                    </FormFieldContainer>

                    <FormFieldContainer>
                        <Label htmlFor="description">
                            Description (Optional)
                        </Label>
                        <TextArea
                            value={formState.content}
                            onChange={handleChange}
                            name="content"
                            id="content"
                            placeholder="Enter a notification description"
                        />
                    </FormFieldContainer>

                    <FlexRow>
                        <FormFieldContainer>
                            <Label htmlFor="brand">Brands (Optional)</Label>
                            <Select
                                value={formState.brand}
                                onChange={handleChange}
                                name="brand"
                                id="brand"
                            >
                                <option value="">Select...</option>
                                {
                                    <>
                                        {data?.brands.map((brand) => (
                                            <option
                                                key={brand.value}
                                                value={brand.value}
                                            >
                                                {brand.label}
                                            </option>
                                        ))}
                                    </>
                                }
                            </Select>
                        </FormFieldContainer>

                        <FormFieldContainer>
                            <Label htmlFor="audience_type">
                                Audience Type (Optional)
                            </Label>
                            <Select
                                value={formState.audience_type}
                                onChange={handleChange}
                                name="audience_type"
                                id="audience_type"
                                defaultValue={AudienceType.PAID}
                            >
                                <option value={AudienceType.PAID}>Paid</option>
                                <option value={AudienceType.FREE}>Free</option>
                                <option value={AudienceType.BOTH}>Both</option>
                            </Select>
                        </FormFieldContainer>

                        <FormFieldContainer>
                            <Label htmlFor="category">
                                Categories (Optional)
                            </Label>
                            <Select
                                value={formState.category}
                                onChange={handleChange}
                                name="category"
                                id="category"
                            >
                                <option value="">Select...</option>
                                {
                                    <>
                                        {data?.categories.map((category) => (
                                            <option
                                                key={category.value}
                                                value={category.value}
                                            >
                                                {category.label}
                                            </option>
                                        ))}
                                    </>
                                }
                            </Select>
                        </FormFieldContainer>
                    </FlexRow>

                    <FormFieldContainer>
                        <Label htmlFor="url">Link (Optional)</Label>
                        <Input
                            value={formState.url}
                            onChange={handleChange}
                            name="url"
                            id="url"
                            placeholder="https://www.nike.com"
                        />
                    </FormFieldContainer>

                    <FlexRow>
                        <FormFieldContainer>
                            <Label htmlFor="when">When?</Label>
                            <Select
                                id="when"
                                name="when"
                                value={when}
                                onChange={(e) => setWhen(e.target.value)}
                            >
                                {timestampOptions.map(({ text, value }) => (
                                    <option key={value} value={value}>
                                        {text}
                                    </option>
                                ))}
                            </Select>
                        </FormFieldContainer>

                        {when !== 'Now' && (
                            <FormFieldContainer>
                                <Label>Sent At (Optional)</Label>
                                <DatePicker
                                    value={formState.send_at as Date}
                                    onChange={handleDateChange}
                                />
                            </FormFieldContainer>
                        )}
                    </FlexRow>

                    <SearchBar />

                    <div
                        style={{
                            marginTop: '20px',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '20px',
                        }}
                    >
                        <FileUpload
                            id="image"
                            label="Image"
                            files={files}
                            setFiles={handleFiles}
                            limit={1}
                        />

                        <ImageUploadTable
                            images={files}
                            setImages={handleFiles}
                        />

                        <PreviewWrapper>
                            <Label htmlFor="content">Preview (Optional)</Label>

                            <PreviewBackground>
                                <PreviewContainer>
                                    <img src={logo} alt="logo" />

                                    <PreviewInfoContainer>
                                        <PreviewTitle>
                                            {formState.title ||
                                                'Notification Title On This Line'}
                                        </PreviewTitle>
                                        <PreviewText>
                                            {formState.content ||
                                                'content text goes on this line, should extend if needed.'}
                                        </PreviewText>
                                    </PreviewInfoContainer>

                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <PreviewTimestamp>
                                            {new Date(
                                                formState.send_at as Date
                                            ).toLocaleDateString()}
                                        </PreviewTimestamp>
                                    </div>
                                </PreviewContainer>
                            </PreviewBackground>
                        </PreviewWrapper>

                        <FormButton
                            isLoading={isLoading || isUploading}
                            color="purple"
                            onClick={handleSubmit}
                        >
                            Send Notification
                        </FormButton>
                    </div>
                </Form>
            </FormContainer>

            <ConfirmFormModal
                showModal={showConfirmModal}
                toggleModal={toggleConfirmModal}
                handleClick={handleCreateNotification}
                question="Are you sure you want to create this notification?"
            />

            <WarningFormModal
                showModal={showWarningModal}
                toggleModal={toggleWarningModal}
                handleClick={(e) => {
                    e.preventDefault()
                    toggleWarningModal()
                    toggleConfirmModal()
                }}
                question={`Warning: a similar notification was sent at ${date}. Do you wish to proceed?`}
            />
        </>
    )
}

export default NotificationForm
