import styled, { css } from 'styled-components'
import { colors } from './Ping.data'
import { Color } from './Ping.types'

const backgroundColor = css<{ color: Color }>`
    background: ${({ color }) => colors[color]};
`

export const Container = styled.span`
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
`

export const Circle = styled.span<{ color: Color }>`
    border-radius: 100%;
    height: 10px;
    width: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    ${backgroundColor}
`

export const OuterCircle = styled.span<{ color: Color }>`
    border-radius: 100%;
    height: 10px;
    width: 10px;
    animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
    opacity: 0.75;
    ${backgroundColor}

    @keyframes ping {
        75%,
        100% {
            transform: scale(2);
            opacity: 0;
        }
    }
`
