import { useState } from 'react'
import FormButton from 'src/components/common/FormButton/FormButton'
import styled from 'styled-components'
import v3Logo from '../assets/v3-logo.svg'

const Page = styled.div`
    display: flex;
    /* width: 1240px; */
    margin: 0 auto;
    max-width: 576px;
    margin-top: 20px;
    flex-direction: row;

    @media only screen and (max-width: 768px) {
        flex-direction: column;
    }

    @media only screen and (max-width: 768px) {
        padding-left: 10px;
        padding-right: 10px;
    }
`

const Card = styled.div`
    flex: 1;
    box-sizing: border-box;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 20px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
`

const Row = styled.div`
    display: flex;
    align-items: center;

    @media only screen and (max-width: 850px) {
        flex-direction: column;
    }
`

const Col = styled.div`
    display: flex;
    flex-direction: column;
`

const Icon = styled.img`
    width: 60px;
    height: 60px;
    border-radius: 10px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
    margin-right: 10px;

    @media only screen and (max-width: 850px) {
        margin-right: 0;
        margin-bottom: 10px;
    }
`

const Title = styled.div`
    font-weight: bold;
    font-size: 18px;
`

const Subtitle = styled.div`
    opacity: 0.6;
`

const SuccessAlert = styled.div`
    margin-top: 20px;
    background: #20bd6f;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 10px;
    color: white;
    font-weight: bold;
    text-align: center;
`

const ErrorAlert = styled.div`
    margin-top: 20px;
    background: #ff425e;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 10px;
    color: white;
    font-weight: bold;
    text-align: center;
`

const Downloads = () => {
    const [isSuccess, setIsSuccess] = useState<boolean>(false)
    const [successMessage, setSuccessMessage] = useState<any>('')
    const [isError, setIsError] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<any>('')
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

    const sendInvite = async () => {
        window.open('https://testflight.apple.com/join/knr5A8IS', '_blank')
        // if (isSubmitting) return

        // setIsSubmitting(true)
        // setIsError(false)
        // setIsSuccess(false)

        // try {
        //     const res = await axios.post("/api/v1/users/@me/sendV3Invite", {}, { withCredentials: true })

        //     setIsSubmitting(false)

        //     if (res.status === 200) {
        //       setIsSuccess(true)
        //       setSuccessMessage('Invite link sent to your email! Please allow a few minutes for it to arrive.')
        //     } else {
        //       setIsError(true)
        //       setErrorMessage(`Something went wrong. Please try again in a few minutes.`)
        //     }
        // } catch (err: any) {
        //   setIsSubmitting(false)
        //   // error planning here
        //   console.log('[error]', err.response)

        //   if (err.response && err.response.status === 402) {
        //     setIsError(true)
        //     setErrorMessage(err.response.data.message)
        //   } else {
        //     setIsError(true)
        //     setErrorMessage(`Something went wrong. Please try again in a few minutes.`)
        //   }
        // }
    }

    return (
        <Page>
            <Card>
                <Row>
                    <Icon src={v3Logo} alt="SwiftSole V3" />
                    <Col>
                        <Title>SwiftSole V3</Title>
                        <Subtitle>Version 3.0.1</Subtitle>
                    </Col>
                </Row>

                {isSuccess && <SuccessAlert>{successMessage}</SuccessAlert>}
                {isError && <ErrorAlert>{errorMessage}</ErrorAlert>}

                <FormButton fullWidth color="purple" onClick={sendInvite}>
                    Open Download Link
                </FormButton>
            </Card>
            {/* <Card>
          <Row>
            <Icon src={desktopLogo} alt="SwiftSole Desktop" />
            <Col>
              <Title>SwiftSole Desktop</Title>
              <Subtitle>Version 1.0.0</Subtitle>
            </Col>
          </Row>
          <Row>
            <DeviceButton
              icon={windowsIcon}
              onClick={() => null}
              color="linear-gradient(180deg, #00EA4B 0%, #00D025 100%)"
            />
            <DeviceButton
              icon={macOSIcon}
              onClick={() => null}
              color="linear-gradient(180deg, #FF6895 0%, #FF425E 100%, #FF3951 100%)"
            />
            <DeviceButton
              icon={linuxIcon}
              onClick={() => null}
              color="linear-gradient(180deg, #6E7CFF 0%, #5B69F1 100%)"
            />
          </Row>
        </Card> */}
        </Page>
    )
}

export default Downloads
