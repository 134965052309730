import styled from "styled-components"

export const Container = styled.div`
    margin-top: 40px;

    > * + * {
        margin-top: 20px;
    }
`

export const AlertContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: baseline;
`