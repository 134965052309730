import axios from 'axios';
import React, { useContext, useState } from 'react';
import { AuthContext, IAuthContext } from '../../../contexts/Auth';
import { CustomInput } from '../../common/common';
import { Button, Container, DetailsText, Error, FlexCol, FlexRow, FlexRowBetween, Heading, InfoContainer, Label, LabelContainer, Text } from './SettingsForm.styled';
import { SettingsFormProps } from './SettingsForm.types';

function SettingsForm({ toggleModal }: SettingsFormProps) {
     const { userInfo, setUserInfo } = useContext<IAuthContext>(AuthContext);
     const [error, setError] = useState('');
     const [editing, setEditing] = useState(false);
     const [formState, setFormState] = useState({
          email: userInfo?.email || '',
          phone: userInfo?.phone || ''
     });

     const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
          const { name, value } = event.target;
          setEditing(true);
          setFormState(prev => ({
              ...prev,
              [name]: value
          }));
     }

     const handleClick = async () => {
          try {
               const { data } = await axios.patch("/api/v1/users/@me", {
                    email: formState.email || userInfo.email,
                    phone: formState.phone || userInfo.phone
               }, { withCredentials: true })
               setUserInfo(data)
               setEditing(false);
          } catch (error) {
               if (axios.isAxiosError(error)) {
                    setError(error.response?.data?.message)
                } else {
                    setError((error as Error).message);
                }
          }
     }

     return (
          <Container>
               { error && <Error>{error}</Error>}
               <Heading>Settings</Heading>

               <InfoContainer>
                    <LabelContainer>
                         <Label>E-Mail Address</Label>
                    </LabelContainer>
                    <CustomInput
                         type='email'
                         name='email'
                         onChange={handleChange}
                         value={formState.email}
                         placeholder='username@email.com'
                    />
               </InfoContainer>

               <InfoContainer>
                    <LabelContainer>
                         <Label>Phone Number</Label>
                    </LabelContainer>
                    <CustomInput
                         name='phone'
                         onChange={handleChange}
                         value={formState.phone}
                         placeholder='(123) 345-6789'
                    />
               </InfoContainer>
               
               <InfoContainer noPaddingBottom>
                    <LabelContainer>
                         <Label>Card Details</Label>
                    </LabelContainer>
                    <FlexRowBetween>
                         {
                              userInfo?.role === 'customer' ? (
                                   <>
                                        <FlexRow>
                                             <FlexCol>
                                                  <DetailsText>{ userInfo.brand } {userInfo.last4}</DetailsText>
                                                  <Text>••••{' '}••••{' '}••••{' '}{userInfo.last4}</Text>
                                             </FlexCol>
                                        </FlexRow>
                                        <Button onClick={toggleModal}>Change Card</Button>
                                   </>
                              ) : (
                                   <>
                                        <p>No card connected</p>
                                        {
                                             editing && (formState.email !== userInfo.email) && <Button onClick={handleClick}>Save changes</Button>
                                        }
                                   </>
                              )
                         }
                    </FlexRowBetween>
               </InfoContainer>
          </Container>
     );
}

export default SettingsForm;