import { pick } from "lodash"
import { CreateReleaseRecord, ReleaseRecord } from "src/types/ReleaseRecord"

export const buildRelease = (selectedRelease: ReleaseRecord) => {
    const propertiesToPick: Array<keyof ReleaseRecord> = [
        'id',
        'release_at',
        'images',
        'brands',
        'categories',
        'keyword_group',
        'sku',
        'title',
        'region',
        'content',
        'colorway',
        'cover_color_hex',
        'cover_transparent',
        'published',
        'publisher_id',
        'type',
        'attachments',
        'items',
        'retail_price_usd',
        'resell_price_usd',
        'cover_image',
        'instructions',
    ]

    const filteredReleaseRecord = pick(selectedRelease, ...propertiesToPick)

    const release_at = new Date(filteredReleaseRecord.release_at)
    const images = filteredReleaseRecord.images.map(({ id, ...image }) => image)
    const brands = filteredReleaseRecord.brands.map((brand) => brand.value)
    const categories = filteredReleaseRecord.categories.map((category) => category.value)
    const keyword_group = filteredReleaseRecord.keyword_group.map((keyword_group) => keyword_group.value)
    const attachments = filteredReleaseRecord.attachments.map(attachment => ({ ...attachment, exists: true }))

    const release: CreateReleaseRecord = {
        ...filteredReleaseRecord,
        release_at,
        images,
        brands,
        categories,
        keyword_group,
        attachments
    }

    return release;
}