import { QueryFunctionContext, useQuery } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { QueryKeys } from 'src/constants/QueryKeys'
import { ReleaseRecord } from 'src/types/ReleaseRecord'
import { Pagination } from 'src/types/Request'
import { axiosInstance } from 'src/utils/axios'

export type SearchReleaseRecord = Pick<
    ReleaseRecord,
    'id' | 'cover_image' | 'release_at' | 'retail_price_usd' | 'title' | 'cover_color_hex' | 'cover_transparent' | 'colorway'
>

type GetSearchReleasesResponse = {
    data: SearchReleaseRecord[]
    pagination: Pagination
}

const getSearchReleases = ({ queryKey }: QueryFunctionContext<string[]>) => {
    const query = queryKey[1]
    return axiosInstance.get<GetSearchReleasesResponse>(
        `/releases/search?query=${query}&per_page=4`
    )
}

export const useSearchReleases = (query: string) => {
    const select = ({
        data: { data, pagination },
    }: AxiosResponse<GetSearchReleasesResponse, any>) => {
        const releases = data.sort(
            (a, b) =>
                new Date(a.release_at).valueOf() -
                new Date(b.release_at).valueOf()
        )

        return {
            releases,
            pagination,
        }
    }

    return useQuery([QueryKeys.searchReleases, query.toLowerCase().trim()], getSearchReleases, {
        select,
        refetchOnWindowFocus: false,
        keepPreviousData: !query,
        enabled: !!query,
    })
}
