import styled from 'styled-components'

const FlexRowBetween = styled.div`
    display: flex;
    justify-content: space-between;
`

export const Container = styled.div`
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #FFFFFF10;
    border-radius: 15px;
`

export const ReleaseWebsiteName = styled.h4`
    color: white;
    font-weight: 700;
    font-size: 14px;
`

export const ReleaseDate = styled.p`
    font-weight: 400;
    font-size: 12px;
    color: #FFFFFF60;
`

export const SVGContainer = styled(FlexRowBetween)`
    > * + * {
        margin-left: 5px;
    }
`

export const SVGWrapper = styled.a<{ releaseType: 'raffle' | 'drop' }>`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    border-radius: 12px;
    background-color: #FFFFFF10;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;

    &:hover {
        background-color: ${({ releaseType }) => releaseType === 'raffle' ? '#F45C92' : '#32D86F'};
    }
`

export const ToolTip = styled.div<{ showToolTip: boolean; releaseType: 'raffle' | 'drop'; }>`
    display: ${({ showToolTip }) => showToolTip ? 'flex' : 'none'};
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 25px;
    width: 85px;
    border-radius: 5px;
    background-color: ${({ releaseType }) => releaseType === 'raffle' ? '#F45C92' : '#32D86F'};
    color: white;
    position: absolute;
    top: 120%;
    padding: 5px;
    font-size: 14px;
`