import styled from 'styled-components';

export const Container = styled.div<{ maxHeight: string, maxWidth: string }>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: ${(({ maxHeight }) => maxHeight)};
    max-width: ${(({ maxWidth }) => maxWidth)};
`

export const Loader = styled.div`
    height: 60px;
    width: 60px;
    border-radius: 9999px;
    border: 4px solid #FFFFFF20;
    border-top-color: #FFFFFF80;
    animation: spinner 0.7s linear infinite;

    @keyframes spinner {
        to {
            transform: rotate(360deg);
        }
    }
`