import React, { useContext } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { AuthContext } from '../contexts/Auth'
import Loading from './Loading'

const ProtectedRouteWeak = ({ component, ...rest }: any) => {
    const authContext = useContext(AuthContext)
    const routeComponent = (props: any) => (
        !authContext.isLoading ?
        (
            authContext.isAuth ?  
            React.createElement(component, props) :
            <Redirect to={{ pathname: '/' }} />
        ):
        React.createElement(Loading, props)
    );
    
    return <Route {...rest} render={routeComponent} />;
};

export default ProtectedRouteWeak