import { State } from './StaffAlertFormContext.types'
import {
    CreateNotificationKeys,
    CreateNotificationValues,
} from 'src/types/Notification'
import { Action } from './StaffAlertFormContext.actions'
import { ActionTypes } from './StaffAlertFormContext.actionTypes'
import { NewFile } from 'src/components/common/FileUpload/FileUpload.types'

export const updateField =
    (dispatch: React.Dispatch<Action>) =>
    (key: CreateNotificationKeys, value: CreateNotificationValues) => {
        dispatch({
            type: ActionTypes.UPDATE_FIELD,
            payload: {
                key,
                value,
            },
        })
    }

export const setFormFields =
    (dispatch: React.Dispatch<Action>) =>
    (notification: State['formState']) => {
        dispatch({
            type: ActionTypes.SET_FORM_FIELDS,
            payload: notification,
        })
    }

export const resetForm = (dispatch: React.Dispatch<Action>) => () => {
    dispatch({
        type: ActionTypes.RESET_FORM,
    })
}


export const setFiles = (dispatch: React.Dispatch<Action>) => (files: NewFile[]) => {
    dispatch({
        type: ActionTypes.SET_FILES,
        payload: files
    })
}