import { useContext } from 'react'
import { Redirect } from 'react-router-dom'
import styled from 'styled-components'
import { AuthContext } from '../contexts/Auth'

const Page = styled.div`
  display: flex;
  width: 1240px;
  margin: 0 auto;
  max-width: 90vw;

  @media only screen and (max-width: 850px) {
    max-width: 100vw;
  }
`

const Landing = () => {
  const authContext = useContext(AuthContext)

  if (!authContext.isLoading && !authContext.isAuth) {
    window.location.href = `/api/v1/auth/loginRedirect?path=${window.location.pathname}`
    return (
      <Page>
      </Page>
    )
  }

  return <Redirect to='/subscriptions' />
}

export default Landing