import { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { checkDuplicate } from 'src/utils/checkDuplicate'
import { notify } from 'src/utils/notify'
import { ReactComponent as PictureIcon } from '../../../assets/picture-icon.svg'
import {
    Container,
    DropZoneContainer,
    FileInput,
    LabelContainer,
    Text,
    Wrapper,
} from './FileUpload.styled'
import { DragAndDropProps, NewFile } from './FileUpload.types'
import { fileIsOverLimit, fileIsValid, readFile } from './FileUpload.utils'

function FileUpload({ id, label, setFiles, files, limit }: DragAndDropProps) {
    const currentCount = files.length

    const onDrop = useCallback(
        async (acceptedFiles: File[]) => {
            const promises = acceptedFiles.map(async (acceptedFile) => {
                return new Promise<NewFile>(async (resolve, reject) => {
                    try {
                        if (
                            limit &&
                            (acceptedFiles.length > limit ||
                                fileIsOverLimit(limit, files.length))
                        ) {
                            reject('Error: file limit exceeded')
                        }

                        checkDuplicate({
                            value: acceptedFile.name,
                            items: files,
                            key: 'name'
                        })

                        fileIsValid(acceptedFile.type)

                        resolve(await readFile(acceptedFile))
                    } catch (error) {
                        reject((error as Error).message)
                    }
                })
            })

            const settledFiles = await Promise.allSettled(promises)

            const fulfilledFiles: NewFile[] = []

            settledFiles.forEach((result) => {
                if (result.status === 'fulfilled') {
                    fulfilledFiles.push(result.value)
                } else {
                    notify(result.reason, 'error')
                }
            })

            setFiles([...files, ...fulfilledFiles])
        },
        [files, setFiles, limit]
    )

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
    })

    return (
        <Container>
            <LabelContainer>
                <p>{label}</p>
                {limit && <p>File limit: {limit}</p>}
                <p>
                    {currentCount} File{currentCount > 1 && 's'}
                </p>
            </LabelContainer>

            <Wrapper htmlFor={id}>
                <DropZoneContainer {...getRootProps()}>
                    <FileInput
                        {...getInputProps()}
                        name={id}
                        type="file"
                        aria-label={label}
                        accept="image/png, image/jpg, image/jpeg"
                    />
                    {isDragActive ? (
                        <Text>Drop the files here ...</Text>
                    ) : (
                        <>
                            <PictureIcon />
                            <Text>Drag and drop file or browse</Text>
                        </>
                    )}
                </DropZoneContainer>
            </Wrapper>
        </Container>
    )
}

export default FileUpload
