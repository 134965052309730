import { useEffect, useRef } from "react";
import { parseError } from "src/utils/parseError";

export const useError = (error: unknown, setError: (message: string) => void) => {
    const savedHandler = useRef(setError);

    useEffect(() => {
        savedHandler.current = setError
    }, [setError])

    useEffect(() => {
        if (!error) return
        savedHandler.current(parseError(error));
    }, [error])
}