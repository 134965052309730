import { useMutation, useQueryClient } from '@tanstack/react-query'
import { CreateNotification } from 'src/types/Notification'
import { axiosInstance } from 'src/utils/axios'
import { QueryKeys } from './../constants/QueryKeys'

const createNotification = async (notification: CreateNotification) => {
    return axiosInstance
        .post<Notification>('/notifications/create', notification)
        .then(({ data }) => data)
}

export const useCreateNotification = () => {
    const queryClient = useQueryClient()

    const onSuccess = async (data: Notification) => {
        await queryClient.invalidateQueries([QueryKeys.notifications])
    }

    return useMutation(createNotification, { onSuccess })
}
