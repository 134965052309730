import styled from "styled-components"

export const SearchBarContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`

export const SearchBarWrapper = styled.div`
    background-image: linear-gradient(180deg, rgba(247,239,243,0.13) 0%, rgba(247,239,243,0.10) 99%);
    border: 1px solid rgba(247,239,243,0.10);
    border-radius: 23px;
    height: 46px;
    display: flex;
    justify-content: center;
    gap: 5px;
    align-items: center;
`

export const SearchBarInput = styled.input`
    font-weight: semibold;
    font-size: 14px;
    color: rgba(247,239,243,0.80);
    text-align: center;
    background-color: transparent;
    outline: none;

    &::placeholder {
        color: rgba(247,239,243,0.80);
    }
`

export const SearchReleasesList = styled.div`
    display: grid;
    grid-template-columns: repeat(4,minmax(0,1fr));
    gap: 20px;
`


export const TextContainer = styled.div`
    grid-column: span 4 / span 4;
    text-align: center;
`