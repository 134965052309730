import { shimmer } from "src/components/common/common"
import styled from "styled-components"

export const Container = styled.div`
    padding: 20px;
`
export const ImageContainer = styled.div`
    min-width: 60px;
    min-height: 60px;
    max-width: 60px;
    max-height: 60px;
    border-radius: 15px;
    background-color: white;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 7px;
`

export const Image = styled.img`
    width: 100%;
`

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    overflow-wrap: anywhere;
    width: 100%;
`

export const NotificationTitle = styled.h4`
    color: #F7EFF3;
    font-weight: 600;
`

export const Link = styled.a`
    font-size: 12px;
    color: #49A2F8;
    font-weight: 500;
    cursor: pointer;
`

export const ReleaseDate = styled.p`
    font-size: 12px;
    color: rgba(247,239,243,0.60);
`

export const ButtonsContainer = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    width: 100%;
`

export const SkeletonImage = styled(ImageContainer)`
    background-color: #FFFFFF10;
    ${shimmer}
`

export const SkeletonText = styled.div<{ width: string; height: string }>`
    background-color: #FFFFFF10;
    height: ${({ height }) => height};
    max-width: ${({ width }) => width};
    border-radius: 5px;
    ${shimmer}
`

export const SkeletonButton = styled.div`
    border-radius: 30px;
    width: 100%;
    height: 46px;
    background-color: #FFFFFF10;
    ${shimmer}
`


