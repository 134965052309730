import { QueryFunctionContext, useQuery } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { QueryKeys } from 'src/constants/QueryKeys'
import { Notification } from 'src/types/Notification'
import { Pagination, Query } from 'src/types/Request'
import { axiosInstanceV2 } from 'src/utils/axios'
import { buildQueryString } from 'src/utils/buildQueryString'
import { formatDate } from 'src/utils/formatDate'

type GetNotificationsResponse = {
    data: Notification[]
    pagination: Pagination
}

export const DEFAULT_GET_NOTIFICATIONS_QUERY = {
    gte: formatDate(new Date()),
}

export const NOTIFICATIONS_PER_PAGE = 17

const getNotifications = ({
    queryKey,
}: QueryFunctionContext<(number | QueryKeys | string)[], any>) => {
    const currentPage = queryKey[1]
    const queryString = queryKey[2]

    return axiosInstanceV2.post<GetNotificationsResponse>(
        `/notifications/pull`,
        {
            page: currentPage,
            per_page: NOTIFICATIONS_PER_PAGE,
        }
    )
}

export const useGetNotifications = (
    currentPage: number,
    query: Query = DEFAULT_GET_NOTIFICATIONS_QUERY
) => {
    const select = ({
        data: { data, pagination },
    }: AxiosResponse<GetNotificationsResponse, any>) => {
        // const notifications = data.sort(
        //     (a, b) =>
        //         new Date(a.send_at || Date.now()).valueOf() -
        //         new Date(b.send_at || Date.now()).valueOf()
        // )

        return {
            notifications: data,
            pagination,
        }
    }

    return useQuery(
        [QueryKeys.notifications, currentPage, buildQueryString(query)],
        getNotifications,
        { select, retry: 3, refetchOnWindowFocus: false }
    )
}
