import SearchItem from '../SearchItem/SearchItem'
import { Container } from './SearchBarDropdown.styled'
import { SearchBarDropdownProps } from './SearchBarDropdown.types'

const SearchBarDropdown = ({ items }: SearchBarDropdownProps) => {
    return (
        <Container>
            {items.map((item) => (
                <SearchItem key={item.id} item={item} />
            ))}
        </Container>
    )
}

export default SearchBarDropdown
