import { memo, useState } from 'react'
import CardSkeleton from 'src/components/common/CardSkeleton/CardSkeleton'
import { SkeletonText } from 'src/components/common/CardSkeleton/CardSkeleton.styled'
import { ErrorAlert } from 'src/components/common/common'
import ChevronLeftIcon from 'src/components/common/icons/ChevronLeft'
import ChevronRightIcon from 'src/components/common/icons/ChevronRight'
import ToggleButton from 'src/components/common/ToggleButton/ToggleButton'
import {
    DEFAULT_GET_RELEASES_QUERY,
    RELEASES_PER_PAGE,
    useGetReleases
} from 'src/hooks/useGetReleases'
import { useToggle } from 'src/hooks/useToggle'
import { Query } from 'src/types/Request'
import { formatDate } from 'src/utils/formatDate'
import ReleaseCard from '../ReleaseCard/ReleaseCard'
import { options } from './ReleasesList.data'
import {
    Button,
    ButtonsContainer,
    Container,
    HeaderContainer,
    Heading, Input, InputContainer, ReleaseItemContainer
} from './ReleasesList.styled'
import { CircularButtonProps, PageNumbersProps, UpcomingReleasesProps } from './ReleasesList.types'

function ReleasesListSkeleton() {
    return (
        <ReleaseItemContainer>
            {Array.from(Array(RELEASES_PER_PAGE).keys()).map((item) => (
                <CardSkeleton key={item} />
            ))}
        </ReleaseItemContainer>
    )
}

function PaginationSkeleton() {
    return (
        <>
            <SkeletonText height="55px" width="90px" />
            <SkeletonText height="22px" width="24px" />
            <SkeletonText height="55px" width="90px" />
        </>
    )
}

function Ellipses() {
    return <span>...</span>
}

function CircularButton({  selected = false, children, ...props }: CircularButtonProps) {
    return (
        <Button selected={selected} {...props}>{ children }</Button>
    )
}

function PageNumbers({ pageNumbers, isFetching, getPage, page }: PageNumbersProps) {
    return (
        <>
            {
                pageNumbers.map((pageNumber) => (
                    <CircularButton
                        key={pageNumber}
                        disabled={isFetching}
                        onClick={() => getPage(pageNumber)}
                        selected={page === pageNumber}
                    >
                        {pageNumber}
                    </CircularButton>
                ))
            }
        </>
    )
}

const MAX_PAGINATION_PAGE_NUMBERS = 7
const ADDITIONAL_PAGES_TO_DISPLAY = 4

const ReleasesList = ({ handleDeleteRelease }: UpcomingReleasesProps) => {
    const [page, setPage] = useState<number | ''>(1)
    const { on: checked, getTogglerProps } = useToggle()
    const [query, setQuery] = useState<Query>(DEFAULT_GET_RELEASES_QUERY)

    const getNextPage = () => setPage((prev) => prev === '' ? 1 : prev + 1)

    const getPrevPage = () => setPage((prev) => prev === '' ? 1 : prev - 1)

    const getPage = (page: number) => setPage(page)

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;

        if (value === '') {
            return setPage(value)
        }

        let num: number;

        const parsedNumber = Number(value)

        if (parsedNumber > 1) {
            if (parsedNumber > pageArray.length) {
                num = totalPages
            } else {
                num = parsedNumber
            }
        } else {
            num = 1
        }

        setPage(Number(num))
    }

    const onClick = () => {
        if (checked) return setQuery(DEFAULT_GET_RELEASES_QUERY)
        setPage(1);
        setQuery({ lt: formatDate(new Date()), per_page: RELEASES_PER_PAGE.toString() })
    }

    const { data, isLoading, error, isFetching } = useGetReleases(page as number, query, { enabled: !!page })

    const totalPages = data?.pagination.total_pages || 7;
    const hasMorePages = data?.pagination.has_more
    const currentPage = data?.pagination.page

    const pageArray = Array.from({ length: totalPages ?? 7 }, (_, i) => i + 1)

    return (
        <Container>
            <HeaderContainer>
                <Heading>Releases</Heading>
                <ToggleButton
                    options={{ option1: options[0], option2: options[1] }}
                    checked={checked}
                    {...getTogglerProps({ onClick })}
                    isLarge
                />
            </HeaderContainer>

            {error && <ErrorAlert>{(error as Error).message}</ErrorAlert>}

            <ReleaseItemContainer>
                {isLoading ? (
                    <ReleasesListSkeleton />
                ) : (
                    data?.releases.map((release) => {
                        return (
                            <ReleaseCard
                                key={release.id as string}
                                release={release}
                                handleDeleteRelease={handleDeleteRelease}
                            />
                        )
                    })
                )}
            </ReleaseItemContainer>

            <ButtonsContainer>
                {data?.pagination ? (
                    <>
                        <CircularButton
                            disabled={currentPage === 1 || isFetching}
                            onClick={getPrevPage}
                        >
                            <ChevronLeftIcon />
                        </CircularButton>

                        <CircularButton
                            disabled={isFetching}
                            onClick={() => getPage(1)}
                            selected={page === 1}
                        >
                            1
                        </CircularButton>

                        {
                            totalPages && currentPage && pageArray.length > MAX_PAGINATION_PAGE_NUMBERS ? (
                                <>
                                    {
                                        currentPage <= ADDITIONAL_PAGES_TO_DISPLAY ? (
                                            <PageNumbers
                                                getPage={getPage}
                                                page={page as number}
                                                isFetching={isFetching}
                                                pageNumbers={pageArray.slice(1, ADDITIONAL_PAGES_TO_DISPLAY + 1)}
                                            />
                                        ) : (
                                            <Ellipses />
                                        )
                                    }

                                    {
                                        (currentPage > ADDITIONAL_PAGES_TO_DISPLAY) && (currentPage < totalPages - ADDITIONAL_PAGES_TO_DISPLAY + 1) && (
                                            <PageNumbers
                                                getPage={getPage}
                                                page={page as number}
                                                isFetching={isFetching}
                                                pageNumbers={pageArray.slice(currentPage - 2, currentPage + 1)}
                                            />
                                        )
                                    }

                                    {
                                        currentPage >= (totalPages - ADDITIONAL_PAGES_TO_DISPLAY + 1) ? (
                                            <PageNumbers
                                                getPage={getPage}
                                                page={page as number}
                                                isFetching={isFetching}
                                                pageNumbers={pageArray.slice(totalPages - ADDITIONAL_PAGES_TO_DISPLAY - 1, totalPages - 1)}
                                            />
                                        ) : (
                                            <Ellipses />
                                        )
                                    }
                                </>
                            ) : (
                                pageArray.slice(1, totalPages - 1).map((pageNumber) => (
                                    <CircularButton
                                        key={pageNumber}
                                        disabled={isFetching}
                                        onClick={() => getPage(pageNumber)}
                                        selected={page === pageNumber}
                                    >
                                        {pageNumber}
                                    </CircularButton>
                                ))
                            )
                        }

                        <CircularButton
                            disabled={isFetching}
                            onClick={() => getPage(totalPages)}
                            selected={page === totalPages}
                        >
                            {totalPages}
                        </CircularButton>

                        <CircularButton
                            disabled={!hasMorePages || isFetching}
                            onClick={getNextPage}
                        >
                            <ChevronRightIcon />
                        </CircularButton>
                    </>
                ) : (
                    <PaginationSkeleton />
                )}
            </ButtonsContainer>
            
            <InputContainer>
                <Input type="number" value={page} name='page' id='page' onChange={handleChange} />
                <span>{`/ ${totalPages}`}</span>
            </InputContainer>
        </Container>
    )
}

export default memo(ReleasesList)