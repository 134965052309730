import { ReactComponent as Cyber } from '../../../assets/cyber.svg'
import { ReactComponent as Pattern } from '../../../assets/pattern.svg'
import {
    Container,
    ReleaseDate,
    ReleaseWebsiteName,
    SVGContainer,
    SVGWrapper,
    ToolTip,
} from './ReleaseItem.styled'
import dayjs from 'dayjs'
import { ReleaseItemProps } from './ReleaseItem.types'
import { useState } from 'react'

function ReleaseItem({
    title,
    url,
    release_at,
    releaseType,
}: ReleaseItemProps) {
    const [{ showCopyToolTip, showOpenToolTip }, setState] = useState({
        showCopyToolTip: false,
        showOpenToolTip: false,
    })

    const toggleOpenToolTip = () =>
        setState((prev) => ({
            ...prev,
            showOpenToolTip: !prev.showOpenToolTip,
        }))
    const toggleCopyToolTip = () =>
        setState((prev) => ({
            ...prev,
            showCopyToolTip: !prev.showCopyToolTip,
        }))

    const copyToClipBoard = async (link: string) => {
        await navigator.clipboard.writeText(link)
        alert('Copied link')
    }

    return (
        <Container>
            <div>
                <ReleaseWebsiteName>{title}</ReleaseWebsiteName>
                <ReleaseDate>
                    {dayjs(release_at._seconds * 1000).format('HH:mm A')}
                </ReleaseDate>
            </div>

            <SVGContainer>
                <SVGWrapper
                    style={{ cursor: 'pointer' }}
                    releaseType={releaseType}
                    onMouseEnter={toggleCopyToolTip}
                    onMouseLeave={toggleCopyToolTip}
                    onClick={() => copyToClipBoard(url)}
                >
                    <Pattern />
                    <ToolTip
                        releaseType={releaseType}
                        showToolTip={showCopyToolTip}
                    >
                        Copy Link
                    </ToolTip>
                </SVGWrapper>

                <SVGWrapper
                    style={{ cursor: 'pointer' }}
                    releaseType={releaseType}
                    href={url}
                    target="_blank"
                    onMouseEnter={toggleOpenToolTip}
                    onMouseLeave={toggleOpenToolTip}
                >
                    <Cyber />
                    <ToolTip
                        releaseType={releaseType}
                        showToolTip={showOpenToolTip}
                    >
                        Open Link
                    </ToolTip>
                </SVGWrapper>
            </SVGContainer>
        </Container>
    )
}

export default ReleaseItem
