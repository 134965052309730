import { useEffect, useRef, useState } from 'react'
import useDebounce from 'src/hooks/useDebounce'
import { SneakerResult } from 'src/types/SneakerResult'
import { algolia, defaultAlgoliaOptions } from 'src/utils/algolia'
import SearchBarDropdown from '../SearchBarDropdown/SearchBarDropdown'
import { Input, SearchBarContainer } from './ReleasesSearchBar.styled'

const ReleasesSearchBar = () => {
    const [value, setValue] = useState('')
    const [results, setResults] = useState<SneakerResult[]>([])
    const query = useDebounce(value)
    const [showDropdown, setShowDropdown] = useState(false)
    const dropdownRef = useRef<HTMLDivElement>(null!)
    const inputRef = useRef<HTMLInputElement>(null!)

    useEffect(() => {
        let unmounted = false;

        ;(async () => {
            const { hits } = await algolia.search<SneakerResult>(
                query,
                defaultAlgoliaOptions
            )
            
            if (!unmounted) setResults(hits)
        })()

        return () => {
            unmounted = true
        };
    }, [query])

    useEffect(() => {
        const handler = (e: MouseEvent) => {
            const element = e.target as Node
            if (
                !dropdownRef?.current?.contains(element) &&
                !inputRef.current.contains(element)
            ) {
                setShowDropdown(false)
            }
        }

        window.addEventListener('mousedown', handler)

        return () => window.removeEventListener('mousedown', handler)
    }, [])

    return (
        <SearchBarContainer>
            <Input
                value={value}
                onChange={(e) => setValue(e.target.value)}
                placeholder="Search StockX"
                onClick={() => setShowDropdown((prev) => !prev)}
                ref={inputRef}
            />

            {showDropdown && (
                <div
                    ref={dropdownRef}
                    onClick={(e) => {
                        e.stopPropagation()
                        setShowDropdown(false)
                    }}
                >
                    <SearchBarDropdown items={results} />
                </div>
            )}
        </SearchBarContainer>
    )
}

export default ReleasesSearchBar
