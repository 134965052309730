import {
    QueryFunctionContext,
    useQuery,
    UseQueryOptions,
} from '@tanstack/react-query'
import axios, { AxiosResponse } from 'axios'
import { QueryKeys } from 'src/constants/QueryKeys'
import { ReleaseRecord } from 'src/types/ReleaseRecord'
import { Pagination, Query } from 'src/types/Request'
import { axiosInstance } from 'src/utils/axios'
import { buildQueryString } from 'src/utils/buildQueryString'
import { formatDate } from 'src/utils/formatDate'

type GetReleasesResponse = {
    data: ReleaseRecord[]
    pagination: Pagination
}

export const RELEASES_PER_PAGE = 17

export const DEFAULT_GET_RELEASES_QUERY = {
    gte: formatDate(new Date()),
    per_page: RELEASES_PER_PAGE.toString(),
}

const getReleases = ({
    queryKey,
}: QueryFunctionContext<(number | string | QueryKeys)[], any>) => {
    const currentPage = queryKey[1]
    const queryString = queryKey[2]

    // return axiosInstance.get<GetReleasesResponse>(
    //     `/releases/pull?page=${currentPage}&all_data=true${queryString}`
    // )

    return axios.get<GetReleasesResponse>(
        `https://pro-alerts-api-production.up.railway.app/api/v1/releases/pull?page=${currentPage}&all_data=true${queryString}`,
        {
            headers: {
                Authorization: 'a1d3b746-0702-4d5b-900e-313dbf9ab0a2',
            },
        }
    )
}

export const useGetReleases = (
    currentPage: number,
    query: Query = DEFAULT_GET_RELEASES_QUERY,
    options?: Omit<
        UseQueryOptions<
            AxiosResponse<GetReleasesResponse, any>,
            unknown,
            {
                releases: ReleaseRecord[]
                pagination: Pagination
            },
            (string | number)[]
        >,
        'initialData' | 'queryFn' | 'queryKey'
    >
    // options?: Parameters<typeof useQuery>[2]
) => {
    const select = ({
        data: { data, pagination },
    }: AxiosResponse<GetReleasesResponse, any>) => {
        const releases = data.sort(
            (a, b) =>
                new Date(a.release_at).valueOf() -
                new Date(b.release_at).valueOf()
        )

        return {
            releases,
            pagination,
        }
    }

    return useQuery(
        [QueryKeys.releases, currentPage, buildQueryString(query)],
        getReleases,
        {
            select,
            keepPreviousData: true,
            ...options,
        }
    )
}
