import axios, { AxiosError } from "axios";

const parseZodError = (error: AxiosError<any, any>) => {
    const item = error.response?.data.issues[0];
    const {message} = item;
    const path = item.path[0];
    return `Invalid ${path}. ${message}.`
}

const parseAxiosError = (error: AxiosError<any, any>) => {
    return error.response?.data?.message
}

const parseGeneralError = (error: Error) => {
    return error.message
}

export const parseError = (error: unknown) => {
    if (!error) return

    if (axios.isAxiosError(error)) {
        // Zod Error
        if (error.response?.data?.issues) {
            return parseZodError(error)
        }

        // Axios Error
        if (error.response?.data?.message) {
            return parseAxiosError(error);
        }
    }

    // Javascript Error
    return parseGeneralError(error as Error);
}