import { isEmpty } from 'lodash'
import { useMemo, useState } from 'react'
import {
    ErrorAlert,
    FormInput,
    FormInputContainerLarge,
    FormLabel,
    FormTextArea
} from 'src/components/common/common'
import FormButton from 'src/components/common/FormButton/FormButton'
import RequiredText from 'src/components/common/RequiredText/RequiredText'
import {
    addInstruction,
    updateInstruction
} from 'src/contexts/StaffReleaseFormContext/StaffReleaseFormContext.services'
import {
    useStaffDispatchContext,
    useStaffReleaseFormContext
} from 'src/hooks/useStaffReleaseForm'
import { InstructionSchema } from 'src/schema/InstructionSchema'
import { checkDuplicate } from 'src/utils/checkDuplicate'
import { validateForm } from 'src/utils/validateForm'
import { ButtonContainer, Container } from './InstructionsForm.styled'
import { InstructionsFormProps } from './InstructionsForm.types'

const InstructionsForm = ({ toggleModal, selectedInstruction }: InstructionsFormProps) => {
    const dispatch = useStaffDispatchContext()
    const { formState: state } = useStaffReleaseFormContext()
    const [errorMessage, setErrorMessage] = useState('')
    const [formState, setFormState] = useState({
        title: selectedInstruction?.title || '',
        description: selectedInstruction?.description || '',
    })

    const isNewItem = useMemo(
        () => isEmpty(selectedInstruction),
        [selectedInstruction]
    )

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { name, value } = e.target

        setFormState((prev) => ({
            ...prev,
            [name]: value,
        }))
    }

    const handleCreateItem = (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        e.preventDefault()
        setErrorMessage('')

        try {
            checkDuplicate({
                value: formState.title,
                items: state.instructions,
                key: 'title',
            })
            validateForm(InstructionSchema, formState)
            addInstruction(dispatch)(formState)
            toggleModal()
        } catch (error) {
            setErrorMessage((error as Error).message)
        }
    }

    const handleEditItem = (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        e.preventDefault()
        setErrorMessage('')

        try {
            if (selectedInstruction?.title !== formState.title) {
                checkDuplicate({
                    value: formState.title,
                    items: state.attachments,
                    key: 'title',
                })
            }
            validateForm(InstructionSchema, { name: 'string' })
            updateInstruction(dispatch)(selectedInstruction.title, formState)
            toggleModal()
        } catch (error) {
            setErrorMessage((error as Error).message)
        }
    }

    return (
        <Container>
            {errorMessage && <ErrorAlert>{errorMessage}</ErrorAlert>}

            <FormInputContainerLarge>
                <FormLabel htmlFor="title">
                    <p>Title</p>
                    <RequiredText />
                </FormLabel>
                <FormInput
                    id="title"
                    name="title"
                    value={formState.title}
                    placeholder="Nike US"
                    onChange={handleChange}
                />
            </FormInputContainerLarge>
            <FormInputContainerLarge>
                <FormLabel htmlFor="description">
                    <p>Description</p>
                    <RequiredText />
                </FormLabel>
                <FormTextArea
                    id="description"
                    name="description"
                    value={formState.description}
                    placeholder="Enter a release description"
                    onChange={handleChange}
                />
            </FormInputContainerLarge>

            <ButtonContainer>
                <FormButton
                    fullWidth
                    color="purple"
                    onClick={isNewItem ? handleCreateItem : handleEditItem}
                >
                    {isNewItem ? 'Create Item' : 'Update Item'}
                </FormButton>
            </ButtonContainer>

            <FormButton fullWidth color="grey" onClick={toggleModal}>
                Close
            </FormButton>
        </Container>
    )
}

export default InstructionsForm
