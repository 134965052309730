import dayjs from 'dayjs'
import FormButton from 'src/components/common/FormButton/FormButton'
import { ReactComponent as Logo } from '../../../assets/v3-logo.svg'
import ColorBar from '../../ColorBar'
import { FlexRowBetween } from '../../common/common'
import {
    Button,
    ButtonContainer,
    Container,
    FlexRowSpace,
    Heading,
    ProductTitle,
    ProgressBarContainer,
    Seperator,
    Stronger,
    SubscriptionDate,
    Text,
} from './SubInfoCard.styled'
import { SubInfoCardProps } from './SubInfoCard.types'

function SubInfoCard({
    price,
    isOn,
    isActive,
    isTrialing,
    isLifetime,
    createdAt,
    expiresAt,
    toggleModal,
    handleClick,
    subscribedSince,
    period,
}: SubInfoCardProps) {
    const currentTS = new Date().getTime() / 1000
    const progressPercentage =
        ((currentTS - createdAt) / (expiresAt - createdAt)) * 100
    const progress = progressPercentage <= 100 ? progressPercentage : 100
    const expiryToString = dayjs.unix(expiresAt).format('MMMM D, YYYY')

    return (
        <Container>
            <FlexRowSpace>
                <Logo />
                <div>
                    <ProductTitle>SwiftSole V3</ProductTitle>
                    <SubscriptionDate>{subscribedSince}</SubscriptionDate>
                </div>
            </FlexRowSpace>

            <Seperator />

            {!isLifetime && isActive && (
                <>
                    {/* <div>
                            <Heading>Payment Method</Heading>
                            <FlexRowBetween>
                                <FlexCol>
                                    <CardType>{brand}{' '}{last4}</CardType>
                                    <CardNumber>••••{' '}••••{' '}••••{' '}9021</CardNumber>
                                </FlexCol>
            
                                <CardBtn>Change Card</CardBtn>
                            </FlexRowBetween>
                        </div>
            
                        <Seperator /> */}

                    <FlexRowBetween>
                        <Heading>Autorenewal</Heading>
                        {!isLifetime && (
                            <Button isOn={isOn} onClick={toggleModal}>
                                {isOn ? 'Cancel' : 'Enable'}
                            </Button>
                        )}
                    </FlexRowBetween>

                    <ProgressBarContainer>
                        <ColorBar progress={progress} warning={progress > 90} />
                    </ProgressBarContainer>

                    <Text>
                        <>
                            Autorenewal is {isOn ? 'on' : 'off'}.{' '}
                            {isOn ? (
                                <>
                                    Your {isTrialing ? 'first' : 'next'}{' '} charge of <Stronger>{price}</Stronger> is due on <Stronger>{expiryToString}</Stronger>.
                                </>
                            ) : (
                                <>
                                    Your subscription will end on{' '}
                                    <Stronger>{expiryToString}</Stronger>
                                </>
                            )}
                        </>
                    </Text>

                    <Seperator isLarge />
                </>
            )}

            <ButtonContainer>
                <FormButton
                    fullWidth
                    color='purple'
                    onClick={handleClick}
                >
                    {isActive ? 'Join Discord' : 'Purchase'}
                </FormButton>
            </ButtonContainer>
        </Container>
    )
}

export default SubInfoCard
