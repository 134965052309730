import { Brand, Container, Heading, Image, ImageContainer, ImageWrapper, InfoContainer, Price } from './ReleaseCard.styled';
import { ReleaseCardProps } from './ReleaseCard.types';

function ReleaseCard({ name, images, item_info: { price }, handleClick }: ReleaseCardProps) {
    return (
        <Container onClick={handleClick}>
            <ImageContainer>
                <ImageWrapper>
                    <Image src={images[0]} />
                </ImageWrapper>
            </ImageContainer>

            <Heading>{name}</Heading>            

            <InfoContainer>
                <Brand>{name.split(' ')[0]}</Brand>
                <Price>{price}</Price>
            </InfoContainer>
        </Container>
    )
}

export default ReleaseCard