import ImageKit from 'imagekit'
import { UploadOptions } from 'imagekit/dist/libs/interfaces'
import { v4 as uuidv4 } from 'uuid'

type UploadImage = {
    file: string
    removeBg?: boolean
}

const URL_ENDPOINT = 'https://ik.imagekit.io/qf0v9lrx43'
const DEFAULT_FOLDER = '/releases/uploads/'
const LOGOS_FOLDER = '/static/logos'

const imagekit = new ImageKit({
    publicKey: 'public_u9uOPhAErcsv333+74+76Nh7KyY=',
    privateKey: 'private_aHFt1G+Axhfa4ZCtNfUsqOpFdCo=',
    urlEndpoint: URL_ENDPOINT,
})

const createFileName = () => uuidv4()

const getExtensions = () => [{ name: 'remove-bg' }]

export function transformImage(data: {
    image_url: string
    cover_transparent: boolean
    cover_color_hex: string
}) {
    const uri = data.image_url.split('/releases/')[1]
    const extensions = data.cover_transparent
        ? `bg-${data.cover_color_hex},w-1250,ar-5-5,cm-pad_resize`
        : 't-false'

    return `https://ik.imagekit.io/qf0v9lrx43/releases/${uri}?tr=${extensions}`
}

export const uploadLogo = async ({ file, removeBg = false }: UploadImage) => {
    const fileName = createFileName()

    const uploadOptions: UploadOptions = {
        file,
        fileName,
        folder: LOGOS_FOLDER,
    }

    if (removeBg) {
        uploadOptions.extensions = getExtensions()
    }

    const { url } = await imagekit.upload(uploadOptions)

    return {
        value: url,
        transparent: removeBg,
    }
}
