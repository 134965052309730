import styled from 'styled-components';
import { ReactComponent as Check } from '../../../assets/plain-check.svg';

export const Label = styled.label`
    color: rgba(255,255,255,.7);
    font-size: 14px;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
`

export const InputContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 55px;
`

export const Input = styled.input<{ checked: boolean; }>`
    outline: none;
    width: 1.75rem;
    height: 1.75rem;
    appearance: none;
    border-radius: 0.5rem;
    background-color: ${(({ checked }) => checked ? '#254130' : '#FFFFFF10')};
    border: ${(({ checked }) => checked ? '2.5px solid #41eb81' : '1px solid #FFFFFF10')};
    cursor: pointer;
`

export const Tick = styled(Check)`
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #41eb81;
    position: absolute;
    cursor: pointer;
`