import { AttachmentSchema } from 'src/schema/AttachmentSchema'
import { InstructionSchema } from 'src/schema/InstructionSchema'
import { ItemSchema } from 'src/schema/ItemSchema'
import z from 'zod'

export type Region = 'US' | 'EU' | 'AS'

// ENUMS
export enum ReleaseType {
    SINGLE = 'SINGLE',
    MULTI = 'MULTI',
}

export type ReleaseTypeKey = keyof typeof ReleaseType

export enum RegionType {
    US = 'US',
    CA = 'CA',
    EU = 'EU',
    MX = 'MX',
    JP = 'JP',
    KR = 'KR',
}

export enum ReleaseItemType {
    DROP = 'DROP',
    RAFFLE = 'RAFFLE',
}

export type ReleaseItemTypeKey = keyof typeof ReleaseItemType

export type CustomFile = {
    value: string
    transparent: boolean
}

export type Item = z.infer<typeof ItemSchema>

export type Attachment = z.infer<typeof AttachmentSchema>

export type Instruction = z.infer<typeof InstructionSchema>

export type Filter = {
    value: string
    label: string
}

export type Filters = {
    brands: Filter[]
    categories: Filter[]
}

// UTILITY TYPES
export type WithId<T> = T & {
    id: string
}

export type GenericObject = {
    id: number
    value: string
}

export type Image<T = CustomFile> = T

// GENERAL RELEASE
// ReleaseRecordPrimitiveProps
// ReleaseRecordObjectArrayProps

type ReleaseRecordPrimitiveProps = {
    id: string
    title: string
    type: ReleaseType
    cover_image: string
    release_at: Date | string
    region?: Region
    content?: string
    published?: boolean
    private?: boolean
    publisher_id?: string
    cover_color_hex?: string
    cover_transparent?: boolean

    // SINGLE
    sku: string
    colorway: string
    retail_price_usd?: number
    resell_price_usd?: number
}

type ReleaseRecordGeneralObjectArrayProps = {
    brands: GenericObject[]
    categories: GenericObject[]
    keyword_group: GenericObject[]
}

type ReleaseRecordObjectArrayProps = {
    images: Array<Image<WithId<CustomFile>>>

    instructions: Array<Instruction>

    // MULTI
    items: Array<Item>

    // SINGLE
    attachments: Array<Attachment>
}

export type ReleaseRecord = ReleaseRecordPrimitiveProps &
    ReleaseRecordGeneralObjectArrayProps &
    ReleaseRecordObjectArrayProps

// CREATE RELEASE
// ReleaseRecordPrimitiveProps
// CreateReleaseRecordPrimitiveArrayProps
// CreateReleaseRecordObjectArrayProps

type CreateReleaseRecordPrimitiveArrayProps = {
    brands: string[]
    categories: string[]
    keyword_group: string[]
}

type CreateReleaseRecordObjectArrayProps = {
    images: Image[]

    instructions: Instruction[]

    // MULTI
    items: Item[]

    // SINGLE
    attachments: Attachment[]
}

export type CreateReleaseRecord = Omit<ReleaseRecordPrimitiveProps, 'id'> &
    CreateReleaseRecordPrimitiveArrayProps &
    CreateReleaseRecordObjectArrayProps

// UPDATE RELEASE
// ReleaseRecordPrimitiveProps
// UpdateReleaseRecordObjectArrayProps

type UpdateReleaseRecordObjectArrayProps = {
    brands?: {
        create?: string[]
        delete?: number[]
    }
    categories?: {
        create?: string[]
        delete?: number[]
    }
    keyword_group?: {
        create?: string[]
        delete?: number[]
    }
    attachments: {
        create?: Attachment[]
        delete?: number[]
    }
    images?: {
        create?: CustomFile[]
        delete?: number[]
    }
    items: {
        create?: Item[]
        delete?: number[]
    }
    instructions?: {
        create?: Instruction[]
        delete?: number[]
    }
}

export type UpdateReleaseRecord = ReleaseRecordPrimitiveProps &
    UpdateReleaseRecordObjectArrayProps

// KEYS
export type ReleaseRecordKeys = keyof ReleaseRecord

export type ReleaseRecordGeneralObjectArrayPropsKeys =
    keyof ReleaseRecordGeneralObjectArrayProps

export type CreateReleaseRecordKeys = keyof CreateReleaseRecord

export type UpdateReleaseRecordKeys = keyof UpdateReleaseRecord

export type ReleaseRecordObjectArrayPropsKeys =
    keyof ReleaseRecordObjectArrayProps

export type CreateReleaseRecordObjectArrayPropsKeys =
    keyof CreateReleaseRecordObjectArrayProps

export type CreateReleaseRecordPrimitiveArrayPropsKeys =
    keyof CreateReleaseRecordPrimitiveArrayProps

// VALUES
export type ReleaseRecordValues = ReleaseRecord[ReleaseRecordKeys]

export type ReleaseRecordGeneralObjectArrayPropsValues =
    ReleaseRecordGeneralObjectArrayProps[ReleaseRecordGeneralObjectArrayPropsKeys]

export type CreateReleaseRecordValues =
    CreateReleaseRecord[CreateReleaseRecordKeys]

export type UpdateReleaseRecordValues =
    UpdateReleaseRecord[UpdateReleaseRecordKeys]

export type ReleaseRecordObjectArrayPropsValues =
    ReleaseRecordObjectArrayProps[ReleaseRecordObjectArrayPropsKeys]

export type CreateReleaseRecordObjectArrayPropsValues =
    CreateReleaseRecordObjectArrayProps[CreateReleaseRecordObjectArrayPropsKeys]

export type CreateReleaseRecordPrimitiveArrayPropsValues =
    CreateReleaseRecordPrimitiveArrayProps[CreateReleaseRecordPrimitiveArrayPropsKeys]
