import styled from 'styled-components';
import { ReactComponent as Emoji } from '../../../assets/sad-emoji.svg';

export const Wrapper = styled.div`
    width: 100%;
    max-width: 500px;
    margin: auto 0;
    padding: 80px 0px;

    @media only screen and (max-width: 640px) {
        padding: 80px 10px;
    }
`

export const Container = styled.div`
    position: relative;
    width: 100%;
    padding: 70px 30px 30px 30px;
    border-radius: 30px;
    background: #1D1D1D;
    box-shadow: 0 30px 60px 0 #000000;
`

export const SadEmoji = styled(Emoji)`
    position: absolute;
    top: -60px;
    left: 50%;
    transform: translateX(-50%);
`
