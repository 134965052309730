import { useStaffDispatchContext, useStaffReleaseFormContext } from 'src/hooks/useStaffReleaseForm';
import Table from '../Table'
import { Spacer, TableData, TableText } from '../Table.styled';
import { tableHeadings } from './InstructionsTable.data';
import { ReactComponent as EditIcon } from 'src/assets/edit.svg'
import { ReactComponent as RemoveIcon } from 'src/assets/remove-icon.svg';
import { InstructionsTableProps } from './InstructionsTable.types';
import { Instruction } from 'src/types/ReleaseRecord';
import { deleteInstruction } from 'src/contexts/StaffReleaseFormContext/StaffReleaseFormContext.services';

const InstructionsTable = ({ toggleModal, setSelectedInstruction }: InstructionsTableProps) => {
    const dispatch = useStaffDispatchContext()
    const { formState: { instructions } } = useStaffReleaseFormContext();

    const handleAddItem = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        setSelectedInstruction({} as Instruction);
        toggleModal();
    }

    const handleDeleteItem = (title: string) => (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.preventDefault();
        deleteInstruction(dispatch)(title);
    }

    return (
        <Table heading='Instruction' handleAddItem={handleAddItem} itemCount={instructions.length} tableHeadings={tableHeadings}>
            {
                instructions.map(({ title, description, id }) => (
                    <tr key={id}>
                        <TableData>
                            <TableText>{title}</TableText>
                        </TableData>
                        <TableData>
                            <Spacer>
                                <TableText>{description}</TableText>
                                <div style={{ cursor: 'pointer' }} onClick={() => {
                                    setSelectedInstruction({ title, description, id });
                                    toggleModal();
                                }}>
                                    <EditIcon />
                                </div>
                                <div style={{ cursor: 'pointer' }} onClick={handleDeleteItem(title)}>
                                    <RemoveIcon />
                                </div>
                            </Spacer>
                        </TableData>
                    </tr>
                ))
            }
        </Table>
    )
}

export default InstructionsTable