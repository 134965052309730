import styled from 'styled-components';

export const CardInfoContainer = styled.div`
    width: 100%;

    > * + * {
        margin-top: 25px;
    }
`

export const HeadingContainer = styled.div`
    text-align: center;
`

export const Heading = styled.h2`
    color: white;
    font-weight: 700;
    font-size: 24px;
`

export const Subheading = styled.p`
    color: #FFFFFF70;
    font-weight: 400;
`

export const InfoHeading = styled.h3`
    color: #FFFFFF;
    font-weight: 600;
    font-size: 18px;
    cursor: pointer;
`

export const CancelContainer = styled.div`
    text-align: center;
`

export const RadioContainer = styled.div`
    > * + * {
        margin-top: 10px;
    }
`

export const TextArea = styled.textarea`
    width: 100%;
    resize: none;
    padding: 10px;
    color: white;
    border-radius: 15px;
    background: #FFFFFF10;
    border: 1px solid #FFFFFF10;
    white-space: pre-line;
    font-weight: 500;
    font-family: prompt;
    font-size: 16px;

    &:focus {
        outline: none;
    }
`