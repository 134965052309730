import styled from 'styled-components';
import SuccessCard from '../components/success/SuccessCard/SuccessCard';
import SuccessVideo from '../components/success/SuccessVideo/SuccessVideo';
import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { AuthContext, IAuthContext } from '../contexts/Auth';

function Success() {
    const { userInfo } = useContext<IAuthContext>(AuthContext);

    useEffect(() => {
        if (!userInfo.discord) {
            window.location.href = `/complete-account-setup/${userInfo?.id}`
        }
    }, [])

    const [discord, setDiscord] = useState({
        message: '',
        error: '',
        isSubmitting: false
    })

    const [download, setDownload] = useState({
        message: '',
        error: '',
        isSubmitting: false
    })

    const joinDiscord = (product: string) => async () => {
        if (discord.isSubmitting) return;

        setDiscord(prev => ({
            ...prev,
            isSubmitting: true
        }));

        try {
            const res = await axios.post("/api/v1/users/subscription/joinDiscord", { product }, { withCredentials: true });
            setDiscord(prev => ({
                ...prev,
                isSubmitting: false
            }));
            
            if (res.status === 200) {
                setDiscord(prev => ({
                    ...prev,
                    message: 'You have successfully joined the Discord server!',
                    error: '',
                }));
            }
        } catch (err) {
            console.log(err)
            setDiscord({
                message: '',
                error: 'There was an error joining the Discord server. Please try again later.',
                isSubmitting: false
            });
        }
    }


    const sendInvite = async () => {
        if (download.isSubmitting) return;

        setDownload(prev => ({
            ...prev,
            isSubmitting: true
        }));

        try {
            const res = await axios.post("/api/v1/users/@me/sendV3Invite", {}, { withCredentials: true });    
            if (res.status === 200) {
                setDownload({
                    message: 'Invite link sent to your email! Please allow a few minutes for it to arrive.',
                    error: '',
                    isSubmitting: false
                })
            } else {
                setDownload({
                    message: '',
                    error: 'Something went wrong. Please try again in a few minutes.',
                    isSubmitting: false
                })
            }
        } catch (err: any) {
            setDownload(prev => ({
                ...prev,
                isSubmitting: true
            }));
    
            // error planning here
            console.log('[error]', err.response)
        
            if (err.response && err.response.status === 402) {
                setDownload(prev => ({
                    ...prev,
                    message: '',
                    error: err.response.data.message
                }))
            } else {
                setDownload(prev => ({
                    ...prev,
                    message: '',
                    error: 'Something went wrong. Please try again in a few minutes.'
                }))
            }
        }
    }

    useEffect(() => {
        if (!userInfo.discord) {
            window.location.href = '/complete-account-setup'
        }
    }, [])

    return  (
        <Container>
            <AlertContainer>
                { discord.error && <ErrorAlert>{discord.error}</ErrorAlert> }
                { discord.message && <SuccessAlert>{discord.message}</SuccessAlert> }
                { download.error && <ErrorAlert>{download.error}</ErrorAlert> }
                { download.message && <SuccessAlert>{download.message}</SuccessAlert> }
            </AlertContainer>

            <Wrapper>
                <SuccessCard
                    message={`Welcome to the team! Please click "Join Discord" below to automatically get added to our Discord server and stay up to date with the latest releases.
                    To download the app, click "Download"; An invite will automatically send to your email! Have questions? Open a ticket in our Discord or email us @ support@swiftsole.app`}
                    sendInvite={sendInvite}
                    joinDiscord={joinDiscord('swiftsole_v3')}
                    isSubmitting={{ discord: discord.isSubmitting, download: download.isSubmitting }}
                />
                <SuccessVideo />
            </Wrapper>
        </Container>
    )
}

export default Success

const Container = styled.div`
    padding: 20px;

    @media only screen and (min-width: 1024px) {
        padding-top: 0px;
    }

    > * + * {
        margin-top: 20px;
    }
`

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    
    > * + * {
        margin-top: 40px;
    }
    
    @media only screen and (min-width: 1024px) {
        flex-direction: row;
        align-items: stretch;
        justify-content: center;

        > * + * {
            margin-top: 0px;
            margin-left: 50px;
        }
    }
`

const AlertContainer = styled.div`
    width: 100%;
    max-width: 440px;
    margin: 0 auto;

    > * + * {
        margin-top: 20px;
    }
`

const Alert = styled.div`
    border-radius: 10px;
    padding: 10px;
    color: white;
    font-weight: bold;
    text-align: center;
    width: 100%;
`

const ErrorAlert = styled(Alert)`
    background: #ff425e;
`

const SuccessAlert = styled(Alert)`
    background: #20bd6f;
`