import ReleasesSearchBar from 'src/components/releases/ReleasesSearchBar/ReleasesSearchBar'
import StaffFormContainer from 'src/components/releases/StaffFormContainer/StaffFormContainer'
import { StaffReleaseFormProvider } from 'src/contexts/StaffReleaseFormContext/StaffReleaseFormContext'
import styled from 'styled-components'

const SearchContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 575px;
    margin: 0 auto;
`

function StaffReleasesPage() {
    return (
        <StaffReleaseFormProvider>
            <SearchContainer>
                <ReleasesSearchBar />
            </SearchContainer>
            <StaffFormContainer />
        </StaffReleaseFormProvider>
    )
}

export default StaffReleasesPage
