import styled from 'styled-components'

export const Container = styled.div`
    padding: 30px;
    width: 100%;
    max-width: 495px;
    border-radius: 30px;
    background: rgba(121, 231, 139, 0.15);
    box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.15);
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(121, 231, 139, 1);

    @media (max-width: 768px) {
        max-width: 100%;
        padding: 20px;
        flex-direction: column;
        gap: 10px;
    }
`

export const Text = styled.p`
    font-size: 16px;
    color: #ffffff;
    margin-bottom: 0;
    text-align: center;
    font-weight: 600;
    padding-left: 10px;
`
