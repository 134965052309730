import { isEmpty } from 'lodash'
import { useMemo, useState } from 'react'
import {
    ErrorAlert,
    FormInput,
    FormInputContainerLarge,
    FormInputContainerSmall,
    FormLabel,
    FormSelect,
} from 'src/components/common/common'
import FileUpload from 'src/components/common/FileUpload/FileUpload'
import { NewFile } from 'src/components/common/FileUpload/FileUpload.types'
import FormButton from 'src/components/common/FormButton/FormButton'
import RequiredText from 'src/components/common/RequiredText/RequiredText'
import {
    addItem,
    updateItem,
} from 'src/contexts/StaffReleaseFormContext/StaffReleaseFormContext.services'
import {
    useStaffDispatchContext,
    useStaffReleaseFormContext,
} from 'src/hooks/useStaffReleaseForm'
import { useToggle } from 'src/hooks/useToggle'
import { ItemSchema } from 'src/schema/ItemSchema'
import { checkDuplicate } from 'src/utils/checkDuplicate'
import { generateId } from 'src/utils/generateId'
import { uploadImage } from 'src/utils/r2'
import { validateForm } from 'src/utils/validateForm'
import ImageUploadTable from '../Table/ImageUploadTable/ImageUploadTable'
import { FormState, ItemsFormProps } from './ItemForm.types'
import { ButtonContainer, Container } from './ItemsForm.styled'

const ItemsForm = ({ toggleModal, selectedItem, filters }: ItemsFormProps) => {
    const [isLoading, setIsLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [files, setFiles] = useState<NewFile[]>(() => {
        return selectedItem.image
            ? [{ url: selectedItem.image, name: '', transparent: false }]
            : []
    })
    const { on: checked, toggle } = useToggle()

    const dispatch = useStaffDispatchContext()
    const { formState: state } = useStaffReleaseFormContext()

    const [formState, setFormState] = useState<FormState>({
        id: selectedItem?.id || generateId(),
        title: selectedItem?.title || '',
        url: selectedItem?.url || '',
        sku: selectedItem?.sku || '',
        image: selectedItem?.image || '',
        brand: selectedItem?.brand || filters.brands[0].value,
        category: selectedItem?.category || filters.categories[0].value,
        retail: selectedItem?.retail || 0,
        resell: selectedItem?.resell || 0,
    })

    const isNewItem = useMemo(() => isEmpty(selectedItem), [selectedItem])

    const handleChange = (
        e: React.ChangeEvent<
            HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
        >
    ) => {
        const { name, value } = e.target

        setFormState((prev) => ({
            ...prev,
            [name]: value,
        }))
    }

    const handleCreateItem = async (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        e.preventDefault()
        setErrorMessage('')
        setIsLoading(true)

        try {
            checkDuplicate({
                value: formState.title,
                items: state.instructions,
                key: 'title',
            })

            const file = files[0]
            if (!file) throw new Error('Please provide an image')

            const { value } = await uploadImage({ file: file.url })

            const data: FormState = {
                ...formState,
                image: value,
                retail: Number(formState.retail),
                resell: Number(formState.resell),
            }

            validateForm(ItemSchema, data)
            addItem(dispatch)(data)
            toggleModal()
        } catch (error) {
            setIsLoading(false)
            setErrorMessage((error as Error).message)
        }
    }

    const handleEditItem = async (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        e.preventDefault()
        setErrorMessage('')
        setIsLoading(true)

        try {
            if (selectedItem?.title !== formState.title) {
                checkDuplicate({
                    value: formState.title,
                    items: state.attachments,
                    key: 'title',
                })
            }

            const file = files[0]
            if (!file) throw new Error('Please provide an image')

            let item: FormState = {
                retail: Number(formState.retail),
                resell: Number(formState.resell),
            } as FormState

            if (selectedItem.image !== file.url) {
                const { value } = await uploadImage({ file: file.url })
                item = {
                    ...formState,
                    ...item,
                    image: value,
                }
            } else {
                item = {
                    ...formState,
                    ...item,
                }
            }

            validateForm(ItemSchema, item)
            updateItem(dispatch)(selectedItem.title, item)
            toggleModal()
        } catch (error) {
            setIsLoading(false)
            setErrorMessage((error as Error).message)
        }
    }

    const handleBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
        const { name, value } = e.target

        if (!value)
            setFormState((prev) => ({
                ...prev,
                [name]: 0,
            }))
    }

    const handleToggleTranspaency = (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        e.preventDefault()

        toggle()
        setFiles((files) => {
            return files.map((file) => ({
                ...file,
                transparent: !checked,
            }))
        })
    }

    return (
        <Container>
            {errorMessage && <ErrorAlert>{errorMessage}</ErrorAlert>}

            <FormInputContainerLarge>
                <FileUpload
                    id="image"
                    label="Image"
                    files={files}
                    setFiles={(files: NewFile[]) => setFiles(files)}
                    limit={1}
                />
            </FormInputContainerLarge>

            <FormInputContainerLarge>
                <ImageUploadTable images={files} setImages={setFiles} />
            </FormInputContainerLarge>

            <FormInputContainerLarge>
                <FormLabel htmlFor="title">
                    <p>Title</p>
                    <RequiredText />
                </FormLabel>
                <FormInput
                    id="title"
                    name="title"
                    value={formState.title}
                    placeholder="Adidas Yeezy 350 V2"
                    onChange={handleChange}
                />
            </FormInputContainerLarge>

            <FormInputContainerSmall>
                <FormLabel htmlFor="url">URL (Optional)</FormLabel>
                <FormInput
                    id="url"
                    name="url"
                    value={formState.url}
                    placeholder="https://www.nike.com"
                    onChange={handleChange}
                />
            </FormInputContainerSmall>

            <FormInputContainerSmall>
                <FormLabel htmlFor="sku">SKU (Optional)</FormLabel>
                <FormInput
                    id="sku"
                    name="sku"
                    value={formState.sku}
                    placeholder="Nike US"
                    onChange={handleChange}
                />
            </FormInputContainerSmall>

            <FormInputContainerSmall>
                <FormLabel htmlFor="brand">Brand (Optional)</FormLabel>
                <FormSelect
                    id="brand"
                    name="brand"
                    value={formState.brand}
                    onChange={handleChange}
                >
                    {filters.brands.map(({ label, value }) => (
                        <option value={value} key={value}>
                            {label}
                        </option>
                    ))}
                </FormSelect>
            </FormInputContainerSmall>

            <FormInputContainerSmall>
                <FormLabel htmlFor="category">Category (Optional)</FormLabel>
                <FormSelect
                    id="category"
                    name="category"
                    value={formState.category}
                    onChange={handleChange}
                >
                    {filters.categories.map(({ label, value }) => (
                        <option value={value} key={value}>
                            {label}
                        </option>
                    ))}
                </FormSelect>
            </FormInputContainerSmall>

            <FormInputContainerSmall>
                <FormLabel htmlFor="retail">Retail (Optional)</FormLabel>
                <FormInput
                    id="retail"
                    name="retail"
                    value={formState.retail}
                    type="number"
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
            </FormInputContainerSmall>

            <FormInputContainerSmall>
                <FormLabel htmlFor="resell">Resell (Optional)</FormLabel>
                <FormInput
                    id="resell"
                    name="resell"
                    value={formState.resell}
                    type="number"
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
            </FormInputContainerSmall>

            <ButtonContainer>
                <FormButton
                    fullWidth
                    color="purple"
                    isLoading={isLoading}
                    onClick={isNewItem ? handleCreateItem : handleEditItem}
                >
                    {isNewItem ? 'Create Item' : 'Update Item'}
                </FormButton>
            </ButtonContainer>

            <FormButton
                fullWidth
                color="pink"
                onClick={handleToggleTranspaency}
            >
                {checked ? 'Disable' : 'Enable'} Transparency
            </FormButton>

            <FormButton color="grey" onClick={toggleModal} fullWidth>
                Close
            </FormButton>
        </Container>
    )
}

export default ItemsForm
