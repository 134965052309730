import styled from 'styled-components'

const Page = styled.div`
  display: flex;
  width: 1240px;
  margin: 0 auto;
  max-width: 90vw;

  @media only screen and (max-width: 850px) {
    max-width: 100vw;
  }
`

const Iframe = styled.iframe`
  width: 100%;
  height: 85vh;
  border: none;
  margin: 0;
  padding: 0;
`

const PrivacyPolicy = () => {

  return (
    <Page>
      <Iframe src="https://getterms.io/view/Wof7C/privacy/en-us"></Iframe>
    </Page>
  )
}

export default PrivacyPolicy
