import { useState } from 'react';
import Modal from 'src/components/common/Modal/Modal';
import styled from 'styled-components';
import CardForm from '../components/settings/CardForm/CardForm';
import SettingsForm from '../components/settings/SettingsForm/SettingsForm';

function SettingsPage() {
    const [showModal, setShowModal] = useState(false);

    const toggleModal = () => setShowModal(prev => !prev);

    return (
        <Container>
            <SettingsForm toggleModal={toggleModal} />

            <Modal
				showModal={showModal}
				toggleModal={toggleModal}
			>
                <CardForm
					toggleModal={toggleModal}
					/>
            </Modal>
        </Container>
    );
}

export default SettingsPage;

export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
`