import styled from "styled-components";

export const Text = styled.span`
    visibility: hidden;
    width: 140px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 150%;
    left: 50%;
    margin-left: -75px;
    opacity: 0;
    transition: opacity 0.3s;

    &::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: #555 transparent transparent transparent;
    }
`

export const Container = styled.div`
    position: relative;
    display: inline-block;

    &:hover ${Text} {
        visibility: visible;
        opacity: 1;
    }
`

export const Button = styled.button`
    outline: none;
    cursor: pointer;
    background-color: transparent;
    font-family: 'Prompt', sans-serif;
`