import { Flex, SkeletonContainer, SkeletonImage, SkeletonText, Wrapper } from './CardSkeleton.styled'

const CardSkeleton = () => {
    return (
        <SkeletonContainer>
            <SkeletonImage />
            <Wrapper>
                <SkeletonText height="20px" width="100%" />
                <Flex>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '5px',
                        }}
                    >
                        <SkeletonText height="20px" width="50px" />

                        <SkeletonText height="20px" width="70px" />
                    </div>
                    <SkeletonText height="20px" width="90px" />
                </Flex>
            </Wrapper>
        </SkeletonContainer>
    )
}

export default CardSkeleton
