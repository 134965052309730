import { BrowserRouter as Router, Route } from 'react-router-dom'
import Footer from './components/Footer'
import { GlobalStyles } from './components/GlobalStyles'
import Nav from './components/Nav'
import ProtectedRoute from './components/ProtectedRoute'
import ProtectedRouteWeak from './components/ProtectedRouteWeak'
import Auth from './contexts/Auth'
import AccountSetup from './pages/AccountSetup'
import AdminReleases from './pages/AdminReleases'
import CookiePolicy from './pages/CookiePolicy'
import Downloads from './pages/Downloads'
import Landing from './pages/Landing'
import PrivacyPolicy from './pages/PrivacyPolicy'
import ReferralLink from './pages/ReferralLink'
import Referrals from './pages/Referrals'
import Release from './pages/Release'
import ReleaseCalendarPage from './pages/ReleaseCalendarPage'
import SettingsPage from './pages/SettingsPage'
import Setup from './pages/Setup'
import StaffAlertsPage from './pages/StaffAlertsPage'
import StaffReleasesPage from './pages/StaffReleasesPage'
import Subscriptions from './pages/Subscriptions'
import Success from './pages/Success'
import ToS from './pages/ToS'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import StaffStoresPage from './pages/StaffStoresPage'
import { Toaster } from 'react-hot-toast'
import Test2 from './pages/Test2'
import StaffReservationsPage from './pages/StaffReservationsPage'
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

const App = () => {
    return (
        <>
            <GlobalStyles />
            <ToastContainer theme="dark" />
            <Toaster />
            <Auth>
                <>
                    <Router>
                        {/* Temp: show subs page when visiting index - / */}
                        <Nav />
                        <Route exact path="/" component={Landing} />
                        <Route
                            exact
                            path="/r/:referralCode"
                            component={ReferralLink}
                        />
                        <Route
                            exact
                            path="/release/:password"
                            component={Release}
                        />
                        <Route
                            exact
                            path="/complete-account-setup/:token"
                            component={AccountSetup}
                        />
                        <ProtectedRoute
                            exact
                            path="/subscriptions"
                            component={Subscriptions}
                        />
                        <ProtectedRoute
                            exact
                            path="/release-calendar"
                            component={ReleaseCalendarPage}
                        />
                        <ProtectedRoute
                            exact
                            path="/downloads"
                            component={Downloads}
                        />
                        <ProtectedRoute
                            exact
                            path="/settings"
                            component={SettingsPage}
                        />
                        <ProtectedRoute
                            exact
                            path="/referrals"
                            component={Referrals}
                        />
                        <ProtectedRoute
                            exact
                            path="/admin/releases"
                            component={AdminReleases}
                        />
                        <ProtectedRoute
                            exact
                            path="/staff/releases"
                            component={StaffReleasesPage}
                        />
                        <ProtectedRoute
                            exact
                            path="/staff/stores"
                            component={StaffStoresPage}
                        />
                        <ProtectedRoute
                            exact
                            path="/staff/reservations"
                            component={StaffReservationsPage}
                        />
                        <ProtectedRoute
                            exact
                            path="/staff/alerts"
                            component={StaffAlertsPage}
                        />
                        <ProtectedRoute
                            exact
                            path="/success"
                            component={Success}
                        />
                        <ProtectedRouteWeak
                            exact
                            path="/setup"
                            component={Setup}
                        />
                        <Route
                            exact
                            path="/privacy-policy"
                            component={PrivacyPolicy}
                        />
                        <Route
                            exact
                            path="/cookie-policy"
                            component={CookiePolicy}
                        />
                        <Route exact path="/tos" component={ToS} />
                        <Footer />
                    </Router>
                </>
            </Auth>
            {/* <ReactQueryDevtools initialIsOpen={false} position='bottom-right' /> */}
        </>
    )
}

export default App
