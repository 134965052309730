import styled from 'styled-components';
import { ReactComponent as Arrow } from '../../../assets/arrow-right.svg';

export const CardInfoContainer = styled.div`
    width: 100%;

    > * + * {
        margin-top: 25px;
    }
`

export const HeadingContainer = styled.div`
    text-align: center;
`

export const Heading = styled.h2`
    color: white;
    font-weight: 700;
    font-size: 24px;
`

export const Subheading = styled.p`
    color: #FFFFFF70;
    font-weight: 400;
`

export const InfoHeading = styled.h3`
    color: #FFFFFF70;
    font-weight: 600;
    font-size: 18px;
    cursor: pointer;
`

export const CancelContainer = styled.div`
    text-align: center;
`

export const ImageContainer = styled.div`
    display: flex;
    justify-content: center;
    overflow: hidden;
    height: 370px;
    padding-top: 25px;
    border-radius: 30px;
    background-image: linear-gradient(15deg, #00A4FF 0%, #F45A90 98%);
    position: relative;
`

export const ImageWrapper = styled.div`
    width: 375px;
    height: 550px;
`

export const Image = styled.img`
    width: 100%;
`

export const PriceContainer = styled.div`
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    background: white;
`

export const PriceWrapper = styled.div`
    text-align: center;
`

export const PriceHeading = styled.h2`
    color: #1D1D1D95;
    font-weight: 500;
`

export const ArrowRight = styled(Arrow)`
    color: #1D1D1D95;

    @media only screen and (max-width: 640px) {
        height: 25px;
        width: 25px;
    }
`

const Price = styled.p`
    font-size: 24px;
    font-weight: 700;

    @media only screen and (max-width: 640px) {
        font-size: 22px;
    }
`

export const RedPrice = styled(Price)`
    color: #FF3C4F;
    text-decoration: line-through;
`

export const GreenPrice = styled(Price)`
    color: #32D86F;
`
