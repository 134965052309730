import styled from 'styled-components'

export const Container = styled.div`
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;

    &::-webkit-scrollbar:vertical {
        width: 1px;
    }

    > * + * {
        border-top: 1px solid #3C3B3B90;
    }
`