export const tableHeadings = [
    'Title',
    'Type',
    'Method',
    'Store',
    'Locations',
    'Release Date',
    'End Date',
    'URL',
]
