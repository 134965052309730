import { useContext } from 'react'
import {
    StaffAlertDispatchContext,
    StaffAlertFormContext
} from 'src/contexts/StaffAlertFormContext/StaffAlertFormContext'

export const useStaffAlertForm = () => {
    const context = useContext(StaffAlertFormContext)

    if (!context) {
        throw new Error(
            'useStaffAlertForm must be used within a StaffAlertFormProvider'
        )
    }

    return context
}

export const useStaffAlertDispatchContext = () => {
    const context = useContext(StaffAlertDispatchContext)

    if (!context) {
        throw new Error(
            'useStaffAlertDispatchContext must be used within a StaffAlertDispatchContext'
        )
    }

    return context
}
