import { Redirect } from 'react-router-dom'

interface ReferralCode {
    referralCode: string
}

interface Match {
    params: ReferralCode
}

interface Props {
    match: Match
}

const ReferralLink = (props: Props) => {
    ///
    const { referralCode } = props.match.params

    if (referralCode) {
        document.cookie = `referralCode=${referralCode}; Max-Age=7200; Path=/`
    }

    window.location.href = `${window.location.protocol}//${window.location.host}/release/V2LIFETIME2022?code=INITIALOFF100`

    return <Redirect to="/subscriptions" />
}

export default ReferralLink
