import { useCallback, useState, useEffect } from 'react'
import FormTextInput from 'src/components/common/FormTextInput/FormTextInput'
import styled from 'styled-components'
import searchAddressIcon from '../../../assets/search-address-icon.svg'
import { Store } from 'src/pages/StaffStoresPage'
import { debounce } from 'lodash'
import axios from 'axios'
import useDebounce from 'src/hooks/useDebounce'

const StoreInput = styled(FormTextInput)<{ isFocused: boolean }>`
    margin-bottom: 10px;
    margin-top: 10px;
    width: 100%;
    grid-column: span 2;
    position: relative;
`

const LocationSelectContainer = styled.div`
    display: flex;
    align-items: center;
    background: #353535;
    border: 1px solid rgba(247, 239, 243, 0.1);
    border-radius: 18px;
    height: 60px;
    padding: 0 15px;
    padding-right: 0;
    width: 100%;
    grid-column: span 2;
    position: relative;

    transition: 0.15s border ease-out;
`

const LeftInputCol = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`

const Label = styled.span`
    font-size: 12px;
    font-weight: 500;
    color: rgba(247, 239, 243, 0.8);
`

const LocationSelect = styled.select`
    background: none;
    outline: none;
    font-family: inherit;
    color: white;
    font-size: 16px;
    font-weight: 600;
    color: #f7eff3;
    width: 100%;

    // Hide arrow:
    -webkit-appearance: none;
`

const AddressOptions = styled.div<{ isOpen: boolean }>`
    display: flex;
    flex-direction: column;
    width: 440px;
    height: 200px;
    background: rgba(247, 239, 243, 0.1);
    border: 1px solid rgba(247, 239, 243, 0.2);
    border-radius: 18px;
    position: absolute;
    top: 590px;
    left: 30px;
    backdrop-filter: blur(25px) saturate(25%);
    z-index: 1;
    transform: scale(${({ isOpen }) => (isOpen ? 1 : 0.95)});
    opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
    visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
    transform-origin: top center;
    will-change: transform;
    overflow-y: auto;

    // Hide scrollbar:
    &::-webkit-scrollbar {
        display: none;
    }

    transition: 0.15s transform ease-out, 0.15s visibility ease-out,
        0.15s opacity ease-out;
`

const AddressOption = styled.span`
    height: 55px;
    border-bottom: 1px solid rgba(247, 239, 243, 0.2);
    font-size: 16px;
    font-weight: 600;
    color: rgba(247, 239, 243, 0.5);
    display: flex;
    align-items: center;
    padding: 0 15px;
    box-sizing: border-box;
    cursor: pointer;
    flex-shrink: 0;

    &:last-child {
        border-bottom: none;
    }

    &:hover {
        background: rgba(247, 239, 243, 0.1);
    }
`

const MatchedPortionOfName = styled.span`
    color: #f7eff3;
`

const AddAnotherLocation = styled.span`
    cursor: pointer;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.7);
    text-align: center;
    grid-column: span 2;
`

const BlurOutRegion = styled.div`
    position: absolute;
    top: 1px; // 1px border top
    right: 0;
    border-top-right-radius: 18px;
    border-bottom-right-radius: 18px;
    width: 30px;
    background: linear-gradient(
        to right,
        rgba(53, 53, 53, 0),
        rgba(53, 53, 53, 1)
    );
    height: calc(100% - 2px); // 1px border top and bottom
`

interface AttachmentLocationsSearchProps {
    previousStore?: Store
    previousLocations?: Store['store_locations']
    onLocationsChange: (locations: Store['store_locations']) => void
    onStoreChange: (store: Store) => void
}

const AttachmentLocationsSearch = (props: AttachmentLocationsSearchProps) => {
    const [isStoreInputFocused, setIsStoreInputFocused] = useState(false)
    const [storeID, setStoreID] = useState<number | null>(null)
    const [storeName, setStoreName] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [storePredictions, setStorePredictions] = useState<Store[]>([])
    const [availableLocations, setAvailableLocations] = useState<
        Store['store_locations']
    >([])
    const [selectedLocations, setSelectedLocations] = useState<
        Store['store_locations']
    >([])
    const [searchTerm, setSearchTerm] = useState('')
    const debouncedSearchTerm = useDebounce(searchTerm, 500)

    useEffect(() => {
        if (debouncedSearchTerm) {
            getStorePredictions(debouncedSearchTerm)
        }
    }, [debouncedSearchTerm])

    // useEffect(() => {
    //     getStorePredictions('')
    // }, [])

    const getStorePredictions = async (name: string) => {
        setIsLoading(true)
        const response = await axios.get(
            `https://pro-alerts-api-production.up.railway.app/api/v2/stores/search?per_page=9999&query=${name.toLowerCase()}`,
            {
                headers: {
                    Authorization: 'a1d3b746-0702-4d5b-900e-313dbf9ab0a2',
                },
            }
        )
        const matchingStores = response.data.data
        setStorePredictions(matchingStores)
        setIsLoading(false)
    }

    useEffect(() => {
        if (props.previousStore) {
            setStoreName(props.previousStore.store_name)
            setStoreID(props.previousStore.id)

            axios
                .get(
                    'https://pro-alerts-api-production.up.railway.app/api/v2/stores/fetch?per_page=9999',
                    {
                        headers: {
                            Authorization:
                                'a1d3b746-0702-4d5b-900e-313dbf9ab0a2',
                        },
                    }
                )
                .then((response) => {
                    setAvailableLocations(
                        response.data.data.find(
                            (store: Store) =>
                                store.id === props.previousStore?.id
                        )?.store_locations || []
                    )
                })
        }
        if (props.previousLocations) {
            setSelectedLocations(props.previousLocations)
        }
    }, [props.previousLocations, props.previousStore])

    return (
        <>
            <StoreInput
                isFocused={isStoreInputFocused}
                onFocus={() => setIsStoreInputFocused(true)}
                onBlur={() => setIsStoreInputFocused(false)}
                shouldHideBlurOutRegion
                label="Store Name"
                value={storeName}
                onChange={(e) => {
                    setAvailableLocations([])
                    setSearchTerm(e.target.value)
                    setStoreName(e.target.value)
                }}
                placeholder="Enter a store name to start searching"
                leadingElement={
                    <img
                        src={searchAddressIcon}
                        width={19}
                        height={19}
                        alt=""
                        style={{ marginRight: '11px' }}
                    />
                }
            />
            <AddressOptions isOpen={isStoreInputFocused}>
                {!isLoading &&
                    storeName &&
                    storePredictions.map((store) => (
                        <AddressOption
                            onClick={() => {
                                setStoreName(store.store_name)
                                setStoreID(store.id)
                                getStorePredictions(store.store_name)
                                setAvailableLocations(store.store_locations)
                                console.log(store.store_locations)
                                props.onStoreChange(store)
                            }}
                        >
                            {store.store_name.slice(
                                0,
                                store.store_name
                                    .toLowerCase()
                                    .indexOf(storeName.toLowerCase())
                            )}
                            <MatchedPortionOfName>
                                {` ${storeName} `}
                            </MatchedPortionOfName>
                            {store.store_name.slice(
                                store.store_name
                                    .toLowerCase()
                                    .indexOf(storeName.toLowerCase()) +
                                    storeName.length
                            )}
                        </AddressOption>
                    ))}
                {isLoading && (
                    <span
                        style={{
                            textAlign: 'center',
                            alignSelf: 'center',
                            margin: 'auto',
                        }}
                    >
                        Loading...
                    </span>
                )}
            </AddressOptions>
            {availableLocations?.length > 0 &&
                selectedLocations.map((location, index) => (
                    <LocationSelectContainer>
                        <LeftInputCol>
                            <Label>Location {index + 1}</Label>
                            <LocationSelect
                                value={location.id}
                                onChange={(e) => {
                                    if (e.target.value === '-1') {
                                        setSelectedLocations((prev) => {
                                            const newSelectedLocations = [
                                                ...prev,
                                            ]
                                            newSelectedLocations.splice(
                                                index,
                                                1
                                            )
                                            props.onLocationsChange(
                                                newSelectedLocations
                                            )
                                            return newSelectedLocations
                                        })
                                    } else {
                                        setSelectedLocations((prev) => {
                                            const newSelectedLocations = [
                                                ...prev,
                                            ]
                                            newSelectedLocations[index] =
                                                availableLocations.find(
                                                    (location) =>
                                                        location.id ===
                                                        parseInt(e.target.value)
                                                )!
                                            props.onLocationsChange(
                                                newSelectedLocations
                                            )
                                            return newSelectedLocations
                                        })
                                    }
                                }}
                            >
                                {availableLocations.map((location) => (
                                    <option value={location.id}>
                                        {location.location_name} (
                                        {location.location_address_formatted})
                                    </option>
                                ))}
                                <option value={-1}>Delete</option>
                            </LocationSelect>
                        </LeftInputCol>
                        <BlurOutRegion />
                    </LocationSelectContainer>
                ))}
            {availableLocations?.length > 0 && (
                <AddAnotherLocation
                    onClick={() =>
                        setSelectedLocations((prev) => {
                            props.onLocationsChange([
                                ...prev,
                                availableLocations[0],
                            ])
                            return [...prev, availableLocations[0]]
                        })
                    }
                >
                    Add another location
                </AddAnotherLocation>
            )}
        </>
    )
}

export default AttachmentLocationsSearch
