import { Container, Loader } from "./Spinner.styled";
import { SpinnerProps } from "./Spinner.types";

function Spinner(props: SpinnerProps) {
    return (
        <Container { ...props }>
            <Loader />
        </Container>
    )
}

export default Spinner;