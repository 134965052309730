import { Container, SadEmoji, Wrapper } from './CardWithSadEmoji.styled'
import { CardWithSadEmojiProps } from './CardWithSadEmoji.types'

const CardWithSadEmoji = ({ children }: CardWithSadEmojiProps)  => {
    return (
        <Wrapper>
            <Container>
                <SadEmoji />
                { children }
            </Container>
        </Wrapper>
    )
}

export default CardWithSadEmoji