import { createContext, useReducer } from 'react'
import { AudienceType, NotificationType } from 'src/schema/NotificationSchema'
import { Action } from './StaffAlertFormContext.actions'
import { ActionTypes } from './StaffAlertFormContext.actionTypes'
import {
    StaffAlertFormContextProps,
    State,
} from './StaffAlertFormContext.types'

const INITIAL_STATE: State = {
    formState: {
        type: NotificationType.ALERT,
        title: '',
        content: '',
        url: '',
        publisher_id: '',
        image_url: '',
        brand: '',
        category: '',
        release_id: '',
        send_at: new Date(),
    },
    files: [],
}

const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case ActionTypes.UPDATE_FIELD: {
            return {
                ...state,
                formState: {
                    ...state.formState,
                    [action.payload.key]: action.payload.value,
                },
            }
        }

        case ActionTypes.SET_FORM_FIELDS: {
            return {
                ...state,
                formState: {
                    ...state.formState,
                    ...action.payload,
                },
            }
        }

        case ActionTypes.RESET_FORM: {
            return {
                ...INITIAL_STATE,
            }
        }

        case ActionTypes.SET_FILES: {
            return {
                ...state,
                files: action.payload,
            }
        }

        default: {
            return state
        }
    }
}

export const StaffAlertFormContext = createContext((null as unknown) as State)
export const StaffAlertDispatchContext = createContext(
    (null as unknown) as React.Dispatch<Action>
)

export const StaffAlertFormProvider = ({
    children,
}: StaffAlertFormContextProps) => {
    const [state, dispatch] = useReducer(reducer, INITIAL_STATE)

    return (
        <StaffAlertFormContext.Provider value={state}>
            <StaffAlertDispatchContext.Provider value={dispatch}>
                {children}
            </StaffAlertDispatchContext.Provider>
        </StaffAlertFormContext.Provider>
    )
}
