import styled from "styled-components"

export const Container = styled.div`
    width: 100%;
    padding: 10px;
    display: flex;
    cursor: pointer;
    border: 1px solid #3f3f4670;
    border-radius: 15px;
    background-color: rgba(255,255,255, 0.07);
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 300ms;
    height: 115px;
    
    > * + * {
        margin-left: 10px;
    }

    &:hover {
        border: 1px solid #d4d4d8;
    }
`

export const ImageContainer = styled.div`
    width: 60px;
    height: 60px;
    border-radius: 15px;
    background-color: white;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const Image = styled.img`
    width: 100%;
`

export const Wrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

export const Flex = styled.div`
    flex: 1;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
`

export const ProductName = styled.h4`
    color: white;
    margin: 0px;
    font-weight: 600;
    text-overflow: ellipsis;
`

export const DateContainer = styled.div`
    margin-top: auto;
`

export const Text = styled.p`
    font-size: 13px;
    color: #ffffff66;
    /* margin: 0px; */
`

export const Price = styled.p`
    color: #43EA80;
    font-size: 13px;
    margin: 0px;
    margin-top: 5px;
`

export const DeleteReleaseButton = styled.button`
    background-color: transparent;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
`