import { Store } from 'src/pages/StaffStoresPage'
import styled from 'styled-components'
import FormTextInput from '../../common/FormTextInput/FormTextInput'
import { useEffect, useState } from 'react'
import searchAddressIcon from '../../../assets/search-address-icon.svg'
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService'

const Overlay = styled.div<{ isOpen: boolean }>`
    background: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
    opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
    border-radius: 30px;

    transition: 0.2s visibility ease-out, 0.2s opacity ease-out;
`

const Modal = styled.div<{ isOpen: boolean }>`
    background: #1d1d1d;
    box-shadow: 0 30px 60px black;
    width: 600px;
    display: flex;
    flex-direction: column;
    border-radius: 30px;
    position: relative;
`

const HeaderCol = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background: rgba(52, 50, 50, 0.8);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    padding: 10px 0;
    border-radius: 30px 30px 0 0;
`

const Heading = styled.span`
    font-size: 24px;
    font-weight: bold;
    color: #f7eff3;
`

const Subheading = styled.span`
    font-size: 14px;
    font-weight: 600;
    color: rgba(247, 239, 243, 0.7);
`

const ModalContent = styled.div`
    padding: 30px;
    display: flex;
    flex-direction: column;
`

const SaveButton = styled.span`
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 18px;
    cursor: pointer;
    border-radius: 30px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
    background: linear-gradient(
        180deg,
        rgba(127, 112, 255, 1) 0%,
        rgba(107, 90, 255, 1) 100%
    );
    font-size: 16px;
    font-weight: bold;
    margin-top: 30px;
    margin-bottom: 10px;
    &:hover {
        filter: brightness(1.25);
    }

    transition: 0.1s filter ease-out;
`

const CancelButton = styled(SaveButton)`
    background: rgba(255, 255, 255, 0.07);
    color: rgba(255, 255, 255, 0.7);
    margin: 0;
    box-shadow: none;
`

const AddressInput = styled(FormTextInput)<{ isFocused: boolean }>`
    margin-bottom: 10px;
    margin-top: 10px;
`

const AddressOptions = styled.div<{ isOpen: boolean }>`
    display: flex;
    flex-direction: column;
    width: 540px;
    height: 200px;
    background: rgba(247, 239, 243, 0.1);
    border: 1px solid rgba(247, 239, 243, 0.2);
    border-radius: 18px;
    position: absolute;
    top: 245px;
    left: 30px;
    backdrop-filter: blur(25px) saturate(25%);
    z-index: 1;
    transform: scale(${({ isOpen }) => (isOpen ? 1 : 0.95)});
    opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
    visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
    transform-origin: top center;
    will-change: transform;
    overflow-y: auto;

    // Hide scrollbar:
    &::-webkit-scrollbar {
        display: none;
    }

    transition: 0.15s transform ease-out, 0.15s visibility ease-out,
        0.15s opacity ease-out;
`

const AddressOption = styled.span`
    height: 55px;
    border-bottom: 1px solid rgba(247, 239, 243, 0.2);
    font-size: 16px;
    font-weight: 600;
    color: #f7eff3;
    display: flex;
    align-items: center;
    padding: 0 15px;
    box-sizing: border-box;
    cursor: pointer;
    flex-shrink: 0;

    &:last-child {
        border-bottom: none;
    }

    &:hover {
        background: rgba(247, 239, 243, 0.1);
    }
`

interface AddLocationModalProps {
    isOpen: boolean
    onClose: () => void
    storeName?: string
    onAddLocation: (
        location: Omit<
            Store['store_locations'][number],
            'storeId' | 'id' | 'created_at' | 'updated_at'
        >
    ) => void
}

const AddLocationModal = (props: AddLocationModalProps) => {
    const [phoneNumber, setPhoneNumber] = useState('')
    const [address, setAddress] = useState('')
    const [isAddressInputFocused, setIsAddressInputFocused] = useState(false)
    const [locationName, setLocationName] = useState('')

    const [selectedPlaceId, setSelectedPlaceId] = useState('')
    const [selectedPlaceAddress1, setSelectedPlaceAddress1] = useState('')
    const [selectedPlaceCity, setSelectedPlaceCity] = useState('')
    const [selectedPlaceState, setSelectedPlaceState] = useState('')
    const [selectedPlacePostalCode, setSelectedPlacePostalCode] = useState('')
    const [selectedPlaceCountry, setSelectedPlaceCountry] = useState('')
    const [selectedPlaceFormattedAddress, setSelectedPlaceFormattedAddress] =
        useState('')
    const [selectedPlaceLatitude, setSelectedPlaceLatitude] = useState(0)
    const [selectedPlaceLongitude, setSelectedPlaceLongitude] = useState(0)

    const [isPhoneError, setIsPhoneError] = useState(false)
    const [isAddressError, setIsAddressError] = useState(false)
    const [isLocationNameError, setIsLocationNameError] = useState(false)

    const {
        placesService,
        placePredictions,
        getPlacePredictions,
        isPlacePredictionsLoading,
    } = usePlacesService({
        apiKey: 'AIzaSyCCndeKlGqpvnMSeps5FGNUZ3V5Dzcfu58', // TODO: move to env
    })

    const resetSelectedPlace = () => {
        setSelectedPlaceId('')
        setSelectedPlaceAddress1('')
        setSelectedPlaceCity('')
        setSelectedPlaceState('')
        setSelectedPlacePostalCode('')
        setSelectedPlaceCountry('')
        setSelectedPlaceFormattedAddress('')
        setSelectedPlaceLatitude(0)
        setSelectedPlaceLongitude(0)
    }

    const onSelectPlace = (placeId: string) => {
        placesService?.getDetails(
            {
                placeId,
            },
            // @ts-ignore
            (placeDetails: {
                address_components: {
                    long_name: string
                    short_name: string
                    types: string[]
                }[]
                formatted_address: string
                formatted_phone_number?: string
                name?: string
                geometry: {
                    location: {
                        lat: () => number
                        lng: () => number
                    }
                }
            }) => {
                setSelectedPlaceId(placeId)
                setSelectedPlaceCity(
                    placeDetails.address_components.find((component) =>
                        component.types.includes('locality')
                    )?.long_name || ''
                )
                setSelectedPlaceCountry(
                    placeDetails.address_components.find((component) =>
                        component.types.includes('country')
                    )?.long_name || ''
                )
                setSelectedPlaceState(
                    placeDetails.address_components.find((component) =>
                        component.types.includes('administrative_area_level_1')
                    )?.long_name || ''
                )
                setSelectedPlacePostalCode(
                    placeDetails.address_components.find((component) =>
                        component.types.includes('postal_code')
                    )?.long_name || ''
                )
                setSelectedPlaceAddress1(placeDetails.name || '')
                setSelectedPlaceFormattedAddress(placeDetails.formatted_address)
                setAddress(placeDetails.formatted_address)
                setSelectedPlaceLatitude(placeDetails.geometry.location.lat())
                setSelectedPlaceLongitude(placeDetails.geometry.location.lng())

                // Set phone number if it's not already set and the place has a phone number
                if (
                    phoneNumber.length === 0 &&
                    placeDetails.formatted_phone_number
                )
                    setPhoneNumber(placeDetails.formatted_phone_number)
            }
        )
    }

    const onSubmit = () => {
        if (!phoneNumber) setIsPhoneError(true)
        if (!address || !selectedPlaceAddress1) setIsAddressError(true)
        if (!locationName) setIsLocationNameError(true)

        if (phoneNumber && address && selectedPlaceAddress1 && locationName) {
            props.onAddLocation({
                google_places_id: selectedPlaceId,
                location_name: locationName,
                location_address_1: selectedPlaceAddress1,
                location_address_2: '',
                location_address_3: '',
                location_address_formatted: selectedPlaceFormattedAddress,
                location_city: selectedPlaceCity,
                location_state: selectedPlaceState,
                location_postal_code: selectedPlacePostalCode,
                location_country: selectedPlaceCountry,
                location_phone: phoneNumber,
                location_latitude: selectedPlaceLatitude,
                location_longitude: selectedPlaceLongitude,
            })
            props.onClose()
            resetState()
        }
    }

    const resetState = () => {
        setPhoneNumber('')
        setAddress('')
        setLocationName('')
        resetSelectedPlace()
        setIsPhoneError(false)
        setIsAddressError(false)
        setIsLocationNameError(false)
    }

    return (
        <Overlay
            isOpen={props.isOpen}
            onClick={(e) => {
                if (e.target === e.currentTarget) {
                    props.onClose()
                    resetState()
                }
            }}
        >
            <Modal isOpen={props.isOpen}>
                <HeaderCol>
                    <Heading>Add Location</Heading>
                    <Subheading>{props.storeName || 'New Store'}</Subheading>
                </HeaderCol>
                <ModalContent>
                    <FormTextInput
                        label="Location Name"
                        placeholder="Enter a name for this location"
                        value={locationName}
                        onChange={(e) => {
                            setLocationName(e.target.value)
                            setIsLocationNameError(false)
                        }}
                        isError={isLocationNameError}
                    />
                    <AddressInput
                        isFocused={isAddressInputFocused}
                        onFocus={() => setIsAddressInputFocused(true)}
                        onBlur={() => setIsAddressInputFocused(false)}
                        shouldHideBlurOutRegion
                        label="Address"
                        value={address}
                        onChange={(e) => {
                            setAddress(e.target.value)
                            resetSelectedPlace()
                            getPlacePredictions({ input: e.target.value })
                            setIsAddressError(false)
                        }}
                        placeholder="Enter an address to start searching"
                        leadingElement={
                            <img
                                src={searchAddressIcon}
                                width={19}
                                height={19}
                                alt=""
                                style={{ marginRight: '11px' }}
                            />
                        }
                        isError={isAddressError}
                    />
                    <AddressOptions isOpen={isAddressInputFocused}>
                        {placePredictions.map((item) => (
                            <AddressOption
                                onClick={() => onSelectPlace(item.place_id)}
                            >
                                {item.description}
                            </AddressOption>
                        ))}
                        {isPlacePredictionsLoading && (
                            <span
                                style={{
                                    textAlign: 'center',
                                    alignSelf: 'center',
                                    margin: 'auto',
                                }}
                            >
                                Loading...
                            </span>
                        )}
                    </AddressOptions>
                    <FormTextInput
                        label="Phone Number"
                        value={phoneNumber}
                        onChange={(e) => {
                            setPhoneNumber(e.target.value)
                            setIsPhoneError(false)
                        }}
                        placeholder="(323) 123-4567"
                        isError={isPhoneError}
                    />
                    <SaveButton onClick={onSubmit}>Add Location</SaveButton>
                    <CancelButton
                        onClick={() => {
                            props.onClose()
                            resetState()
                        }}
                    >
                        Cancel
                    </CancelButton>
                </ModalContent>
            </Modal>
        </Overlay>
    )
}

export default AddLocationModal
