import { memo } from 'react'
import { Input, InputContainer, Label, Tick } from './CustomCheckbox.styled'
import { CustomCheckboxProps } from './CustomCheckbox.types'

const CustomCheckbox = memo(({ name, handleCheck, label, checked }: CustomCheckboxProps) => {
    return (
        <div>
            <Label>{label}</Label>

            <InputContainer>
                <Input
                    id={name}
                    name={name}
                    type="checkbox"
                    checked={checked}
                    onChange={handleCheck}
                />

                { checked && <Tick /> }
            </InputContainer>
        </div>
    )
})

export default CustomCheckbox