import axios from 'axios'
import { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import ReservationCard from 'src/components/reservations/ReservationCard/ReservationCard'
import ReservationEditForm from 'src/components/reservations/ReservationEditForm/ReservationEditForm'
import FormTextInput from 'src/components/common/FormTextInput/FormTextInput'
import styled from 'styled-components'

const Container = styled.div`
    width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
`

const TopRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
`

const Title = styled.span`
    font-size: 28px;
    font-weight: bold;
`

const NewStoreButton = styled.span`
    height: 46px;
    display: flex;
    align-items: center;
    padding: 0 18px;
    cursor: pointer;
    border-radius: 30px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
    background: linear-gradient(
        180deg,
        rgba(127, 112, 255, 1) 0%,
        rgba(107, 90, 255, 1) 100%
    );
    font-size: 16px;
    font-weight: bold;

    &:hover {
        filter: brightness(1.25);
    }

    transition: 0.1s filter ease-out;
`

const StoresContainer = styled.div`
    background: #272727;
    width: 1200px;
    margin: 0 auto;
    display: flex;
    height: calc(100vh - 225px);
    border-radius: 30px;
    position: relative;
`

const StoreCardsCol = styled.div`
    display: flex;
    flex-direction: column;
    width: 490px;
    border-bottom: 1px solid rgb(39, 39, 39);
    border-bottom-left-radius: 30px;
    border-right: 1px solid #3c3b3b;
    overflow-y: auto;

    &::-webkit-scrollbar {
        display: none;
    }
`

const StoreCardsFadeOutRegion = styled.div`
    background: linear-gradient(
        to bottom,
        rgba(39, 39, 39, 0),
        rgba(39, 39, 39, 1)
    );
    height: 40px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 489px;
    border-bottom-left-radius: 29px;
`

const StoreNameSearchInputWrapper = styled.div`
    padding: 20px;
    position: sticky;
    top: 0;
    background: rgba(39, 39, 39, 0.7);
    backdrop-filter: blur(30px);
    border-top-left-radius: 30px;
`

const StoreNameSearchInput = styled(FormTextInput)``

const PaginationControlsRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px;
`

const CircularButton = styled.button<{ selected?: boolean }>`
    color: #ffffff;
    font-weight: 600;
    font-size: 16px;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #ffffff10;
    border: 1px solid
        ${({ selected }) => (selected ? '#ffffff05' : '#ffffff10')};
    outline: none;
    font-family: 'Prompt', sans-serif;
    cursor: pointer;
    transition-property: border-color;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 300ms;

    &:disabled {
        cursor: not-allowed;
        color: #ffffff10;
    }

    &:not(:disabled):hover {
        border-color: #ffffff90;
    }
`

export interface Reservation {
    releaseTitle: string
    coverImage: string
    colorway: string
    coverColorHex: string
    coverTransparent: number
    rate: number
    subscriber_rate: number
    shipping_rate: number
    notes: string | null
    reservationRecordId: string
    releaseChannelId: string
    releaseRecordId: string
    closeDate: Date
    openDate: Date
    estimatedSuccess: number
    sizes: {
        id: number
        title: string
    }[]
}

const StaffReservationsPage = () => {
    const [selectedReservation, setSelectedReservation] =
        useState<Reservation | null>(null)
    const [reservations, setReservations] = useState<Reservation[]>([])
    const [shouldRevalidate, setShouldRevalidate] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        setIsLoading(true)
        axios
            .get(
                `https://pro-alerts-api-production.up.railway.app/api/v2/reservations/pull?includeRelease=true`,
                {
                    headers: {
                        Authorization: 'a1d3b746-0702-4d5b-900e-313dbf9ab0a2',
                    },
                }
            )
            .then((res) => {
                setReservations(res.data.data)
            })
            .catch((err) => {
                console.log(err)
                toast.error(
                    'Failed to fetch reservations. See console for error log.'
                )
            })
            .finally(() => {
                setIsLoading(false)
            })
    }, [shouldRevalidate])

    return (
        <Container>
            <TopRow>
                <Title>Reservations</Title>
                <NewStoreButton onClick={() => setSelectedReservation(null)}>
                    New Reservation
                </NewStoreButton>
            </TopRow>
            <StoresContainer>
                <StoreCardsCol>
                    {isLoading ? (
                        <span style={{ textAlign: 'center' }}>Loading...</span>
                    ) : (
                        reservations?.map((reservation) => (
                            <ReservationCard
                                key={reservation.reservationRecordId}
                                isSelected={
                                    selectedReservation?.reservationRecordId ===
                                    reservation.reservationRecordId
                                }
                                reservation={reservation}
                                onDeleteClick={async () => {
                                    await toast.promise(
                                        axios.delete(
                                            `https://pro-alerts-api-production.up.railway.app/api/v2/reservations/delete`,
                                            {
                                                headers: {
                                                    Authorization:
                                                        'a1d3b746-0702-4d5b-900e-313dbf9ab0a2',
                                                },
                                                data: {
                                                    reservationId:
                                                        reservation.reservationRecordId,
                                                },
                                            }
                                        ),
                                        {
                                            loading: 'Deleting reservation...',
                                            success: 'Reservation deleted!',
                                            error: 'Failed to delete reservation',
                                        }
                                    )
                                    setShouldRevalidate((prev) => !prev)
                                }}
                                onEditClick={() => {}}
                                onSelect={() =>
                                    setSelectedReservation(reservation)
                                }
                            />
                        ))
                    )}
                </StoreCardsCol>
                <ReservationEditForm
                    isEditing={selectedReservation !== null}
                    editingReservation={selectedReservation}
                    shouldRevalidate={() =>
                        setShouldRevalidate((prev) => !prev)
                    }
                    shouldRevertToHomeView={() => setSelectedReservation(null)}
                />
                <StoreCardsFadeOutRegion />
            </StoresContainer>
        </Container>
    )
}

export default StaffReservationsPage
