import styled from "styled-components";
import { FormSelect } from "../../common/common";

export const Container = styled.div`
    width: 100%;
    max-width: 800px;

    > * + * {
        margin-top: 10px;
    }
`

export const FlexRowBetween = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const Heading = styled.h2`
    color: white;
    font-size: 24px;
    font-weight: 700;
`

export const Form = styled.form`
    width: 100%;
    padding: 30px;
    border-radius: 30px;
    box-shadow: 0px 20px 40px rgba(0,0,0, 0.15);
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.14) 0%, rgba(255, 255, 255, 0.07) 100%);

    > * + * {
        margin-top: 20px;
    }
`

export const Label = styled.label`
    color: rgba(255,255,255,.7);
    font-size: 14px;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
`

export const FlexRowBetweenWithSpace = styled.div`
    display: flex;
    justify-content: center;

    > * + * {
        margin-left: 20px;
    }

    > * {
        flex: 1;
    }
`


export const CustomSelect = styled(FormSelect)`
    height: 50px;
    width: 100px;
    padding: 10px;
`

export const PublisherContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;

    > * + * {
        margin-left: 20px;
    }
`