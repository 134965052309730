import dayjs from 'dayjs';
import { DropItemDate, Container, DropItemImgContainer, DropItemInfo, DropItemName, DropItemPrice, FlexRowBetween } from './DropItem.styled';
import { ReleaseItemProps } from './DropItem.types';

function DropItem({ imageSrc, name, releaseTime, retailPrice, handleClick }: ReleaseItemProps) {
    return (
        <Container onClick={handleClick}>
            <DropItemImgContainer>
                <img style={{ maxWidth: '100%' }} src={imageSrc} alt="" />
            </DropItemImgContainer>

            <DropItemInfo>
                <DropItemName>{name}</DropItemName>

                <FlexRowBetween>
                    <DropItemDate>{dayjs.unix(releaseTime).format('M-D-YY HH:mm A')}</DropItemDate>
                    <DropItemPrice>{retailPrice}</DropItemPrice>
                </FlexRowBetween>
            </DropItemInfo>
        </Container>
    )
}

export default DropItem