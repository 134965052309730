import React from 'react'
import styled from 'styled-components'

const Background = styled.div`
  position: relative;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 1.5px;
  height: 3px;
`

const Bar = styled.div<{ warning: boolean; progress: number }>`
  position: absolute;
  background: ${({ warning }) => (warning ? '#FF566F' : '#6e7cff')};
  border-radius: 1.5px;
  height: 3px;
  width: ${({ progress }) => progress}%;
`

interface ColorBarProps {
  progress: number
  warning: boolean
}

const ColorBar = ({ progress, warning }: ColorBarProps) => {
  return (
    <Background>
      <Bar warning={warning} progress={progress} />
    </Background>
  )
}

export default ColorBar
