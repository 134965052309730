import { useError } from 'src/hooks/useError'
import { useGetNotifications } from 'src/hooks/useGetNotifications'
import { notify } from 'src/utils/notify'
import NotificationCard, {
    NotificationCardSkeleton,
} from '../NotificationCard/NotificationCard'
import { Container } from './NotificationsList.styled'

const NotificationsList = () => {
    const { data, isLoading, error } = useGetNotifications(1)

    useError(error, (message: string) => notify(message, 'error'))

    return (
        <Container>
            {isLoading || !data
                ? Array.from({ length: 6 }, (_, i) => (
                      <NotificationCardSkeleton key={i} />
                  ))
                : data?.notifications.map((notification) => (
                      <NotificationCard
                          notification={notification}
                          key={notification.id}
                      />
                  ))}
        </Container>
    )
}

export default NotificationsList
