import {
    CardCvcElement,
    CardExpiryElement,
    CardNumberElement,
    useElements,
    useStripe,
} from '@stripe/react-stripe-js'
import { StripeCardNumberElement } from '@stripe/stripe-js'
import axios from 'axios'
import React, { useContext, useState } from 'react'
import { ReactComponent as Card } from '../../../assets/card.svg'
import { AuthContext, IAuthContext } from '../../../contexts/Auth'
import {
    CancelButton,
    Container,
    ErrorText,
    FlexRow,
    Form,
    Heading,
    Input,
    InputContainer,
    Label,
    SaveButton,
    Subheading,
    Success,
    Wrapper,
} from './CardForm.styled'
import { CardFormProps } from './CardForm.types'

const stripeStyle = {
    style: {
        base: {
            color: '#FFF',
            fontFamily: 'Prompt, sans-serif',
            fontWeight: '600',
            fontSize: '16px',
        },
    },
}

const CardForm = ({ toggleModal }: CardFormProps) => {
    const stripe = useStripe()
    const elements = useElements()

    const { setUserInfo } = useContext<IAuthContext>(AuthContext)
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<any>('')
    const [successMessage, setSuccessMessage] = useState('')

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        setErrorMessage('')

        if (isSubmitting) return

        if (!stripe || !elements) return

        setIsSubmitting(true)

        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(
                CardNumberElement
            ) as StripeCardNumberElement,
        })

        if (error) {
            setIsSubmitting(false)
            return setErrorMessage(error.message)
        } else {
            if (paymentMethod) {
                try {
                    const res = await axios.post(
                        '/api/v1/users/subscription/changeCard',
                        {
                            cardToken: paymentMethod.id,
                        },
                        { withCredentials: true }
                    )
                    setIsSubmitting(false)

                    if (res.status === 200) {
                        setUserInfo(res.data)
                    } else {
                        setErrorMessage(
                            `Something went wrong. Please try again.`
                        )
                    }
                    setSuccessMessage('Your card was successfully changed')
                    toggleModal()
                } catch (err: any) {
                    setIsSubmitting(false)

                    if (err.response && err.response.status === 402) {
                        setErrorMessage(err.response.data.message)
                    } else {
                        setErrorMessage(
                            `Something went wrong. Please try again.`
                        )
                    }
                }
                // toggleModal();
            }
        }
    }

    return (
        <Container>
            <Form onSubmit={handleSubmit}>
                <Wrapper>
                    <Card />
                    <Heading>Change Card</Heading>
                    <Subheading>
                        Please enter your new card details here
                    </Subheading>
                    {successMessage ? (
                        <Success>{successMessage}</Success>
                    ) : (
                        errorMessage && <ErrorText>{errorMessage}</ErrorText>
                    )}
                </Wrapper>

                <InputContainer>
                    <Label>Card Number</Label>
                    <CardNumberElement
                        className="stripe-input"
                        options={stripeStyle}
                    />
                </InputContainer>

                <FlexRow>
                    <InputContainer>
                        <Label>Expiration Date</Label>
                        <CardExpiryElement
                            className="stripe-input"
                            options={stripeStyle}
                        />
                    </InputContainer>
                    <InputContainer>
                        <Label>Security Code</Label>
                        <CardCvcElement
                            className="stripe-input"
                            options={stripeStyle}
                        />
                    </InputContainer>
                    <InputContainer>
                        <Label>ZIP Code</Label>
                        <Input placeholder="638" />
                    </InputContainer>
                </FlexRow>

                <SaveButton type="submit" disabled={!stripe || isSubmitting}>
                    Save Card
                </SaveButton>

                <CancelButton onClick={toggleModal}>Cancel</CancelButton>
            </Form>
        </Container>
    )
}

export default CardForm
