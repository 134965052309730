import { useState } from 'react'
import { Reservation } from 'src/pages/StaffReservationsPage'
import styled from 'styled-components'

const Container = styled.div<{ isSelected: boolean }>`
    height: 162px;
    box-sizing: border-box;
    padding: 20px;
    border: 1px solid
        ${(props) => (props.isSelected ? '#978bff' : 'transparent')};
    cursor: pointer;
    background: ${(props) => (props.isSelected ? '#3c3b3b' : 'none')};

    &:hover {
        background: ${(props) =>
            props.isSelected ? '#3c3b3b' : 'rgba(60, 59, 59, 0.5)'};
    }

    transition: 0.15s border ease-out;

    &:first-child {
        border-top-left-radius: 30px;
    }

    &:last-child {
        margin-bottom: 40px;
    }
`

const StoreInfoRow = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 15px;
`

const StoreImage = styled.img`
    border-radius: 15px;
    width: 60px;
    height: 60px;
    margin-right: 10px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: white;
`

const StoreInfoCol = styled.div`
    display: flex;
    flex-direction: column;
`

const StoreName = styled.span`
    font-size: 18px;
    font-weight: 600;
`

const StoreSubtitle = styled.span`
    font-size: 12px;
    color: rgba(255, 255, 255, 0.6);
`

const ButtonsRow = styled.div`
    display: flex;
    gap: 11px;
`

const EditButton = styled.span`
    flex: 1;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 30px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
    background: linear-gradient(
        180deg,
        rgba(127, 112, 255, 1) 0%,
        rgba(107, 90, 255, 1) 100%
    );
    font-size: 16px;
    font-weight: bold;

    &:hover {
        filter: brightness(1.25);
    }

    transition: 0.1s filter ease-out;
`

const DeleteButton = styled.span`
    flex: 1;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 30px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
    background: linear-gradient(
        180deg,
        rgba(235, 78, 85, 1) 0%,
        rgba(200, 62, 68, 1) 100%
    );
    font-size: 16px;
    font-weight: bold;

    &:hover {
        filter: brightness(1.25);
    }

    transition: 0.1s filter ease-out;
`

interface ReservationCardProps {
    reservation: Reservation
    isSelected: boolean
    onEditClick: () => void
    onDeleteClick: () => void
    onSelect: () => void
}

const ReservationCard = (props: ReservationCardProps) => {
    const [isConfirmingDelete, setIsConfirmingDelete] = useState(false)

    return (
        <Container
            isSelected={props.isSelected}
            onClick={props.onSelect}
            key={props.reservation.reservationRecordId}
        >
            <StoreInfoRow>
                <StoreImage
                    src={props.reservation.coverImage}
                    alt={props.reservation.releaseTitle}
                    width={60}
                    height={60}
                />
                <StoreInfoCol>
                    <StoreName>{props.reservation.releaseTitle}</StoreName>
                    <StoreSubtitle>
                        Rate: ${props.reservation.rate}
                    </StoreSubtitle>
                </StoreInfoCol>
            </StoreInfoRow>
            <ButtonsRow>
                <EditButton onClick={props.onEditClick}>Edit</EditButton>
                <DeleteButton
                    onClick={() => {
                        if (isConfirmingDelete) {
                            props.onDeleteClick()
                        } else {
                            setIsConfirmingDelete(true)
                            setTimeout(() => {
                                setIsConfirmingDelete(false)
                            }, 2000)
                        }
                    }}
                >
                    {isConfirmingDelete ? 'Confirm?' : 'Delete'}
                </DeleteButton>
            </ButtonsRow>
        </Container>
    )
}

export default ReservationCard
