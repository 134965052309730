import styled, { css } from 'styled-components'
import CustomDateTimePicker from './CustomDateTimePicker/CustomDateTimePicker'

export const CustomInput = styled.input`
    display: block;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 7px;
    padding: 7px 10px;
    color: white;
    border: none;
    border: 2px solid transparent;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 300ms;
    width: 100%;
    font-size: 16px;

    &:focus {
        outline: none;
        border-color: #c3c3c32e;
    }
`

export const FormContainer = styled.div`
    display: grid;
    gap: 10px;
    padding: 30px;
    width: 100%;
    height: fit-content;
    border-radius: 30px;
    background: rgba(255, 255, 255, 0.05);
    box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.15);
    grid-template-columns: repeat(2, minmax(0, 1fr));
`

const FormInputContainer = styled.div`
    display: flex;
    flex-direction: column;

    > * + * {
        margin-top: 5px;
    }
`

export const FormInputContainerLarge = styled(FormInputContainer)`
    grid-column: span 2 / span 2;
    position: relative;
`

export const FormInputContainerSmall = styled(FormInputContainer)`
    grid-column: span 1 / span 1;
`

export const FormLabel = styled.label`
    font-weight: 400;
    color: #ffffff90;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const FormInput = styled.input`
    color: #ffffff;
    font-weight: 600;
    font-size: 16px;
    padding: 15px 10px;
    border-radius: 15px;
    background-color: #ffffff10;
    border: 1px solid #ffffff10;
    outline: none;
    font-family: 'Prompt', sans-serif;
    width: 100%;

    &:focus {
        outline: none;
    }
`

export const FormDatePicker = styled(CustomDateTimePicker)`
    display: block;
    font-weight: 600;
    font-size: 16px;
    padding: 15px 10px;
    border-radius: 15px;
    background-color: #ffffff10;
    border: 1px solid #ffffff10;
    outline: none;
    font-family: 'Prompt', sans-serif;
    width: 100%;

    &:focus {
        outline: none;
        border-color: #c3c3c32e;
    }
`

export const FormTextArea = styled.textarea`
    color: #ffffff;
    font-weight: 600;
    font-size: 16px;
    padding: 15px 10px;
    border-radius: 15px;
    background-color: #ffffff10;
    border: 1px solid #ffffff10;
    height: 140px;
    width: 100%;
    outline: none;
    resize: none;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
`

export const FormSelect = styled.select`
    color: #ffffff;
    font-weight: 600;
    font-size: 16px;
    padding: 15px 10px;
    border-radius: 15px;
    background-color: #ffffff10;
    border: 1px solid #ffffff10;
    outline: none;
    font-family: 'Prompt', sans-serif;
    width: 100%;

    &:focus {
        outline: none;
    }
`

export const HollowButton = styled.button`
    color: #ffffff;
    font-weight: 600;
    font-size: 16px;
    padding: 15px 25px;
    border-radius: 15px;
    background-color: #ffffff10;
    border: 1px solid #ffffff10;
    outline: none;
    font-family: 'Prompt', sans-serif;
    cursor: pointer;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 300ms;
    grid-column: span 1 / span 4;

    &:disabled {
        cursor: not-allowed;
    }

    &:hover {
        transform: translateY(-0.15rem);
    }
`

export const SubscriptionsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    padding-top: 40px;

    @media only screen and (max-width: 768px) {
        padding: 100px 20px 0px 20px;
    }
`

const Alert = styled.p`
    border-radius: 10px;
    white-space: pre;
    padding: 10px;
    margin-bottom: 10px;
    color: white;
    font-weight: bold;
    text-align: center;
    grid-column: span 2 / span 2;
`

export const ErrorAlert = styled(Alert)`
    background: #ff3c4f;
`

export const SuccessAlert = styled(Alert)`
    background: #20bd6f;
`

const SquaredButton = styled.div`
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    color: white;
    border: 0px;
    cursor: pointer;
    margin-top: 20px;
    padding: 10px 0;
`

export const PurpleSquaredButton = styled(SquaredButton)`
    background: linear-gradient(180deg, #6e7cff 0%, #5b69f1 100%);
`

export const FlexRow = styled.div`
    display: flex;
`

export const FlexRowBetween = styled(FlexRow)`
    justify-content: space-between;
`

export const FlexRowBetweenAlign = styled(FlexRowBetween)`
    align-items: center;
`

export const FlexCol = styled.div`
    display: flex;
    flex-direction: column;
`

export const BlueText = styled.p`
    color: #00a4ff;
    font-weight: 600;
    cursor: pointer;
`

export const shimmer = css`
    overflow: hidden;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        inset: 0px;
        transform: translateX(-100%);
        animation: shimmer 1.5s infinite;
        background: linear-gradient(
            to right,
            transparent,
            #ffffff10,
            transparent
        );
    }

    @keyframes shimmer {
        to {
            transform: translateX(100%);
        }
    }
`
