import styled from "styled-components";


export const Container = styled.div`
    width: 100%;
    max-width: 500px;
    border-radius: 30px;
    background-color: #1D1D1D;
`

export const Form = styled.form`
    width: 100%;
    padding: 30px;

    > * + * {
        margin-top: 20px;
    }
`

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    > * + * {
        margin-top: 15px;
    }
`

export const Heading = styled.h3`
    margin: 0;
    font-weight: 700;
    font-size: 24px;
`

export const Subheading = styled.h4`
    margin: 0;
    font-weight: 400;
    font-size: 16px;
    color: #FFFFFF70;
`

export const InputContainer = styled.div`
    flex: 1;

    > * + * {
        margin-top: 5px;
    }
`

export const Label = styled.label`
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF70;
`

export const Input = styled.input`
    width: 100%;
    border: none;
    display: block;
    padding: 13px 11px;
    border-radius: 15px;
    font-weight: 600;
    color: white;
    border: 1px solid #FFFFFF10;
    background-color: #282828;
    font-family: 'Prompt', sans-serif;
    font-size: 16px;

    &::placeholder {
        color: #FFFFFF40;
    }

    &:focus {
        outline: none;
    }
`

export const FlexRow = styled.div`
    display: flex;
    align-items: center;

    > * + * {
        margin-left: 10px;
    }
`

export const SaveButton = styled.button`
    color: white;
    font-weight: 700;
    font-size: 18px;
    text-align: center;
    padding: 15px 0;
    width: 100%;
    display: block;
    border-radius: 9999px;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 300ms;
    background: linear-gradient(to bottom, #818DFF, #6E7CFF);
    cursor: pointer;

    &:hover {
        transform: translateY(-0.1rem);
    }

    &:disabled {
        cursor: not-allowed;
    }
`

export const CancelButton = styled.p`
    font-weight: 600;
    font-size: 18px;
    color: #FFFFFF80;
    text-align: center;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 300ms;
    cursor: pointer;

    &:hover {
        color: white;
    }
`

export const ErrorText = styled.p`
    color: red;
    text-align: center;
`

export const Success = styled.p`
    color: green;
`