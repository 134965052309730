import styled from 'styled-components'

export const SearchBarContainer = styled.div`
    position: relative;
    width: 100%;
    z-index: 10;
`

export const Input = styled.input`
    color: #ffffff;
    font-weight: 600;
    font-size: 16px;
    padding: 15px 10px;
    border-radius: 15px;
    background-color: #ffffff10;
    border: 1px solid #ffffff10;
    outline: none;
    font-family: 'Prompt', sans-serif;
    width: 100%;

    &:focus {
        outline: none;
    }
`
