import styled from 'styled-components';
import { ReactComponent as LeftArrow } from '../../../assets/arrow-left.svg';
import { ReactComponent as RightArrow } from '../../../assets/arrow-right.svg';
import { ReactComponent as Plus } from '../../../assets/plus.svg';

export const FlexRow = styled.div`
    display: flex;
`

const FlexRowBetween = styled(FlexRow)`
    justify-content: space-between;
`

export const FlexRowAlignBetween = styled(FlexRowBetween)`
    align-items: center;
`

export const Container = styled.div`
    
    > * + * {
        margin-top: 35px;
    }
    
    @media only screen and (max-width: 768px) {
        width: 100%;
        max-width: 450px;
        margin: auto 0;
        padding: 20px;
    }
`

export const LargeLeftArrow = styled(LeftArrow)<{ isDisabled?: boolean; }>`
    color: ${({ isDisabled }) => isDisabled ? '#FFFFFF30' : '#FFFFFF80'};
    cursor: ${({ isDisabled }) => isDisabled ? 'not-allowed' : 'pointer'};
    margin-right: 20px;

    @media only screen and (max-width: 768px) {
        display: none;
    }
`

export const LargeRightArrow = styled(RightArrow)<{ isDisabled?: boolean; }>`
    color: ${({ isDisabled }) => isDisabled ? '#FFFFFF30' : '#FFFFFF80'};
    cursor: ${({ isDisabled }) => isDisabled ? 'not-allowed' : 'pointer'};

    @media only screen and (max-width: 768px) {
        display: none;
    }
`

export const Cross = styled(Plus)`
    color: #FFFFFF80;
    cursor: pointer;
    display: none;
    transform: rotate(45deg);

    @media only screen and (max-width: 768px) {
        display: block;
    }
`

export const HeaderContainer = styled(FlexRowBetween)`
    align-items: center;
`

export const TitleContainer = styled(FlexRow)`
    align-items: center; 
`

export const LightBoldText = styled.p`
    font-weight: 500;
    font-size: 14px;
    color: #FFFFFF50;
`
export const ReleaseName = styled.h1`
    color: white;
    font-size: 30px;
    font-weight: bold;
`

export const InfoContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    > * + * {
        margin-left: 40px;
    }

    @media only screen and (max-width: 768px) {
        flex-direction: column;
        > * + * {
            margin-left: 0px;
            margin-top: 40px;
        }
    }
`

export const ImageContainer = styled.div`
    background-color: white;
    border-radius: 20px;
    overflow: hidden;
    flex: 1;
    max-height: 280px;
`

export const ImageWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`

export const Image = styled.img`
    max-width: 450px;
`

export const PriceInfoContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    max-width: 260px;
    
    > * + * {
        margin-top: 20px;
    }

    @media only screen and (max-width: 768px) {
        max-width: 500px;
        flex-direction: row;

        > * + * {
            margin-top: 0px;
            margin-left: 20px;
        }
    }
`

export const MonetaryHeading = styled.h3`
    margin: 0px;
    font-size: 30px;
    color: white;
    font-weight: bold;
`

const MonetaryItem = styled.div`
    padding: 15px 20px;
    border-radius: 15px;
    flex: 1;

    > * + * {
        margin-top: 15px;
    }
`

export const MonetaryInfo = styled(FlexRow)`
    font-size: 10px;
    color: #FFFFFF50;

    > * + * {
        margin-left: 5px;
    }

    @media only screen and (max-width: 768px) {
        display: none;
    }
`

export const PriceItem = styled(MonetaryItem)<{ doesHaveResell: boolean; willBreakEven: boolean; }>`
    border: ${({ doesHaveResell, willBreakEven }) => willBreakEven ? '1px solid #FFFF00' : doesHaveResell ? '1px solid #43EA80' : '1px solid #e23c4c' };
    background: ${({ doesHaveResell, willBreakEven }) => willBreakEven ? '#FFFF0020' :  doesHaveResell ? 'rgba(67,234,128,0.15)' : '#e23c4c40'};

`

export const ResellItem = styled(MonetaryItem)`
    background: #FFFFFF10;
    border: 1px solid #FFFFFF10;
`

export const ReleaseInfo = styled.p`
    color: #FFFFFF80;
    font-weight: 500;

    @media only screen and (max-width: 768px) {
        color: #FFFFFF;
    }
`

export const ReleaseTypesContainer = styled.div`
    display: flex;
    flex-direction: row;
    
    > * + * {
        margin-left: 20px;
    }

    @media only screen and (max-width: 768px) {
        flex-direction: column;
        > * + * {
            margin-left: 0px;
            margin-top: 20px;
        }
    }
`

export const ReleaseType = styled.div`
    flex: 1;

    > * + * {
        margin-top: 10px;
    }
`
export const ReleaseTypeHeading = styled.h3`
    color: white;
    margin-top: 0px;
    font-size: 20px;
    font-weight: 700;
`

export const ReleaseItem = styled.div`
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #FFFFFF10;
    border-radius: 15px;
`

export const ReleaseWebsiteName = styled.h4`
    color: white;
    font-weight: 700;
    font-size: 14px;
`

export const ReleaseDate = styled.p`
    font-weight: 400;
    font-size: 12px;
    color: #FFFFFF60;
`

export const SVGContainer = styled(FlexRowBetween)`
    > * + * {
        margin-left: 5px;
    }
`

export const SVGWrapper = styled.div<{ releaseType: 'raffle' | 'drop' }>`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    border-radius: 12px;
    background-color: #FFFFFF10;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;

    &:hover {
        background-color: ${({ releaseType }) => releaseType === 'raffle' ? '#F45C92' : '#32D86F'};
    }
`