import proAlertsAPI from 'src/constants/proAlertsAPI'
import axios from 'axios'

const AUTH_KEY = process.env.REACT_APP_AUTH_KEY as string

export const axiosInstance = axios.create()
export const axiosInstanceV2 = axios.create()

axiosInstance.defaults.baseURL = proAlertsAPI
axiosInstance.defaults.headers.common['Authorization'] = AUTH_KEY
axiosInstance.defaults.headers.common['Content-Type'] = 'application/json'

axiosInstanceV2.defaults.baseURL =
    'https://pro-alerts-api-production.up.railway.app/api/v2'
axiosInstanceV2.defaults.headers.common['Authorization'] = AUTH_KEY
axiosInstanceV2.defaults.headers.common['Content-Type'] = 'application/json'
