import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  max-width: 500px;
  margin: auto 0;
  padding: 80px 0px;
  @media only screen and (max-width: 640px) {
    padding: 80px 10px;
  }
`

export const Container = styled.div`
  position: relative;
  width: 100%;
  padding: 70px 30px 30px 30px;
  border-radius: 30px;
  background: #1D1D1D;
  box-shadow: 0 30px 60px 0 #000000;
`

export const CardInfoContainer = styled.div`
  width: 100%;

  > * + * {
    margin-top: 25px;
  }
`

export const HeadingContainer = styled.div`
  text-align: center;
`

export const Heading = styled.h2`
  color: white;
  font-weight: 700;
  font-size: 20px;
`

export const InfoHeading = styled.h3`
  color: #FFFFFF;
  font-weight: 600;
  font-size: 18px;
  cursor: pointer;
`

export const CancelContainer = styled.div`
  text-align: center;
`