import styled from 'styled-components';

export const FlexRow = styled.div`
    display: flex;
`

export const FlexRowBetween = styled(FlexRow)`
    justify-content: space-between;
`

export const Container = styled(FlexRow)`
    padding: 10px;
    cursor: pointer;
`

export const DropItemImgContainer = styled.div`
    background: white;
    border-radius: 10px;
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
`

export const DropItemInfo = styled.div`
    flex: 1;
    margin-left: 10px;
`

export const DropItemName = styled.p`
    font-weight: 500;
    color: #FFFFFF;
`

export const DropItemDate = styled.p`
    font-size: 12px;
    color: #FFFFFF95;
`

export const DropItemPrice = styled.p`
    font-size: 12px;
    color: #43EA80;
`