import { ReactComponent as DiscordIcon } from '../../../assets/discord.svg';
import { ReactComponent as DownloadIcon } from '../../../assets/download.svg';
import { ButtonsContainer, Container, DiscordButton, DownloadButton, Heading, Text } from './SuccessCard.styled';
import { SuccessCardProps } from './SuccessCard.types';
import { TailSpin } from 'react-loading-icons'

function SuccessCard({ message, joinDiscord, sendInvite, isSubmitting: { discord, download } }: SuccessCardProps) {
    return (
        <Container>
            <Heading>Purchase Successful!</Heading>

            { 
                message && (
                    message
                        .split('\n')
                        .filter(line => !!line.trim())
                        .map(line => <Text key={line}>{line}</Text>)
                )
            }

            <ButtonsContainer>
                <DiscordButton onClick={joinDiscord}>
                    {
                        discord ? (
                            <TailSpin /> 
                        ) : (
                            <>
                            <DiscordIcon />
                            <span>Join Discord</span>
                            </>
                        )
                    }
                </DiscordButton>

                <DownloadButton onClick={sendInvite}>
                    {
                        download ? (
                            <TailSpin /> 
                        ) : (
                            <>
                            <DownloadIcon />
                            <span>Download</span>
                            </>
                        )
                    }
                </DownloadButton>
            </ButtonsContainer>
        </Container>
    )
}

export default SuccessCard

