import React from 'react'
import styled from 'styled-components'
import logo from '../assets/footer-logo.svg'

import twitterIcon from '../assets/twitter-icon.svg'
import instagramIcon from '../assets/instagram-icon.svg'
import youtubeIcon from '../assets/youtube-icon.svg'

const Container = styled.div`
  background: rgba(0, 0, 0, 0.2);
  padding: 100px 0;
  margin-top: 9.5rem;
`

const ContentWrapper = styled.div`
  width: 1240px;
  margin: 0 auto;
  max-width: 90vw;
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 900px) {
    flex-direction: column;
  }
`

const Col = styled.div`
  display: flex;
  flex-direction: column;
`

const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`

const Logo = styled.img`
  margin-right: 10px;
`

const Heading = styled.span`
  font-weight: bold;
  font-size: 38px;
`

const Title = styled.span`
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 5px;
`

const SectionTitle = styled.span`
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 15px;
`

const Text = styled.div`
  color: #828493;
  width: 300px;
`

const Link = styled.a`
  color: #828493;
  font-weight: 600;
  text-decoration: none;
  margin-bottom: 5px;
`

const Email = styled(Link)`
  color: #ff425e;
  font-weight: 500;
  margin-bottom: 15px;
`

const SocialLink = styled.a`
  margin-right: 10px;

  &:nth-last-child(1) {
    margin-right: 0;
  }
`

const Footer = () => {
  return (
    <Container>
      <ContentWrapper>
        <Col>
          <Row>
            <Logo src={logo} alt="SwiftSole" />
            <Heading>SwiftSole</Heading>
          </Row>
          <Title></Title>
          <Text>© 2022 SwiftSole Development LLC. All rights reserved.</Text>
        </Col>
        <Col>
          <SectionTitle>Products</SectionTitle>
          <Link href="https://swiftsole.app/#products" target="_blank">SwiftSole V3</Link>
          <Link href="https://swiftsole.app/#products" target="_blank">SwiftSole Desktop</Link>
        </Col>
        <Col>
          <SectionTitle>Information</SectionTitle>
          <Link href="https://swiftsole.app/#faq" target="_blank">FAQ</Link>
          <Link href="https://twitter.com/swiftsoleios" target="_blank">Success</Link>
        </Col>
        <Col>
          <SectionTitle>Help</SectionTitle>
          <Link href="https://dash.swiftsole.app/tos" target="_blank">Terms of Service</Link>
          <Link href="https://dash.swiftsole.app/privacy-policy" target="_blank">Privacy Policy</Link>
          <Link href="https://dash.swiftsole.app/cookie-policy" target="_blank">Cookie Policy</Link>
          <Link href="https://forms.gle/5dswLzQjHF8e16fN6" target="_blank">Bulk Discounts</Link>
        </Col>
        <Col>
          <SectionTitle>Contact Us</SectionTitle>
          <Email href="mailto:support@swiftsole.app">
            support@swiftsole.app
          </Email>
          <Row>
            <SocialLink href="https://twitter.com/swiftsoleios" target="_blank">
              <img src={twitterIcon} alt="Twitter" />
            </SocialLink>
            <SocialLink href="https://www.instagram.com/swiftsole/" target="_blank">
              <img src={instagramIcon} alt="Instagram" />
            </SocialLink>
            <SocialLink href="https://www.youtube.com/channel/UCSp8P9WQPD2NOo-tTMd5Few" target="_blank">
              <img src={youtubeIcon} alt="YouTube" />
            </SocialLink>
          </Row>
        </Col>
      </ContentWrapper>
    </Container>
  )
}

export default Footer
